import * as React from "react";
import cn from "clsx";
import UnDeleteIcon from "@material-ui/icons/RestoreFromTrash";
import Swal from "sweetalert2";

import { Chip } from "../../../../Helpers/Chip/Chip";
import {
  useImageReviewerDispatch,
  useImageReviewerState,
  useImageReviewerRefresh,
  useImageReviewerResponse,
} from "../../contexts/ImageReviewerContext/ImageReviewerContext";
import { useLazyApi } from "../../../../../hooks/useApi";

const getActionTitle = (imageCount) => {
  return imageCount > 1 ? "UnDelete selected images" : "UnDelete selected image";
};

export const ImageReviewerUnDeleteAction = React.memo(({ className }) => {
  const { selectedImages, selectedImagesById } = useImageReviewerState();
  const dispatch = useImageReviewerDispatch();
  const refresh = useImageReviewerRefresh();
  const { data } = useImageReviewerResponse();

  const [executeCall] = useLazyApi("/api/v1/image_review/images/undelete", {
    method: "PATCH",
  });

  const disabled = selectedImages.length === 0;

  const onUnDelete = React.useCallback(async () => {
    const { isConfirmed } = await new Swal({
      title: "Are you sure you want to undo the deletion of these images?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, restore it!",
      cancelButtonText: "No, cancel!",
    });

    if (isConfirmed) {
      try {
        // Count how many of the images we're removing were not reviewed
        const deletedUnreviewedImagesCount = data.reduce((reducer, page) => {
          const count = page.probeImages.reduce((r, img) => {
            // If the selected image hasn't been reviewed
            if (selectedImagesById[img.id] && !img.reviewed) {
              return r + 1;
            }

            return r;
          }, 0);

          return reducer + count;
        }, 0);

        // Optimistic update of the UI
        refresh(
          data.map((page) => {
            return {
              ...page,
              totalImageCount: page.totalImageCount - selectedImages.length,
              unreviewedImageCount:
                page.unreviewedImageCount - deletedUnreviewedImagesCount,
              probeImages: page.probeImages.filter((img) => {
                return !selectedImages.includes(img.id);
              }),
            };
          }),
          false
        );

        await executeCall({
          data: {
            probe_image_ids: selectedImages,
          },
        });

        // If everything went right we'll remove the image selection.
        dispatch({
          type: "image-delete-success",
        });
      } catch (e) {
        new Swal({
          title: "Something went wrong",
          html:
            "We're sorry but we couldn't complete your request. Please try again and if the problem persists let us know.",
          icon: "error",
        });

        // If the request failed we'll simply refetch the collection
        // and the items that couldn't be removed will re-appear
        refresh();
      }
    }
  }, [
    data,
    dispatch,
    executeCall,
    refresh,
    selectedImages,
    selectedImagesById,
  ]);

  return (
    <Chip
      title={getActionTitle(selectedImages.length)}
      disabled={disabled}
      onClick={onUnDelete}
      className={cn(className)}
    >
      <UnDeleteIcon />
    </Chip>
  );
});

ImageReviewerUnDeleteAction.displayName = "ImageReviewerUnDeleteAction";
