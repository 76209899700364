import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Typography } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../../../../Helpers/Accordion/Accordion";
import { Shelf } from "../../../../Helpers/Shelf/Shelf";
import { ShowImageReviewerShelfSummary } from "./ShowImageReviewerShelfSummary";
import { ShowImageReviewerShelfAppliedStressItems } from "./ShowImageReviewerShelfAppliedStressItems";
import { ShowImageReviewerShelfCrop } from "./ShowImageReviewerShelfCrop";
import { ShowImageReviewerShelfStressItems } from "./ShowImageReviewerShelfStressItems";
import {
  useImageReviewerDispatch,
  useImageReviewerState,
} from "../../contexts/ImageReviewerContext/ImageReviewerContext";
import { Button } from "@material-ui/core";
import { useSaveSelectionImageReview } from "../../hooks/useSaveSelectionImageReview";

const useShelfStyles = makeStyles((theme) => ({
  textArea: {
    transition: `.15s ease-in`,
    outline: "0",
    boxShadow: "none",
    border: "none",
    borderBottom: `1px solid ${theme.palette.text.primary}`,
    fontFamily: theme.typography.fontFamily,
    borderRadius: "0px",
    boxSizing: `content-box`,
    backgroundColor: `transparent`,
    color: theme.palette.text.primary,
    padding: `1.5rem 0`,
    resize: `none`,
    width: "100%",
    height: "7rem",
    overflowY: "auto",
  },
  saveContainer: {
    padding: "7px 21px",
    fontSize: "0.9375rem",
  },
  saveButton: {
    width: `100%`,
    marginBottom: '1rem',
  },
  saveButton__saved: {
    width: "100%",
    border: `3px solid #2ecc71`,
    backgroundColor: `#2ecc71`,
    color: `#fff`,
    transition: "0.25s all",
    marginBottom: '1rem',
  },
  shelfContainer: {
    "& > div:first-of-type": {
      overflowY: "auto",
      maxHeight: "100%",
      height: "100%",
    },
  },
  switch: {
    marginLeft: "2rem",
  },
  formControlLabel: {
    fontSize: '1rem',
    '& label': { fontSize: '1rem' }
  },
}));

export const ShowImageReviewerShelf = () => {
  const classes = useShelfStyles();
  const { selectedImages, selectedImagesById, thumbnailImages } = useImageReviewerState();
  const [selectedImageId, setSelectedImageId] = React.useState("");
  const lastSelectedImage = React.useMemo(
    () => selectedImagesById[selectedImages[selectedImages.length - 1]],
    [selectedImages, selectedImagesById]
  );

  React.useEffect(() => {
    if (lastSelectedImage?.id !== selectedImageId) setSavedSuccessfully(false);
    setSelectedImageId(lastSelectedImage?.id);
    // eslint-disable-next-line
  }, [lastSelectedImage]);
  const dispatch = useImageReviewerDispatch();
  const handleNoteChange = (event) => {
    dispatch({
      type: "set-notes",
      payload: { ...lastSelectedImage, notes: event.target.value },
    });
  };

  const onSave = useSaveSelectionImageReview();
  const [savedSuccessfully, setSavedSuccessfully] = React.useState(false);
  const [savedPublishedSuccessfully, setSavedPublishedSuccessfully] = React.useState(false);

  const [isSaving, setIsSaving] = React.useState(false);
  const [isSavingPublishing, setIsSavingPublishing] = React.useState(false);
  const [advanceToNextImage, setAdvanceToNextImage] = React.useState(true);

  function goToNextImage() {
    var nextImage;
    const groups = Object.values(thumbnailImages).length;
    for (let group = 0; group < groups; group++) {
      // for each group, check to see if this image is in it.
      const nextIndex = Object.values(Object.values(thumbnailImages)[group])[0].findIndex((o) => { return o.id === selectedImageId }) + 1;
      if (nextIndex > 0) {
        // found it, so in this group
        const groupSize = Object.values(Object.values(thumbnailImages)[group])[0].length;
        if (nextIndex >= groupSize) {
          // but next image is past the end of this group
          // so check if we are the last group
          if (group === groups - 1) {
            // we are the last group so stay put here
            nextImage = Object.values(Object.values(thumbnailImages)[group])[0][nextIndex - 1];
            break;
          } else {
            // not the last group so we go to next group and get the first item, there
            nextImage = Object.values(Object.values(thumbnailImages)[group + 1])[0][0];
            break;
          }
        } else {
          // get next item in this group
          nextImage = Object.values(Object.values(thumbnailImages)[group])[0][nextIndex];
          break;
        }
      }
      // here we did not find the image, yet, so continue to loop to search for it
    }

    dispatch({
      type: "image-press",
      payload: {
        ...nextImage,
        id: nextImage.id,
        action: "select",
      },
    });
  }

  return (
    <Shelf open={false} className={classes.shelfContainer}>
      {/* ImageReviewerCropsStressItemsSelectionProvider is now rendered as a parent of this component. */}
      <ShowImageReviewerShelfContent>
        <Accordion>
          <AccordionSummary>Summary</AccordionSummary>
          <AccordionDetails>
            <ShowImageReviewerShelfSummary />
          </AccordionDetails>
        </Accordion>

        <Accordion TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary>Crop</AccordionSummary>
          <AccordionDetails>
            <ShowImageReviewerShelfCrop />
          </AccordionDetails>
        </Accordion>

        <Accordion TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary>Stress Items</AccordionSummary>
          <AccordionDetails>
            <ShowImageReviewerShelfStressItems
              mode={
                lastSelectedImage &&
                  !!lastSelectedImage.agronomyReviewStressItems
                  ? "rereview"
                  : undefined
              }
            />
          </AccordionDetails>
        </Accordion>

        <Accordion TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary>Notes</AccordionSummary>
          <AccordionDetails>
            <textarea
              className={classes.textArea}
              placeholder={`Enter notes about the image..`}
              value={lastSelectedImage?.notes ?? ""}
              onChange={handleNoteChange}
            />
          </AccordionDetails>
        </Accordion>

        <div className={classes.stressItemsContainer}>
          <ShowImageReviewerShelfAppliedStressItems />
        </div>

        <FormControlLabel
          control={
            <Switch
              className={classes.switch}
              checked={advanceToNextImage}
              onChange={() => {
                setAdvanceToNextImage(!advanceToNextImage);
              }}
              name="advanceToNextImage"
              color="primary"
            />
          }
          label={<Typography className={classes.formControlLabel}>Advance to next image on save</Typography>}
        />

        <div className={classes.saveContainer}>
          <Button
            className={
              savedSuccessfully ? classes.saveButton__saved : classes.saveButton
            }
            onClick={() => {
              setIsSaving(true);
              onSave()
                .then((isSuccessful) => setSavedSuccessfully(isSuccessful))
                .finally(() => {
                  if (advanceToNextImage) goToNextImage()
                  // regardless if save was successful or not turn it back off
                  setIsSaving(false);
                  setTimeout(() => setSavedSuccessfully(false), 2500);
                });
            }}
            variant="outlined"
            size="large"
          >
            {!!isSaving && (
              <>
                Saving &nbsp; <i className="fas fa-spinner fa-spin"></i>
              </>
            )}
            {!isSaving && (
              <>
                {savedSuccessfully ? `Saved` : `Save`}{" "}
                {savedSuccessfully ? (
                  <span style={{ color: "#fff", fontSize: 18 }}>✓</span>
                ) : null}{" "}
              </>
            )}
          </Button>
          <Button
            className={
              savedPublishedSuccessfully ? classes.saveButton__saved : classes.saveButton
            }
            onClick={() => {
              setIsSavingPublishing(true);
              onSave(true)
                .then((isSuccessful) => setSavedPublishedSuccessfully(isSuccessful))
                .finally(() => {
                  goToNextImage()
                  // regardless if save was successful or not turn it back off
                  setIsSavingPublishing(false);
                  setTimeout(() => setSavedPublishedSuccessfully(false), 2500);
                });
            }}
            variant="outlined"
            size="large"
          >
            {!!isSavingPublishing && (
              <>
                Saving & Publishing &nbsp; <i className="fas fa-spinner fa-spin"></i>
              </>
            )}
            {!isSavingPublishing && (
              <>
                {savedPublishedSuccessfully ? `Saved & Published` : `Save & Publish`}{" "}
                {savedPublishedSuccessfully ? (
                  <span style={{ color: "#fff", fontSize: 18 }}>✓</span>
                ) : null}{" "}
              </>
            )}
          </Button>
        </div>
      </ShowImageReviewerShelfContent>
    </Shelf>
  );
};

const useContentStyles = makeStyles(() => ({
  ShowImageReviewerShelfContent: {
    alignSelf: "baseline",
    display: "grid",
    gridTemplateRows: "80px 1fr",
    width: "100%",
  },
  ShowImageReviewerShelfTitle: {
    display: "flex",
    alignItems: "center",
    padding: "0 16px",
  },
}));

const ShowImageReviewerShelfContent = ({ title = "Review Notebook", children }) => {
  const classes = useContentStyles();

  return (
    <div className={classes.ShowImageReviewerShelfContent}>
      <div className={classes.ShowImageReviewerShelfTitle}>
        {/* TODO: replace this with Typography once the styles are ready */}
        <h2>{title}</h2>
      </div>

      <div>{children}</div>
    </div>
  );
};
