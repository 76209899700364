import React from "react";
import {
    MapProvider,
    ThemeProvider,
    AuthProvider
} from "../globalState";

const ProviderComposer = ({ contexts, children }) => {
    return contexts.reduceRight(
        (kids, parent) =>
            React.cloneElement(parent, {
                children: kids,
            }),
        children
    );
};

const ContextProvider = ({ children }) => {
    return (
        <ProviderComposer
            contexts={[
                <MapProvider />,
                <ThemeProvider />,
                <AuthProvider />
            ]}
        >
            {children}
        </ProviderComposer>
    );
};

export { ContextProvider };
