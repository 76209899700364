import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Input } from "../../Helpers/Input/Input";
import { Flex } from "../../Layouts/Flex";
import { Button } from "../../Helpers";
import Swal from "sweetalert2";
import { createUser } from "../../../controllers/auth";
import styles from "./Home.module.scss";
import { ACTIONS } from "./Home";
import cn from "clsx";
import { AuthContext } from "../../../globalState";

export const SignUpForm = ({ userData, dispatch }) => {
  // eslint-disable-next-line
  const history = useHistory();
  const { setToken, setUserId } = useContext(AuthContext);

  const createAccount = async (e) => {
    e.preventDefault();
    if (userData.password === userData.passwordConfirmation) {
      await createUser(userData)
        .then(async (res) => {
          if (res.success) {
            document.cookie = `authToken=${res.data.token}`;
            document.cookie = `userId=${res.data.id}`;
            setUserId(res.data.id);
            setToken(res.data.token);
            history.push("/farms");
          } else {
            alert();
          }
        })
        .catch(async () => {
          alert();
        });
    } else {
      await Swal.fire({
        icon: "error",
        title: "Passwords don't match",
        text: "Please confirm that you are entering the same password.",
      });
    }
  };

  const alert = async () => {
    await Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "An error occured while creating your account.",
    });
  };

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className={styles.SignUpForm}
    >
      <h1>Create Free Account</h1>
      <form className={styles.Form} onSubmit={(e) => createAccount(e)}>
        <Flex className={styles.FormRow}>
          <Input
            type="text"
            placeholder="Farm Title *"
            value={userData.farmTitle}
            onChange={(e) =>
              dispatch({
                type: ACTIONS.UPDATE_FIELD,
                payload: { field: "farmTitle", value: e.target.value },
              })
            }
            required
          />
        </Flex>
        <Flex justifyContent="space-between" className={styles.FormRow}>
          <Input
            type="text"
            placeholder="First Name *"
            value={userData.firstName}
            onChange={(e) =>
              dispatch({
                type: ACTIONS.UPDATE_FIELD,
                payload: { field: "firstName", value: e.target.value },
              })
            }
            autofocus
            required
            className={styles.NameInput}
          />
          <Input
            type="text"
            placeholder="Last Name *"
            value={userData.lastName}
            onChange={(e) =>
              dispatch({
                type: ACTIONS.UPDATE_FIELD,
                payload: { field: "lastName", value: e.target.value },
              })
            }
            autofocus
            required
            className={styles.NameInput}
          />
        </Flex>
        <Flex className={cn(styles.FormRow, styles.EmailAddressRow)}>
          <Input
            type="email"
            placeholder="Email Address *"
            value={userData.email}
            onChange={(e) =>
              dispatch({
                type: ACTIONS.UPDATE_FIELD,
                payload: { field: "email", value: e.target.value },
              })
            }
            autofocus
            required
          />
        </Flex>
        <Flex className={styles.FormRow}>
          <span className={styles.PolicySpan}>(Privacy Policy)</span>
        </Flex>
        <Flex justifyContent="space-between" className={styles.FormRow}>
          <Input
            type="password"
            placeholder="Password *"
            value={userData.password}
            onChange={(e) =>
              dispatch({
                type: ACTIONS.UPDATE_FIELD,
                payload: { field: "password", value: e.target.value },
              })
            }
            autofocus
            required
            className={styles.PasswordInput}
            minlength="8"
          />
          <Input
            type="password"
            placeholder="Confirm *"
            value={userData.passwordConfirmation}
            onChange={(e) =>
              dispatch({
                type: ACTIONS.UPDATE_FIELD,
                payload: {
                  field: "passwordConfirmation",
                  value: e.target.value,
                },
              })
            }
            autofocus
            required
            className={styles.PasswordInput}
            minlength="8"
          />
        </Flex>
        <Button className={styles.SignInBtn} type="submit" text="Sign Up" />
      </form>
    </Flex>
  );
};
