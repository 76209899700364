import * as React from "react";
import cn from "clsx";
import GetAppIcon from "@material-ui/icons/GetApp";

import { useImageReviewerState } from "../../contexts/ImageReviewerContext/ImageReviewerContext";
import { Chip } from "../../../../Helpers/Chip/Chip";

export const ImageReviewerDownloadAction = ({ className }) => {
  const { selectedImages, selectedImagesById } = useImageReviewerState();

  const disabled = selectedImages.length !== 1;

  const Comp = disabled ? React.Fragment : "a";

  const compProps = React.useMemo(() => {
    if (disabled) {
      return {};
    }
    const selectedImage = selectedImagesById[selectedImages[0]];
    return {
      href: selectedImage.imagePath,
      download: selectedImage.fileName,
    };
  }, [disabled, selectedImages, selectedImagesById]);

  return (
    <Chip title="Download image" disabled={disabled} className={cn(className)}>
      <Comp {...compProps}>
        <GetAppIcon />
      </Comp>
    </Chip>
  );
};
