import React from "react";
import { makeStyles, Link as MuiLink } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom/cjs/react-router-dom.min";

const useLinkStyles = makeStyles((theme) => ({
  underlineHover: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

export const InternalLink = ({ to, children, color, onClick }) => {
  const classes = useLinkStyles();
  return (
    <MuiLink
      component={RouterLink}
      to={to}
      classes={classes}
      color={color || "textPrimary"}
      onClick={onClick}
    >
      {children}
    </MuiLink>
  );
};
