import * as React from "react";

// Holds the filters to be applied when performing API requests to our
// backend (favorited, healthy, etc)
const ImageReviewerFiltersStateContext = React.createContext();
const ImageReviewerFiltersDispatchContext = React.createContext(() => {});

const reducer = (state, action) => {
  switch (action.type) {
    case "filter-apply": {
      const {
        type: filterType,
        group: filterGroup,
        name: filterName,
      } = action.payload;

      if (action.payload?.action === "replace") {
        return {
          ...state,
          [filterType]: {
            ...state[filterType],
            [filterGroup]: filterName ? [filterName] : [],
          },
        };
      } else if (action.payload?.action === "remove") {
        return {
          ...state,
          [filterType]: {
            ...state[filterType],
            [filterGroup]: state[filterType][filterGroup].filter((item) => {
              if (item !== filterName) {
                return true;
              }

              return false;
            }),
          },
        };
      }

      return {
        ...state,
        [filterType]: {
          ...state[filterType],
          [filterGroup]: state[filterType]?.[filterGroup]
            ? [...state[filterType][filterGroup], filterName]
            : [filterName],
        },
      };
    }

    default:
      return state;
  }
};

export const ImageReviewerFiltersController = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, {});

  return (
    <ImageReviewerFiltersDispatchContext.Provider value={dispatch}>
      <ImageReviewerFiltersStateContext.Provider value={state}>
        {children}
      </ImageReviewerFiltersStateContext.Provider>
    </ImageReviewerFiltersDispatchContext.Provider>
  );
};

export const useImageReviewerFiltersState = () => {
  const state = React.useContext(ImageReviewerFiltersStateContext);

  return state || {};
};

export const useImageReviewerFiltersDispatch = () => {
  const dispatch = React.useContext(ImageReviewerFiltersDispatchContext);

  return dispatch;
};
