import React from "react";
import { Switch, Route } from "react-router-dom";

import {
  Home,
  Farms,
  Account,
  Reports,
  Farm,
  AddField,
  HomeImageReviewer,
  BulkImageReviewer,
  ShowImageReviewer,
} from "./Components";
import { FieldWrapper } from "./Components/Views/Field/FieldWrapper";
import { WorkOrdersWrapper } from "./Components/Views/WorkOrders/WorkOrdersWrapper";

export default (
  <Switch>
    <Route exact path="/account-setup/:token" component={Home} />
    <Route exact path="/" component={Home} />
    <Route exact path="/error/:status" component={Home} />
    <Route exact path="/sign-up" component={Home} />
    <Route exact path="/forgot-password" component={Home} />
    <Route exact path="/reset-sign-in" component={Home} />
    <Route exact path="/reset-sign-in/:redirect" component={Home} />
    <Route exact path="/reset-password/:token" component={Home} />
    <Route exact path="/reset-password/:redirect/:token" component={Home} />
    <Route exact path="/login/:authToken" component={Home} />
    <Route exact path="/farms" component={Farms} />
    <Route exact path="/farm/:farmId/field/:fieldId" component={FieldWrapper} />
    <Route exact path="/farm/:farmId/field/:fieldId/work-order/:workOrderId" component={FieldWrapper} />
    <Route exact path="/farm/:farmId" component={Farm} />
    <Route exact path="/farm/:farmId/create-field" component={AddField} />
    <Route exact path="/settings" component={Account} />
    <Route path="/settings" component={Account} />
    <Route exact path="/reports" component={Reports} />
    <Route exact path="/tasks" component={WorkOrdersWrapper} />
    <Route exact path="/image-reviewer" component={HomeImageReviewer} />
    <Route exact path="/image-reviewer/:fieldId/bulk-review" component={BulkImageReviewer} />
    <Route exact path="/image-reviewer/:fieldId" component={ShowImageReviewer} />
  </Switch>
);
