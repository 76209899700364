import * as React from 'react';
// import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';

import { EditFarm } from './EditFarm';

const useStyles = makeStyles((theme) => ({
    text: {
        fontFamily: "var(--fontFamily) !important",
        color: "var(--fontColorMain) !important",
    },
}));

interface Farm {
    [key: string]: any;
}
interface Props {
    open: boolean;
    handleClose: () => void;
    currentFarm: Farm;
    farmHands: [];
    hideHeading: boolean;
}

export const EditFarmPopup = (props: Props) => {
    const classes = useStyles();

    return (
        <Dialog open={props.open}
            onClose={props.handleClose}
            PaperProps={{
                style: {
                    backgroundColor: "var(--bgColorMain)",
                    color: "var(--fontColorMain)",
                }
            }}
        >
            <DialogTitle >Edit Farm</DialogTitle>
            <DialogContent >
                <DialogContentText className={classes.text}>
                    You can change the farm name and/or deafult farm hand here.
                </DialogContentText>
                <EditFarm
                    farmId={props.currentFarm?.id}
                    farmName={props.currentFarm?.title}
                    toggleEditFarm={props.handleClose}
                    farmHands={props.farmHands}
                    hideHeading={true}
                />
            </DialogContent>
            {/* <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <Button onClick={props.handleClose}>Save</Button>
            </DialogActions> */}
        </Dialog>
    );
}
