import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, ListSkeleton, SearchWithIcon } from "../../../Helpers";
import { Flex } from "../../../Layouts/Flex";
import { FarmFieldsList } from "../FarmFieldsList/FarmFieldsList";
import styles from "./FarmShelf.module.scss";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { DateRangePicker } from "../../../Helpers/DatePicker/DatePicker";
import {
  makeStyles,
  IconButton,
} from "@material-ui/core";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import { utcStringToDate } from "../../../Helpers/utcStringToDate";

let minPlantDate
let maxPlantDate

export const FarmShelf = ({
  farmFields,
  searchValue,
  setSearchValue,
  showFilter,
  setShowFilter,
  filterOptions,
  selectField,
  handleFilterSelect,
  selectedFilters,
}) => {
  const history = useHistory();
  const farmId = window.location.pathname.replace("/farm/", "");
  const useStyles = makeStyles((theme) => ({
    selectedFilter: {
      color: theme.palette.colors.blue,
    },
    primaryColor: {
      color: theme.palette.text.primary,
    },
    dialog: {
      border: "1px solid #eee",
      position: "absolute",
      right: "5%",
      zIndex: 5,
      bottom: "2%",
      backgroundColor: theme.palette.background.default,
      width: 350,
      borderRadius: 6,
      padding: 15,
    },

  }));

  const classes = useStyles();
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [tmpStartDate, tmpSetStartDate] = React.useState(null);
  const [tmpEndDate, tmpSetEndDate] = React.useState(null);
  const [showDateFilter, setShowDateFilter] = React.useState(false);
  const [farmField, setFarmFields] = useState([]);
  const [defaultSort, setDefaultSort] = useState([])
  const [resetDefaultSort, setResetDefaultSort] = useState([])

  const [selectedSort, setSelectedSort] = useState({
    selectedSeverity: localStorage.getItem('selectedSeverity') || "",
    selectedTrend: localStorage.getItem('selectedTrend') || "",
  })

  const ahiValue = (field) => {
    return field?.seasons?.[0]?.enrolledInAhi && field?.seasons?.[0]?.ahi?.[0]?.fieldAhi
  }

  const ahiTrend = (field) => {
    return field?.seasons?.[0]?.enrolledInAhi && field?.seasons?.[0]?.ahi?.[0]?.fieldTrend
  }

  function handleSeverityAscFilter() {
    var severityAscFilter = [];
    if (selectedSort.selectedSeverity === "up") {
      severityAscFilter = (startDate === "undefined" && endDate === "undefined") ? farmFields : farmField.length < 1 ? [] : selectedSort.selectedSeverity === "up" ? resetDefaultSort : defaultSort;
    } else {
      // put fields with no AHI last/bottom
      const defaultAhi = 10
      severityAscFilter = [...farmField].sort((a, b) => Number(ahiValue(a) || defaultAhi) - Number(ahiValue(b) || defaultAhi));
    }
    setFarmFields(severityAscFilter);
    setSelectedSort({ ...selectedSort, selectedTrend: "", selectedSeverity: selectedSort.selectedSeverity === "up" ? "" : "up" });
    localStorage.setItem('selectedSeverity', selectedSort.selectedSeverity === "up" ? "" : "up");
    localStorage.setItem('selectedTrend', "");
  }

  function handleSeverityDescFilter() {
    var severityDescFilter = [];
    if (selectedSort.selectedSeverity === "down") {
      severityDescFilter = (startDate === "undefined" && endDate === "undefined") ? farmFields : farmField?.length < 1 ? [] : selectedSort.selectedSeverity === "down" ? resetDefaultSort : defaultSort;
    } else {
      // put fields with no AHI last/bottom
      const defaultAhi = -10
      severityDescFilter = [...farmField].sort((a, b) => Number(ahiValue(b) || defaultAhi) - Number(ahiValue(a) || defaultAhi));
    }
    setFarmFields(severityDescFilter);
    setSelectedSort({ ...selectedSort, selectedTrend: "", selectedSeverity: selectedSort.selectedSeverity === "down" ? "" : "down" })
    localStorage.setItem('selectedSeverity', selectedSort.selectedSeverity === "down" ? "" : "down");
    localStorage.setItem('selectedTrend', "");
  }

  function handleTrendAscFilter() {
    var trendAscFilter = [];
    if (selectedSort.selectedTrend === "up") {
      trendAscFilter = (startDate === "undefined" && endDate === "undefined") ? farmFields : farmField.length < 1 ? [] : selectedSort.selectedTrend === "up" ? resetDefaultSort : defaultSort;
    } else {
      const defaultTrend = 10
      trendAscFilter = [...farmField].sort((a, b) => Number(ahiTrend(a) || defaultTrend) - Number(ahiTrend(b) || defaultTrend));
    }
    setFarmFields(trendAscFilter);
    setSelectedSort({ ...selectedSort, selectedSeverity: "", selectedTrend: selectedSort.selectedTrend === "up" ? "" : "up" })
    localStorage.setItem('selectedTrend', selectedSort.selectedTrend === "up" ? "" : "up");
    localStorage.setItem('selectedSeverity', "");
  }

  function handleTrendDescFilter() {
    var trendDescFilter = [];
    if (selectedSort.selectedTrend === "down") {
      trendDescFilter = (startDate === "undefined" && endDate === "undefined") ? farmFields : farmField.length < 1 ? [] : selectedSort.selectedTrend === "down" ? resetDefaultSort : defaultSort;
    } else {
      const defaultTrend = -10
      trendDescFilter = [...farmField].sort((a, b) => Number(ahiTrend(b) || defaultTrend) - Number(ahiTrend(a) || defaultTrend));
    }
    setFarmFields(trendDescFilter);
    setSelectedSort({ ...selectedSort, selectedSeverity: "", selectedTrend: selectedSort.selectedTrend === "down" ? "" : "down" });
    localStorage.setItem('selectedTrend', selectedSort.selectedTrend === "down" ? "" : "down");
    localStorage.setItem('selectedSeverity', "");
  }

  useEffect(() => {
    setFarmFields(farmFields);
    let plantDateArray = farmFields?.map((field) => {
      const fieldSeason = field.seasons.map((date) => {
        return date?.plantDate
      });
      return fieldSeason[0]
    });
    // maybe no plant date on some fields?
    plantDateArray = plantDateArray?.filter((item) => {
      return item !== undefined
    });

    minPlantDate = new Date(plantDateArray?.reduce((acc, date) => { return acc && new Date(acc) < new Date(date) ? acc : date }, '') + 'T00:00:00')
    maxPlantDate = new Date(plantDateArray?.reduce((acc, date) => { return acc && new Date(acc) > new Date(date) ? acc : date }, '') + 'T00:00:00')
    if (farmFields) {
      if (localStorage.getItem('activeFarm') !== farmId) {
        resetAndUpdateData();
      }
      localStorage.setItem('activeFarm', farmId)
      //check if previsously selected sorting 
      let tmpSelectedSeverity = localStorage.getItem('selectedSeverity');
      let tmpSelectedTrend = localStorage.getItem('selectedTrend');
      let tmpData = [];
      if (tmpSelectedSeverity || tmpSelectedTrend) {
        if (tmpSelectedSeverity === "up") {
          const defaultAhi = 10
          tmpData = [...farmFields].sort((a, b) => Number(ahiValue(a) || defaultAhi) - Number(ahiValue(b) || defaultAhi));
        }
        else if (tmpSelectedSeverity === "down") {
          const defaultAhi = -10
          tmpData = [...farmFields].sort((a, b) => Number(ahiValue(b) || defaultAhi) - Number(ahiValue(a) || defaultAhi));
        }
        if (tmpSelectedTrend === "up") {
          const defaultTrend = 10
          tmpData = [...farmFields].sort((a, b) => Number(ahiTrend(a) || defaultTrend) - Number(ahiTrend(b) || defaultTrend));
        }
        else if (tmpSelectedTrend === "down") {
          const defaultTrend = -10
          tmpData = [...farmFields].sort((a, b) => Number(ahiTrend(b) || defaultTrend) - Number(ahiTrend(a) || defaultTrend));
        }
      }
      else {
        tmpData = farmFields
      }

      let curFarm = JSON.parse(localStorage.getItem("slectedFarm"))
      let preFarm = JSON.parse(localStorage.getItem("preFarm"))
      let goToField = JSON.parse(localStorage.getItem('goToField'))
      if (!curFarm || preFarm) {
        if (!goToField) {
          if (!curFarm || (curFarm !== preFarm)) {
            localStorage.setItem('startDate', minPlantDate);
            localStorage.setItem('endDate', maxPlantDate);
            setStartDate(minPlantDate);
            setEndDate(maxPlantDate);
            tmpSetStartDate(minPlantDate);
            tmpSetEndDate(maxPlantDate)
            filteredData(localStorage.getItem('startDate'), localStorage.getItem('endDate'));
          }
        }
      }

      setFarmFields(tmpData);
      filteredData(localStorage.getItem('startDate'), localStorage.getItem('endDate'));
    }
    // eslint-disable-next-line
  }, [farmFields]);

  const filteredData = (tmpStartDate, tmpEndDate) => {

    if (!(tmpEndDate)) {
      tmpEndDate = tmpStartDate
    }
    if ([null, undefined].includes(tmpStartDate) && [null, undefined].includes(tmpStartDate)) {
      localStorage.setItem('startDate', minPlantDate);
      localStorage.setItem('endDate', maxPlantDate);
      setStartDate(minPlantDate);
      setEndDate(maxPlantDate);
      tmpSetStartDate(minPlantDate);
      tmpSetEndDate(maxPlantDate)
      tmpStartDate = minPlantDate;
      tmpEndDate = maxPlantDate;
    }
    else {
      localStorage.setItem('startDate', tmpStartDate);
      localStorage.setItem('endDate', tmpEndDate);
      if (typeof tmpStartDate === "string" && typeof tmpEndDate === "string") {
        tmpStartDate = new Date(tmpStartDate);
        tmpEndDate = new Date(tmpEndDate);
      }
      setStartDate(typeof tmpStartDate === "string" ? new Date(tmpStartDate) : tmpStartDate);
      setEndDate(typeof tmpEndDate === "string" ? new Date(tmpEndDate) : tmpEndDate);
      tmpSetStartDate(typeof tmpStartDate === "string" ? new Date(tmpStartDate) : tmpStartDate);
      tmpSetEndDate(typeof tmpEndDate === "string" ? new Date(tmpEndDate) : tmpEndDate);
    }
    if (!!Date.parse(tmpStartDate) && !!Date.parse(tmpEndDate)) {
      let tmpSelectedSeverity = localStorage.getItem('selectedSeverity');
      let tmpSelectedTrend = localStorage.getItem('selectedTrend');
      let tmpData = [];
      if (tmpSelectedSeverity || tmpSelectedTrend) {
        if (tmpSelectedSeverity === "up") {
          const defaultAhi = 10
          tmpData = [...farmFields].sort((a, b) => Number(ahiValue(a) || defaultAhi) - Number(ahiValue(b) || defaultAhi));
        }
        else if (tmpSelectedSeverity === "down") {
          const defaultAhi = -10
          tmpData = [...farmFields].sort((a, b) => Number(ahiValue(b) || defaultAhi) - Number(ahiValue(a) || defaultAhi));
        }
        if (tmpSelectedTrend === "up") {
          const defaultTrend = 10
          tmpData = [...farmFields].sort((a, b) => Number(ahiTrend(a) || defaultTrend) - Number(ahiTrend(b) || defaultTrend));
        }
        else if (tmpSelectedTrend === "down") {
          const defaultTrend = -10
          tmpData = [...farmFields].sort((a, b) => Number(ahiTrend(b) || defaultTrend) - Number(ahiTrend(a) || defaultTrend));
        }
      }
      else {
        tmpData = farmFields
      }
      tmpStartDate.setHours(0, 0, 0, 0)
      tmpEndDate.setHours(0, 0, 0, 0)
      setResetDefaultSort(farmFields?.filter((a) => {
        let seasonsItem = null,
          filterDate = null;
        a.seasons.forEach((item) => {
          if (item.current) {
            seasonsItem = item;
          }
        });
        // force any field with no season/plant date to appear
        filterDate = (seasonsItem) ? utcStringToDate(seasonsItem.plantDate) : tmpStartDate
        return (filterDate >= tmpStartDate) && (filterDate <= tmpEndDate);
      }))
      let filteredPlantData = tmpData?.filter((a) => {
        let seasonsItem = null,
          filterDate = null;
        a.seasons.forEach((item) => {
          if (item.current) {
            seasonsItem = item;
          }
        });
        // force any field with no season/plant date to appear
        filterDate = (seasonsItem) ? utcStringToDate(seasonsItem.plantDate) : tmpStartDate
        return (filterDate >= tmpStartDate) && (filterDate <= tmpEndDate);
      });
      setFarmFields(filteredPlantData);
      setDefaultSort(filteredPlantData)
    }
  };

  const resetAndUpdateData = () => {
    const lastSavedData = localStorage.getItem("farmField");
    const parseddata = JSON.parse(lastSavedData);
    parseddata !== null && setFarmFields(parseddata);
    localStorage.setItem('startDate', minPlantDate);
    localStorage.setItem('endDate', maxPlantDate);
    setStartDate(minPlantDate);
    setEndDate(maxPlantDate);
    tmpSetStartDate(minPlantDate);
    tmpSetEndDate(maxPlantDate)
    filteredData(localStorage.getItem('startDate'), localStorage.getItem('endDate'));
  }

  //const utcMMddFormatter = new Intl.DateTimeFormat(locale, dateOptions)
  return (
    <Flex flexDirection="column" className={styles.FarmShelf}>
      <Flex flexDirection="column" className={styles.SearchAndFilter}>
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          className={styles.FilterContainer}
        >
          <SearchWithIcon
            className={styles.SearchInput}
            searchValue={searchValue}
            updateSearchValue={(e) => setSearchValue(e.target.value)}
          />
          <div className={styles.sortFieldFilter}>
            Crop Health Severity
            <div style={{ display: "grid", marginLeft: "0", marginRight: "3rem", cursor: "pointer" }}>
              <ArrowDropUpIcon
                onClick={handleSeverityAscFilter}
                className={selectedSort.selectedSeverity === "up" ? `${styles.on}` : ""}
              />
              <ArrowDropDownIcon
                onClick={handleSeverityDescFilter}
                className={selectedSort.selectedSeverity === "down" ? `${styles.on}` : ""}
              />
            </div>
            Crop Health Trend
            <div style={{ display: "grid", marginLeft: "0", marginRight: "1rem", cursor: "pointer" }}>
              <ArrowDropUpIcon
                onClick={handleTrendAscFilter}
                className={`${selectedSort.selectedTrend === "up" ? `${styles.on}` : ""}`}
              />
              <ArrowDropDownIcon
                onClick={handleTrendDescFilter}
                className={`${selectedSort.selectedTrend === "down" ? `${styles.on}` : ""}`}
              />
            </div>
          </div>
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          className={styles.FilterContainer}
        >
          <div style={{ marginTop: "1rem" }} >
            <InsertInvitationIcon
              className={`${!showDateFilter && classes.primaryColor
                } ${showDateFilter && classes.selectedFilter}`}
              onClick={() => {
                if (!!Date.parse(startDate)) { setShowDateFilter(!showDateFilter); }
              }}
              style={{ marginBottom: "-0.25rem", marginRight: "0.5rem" }}
              isSelected={showDateFilter}
            />
            Plant Date
          </div>
          {startDate && endDate && startDate < endDate && (
            <div>{startDate.toLocaleDateString("en-US", { month: 'short', day: 'numeric' })} - {endDate.toLocaleDateString("en-US", { month: 'short', day: 'numeric' })}</div>
          )}
          {startDate && startDate >= endDate && (
            <div>{startDate.toLocaleDateString("en-US", { month: 'short', day: 'numeric' })}</div>
          )}
          {showDateFilter && (
            <>
              <div className={classes.backdrop} />
              <div className={`${classes.dialog} democalendar`}>
                <IconButton
                  style={{ color: "var(--fontColorMain)", float: "right" }}
                  onClick={() => {
                    setShowDateFilter(false);
                  }}
                  className={`${classes.primaryColor} fa fa-times custumr8`}
                />
                <DateRangePicker
                  className={classes.dayPicker}
                  indefinite
                  from={tmpStartDate}
                  to={tmpEndDate}
                  data={(isReset) => {
                    setFarmFields(farmFields);
                    setStartDate(undefined);
                    setEndDate(undefined);
                  }}
                  setDateRange={({ from, to }) => {
                    tmpSetStartDate(from);
                    tmpSetEndDate(to);
                  }}
                  onChange={(day) => { }}
                  onUpdate={() => {
                    setStartDate(tmpStartDate);
                    setEndDate(tmpEndDate);
                    filteredData(tmpStartDate, tmpEndDate);
                  }}
                  resetAndUpdateData={resetAndUpdateData}
                  farmShelf={true}
                  numberOfMonths={2}
                />
              </div>
            </>
          )}

          {/* <div>
          Severity <i
            className={`fas fa-uparrow ${styles.FilterIcon}`}
          />
          Trend <i
            className={`fas fa-sort ${styles.FilterIcon}`}
          />
          </div> */}
          {/* <i
            className={`fas fa-filter ${styles.FilterIcon} ${
              showFilter && styles.ShowFilter
            }`}
            onClick={() => setShowFilter(!showFilter)}
          /> */}
          {(farmField && farmField.length === 0) && (
            <>
              <span>
                You have not yet added any fields!
                <br />
                Add one now:
              </span>
              <Button
                className={styles.AddFieldBtn}
                text="+ New Field"
                click={() =>
                  history.push(`/farm/${farmId}/create-field`)
                }
              />
            </>
          )}
          {(!Date.parse(startDate)) && (
            <>
              No seasons with plant dates.
              <br />
              Select a field and add a season to enable Plant Date filter.
            </>
          )}
        </Flex>
        {showFilter && (
          <Flex flexWrap="flex-wrap" className={styles.FilterMenu}>
            <h5>Filters</h5>
            {filterOptions.map((option, i) => (
              <Flex
                key={i}
                className={`${styles.FilterOption} ${selectedFilters.includes(i) && styles.FilterOptionShow
                  }
            `}
                onClick={() => handleFilterSelect(i)}
              >
                {option.icon}
                <span>{option.name}</span>
              </Flex>
            ))}
          </Flex>
        )}
      </Flex>

      {
        farmField && farmField.length > 0 ? (
          <FarmFieldsList
            farmFields={farmField.filter(farm => {
              return farm.title.toLowerCase().includes(searchValue.toLowerCase())
            })}
            selectField={selectField}
            options={{ border: true }}
          />
        ) : farmField && farmField.length === 0 ? (
          <ListSkeleton
            options={{ border: true }}
            listCount={4}
            styleH1={{ width: "60%", height: "1rem" }}
            styleH5={{ width: "30%", height: ".6rem" }}
            styleP={{ width: "50%", height: ".6rem" }}
            animate={false}
          />
        ) : (
          <ListSkeleton
            options={{ border: true }}
            listCount={9}
            styleH1={{ width: "60%", height: "1rem" }}
            styleH5={{ width: "30%", height: ".6rem" }}
            styleP={{ width: "50%", height: ".8rem" }}
          />
        )
      }
    </Flex >
  );
};
