import React, { useState } from "react";
import { MuiButton } from "../../../Helpers";
import { Autocomplete2 } from '../../../Helpers/Autocomplete/Autocomplete';
import { Flex } from "../../../Layouts/Flex";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import { createFarm } from "../../../../controllers/farms";
import { useAuth } from "../../../../hooks/useAuth";
import { makeStyles, Grid } from "@material-ui/core";
import { useSelectedCustomerAccount } from "../../../../globalState";
import Swal from 'sweetalert2'
import omit from '../../../../lib/omit'
import { Typography, TextField } from '@material-ui/core'
import clsx from 'clsx';
import { useHistory } from "react-router-dom";
import { updateableAccounts } from "../../../../lib/customerAccount";

const useStyles = makeStyles((theme) => ({
  addFarmFormButton: {
    marginTop: theme.spacing(1),
    marginLeft: "1.5rem",
    marginRight: "1.5rem",
  },
  addFarmField: {
    width: "22.5rem",
    // marginBottom: theme.spacing(1), 
    border: 'none',
    // marginTop:"0px !important",
    // marginRight:"20px"
  },
  textField: {
    // marginTop: theme.spacing(1),
    width: "22.5rem",
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
  },
  inputStyling: {
    borderRadius: 4,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.tertiary
  },
  labelShrink: {
    transform: `translate(0px, -18px) scale(0.75) !important`,
  }
}));

const selectAccount = {
  id: "",
  name: "",
};

export const AddFarm = ({ customerAccountsList, setShowAddFarm }) => {
  const history = useHistory();
  const classes = useStyles();
  const { token } = useAuth();
  const globallySelectedCustomerAccount = useSelectedCustomerAccount();
  const [newFarmName, setNewFarmName] = useState("");
  const updateableCustomeAccountsList = updateableAccounts(customerAccountsList);
  const [selectedCustAccount, setSelectedCustAccount] = useState({
    ...(
      globallySelectedCustomerAccount
        ? updateableCustomeAccountsList.find(a => a.id === globallySelectedCustomerAccount.id)
        : selectAccount
    ),
  });

  const [errors, setErrors] = useState({})

  const [farmHand, setFarmHand] = useState("")
  const [farmHandsList, setFarmHandsList] = useState([])

  const validate = () => {
    const newErrors = {}
    if (selectedCustAccount.id === "") {
      newErrors.selectedCustAccount = "You must choose a customer account"
    }
    if (!Boolean(newFarmName.trim())) {
      newErrors.newFarmName = "Farm name is required."
    }
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleCreateFarm = async () => {
    if (validate()) {
      try {
        const newFarm = await createFarm(token, {
          customer_account_id: selectedCustAccount.id,
          farm_title: newFarmName,
          default_farm_hand_id: farmHand.userId
        });
        history.push(`/farm/${newFarm.id}`);
      } catch (err) {
        console.error(err)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "An error occurred while creating your farm."
        });
      }
    }
  };

  React.useEffect(() => {
    if (selectedCustAccount) {
      setFarmHandsList(updateableCustomeAccountsList.find(a => selectedCustAccount.id === a.id)?.farmHands)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustAccount])

  return (
    <Flex
      flexDirection="column"
      alignItems="flex-start"
    >
      <Typography variant={'h3'}>Add a New Farm</Typography>
      <Flex
        flexDirection="column"
        alignItems="flex-start"
      >
        <Typography color="textSecondary">Customer Account</Typography>
        <Autocomplete2
          className={clsx(classes.addFarmField, classes.textField)}
          options={updateableCustomeAccountsList}
          getOptionLabel={o => o.name}
          labelText={'Select Customer Account'}
          error={errors.selectedCustAccount}
          value={updateableCustomeAccountsList.find(a => selectedCustAccount.id === a.id) || null}
          onChange={(_e, choice) => {
            if (errors.selectedCustAccount) {
              setErrors(omit(errors, 'selectedCustAccount'))
            }
            setSelectedCustAccount(choice === null ? selectAccount : choice)
          }}
        />

        <Typography color="textSecondary">Farm Name</Typography>
        <TextField
          variant={"outlined"}
          label={"Type Farm Name"}
          classes={{ root: classes.textField }}
          InputProps={{
            notched: false,
          }}
          inputProps={{
            className: classes.inputStyling
          }}
          InputLabelProps={{
            classes: {
              shrink: classes.labelShrink
            }
          }}
          error={Boolean(errors.newFarmName)}
          helperText={errors.newFarmName}
          onChange={(e) => {
            if (errors.newFarmName) {
              setErrors(omit(errors, 'newFarmName'))
            }
            setNewFarmName(e.target.value)
          }}
          value={newFarmName}
        />

        {/* ------------------------------------------------------- */}

        <Typography color="textSecondary">Default Farm Hand</Typography>
        <Autocomplete2
          className={clsx(classes.addFarmField, classes.textField)}
          options={farmHandsList}
          getOptionLabel={o => o?.fullName}
          labelText={'Select Farm Hand'}
          // error={errors.selectedCustAccount}
          value={farmHand || null}
          onChange={(_, v) => setFarmHand(v)}
        />

        {/* ------------------------------------------------------- */}
        <Grid container justifyContent="space-between" style={{ width: "100%" }}>
          <MuiButton
            text="Add Farm"
            startIcon={<AddIcon />}
            type="submit"
            variant="contained"
            color="primary"
            className={classes.addFarmFormButton}
            onClick={() => handleCreateFarm()}
          />
          <MuiButton
            className={classes.addFarmFormButton}
            text="Cancel"
            startIcon={<ClearIcon />}
            onClick={() => setShowAddFarm(false)}
          />
        </Grid>
      </Flex>
    </Flex >
  );
};
