import React from "react";
import { Link } from "react-router-dom";
import styles from "./Navigation.module.scss";

export const NavigationItem = ({
  icon,
  label,
  isSelected,
  redirect = "",
  onClick,
}) => {
  return (
    <Link
      to={redirect}
      id="farms"
      className={`Link ${styles.NavigationItem} ${
        isSelected && styles.Selected
      }`}
      onClick={onClick}
    >
      {icon}
      <span>{label}</span>
    </Link>
  );
};
