import axios from "axios";
const isStagingSite = process.env.REACT_APP_ENV.includes("staging");
const isDevSite = process.env.REACT_APP_ENV.includes("development");
const baseUrl = isDevSite
    ? process.env.REACT_APP_BACK_OFFICE_URL
    : isStagingSite
        ? process.env.REACT_APP_BACK_OFFICE_URL_STAGING
        : process.env.REACT_APP_BACK_OFFICE_URL_PRODUCTION;

export const maxRegionNameLengthForDisplay = 10;

export const createRegion = async (authToken, regionData) => {
    const result = await axios({
        method: 'POST',
        url: `${baseUrl}/api/v1/regions`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
        },
        data: {
            data: {
                ...regionData,
                yield: regionData.cropYield
            },
        }
    })
    return result
}
export const updateRegion = async (authToken, regionData, regionId) => {
    const result = await axios({
        method: 'PUT',
        url: `${baseUrl}/api/v1/regions/${regionId}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
        },
        data: {
            data: {
                ...regionData,
                yield: regionData.cropYield
            },
        }
    })
    return result.data.data
}
export const deleteRegion = async (authToken, regionId) => {
    const result = await axios({
        method: 'DELETE',
        url: `${baseUrl}/api/v1/regions/${regionId}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
        },
    })
    return result
}

