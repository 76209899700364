import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as turf from "@turf/turf";
import { Shelf } from "../../Helpers";
import { getFarmsFields } from "../../../controllers/farms";
import { Map } from "../../Map/Map";
import { Flex } from "../../Layouts/Flex";
import { Container } from "../../Layouts/Container";
import styles from "./AddField.module.scss";
import { useAuth } from "../../../hooks/useAuth";
import { AddFieldShelf } from "./AddFieldShelf/AddFieldShelf";
import { createField } from "../../../controllers/fields";
import { searchForAddress } from "../../../controllers/mapboxApi";
import Swal from "sweetalert2";

export const AddField = () => {
  const history = useHistory();
  const location = useLocation();
  const { token } = useAuth();
  const farmId = location.pathname
    .replace("/farm/", "")
    .replace("/create-field", "");
  const [farmFields, setFarmFields] = useState(null);
  const [farmBounds, setFarmBounds] = useState(null);
  const [addressCenter, setAddressCenter] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchAddress, setSearchAddress] = useState("");

  const [newFieldData, setNewFieldData] = useState({
    farmId: +farmId,
    title: "",
    acres: 0,
    id: "new",
  });

  const [polygonData, setPolygonData] = useState({ polygon: {} });
  const mapPolygonData = React.useMemo(() => {
    if (
      newFieldData &&
      newFieldData.geojson &&
      newFieldData.geojson.coordinates
    ) {
      // coordinates are saved in this format of [[[0,1]]],
      // so we need to do this to allow for an imported polygon to be rendered...
      if (Array.isArray(newFieldData.geojson.coordinates[0][0]))
        return {
          ...newFieldData,
          geometry: {
            ...newFieldData.geojson,
            coordinates: newFieldData.geojson.coordinates,
          },
          polygon: {
            type: "Feature",
            geometry: {
              ...newFieldData.geojson,
              coordinates: newFieldData.geojson.coordinates[0],
            },
          },
        };
    }
    return newFieldData;
    // eslint-disable-next-line
  }, [newFieldData, polygonData]);

  // Status can be "New" | "Submitting" | "Success" | "Error"
  const [formStatus, setFormStatus] = useState("New");

  useEffect(() => {
    if (token) {
      getFarmsFields(token, farmId).then(async (res) => {
        if (res.length > 0) {
          let centers = [];
          const formattedFarmFields = res.map((field) => {
            const formattedBounds = field.bounds.map((point) => [
              +point[1],
              +point[0],
            ]);
            const point1 = turf.point(formattedBounds[0]);
            const point2 = turf.point(formattedBounds[1]);
            const midpoint = turf.midpoint(point1, point2).geometry.coordinates;
            centers.push(midpoint);
            return {
              ...field,
              center: midpoint,
              showMarker: true,
            };
          });
          centers.push(centers[0]);
          const bounds = turf.bbox(turf.lineString(centers));
          setFarmBounds(bounds);
          setFarmFields(formattedFarmFields);
        } else {
          setFarmFields([]);
        }
      });
    }

    return () => {
      setFarmFields(null);
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [token]);

  useEffect(() => {
    searchForAddress(searchAddress).then((res) => {
      if (res) {
        setSearchResults(res);
      }
    });
  }, [searchAddress]);

  useEffect(() => {
    if (formStatus === "Submitting") {
      const payload = {
        farmId: newFieldData.farmId,
        acres: polygonData.acres,
        title: newFieldData.title,
        bounds: JSON.stringify(
          polygonData.bounds?.map((point) => [point[1], point[0]])
        ),
        polygon: JSON.stringify(polygonData.polygon),
      };
      createNewField(payload)
        .then(() => setFormStatus("Success"))
        .catch((err) => {
          console.error(err);
          setFormStatus("Error");
        });
    }
    // eslint-disable-next-line
  }, [formStatus, newFieldData, polygonData]);

  const updateSearchAddress = (input) => {
    if (!showSearchResults) {
      setShowSearchResults(true);
    } else if (input === "") {
      setShowSearchResults(false);
      setAddressCenter(null);
    }
    setSearchAddress(input);
  };

  const selectSearchResult = (address) => {
    setSearchAddress(address.place_name);
    setAddressCenter(address.center);
    setShowSearchResults(false);
    setSearchResults([]);
  };

  const findMe = document.querySelector('.mapboxgl-ctrl-geolocate')
  if (findMe) {
    findMe.setAttribute('disabled', 'true')
  }

  const createNewField = async (payload) => {
    if (
      !!payload.title &&
      (!!payload.polygon.geometry || typeof payload.polygon === "string")
    ) {
      await createField(token, payload).then((res) => {
        if (res.success) {
          history.push(`/farm/${farmId}/field/${res.data.id}`);
        }
      });
    } else {
      const message = payload.title
        ? "Please draw your field on the map."
        : "Please enter a title for your field.";
      await Swal.fire({
        icon: "error",
        title: "Required data missing",
        text: message,
      });
    }
  };

  const createNewFieldFromKML = async (fieldData) => {
    const polygonBounds = turf.bbox(fieldData.geojson);
    setFarmBounds(polygonBounds);
    const formattedPolygonBounds = [
      [polygonBounds[1], polygonBounds[0]],
      [polygonBounds[3], polygonBounds[2]],
    ];
    const payload = {
      farmId: fieldData.farmId,
      acres: +fieldData.acres,
      title: fieldData.title,
      bounds: JSON.stringify(formattedPolygonBounds),
      polygon: JSON.stringify({ type: 'Feature', geometry: fieldData.geojson }),
    };
    createNewField(payload);
  };

  return (
    <Container>
      <Flex flexDirection="column" className={styles.AddField}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          className={styles.Header}
        >
          <Flex
            flexDirection="column"
            alignItems="flex-start"
            className={styles.Title}
          >
            <h1>Add a New Field</h1>
            <h5>
              {farmFields && farmFields.length > 0
                ? farmFields[0].farm.title
                : ""}
            </h5>
          </Flex>
        </Flex>

        <Flex className={styles.Body}>
          <Shelf>
            <AddFieldShelf
              polygonData={polygonData}
              setFarmBounds={setFarmBounds}
              setPolygonData={setPolygonData}
              newFieldData={newFieldData}
              setNewFieldData={setNewFieldData}
              createNewFieldFromKML={createNewFieldFromKML}
              createNewField={() => setFormStatus("Submitting")}
              searchResults={searchResults}
              selectSearchResult={selectSearchResult}
              showSearchResults={showSearchResults}
              updateSearchAddress={updateSearchAddress}
              searchAddress={searchAddress}
            />
          </Shelf>
          <Map
            mapOptions={{
              maxZoom: 17,
            }}
            containerId="field-map"
            drawingEnabled={true}
            goToZoomLevel={15}
            geometryToEdit={mapPolygonData}
            setGeometryToEdit={setPolygonData}
            fields={farmFields}
            mapBounds={addressCenter ? null : farmBounds}
            goToPoint={addressCenter}
          />
        </Flex>
      </Flex>
    </Container>
  );
};
