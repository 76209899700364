import * as React from "react";

export const useIntersectionObserver = ({ elRef, options }) => {
  const [isIntersecting, setIsIntersecting] = React.useState(false);

  React.useEffect(() => {
    if (elRef) {
      let newOptions = {
        root: document.querySelector("#scrollArea"),
        threshold: 0.5,
        ...options,
      };

      let callback = (entries, _) => {
        entries.forEach((entry) => {
          setIsIntersecting(entry.isIntersecting);
        });
      };

      let observer = new IntersectionObserver(callback, newOptions);
      observer.observe(elRef.current);
    }
  }, [elRef, options]);

  return { isIntersecting };
};
