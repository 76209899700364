import React from "react";

import cn from "clsx";

import {
  makeStyles,
  Grid,
  Typography,
  Breadcrumbs,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Collapse,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import NotificationsIcon from "@material-ui/icons/NotificationsNone";
import Paper from "@material-ui/core/Paper";

import { useParams } from "react-router-dom";

import { SettingsHeading } from "../Account";
import { Chip } from "../../../Helpers/Chip/Chip";
import { Button } from "../../../Helpers/Button/Button";
import { Input } from "../../../Helpers/Input/Input";
import { Select } from "../../../Helpers";

import { useApi, useLazyApi } from "../../../../hooks/useApi";

import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    marginBottom: "2rem",
  },
  toolBarButton: {
    height: "2rem",
    backgroundColor: "inherit",

    "& i": {
      margin: "auto",
    },
  },
  buttonAdd: {
    color: theme.palette.text.primary,
    backgroundColor: "inherit",
    padding: 0,
  },
  buttonAddSelected: {
    color: theme.palette.primary.main,
  },
  buttonSelectAll: {
    backgroundColor: theme.palette.background.quinary,
  },
  buttonDelete: {
    color: theme.palette.primary.main,
    padding: 0,
  },
  buttonSelectAllSelected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.primary,
  },
  muiIcon: {
    stroke: "initial",
    strokeWidth: "1px",
  },
  table: {
    minWidth: 1275,
  },
}));

const UserAccessRow = ({ role, name, email, selected, onSelect }) => {
  return (
    <TableRow>
      <TableCell align="center" padding="checkbox">
        {role !== "Customer Account Manager" && (
          <Grid item>
            <Checkbox color="primary" checked={selected} onClick={onSelect} />
          </Grid>
        )}
      </TableCell>
      <TableCell align="left">
        <Typography variant="h6">{name}</Typography>
        <Typography variant="body1">{email}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="body1">{role}</Typography>
      </TableCell>
    </TableRow>
  );
};

const FarmFieldsUsersAccessList = ({ itemType, id }) => {
  const classes = useStyles();

  const { data, revalidate: revalidateUsers } = useApi(
    `/api/v1/${itemType}/${id}/user-access-list`
  );

  let users = [];

  if (data) {
    const { subscribers, customerAccountManagers } = data;
    users = subscribers.concat(customerAccountManagers);
  }

  const { data: itemData } = useApi(`/api/v1/${itemType}s/${id}`);

  const [showAddUserPanel, setShowAddUserPanel] = React.useState(false);
  const [selectedUsers, setSelectedUsers] = React.useState({});
  const [isSelectAllMode, setIsSelectAllMode] = React.useState(false);
  const [userEmail, setUserEmail] = React.useState("");
  const [role, setRole] = React.useState("");
  const [isNotifying, setIsNotifying] = React.useState(false);
  const notifyText = React.useMemo(
    () => (isNotifying ? "Notifying User" : "Notify User"),
    [isNotifying]
  );

  const [addSubscription] = useLazyApi(`/api/v1/subscriptions/`, {
    method: "POST",
  });

  const [removeSubscription] = useLazyApi(`/api/v1/subscriptions/remove`, {
    method: "DELETE",
  });

  const itemTypes = {
    farm: "Farm",
    field: "Field",
  };

  const onAddUser = async (userEmail) => {
    await addSubscription({
      data: {
        resourceId: id,
        resourceType: itemTypes[itemType],
        subscribers: [
          {
            email: userEmail,
            role,
          },
        ],
      },
    })
      .then(async (res) => {
        setIsNotifying(false);
        await Swal.fire({
          icon: "success",
          title: "Success!",
          text: "User added",
        });
        revalidateUsers();
        return res;
      })
      .catch(async (err) => {
        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "An error occurred while adding image reviewer",
        });
        console.error(err);
        setSelectedUsers({});
        revalidateUsers();
      });
  };

  const onCancel = () => setShowAddUserPanel(false);

  return (
    <Grid container direction="row" justifyContent="space-between" spacing={3}>
      <Grid
        item
        container
        direction="column"
        className={classes.sectionContainer}
      >
        <Breadcrumbs>
          <Typography variant="body1">Farm/Field Access</Typography>
          <Typography variant="body1">{itemTypes[itemType]}</Typography>
          <Typography variant="body1">Users</Typography>
        </Breadcrumbs>
      </Grid>

      <Grid item>
        <SettingsHeading text={itemData && itemData.title} />
      </Grid>

      <Grid item container xs={2} justifyContent="center" direction="row">
        <Grid item container wrap="nowrap" spacing={4} justifyContent="flex-end">
          <Grid item>
            <Chip
              className={cn(
                classes.toolBarButton,
                classes.buttonSelectAll,
                isSelectAllMode && classes.buttonSelectAllSelected
              )}
              onClick={() => {
                if (isSelectAllMode) {
                  setSelectedUsers(
                    Object.fromEntries(
                      users.map((user) => [user.subscriptionId, false])
                    )
                  );
                  setIsSelectAllMode(false);
                } else {
                  setSelectedUsers(
                    Object.fromEntries(
                      users.map((user) => [user.subscriptionId, true])
                    )
                  );
                  setIsSelectAllMode(true);
                }
              }}
            >
              Select all
            </Chip>
          </Grid>

          {Object.values(selectedUsers).find((v) => v) && (
            <Grid item>
              <Button
                click={async () => {
                  const subscriptionIds = Object.keys(selectedUsers);

                  if (subscriptionIds.length > 0) {
                    await removeSubscription({
                      data: {
                        subscriptionIds: subscriptionIds,
                      },
                    })
                      .then(async () => {
                        await Swal.fire({
                          icon: "success",
                          title: "Success!",
                          text: "Users removed",
                        });
                        setSelectedUsers({});
                        revalidateUsers();
                      })
                      .catch(async (err) => {
                        await Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "An error occurred while removing users",
                        });
                        console.error(err);
                        setSelectedUsers({});
                        revalidateUsers();
                      });
                  }
                }}
                icon={
                  <i className={cn("far", "fa-trash-alt", classes.muiIcon)} />
                }
                className={cn(classes.toolBarButton, classes.buttonDelete)}
              />
            </Grid>
          )}
          <Grid item>
            <Button
              click={() => setShowAddUserPanel(!showAddUserPanel)}
              icon={
                showAddUserPanel ? (
                  <ClearIcon className={classes.muiIcon} />
                ) : (
                  <AddIcon className={classes.muiIcon} />
                )
              }
              className={cn(
                classes.toolBarButton,
                classes.buttonAdd,
                showAddUserPanel && classes.buttonAddSelected
              )}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container item>
        <Collapse in={showAddUserPanel}>
          <Grid container item direction="column" spacing={4}>
            <Grid
              container
              item
              direction="column"
              justifyContent="center"
              style={{ height: "4rem" }}
            >
              <Grid item>
                <Typography style={{ fontSize: "1.25rem" }} variant="h2">
                  Add User Permissions
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography>
                If this person has a user email under this customer account,
                they will be notified when they've been given access. Otherwise,
                an email will be sent inviting them to join Aker.
              </Typography>
            </Grid>

            <Grid container item spacing={4}>
              <Grid item xs={4}>
                <Input
                  placeholder="New User Email Address"
                  onChange={(e) => setUserEmail(e.target.value)}
                  name="emailAddress"
                  value={userEmail}
                />
              </Grid>
              <Grid item xs={3}>
                <Select
                  defaultLabel="Select new user role"
                  options={
                    (itemType === "farm" && [
                      { title: "Farm User" },
                      { title: "Farm Manager" },
                    ]) ||
                    (itemType === "field" && [
                      { title: "Field User" },
                      { title: "Grower" },
                    ])
                  }
                  selectedOption={{ title: role }}
                  selectOption={(option) => {
                    setRole(option.title);
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  icon={
                    isNotifying ? (
                      <i className={"fa fa-spinner fa-spin"}></i>
                    ) : (
                      <NotificationsIcon stroke="none" />
                    )
                  }
                  click={async () => {
                    if (userEmail === "") return;
                    onAddUser && setIsNotifying(true);
                    onAddUser && (await onAddUser(userEmail));
                    setUserEmail("");
                  }}
                  text={notifyText}
                  type="button"
                />
              </Grid>
              <Grid item>
                <Button
                  icon={<ClearIcon stroke="none" />}
                  click={() => onCancel && onCancel()}
                  text="Cancel Add User"
                  type="button"
                />
              </Grid>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>

      <Grid item container="column">
        <TableContainer>
          <Table className={classes.table} component={Paper}>
            <TableBody>
              {users.map((user) => {
                return (
                  <UserAccessRow
                    name={user.name}
                    email={user.email}
                    role={user.role}
                    selected={selectedUsers[user.subscriptionId] === true}
                    onSelect={() => {
                      if (selectedUsers[user.subscriptionId]) {
                        setSelectedUsers({
                          ...selectedUsers,
                          [user.subscriptionId]: false,
                        });
                      } else {
                        setSelectedUsers({
                          ...selectedUsers,
                          [user.subscriptionId]: true,
                        });
                      }
                    }}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

const FarmFieldsUsersAccess = (props) => {
  const { itemType, id } = useParams();

  return <FarmFieldsUsersAccessList itemType={itemType} id={id} />;
};

export default FarmFieldsUsersAccess;
