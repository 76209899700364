import React, { useState } from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import styles from "./Select.module.scss";
import cn from "clsx";
import {
  FormControl,
  Select as MatUISelect,
  makeStyles,
  MenuItem,
  InputBase,
  withStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "3rem",
    width: "100%",
  },
  option: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: theme.palette.background.tertiary
    }
  },
  selectedOption: {
    backgroundColor: `${theme.palette.background.tertiary} !important`,
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: theme.palette.background.default
    }
  }
}));

const CustomInput = withStyles((theme) => ({
  root: {
    height: "100%",
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiSelect-icon": {
      right: "0.5rem",
      color: theme.palette.text.primary,
    },
  },
  input: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "100%",
    borderRadius: 4,
    padding: "0.5rem 0.8rem",
    backgroundColor: theme.palette.background.tertiary,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    boxSizing: "border-box",
    "&:focus": {
      borderRadius: 4,
      borderColor: theme.palette.colors.blue,
    },
  },
}))(InputBase);

export const Select = ({
  options = [{ title: "" }],
  defaultLabel,
  selectOption,
  selectedOption,
  wrapperClassName,
  inputClassName,
}) => {
  const classes = useStyles();
  const optionsList = defaultLabel
    ? [{ title: defaultLabel }, ...options]
    : [...options];

  return (
    <FormControl className={cn(classes.container, wrapperClassName)}>
      <MatUISelect
        className={cn(classes.input, inputClassName)}
        value={
          selectedOption && selectedOption.title
            ? selectedOption.title
            : optionsList[0].title
        }
        input={<CustomInput />}
      >
        {optionsList.map((option, i) => (
          <MenuItem
            classes={{ selected: classes.selectedOption, root: classes.option}}
            key={i}
            value={option.title}
            onClick={() => selectOption(option)}
          >
            {option.title}
          </MenuItem>
        ))}
      </MatUISelect>
    </FormControl>
  );
};

export const OldSelect = ({
  options = [],
  defaultLabel = "None",
  selectOption = () => { },
  selectedOption,
  setSelectedOption,
  style = {},
  className,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const selectOptions = defaultLabel ? ["None", ...options] : [...options];

  const handleSelect = (option) => {
    const optionName = option === "None" ? "" : option;
    setSelectedOption(optionName);
    setShowDropDown(false);
    selectOption(optionName);
  };

  return (
    <div className={cn(styles.Select, className, "Select")} style={style}>
      <div
        className={cn(styles.SelectBox, selectedOption && styles.Selected)}
        onClick={() => setShowDropDown(!showDropDown)}
      >
        {!selectedOption ? defaultLabel : selectedOption}
        <ArrowForwardIosIcon className="text-base" />
      </div>

      {showDropDown && (
        <div className={`${styles.DropDown} ${styles.ScrollBar}`}>
          {selectOptions.map((option, i) => (
            <span key={i} onClick={() => handleSelect(option)}>
              {option}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};
