import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import { Input } from "../Input/Input";
import styles from "./Search.module.scss";
import cn from "clsx";

export const SearchWithIcon = ({
  type = "text",
  searchValue,
  updateSearchValue,
  placeholder = "Search",
  style = {},
  className,
}) => {
  return (
    <div className={cn(styles.SearchWithIcon, className)} style={style}>
      <SearchIcon />
      <Input
        type={type}
        value={searchValue}
        onChange={updateSearchValue}
        placeholder={placeholder}
      />
    </div>
  );
};
