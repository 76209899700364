import * as React from "react";
import Swal from "sweetalert2";

import { useLazyApi } from "../../../../hooks/useApi";
import { useUser } from "../../../../hooks/useUser";
import {
  useImageReviewerContent,
  useImageReviewerRefresh,
  useImageReviewerResponse,
  useImageReviewerSelectedImage,
  useImageReviewerState,
} from "../contexts/ImageReviewerContext/ImageReviewerContext";
import { useImageReviewerCropSelectionState } from "../contexts/ImageReviewerCropsContext/ImageReviewerCropsContext";

export const useSaveSelectionImageReview = () => {
  const refresh = useImageReviewerRefresh();
  const { data } = useImageReviewerResponse();
  const { meta } = useImageReviewerContent();
  const selectedImage = useImageReviewerSelectedImage();
  const { selectedImagesById, selectedImages } = useImageReviewerState();
  const lastSelectedImage = selectedImagesById[selectedImages[selectedImages.length - 1]];
  const internalState = useImageReviewerCropSelectionState();
  if (JSON.stringify(internalState) === "{}") {
    throw new Error("useSaveSelectionImageReview must be called inside a child of <ImageReviewerCropsStressItemsSelectionProvider>")
  }

  const user = useUser();

  const [executeCall] = useLazyApi(`/api/v1/image_review/${meta.id}`, {
    method: "PUT",
  });

  const onSave = React.useCallback(async (publish = false) => {
    try {
      let newSelectedImageData;

      const newData = data.map((page) => {
        return {
          ...page,
          probeImages: page.probeImages.map((img) => {
            if (selectedImage.id === img.id) {
              // we'll always give priority to our internalState since
              // that represents the last user's selection that might
              // not be yet persisted in the DB
              const cropId =
                internalState.crop || selectedImage.observedCrop.id;

              const newItems = internalState &&
                internalState.stressItems &&
                internalState.stressItems[cropId] ?
                Object.values(
                  internalState.stressItems[cropId] || {}
                )
                  .filter((item) => item)
                  .map((item) => {
                    return {
                      ...item,
                      taggedBy: {
                        id: user.currentUser.id,
                        name: user.currentUser.name,
                        email: user.currentUser.email,
                      },
                    };
                  }) : [];

              // Now we need to do some "magic"
              // If no stress items are selected, we are healthy. So we need to save that item to the local state, as well.
              // But, if we DO have items selected, then we cannot be healthy so remove the healthy item if it is present in the list
              const newStressItems = newItems.length > 0
                ? newItems.filter(function (obj) { return obj.id !== 424; })
                : [
                  {
                    id: 424,
                    name: "No Indication",
                    valuesort_order: 100,
                    taggedBy: {
                      id: user.currentUser.id,
                      name: user.currentUser,
                      email: user.currentUser.email,
                    }
                  },
                ];

              let updated;

              // If crop type hasn't changed we'll update the existing collection
              if (!internalState.crop) {
                updated = {
                  ...img,
                  notes: lastSelectedImage?.notes ?? '',
                  customerReviewStressItems: img.customerReviewStressItems
                    .map((item) => {
                      // Avoids re-adding the same item more than once
                      if (
                        internalState.stressItems &&
                        item.id in
                        internalState.stressItems[selectedImage.observedCrop.id]
                      ) {
                        return null;
                      }

                      return item;
                    })
                    .filter((item) => item)
                    .concat(newStressItems),
                };


                // If the crop type has changed we'll send whatever
                // the user had selected (if any)
              } else {
                updated = {
                  ...img,
                  customerReviewStressItems: newStressItems,
                  notes: lastSelectedImage?.notes ?? ''
                };
              }
              updated = { ...updated, visible: publish }

              // We'll get a reference of the updated data so we can later on send it to the server.
              newSelectedImageData = { ...updated, notes: lastSelectedImage?.notes ?? '', visible: publish };

              return updated;
            }

            return img;
          }),
        };
      });
      // Optimistic update of the UI
      refresh(newData, false);

      const cropStressFindingAttributes = newSelectedImageData.customerReviewStressItems.map(
        (finding) => ({
          cropStressItemId: finding.id,
          taggedById: finding.taggedBy.id,
        })
      );

      await executeCall({
        data: {
          probeImageId: selectedImage.id,
          observedCropId: internalState.crop || selectedImage.observedCrop.id,
          cropStressFindingAttributes,
          rereview: internalState.rereview,
          notes: newSelectedImageData.notes,
          visible: newSelectedImageData.visible,
        },
      });
      return true;
    } catch (e) {
      new Swal({
        title: "Something went wrong",
        html:
          "We're sorry but we couldn't complete your request. Please try again and if the problem persists let us know.",
        icon: "error",
      });

      refresh();
      return false;
    }
  }, [executeCall, data, refresh, lastSelectedImage, selectedImage, internalState, user]);

  return onSave;
};
