import * as React from "react";

// Holds the filters to be applied when performing API requests to our
// backend (favorited, healthy, etc)
const FarmToggleSliderStateContext = React.createContext({ isToggled: true });
const FarmToggleSliderDispatchContext = React.createContext(() => { });

const reducer = (state, action) => {
    switch (action.type) {

        case 'toggle': {
            return { ...state, isToggled: !state.isToggled }
        }
        default: return state;
    }
};

export const FarmToggleSliderFilters = ({ children }) => {
    const [state, dispatch] = React.useReducer(reducer, { isToggled: true });

    return (
        <FarmToggleSliderDispatchContext.Provider value={dispatch}>
            <FarmToggleSliderStateContext.Provider value={state}>
                {children}
            </FarmToggleSliderStateContext.Provider>
        </FarmToggleSliderDispatchContext.Provider>
    );
};

export const useFieldToggleSliderFilterState = () => {
    const state = React.useContext(FarmToggleSliderStateContext);

    return state || { isToggled: true };
};

export const useFieldToggleSliderFilterDispatch = () => {
    const dispatch = React.useContext(FarmToggleSliderDispatchContext);

    return dispatch;
};
