import * as React from "react";

// Holds the filters to be applied when performing API requests to our
// backend (favorited, healthy, etc)
const TrueCauseStressItemFiltersStateContext = React.createContext();
const TrueCauseStressItemFiltersDispatchContext = React.createContext(() => {});

const reducer = (state, action) => {
  switch (action.type) {
    case "image-hover": {
      return { ...state, currentHovered: action.payload?.id };
    }
    case "image-off-hover": {
      return { ...state, currentHovered: undefined };
    }
    case "filter-clear": {
      return {};
    }
    case "filter-apply": {
      const { type: filterType, name: filterName } = action.payload;
      if (action.payload?.action === "replace") {
        return {
          ...state,
          [filterType]: {
            ...(state[filterType] || {}),
            [filterName]: filterName,
          },
        };
      } else if (action.payload?.action === "remove") {
        return {
          ...state,
          [filterType]: {
            ...(state[filterType] || {}),
            [filterName]: undefined,
          },
        };
      } else if (action.payload?.action === "add") {
        return {
          ...state,
          [filterType]: {
            ...(state[filterType] || {}),
            [filterName]: filterName,
          },
        };
      }
      break;
    }

    default:
      return state;
  }
};

export const TrueCauseItemFilters = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, {});

  return (
    <TrueCauseStressItemFiltersDispatchContext.Provider value={dispatch}>
      <TrueCauseStressItemFiltersStateContext.Provider value={state}>
        {children}
      </TrueCauseStressItemFiltersStateContext.Provider>
    </TrueCauseStressItemFiltersDispatchContext.Provider>
  );
};

export const useTrueCauseStressItemFilterState = () => {
  const state = React.useContext(TrueCauseStressItemFiltersStateContext);

  return state || {};
};

export const useTrueCauseStressItemFilterDispatch = () => {
  const dispatch = React.useContext(TrueCauseStressItemFiltersDispatchContext);

  return dispatch;
};
