import React from "react";
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid } from "@material-ui/core";
import { MuiButton } from '../../../../Helpers';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
import { useHistory } from "react-router-dom";

// Custom styles for the Dialog component
const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        position: 'absolute',
        paddingTop: '1rem',
        marginTop: 0,
        maxHeight: '90vh',
        overflowY: 'auto',
        borderRadius: 8,
        boxShadow: theme.shadows[5],
        color: "var(--fontColorMain)",
        backgroundColor: "var(--bgColorTertiary)",
        width: '20rem',
        [theme.breakpoints.down('md')]: {
            width: '20rem',
        },
    },
}));

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
            scale={1}
        >
            <div className="box">
                <Paper {...props} />
            </div>
        </Draggable>
    );
}

export const ConfirmSoilPointPopup = ({ open, onClose, workOrderId }) => {
    const history = useHistory();
    const handleClose = (_event, reason) => {
        if (reason === "backdropClick") {
            return; // Don't close on backdrop click
        } else {
            open = false;
        }
    };

    function stripUrl(url) {
        const regex = /^http:\/\/[^/]+\/[^/]+\/(\d+)\/field\/(\d+).*$/;
        const match = url.match(regex);
        if (match) {
            const [, farmId, fieldId] = match;
            return `farm/${farmId}/field/${fieldId}`;
        }
        return '';
    }


    const handleEdit = () => {
        console.log("edit points");
        var reload = false;
        if (window.location.href.includes("work-order")) {
            reload = true;
        }
        const baseUrl = stripUrl(window.location.href);
        history.replace(`/${baseUrl}/work-order/${workOrderId}`);
        if (reload) {
            window.location.reload();
        }
        onClose();
    };

    return (
        <StyledDialog
            open={open}
            onClose={handleClose}
            hideBackdrop={true}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle >Do you wish to accept or edit the suggested points?</DialogTitle>
            <DialogContent >
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    style={{ alignSelf: "flex-start", padding: "0.5rem 0.25rem" }}
                >
                    <Grid container justifyContent="space-between" style={{ width: "100%", marginLeft: "-0.5rem", marginTop: "20px" }}>
                        <MuiButton
                            text="Accept"
                            style={{ width: "45%" }}
                            onClick={onClose}
                        />
                        <MuiButton
                            text="Edit"
                            style={{ width: "45%" }}
                            variant="contained"
                            color="primary"
                            onClick={handleEdit}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </StyledDialog>
    );
};

