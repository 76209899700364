import React from "react";
import { Grid, Button, Typography, makeStyles } from "@material-ui/core";
import { Textarea, Checkbox } from "../../../../../../Helpers";

const useStyles = makeStyles((theme) => ({
  issueImageContainer: {
    width: "100%",
    "& p": {
      marginTop: "0.5rem",
      marginBottom: "0",
      fontSize: theme.typography.body2.fontSize,
    },
    "& textarea": {
      height: "6rem",
      padding: "0.3rem",
    },
  },
  imageContainer: {
    position: "relative",
  },
  buttonImage: {
    height: 'fit-content',
    width: "100%",
    padding: 0,
    borderRadius: "4px",
    overflow: "hidden",
    "& span, img": {
      width: "100%",
      margin: 0,
    },
  },
  imageSelectBtn: {
    position: "absolute",
    top: "-0.3rem",
    left: "-0.3rem",
  },
}));

export const IssueImageCard = ({
  i,
  image,
  selectImage,
  cropStressFinding,
  updateNote,
}) => {
  const classes = useStyles();
  
  return (
    <Grid key={i} className={classes.issueImageContainer}>
      <Grid className={classes.imageContainer}>
        <Button
          className={classes.buttonImage}
          startIcon={<img src={image.thumbnailPath} alt="true cause" />}
          onClick={() => selectImage(image.id)}
        ></Button>
        <Checkbox
          className={classes.imageSelectBtn}
          checked={cropStressFinding.id ? true : false}
          onChange={() => selectImage(image.id)}
        />
      </Grid>
      <Typography>Notes</Typography>
      <Textarea
        placeholder="Add a description"
        value={cropStressFinding.notes ? cropStressFinding.notes : ""}
        onChange={(e) => updateNote(image.id, e.target.value)}
      />
    </Grid>
  );
};
