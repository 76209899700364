import { useEffect } from "react"
import mapboxgl from "mapbox-gl";
import { makeStyles } from "@material-ui/core";
import { useField } from "../../globalState/fieldContext"

const useMarkerStyles = makeStyles(theme => ({
    // marker: {
    //     backgroundColor: "rgba(34,34,34,0.7)",
    //     paddingTop: "2px",
    //     paddingLeft: "8px",
    //     paddingBottom: "2px",
    //     paddingRight: "8px",
    //     borderTopLeftRadius: "10px",
    //     borderTopRightRadius: "10px",
    //     borderBottomRightRadius: "10px",
    //     borderBottomLeftRadius: "10px",
    // },
    marker: {
        width: "0",
        height: "0",
        "& span": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxSizing: "border-box",
            width: "35px",
            height: "35px",
            color: "white",
            background: "var(--logoColor)",
            border: "solid 2px",
            borderRadius: "0 70% 70% 70%",
            boxShadow: "0 0 0 2px black",
            cursor: "pointer",
            transformOrigin: "0 0",
            transform: "rotateZ(-135deg)",
            paddingTop: "2px",
            paddingLeft: "8px",
            paddingBottom: "2px",
            paddingRight: "8px",
        },
        "& b": {
            transform: "rotateZ(135deg)",
            textAlign: "center",
            lineHeight: "12px"
        },
    }
}))

let activityMarkers = []

const EditPointLocationLayer = ({ map }) => {

    const classes = useMarkerStyles();

    const { pointEditMode, activityEditData, setActivityEditData, newPointPrefix } = useField()

    const optionPopup = new mapboxgl.Popup({
        offset: 25
    });


    const editPopupWrapper = document.createElement("div");
    const editPopupFields = document.createElement("div");
    editPopupFields.innerHTML = `<span>Label:</span><input type="text" id="point_label" value="" placeholder="Enter sample label"/>
                            <span>Order:</span><input type="text" id="point_order" value="" placeholder="Enter sample order"/>
                            <span>Special instruction:</span><input type="text" id="point_si" value="" placeholder="Enter special instruction"/>`;
    editPopupWrapper.appendChild(editPopupFields);

    const editPopupbtnWrapper = document.createElement("div");

    const saveEditBtn = document.createElement("div");
    saveEditBtn.innerHTML = `<button>Save</button>`
    editPopupbtnWrapper.appendChild(saveEditBtn)

    const cancelEditBtn = document.createElement("div");
    cancelEditBtn.innerHTML = `<button>Cancel</button>`
    editPopupbtnWrapper.appendChild(cancelEditBtn)

    editPopupWrapper.appendChild(editPopupbtnWrapper);

    const popup = new mapboxgl.Popup({
        offset: 25
    });

    const handleEditPoint = (activity) => {
        document.getElementById("point_label").value = activity.label
        document.getElementById("point_order").value = activity.order
        document.getElementById("point_si").value = activity.activities[0].notes

        saveEditBtn.addEventListener("click", async () => {

            let newPtLabel = await document?.getElementById("point_label")?.value
            let newPtOrder = await document?.getElementById("point_order")?.value
            let newPtSi = await document?.getElementById("point_si")?.value
            setActivityEditData(activityEditData.map((cad) => {
                if (JSON.stringify(cad) === JSON.stringify(activity)) {
                    return { ...cad, label: newPtLabel, order: parseInt(newPtOrder), activities: cad.activities.map((ca) => { return { ...ca, notes: newPtSi } }) }
                } else {
                    return cad
                }
            }))

            popup.remove();
        });
        cancelEditBtn.addEventListener("click", () => {
            popup.remove();
        });
    };

    const deleteConfirmation = new mapboxgl.Popup({
        offset: 25
    });

    const deletePopupWrapper = document.createElement("div");
    const deletePopupMessage = document.createElement("div");
    deletePopupMessage.innerHTML = `<p>Are you sure?</p>`
    deletePopupWrapper.appendChild(deletePopupMessage)

    const saveDeleteBtn = document.createElement("div");
    saveDeleteBtn.innerHTML = `<button>Save</button>`
    deletePopupWrapper.appendChild(saveDeleteBtn)

    const cancelDeleteBtn = document.createElement("div");
    cancelDeleteBtn.innerHTML = `<button>Cancel</button>`
    deletePopupWrapper.appendChild(cancelDeleteBtn)

    const handleDeletePoint = (e) => {

        saveDeleteBtn.addEventListener("click", () => {
            let deletePointId = parseInt(e?.target?.id?.substr(6));
            setActivityEditData(activityEditData.filter(cad => {
                return cad.order !== deletePointId
            }))
            deleteConfirmation.remove();
        });

        cancelDeleteBtn.addEventListener("click", () => {
            deleteConfirmation.remove();
        });

    }

    // If we have a long name, find the number and then use a short prefix with the number 
    // to label the pins on screen
    const label = (activity) => {
        return shortName(activity?.label)
    }

    const shortName = (name) => {
        let l = '';
        if (name?.length > 3) {
            const match = name.match(/\d+/); // find the first set of consecutive digits
            if (match) {
                l = newPointPrefix + match[0]; // concatenate the matched digits with "w"
            } else {
                l = name.slice(0, 3); // if no digits found, truncate to first 3 characters
            }
        } else {
            l = name
        }
        return l
    }

    useEffect(() => {

        if (activityEditData?.length > 0 && pointEditMode) {
            if (activityMarkers !== null) {
                for (var r = activityMarkers.length - 1; r >= 0; r--) {
                    activityMarkers[r].remove();
                }
            }
            // eslint-disable-next-line
            activityEditData?.forEach((activity) => {
                var el = document.createElement('div');
                el.className = classes.marker;
                el.innerHTML = '<span><b>' + label(activity) + '</b></span>'

                const buttonGroup = document.createElement('div');

                const deleteBtn = document.createElement('div');
                deleteBtn.innerHTML = `<button id=delete${activity?.order}>Delete</button>`;
                buttonGroup.append(deleteBtn)

                deleteBtn.addEventListener('click', (e) => {
                    deleteConfirmation
                        .setDOMContent(deletePopupWrapper)
                        .setLngLat([activity?.lng, activity?.lat])
                        .on("open", () => {
                            handleDeletePoint(e);
                        })
                        .addTo(map);
                });

                const editBtn = document.createElement('div');
                editBtn.innerHTML = `<button id=edit${activity?.order}>Edit</button>`;
                buttonGroup.append(editBtn)

                editBtn.addEventListener('click', (e) => {
                    popup
                        .setDOMContent(editPopupWrapper)
                        .setLngLat([activity?.lng, activity?.lat])
                        .on("open", () => {
                            handleEditPoint(activity);
                        })
                        .addTo(map);
                });

                let activityMarker = new mapboxgl.Marker(el, { draggable: true })
                    .setLngLat([activity?.lng, activity?.lat]).addTo(map)

                activityMarker.getElement().addEventListener('contextmenu', () => {
                    optionPopup.setDOMContent(buttonGroup).setLngLat([activity?.lng, activity?.lat]).addTo(map)
                })

                activityMarkers.push(activityMarker);

                function onDragEnd() {
                    const lngLat = activityMarker.getLngLat();
                    setActivityEditData(activityEditData.map((cad) => {
                        if (shortName(cad.label) === activityMarker.getElement().innerText) {
                            return { ...cad, lat: lngLat.lat, lng: lngLat.lng }
                        } else {
                            return cad
                        }
                    }))
                }
                activityMarker.on('dragend', onDragEnd);
            })
        } else {
            for (let p = activityMarkers.length - 1; p >= 0; p--) {
                activityMarkers[p].remove();
            }
        }
        // eslint-disable-next-line
    }, [activityEditData, pointEditMode])

    return null
}

export default EditPointLocationLayer