import React from "react";
import { Grid, makeStyles, Slider, Typography } from "@material-ui/core";
import { utcDateToString } from "../../../Helpers/utcDateToString";
import { fixTiledriverURL } from "../../../Helpers/fixTiledriverURL";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    top: "4rem",
    marginRight: "-.35rem",
    right: "1rem",
    width: "21rem",
    padding: "9px 14px",
    backgroundColor: theme.palette.background.primary,
    borderRadius: "4px",
    boxSizing: "border-box",
    height: "88px",
  },
  text: { opacity: "0.87" },
}));

export const FlightDateSlider = ({
  imageryDates,
  setSliderDate,
  sliderDate,
  drawerState,
  imagery,
  setImageToDisplay,
  stressMapToDisplay
}) => {
  const classes = useStyles();
  const isFlightData = imageryDates?.length > 0;

  const updateValue = (value) => {
    const newSliderDate = imageryDates[imageryDates.length - value];
    const { selectedImageType } = drawerState.aerialImagery;
    setSliderDate(newSliderDate);
    let tempImageToDisplay = null;
    imagery.forEach((image) => {
      const imageDate = utcDateToString(image.dateFlown);
      const imageType = image.name?.toUpperCase() ?? "";
      if (imageDate === newSliderDate && imageType === selectedImageType) {
        tempImageToDisplay = image;
      }
    });

    if (tempImageToDisplay) {
      setImageToDisplay(fixTiledriverURL(tempImageToDisplay));
    } else {
      setImageToDisplay(null);
    }
  };

  if (imageryDates && imageryDates.length && imageryDates.length > 1) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="flex-start"
        className={`${classes.container} slider`}
        id="FlightDateSlider"
        background="primary"
      >
        {isFlightData ? (
          <>
            <Grid
              container
              item
              justifyContent="space-between"
              className={classes.header}
            >
              <Typography variant="body2" className={classes.text}>
                Imagery Date: {moment(sliderDate).format("YYYY-MM-DD")}
              </Typography>
              <Slider
                aria-labelledby="discrete-slider"
                valueLabelDisplay="off"
                value={imageryDates.length - imageryDates.indexOf(sliderDate)}
                step={1}
                marks
                onChange={(_e, v) => updateValue(v)}
                min={1}
                max={imageryDates.length}
                disabled={stressMapToDisplay}
              />
              {/* <Grid container item justifyContent="space-between" className={classes.header}>
                <Typography style={{ position: "relative" }} variant="caption">
                  {sliderDate}
                </Typography>
              </Grid> */}
            </Grid>
          </>
        ) : (
          <>
            <Typography variant="body2" className={classes.text}>
              No Flight data found
            </Typography>
          </>
        )}
      </Grid>
    );
  } else return null;
};
