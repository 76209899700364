import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Input } from "../../Helpers/Input/Input";
import { Flex } from "../../Layouts/Flex";
import { Button } from "../../Helpers";
import Swal from "sweetalert2";
import {
  fetchInvitation,
  updateSignUp,
} from "../../../../src/controllers/auth";
import styles from "../Home/Home.module.scss";
import cn from "clsx";
import { AuthContext } from "../../../globalState";
import { makeStyles } from "@material-ui/core";
import omit from "../../../lib/omit";

const useStyles = makeStyles((theme) => ({
  leftInviteMessage: {
    textAlign: "center",
    maxWidth: 280,
    margin: "15px auto",
    paddingBottom: "2rem",
  },
  rightInviteMessage: {
    width: `280px !important`,
    textAlign: "center",
  },
  linkContainer: {
    textAlign: "left !important",
  },
  link: {
    textDecoration: "underline",
    marginBottom: theme.spacing(3),
  },
  textCenter: {
    textAlign: "center",
  },
  textwhite: {
    color: "#FFF",
  },
  SignUp: {
    backgroundColor: "hsl(189deg 75% 33%) !important",
    opacity: `1 !important`,
    filter: `opacity(0.9)`,
  },
  stepOneText: {
    color: "hsl(189deg 75% 33%)",
    position: "relative",
    left: "42%",
    bottom: "3%",
  },
  stepTwoText: {
    color: "hsl(189deg 75% 33%)",
    position: "relative",
    left: "42%",
    bottom: "0",
  },
  OutlineButton: {
    border: `1px solid ${theme.palette.text.primary}`,
    color: theme.palette.text.primary,
    backgroundColor: "transparent",
  },
  FormRow: {
    marginBottom: ".25rem !important",
    paddingBottom: ".25rem !important",
  },
  saveRow: {
    width: "600px",
    marginTop: "2rem",
    "& button": {
      width: "150px",
    },
  },
}));

export const AccountSetup = () => {
  const { token } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const totalSteps = `2`;
  const [step, setStep] = useState("1");

  const [invitationData, setInvitationData] = React.useState();
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    mobile: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
  });

  useEffect(() => {
    if (!invitationData && token) {
      fetchInvitation(token).then((response) => {
        const { data } = response;
        setInvitationData(data);
        setUserData({ ...userData, ...data, email: data.email });
      });
    }
    // eslint-disable-next-line
  }, [token, invitationData]);
  const validateUserData = (isStepOne) => {
    return isStepOne
      ? // check the step one properties all have a value &&
        // the passwords match and are appropriate length
        !!userData.firstName &&
          !!userData.lastName &&
          !!userData.email &&
          !!userData.password &&
          !!userData.passwordConfirmation &&
          userData.password === userData.passwordConfirmation &&
          userData.password.length >= 8
      : // step two means they need to have ALL the fields filled out
        Object.keys(userData)
          .filter(
            (key) =>
              !["name", "role", "address2", "farmName", "companyName"].some(
                (a) => a === key
              )
          )
          .every((key) => !!userData[key]) &&
          // along with password stuff
          userData.passwordConfirmation === userData.password &&
          userData.password.length >= 8;
  };

  const { setToken, setUserId } = useContext(AuthContext);

  const createAccount = async (e) => {
    e.preventDefault();
    if (userData.password === userData.passwordConfirmation) {
      await updateSignUp(omit(userData, "name"), invitationData.id, token)
        .then(async (res) => {
          if (res.success) {
            document.cookie = `authToken=${res.data.token}`;
            document.cookie = `userId=${res.data.id}`;
            setUserId(res.data.id);
            setToken(invitationData.token);
            history.push("/farms");
          } else {
            alert();
          }
        })
        .catch(async () => {
          alert();
        });
    } else {
      await Swal.fire({
        icon: "error",
        title: "Passwords don't match",
        text: "Please confirm that you are entering the same password.",
      });
    }
  };

  const alert = async () => {
    await Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "An error occured while creating your account.",
    });
  };

  return (
    <Flex justifyContent={"center"} className={styles.Home}>
      <Flex className={styles.SignUp}>
        <div
          className={cn(styles.BgImgCover, classes.SignUp)}
          style={{ opacity: 1 }}
        />
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className={cn(styles.Info, classes.textwhite)}
        >
          <h1>Welcome </h1>
          <small className={classes.leftInviteMessage}>
            You have been invited by an Aker customer to setup an account.
          </small>
          <span className={classes.linkContainer}>
            <span className={classes.link}>
              Get Aker <i className="fa fa-chevron-right"></i>
            </span>
            <br />
            <br />
            <span className={classes.link}>
              Find a Retailer <i className="fa fa-chevron-right"></i>
            </span>
          </span>
        </Flex>
      </Flex>
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        className={styles.SignUpForm}
      >
        <span
          className={cn(
            step === "1" ? classes.stepOneText : classes.stepTwoText
          )}
        >
          (Step {step}/{totalSteps})
        </span>
        <h1>Account Setup</h1>
        <form className={styles.Form} onSubmit={(e) => createAccount(e)}>
          {step === "1" && (
            <>
              <span className={classes.rightInviteMessage}>
                Please provide some additional details below to set up your Aker
                account.
              </span>
              <Flex justifyContent={"space-between"} className={styles.FormRow}>
                <Input
                  type="text"
                  placeholder="First Name *"
                  value={userData.firstName}
                  onChange={(e) =>
                    setUserData({ ...userData, firstName: e.target.value })
                  }
                  required
                  className={styles.NameInput}
                  autofocus
                />
                <Input
                  type="text"
                  placeholder="Last Name *"
                  value={userData.lastName}
                  onChange={(e) =>
                    setUserData({ ...userData, lastName: e.target.value })
                  }
                  required
                  className={styles.NameInput}
                  autoFocus
                />
              </Flex>
              <Flex className={cn(styles.FormRow, styles.EmailAddressRow)}>
                <Input
                  type="email"
                  placeholder="Email Address *"
                  value={userData.email}
                  onChange={(e) =>
                    setUserData({ ...userData, email: e.target.value })
                  }
                  autoFocus
                  required
                />
              </Flex>
              <Flex justifyContent="space-between" className={styles.FormRow}>
                <Input
                  type="password"
                  placeholder="Password *"
                  value={userData.password}
                  onChange={(e) =>
                    setUserData({ ...userData, password: e.target.value })
                  }
                  autoFocus
                  required
                  className={styles.PasswordInput}
                  minlength="8"
                />
                <Input
                  type="password"
                  placeholder="Confirm *"
                  value={userData.passwordConfirmation}
                  onChange={(e) =>
                    setUserData({
                      ...userData,
                      passwordConfirmation: e.target.value,
                    })
                  }
                  autoFocus
                  required
                  className={styles.PasswordInput}
                  minlength="8"
                />
              </Flex>
              <Button
                disabled={!validateUserData(true)}
                onClick={() => {
                  if (validateUserData(true)) setStep("2");
                }}
                className={styles.SignInBtn}
                text="Next"
              />
            </>
          )}
          {step === "2" && (
            <>
              <Flex className={cn(styles.FormRow, styles.EmailAddressRow)}>
                <Input
                  type="text"
                  placeholder="Cell Number *"
                  value={userData.mobile}
                  onChange={(e) =>
                    setUserData({ ...userData, mobile: e.target.value })
                  }
                  required
                  autoFocus
                />
              </Flex>
              <Flex className={cn(styles.FormRow, classes.FormRow)}>
                <span className={cn(styles.PolicySpan, classes.FormText)}>
                  (###) ###-####
                </span>
              </Flex>
              <Flex className={cn(styles.FormRow)}>
                <Input
                  type="text"
                  placeholder="Address Line 1 *"
                  value={userData.address1}
                  onChange={(e) =>
                    setUserData({ ...userData, address1: e.target.value })
                  }
                  autoFocus
                  required
                />
              </Flex>
              <Flex className={cn(styles.FormRow)}>
                <Input
                  type="text"
                  placeholder="Address Line 2"
                  value={userData.address2}
                  onChange={(e) =>
                    setUserData({ ...userData, address2: e.target.value })
                  }
                  autoFocus
                />
              </Flex>
              <Flex className={cn(styles.FormRow)}>
                <Input
                  type="text"
                  placeholder="Town / City *"
                  value={userData.city}
                  onChange={(e) =>
                    setUserData({ ...userData, city: e.target.value })
                  }
                  autoFocus
                  required
                />
              </Flex>
              <Flex justifyContent="space-between" className={styles.FormRow}>
                <Input
                  type="text"
                  placeholder="State *"
                  value={userData.state}
                  onChange={(e) =>
                    setUserData({ ...userData, state: e.target.value })
                  }
                  autoFocus
                  className={styles.NameInput}
                  required
                />
                <Input
                  type="number"
                  placeholder="Zip Code *"
                  value={userData.zipCode}
                  className={styles.NameInput}
                  onChange={(e) =>
                    setUserData({ ...userData, zipCode: e.target.value })
                  }
                  autoFocus
                  required
                  minlength="5"
                />
              </Flex>
              <Flex justifyContent="space-between" className={classes.saveRow}>
                <Button
                  onClick={() => {
                    setStep("1");
                  }}
                  className={classes.OutlineButton}
                  text="Previous"
                />

                <Button
                  disabled={!validateUserData()}
                  type="submit"
                  className={styles.SignInBtn}
                  text="Finish"
                />
              </Flex>
            </>
          )}
        </form>
      </Flex>
    </Flex>
  );
};
