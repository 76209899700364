import * as React from "react";

import {
  useImageReviewerFiltersDispatch,
  useImageReviewerFiltersState,
} from "../ImageReviewerFilters/ImageReviewerFilters";
import { Autocomplete } from "../../../../Helpers/Autocomplete/Autocomplete";
//import { useImageReviewerDispatch, useImageReviewerContent } from "../../contexts/ImageReviewerContext/ImageReviewerContext"

const options = [
  { label: "All", value: "" },
  { label: "Reviewed", value: "reviewed" },
  { label: "Unreviewed", value: "unreviewed" },
  { label: "No Indication", value: "healthy" },
  { label: "Unhealthy", value: "unhealthy" },
  { label: "Favorited", value: "favorited" },
  { label: "Published", value: "visible" },
  { label: "Deleted", value: "deleted" },
];

const renderInput = (params) => {
  const value =
    params.inputProps.value === "All"
      ? `Review status - All`
      : params.inputProps.value;

  return (
    <Autocomplete.Input
      {...params}
      inputProps={{
        ...params.inputProps,
        value,
      }}
    />
  );
};

export const FilterStatusMenu = ({ group = "status", type = "remote" }) => {
  const filters = useImageReviewerFiltersState();
  const dispatch = useImageReviewerFiltersDispatch();
  // const data = useImageReviewerContent();
  // const dispatchUnselect = useImageReviewerDispatch();
  const onChange = React.useCallback(
    (_, selected) => {
      dispatch({
        type: "filter-apply",
        payload: {
          name: selected.value,
          group,
          type,
          action: "replace",
        },
      });
      // dispatchUnselect({
      //   type: "select-unselect-all",
      //   payload: data,
      // });
      // dispatchUnselect({
      //   type: "select-unselect-all",
      //   payload: data,
      // });
    },
    [dispatch, group, type]
  );

  const defaultValue = React.useMemo(() => {
    const hasFilters = filters.remote?.[group]?.length > 0;

    if (hasFilters) {
      const value = options.find((v) => v.value === filters.remote[group][0]);

      return value;
    }

    return options[0];

    // We only need this on page load and there's no need to update this
    // value since we only need it for `defaultValue`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  return (
    <Autocomplete
      defaultValue={defaultValue}
      options={options}
      onChange={onChange}
      renderInput={renderInput}
      disableClearable
    />
  );
};
