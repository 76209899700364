import React from "react";
import { ListSkeleton } from "../../../Helpers";
import styles from "./FarmFieldsList.module.scss";
import { Flex } from "../../../Layouts/Flex";
import { useTrueCauseStressItemFilterDispatch } from "../../Field/FieldShelf/TrueCause/FilterTrueCauseStressItem";
import { ReactComponent as ArrowRightSolid } from "../../../../assets/icons/ArrowRightSolid.svg";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { yellowThreshold, greenThreshold, trendRightThreshold, trendDownThreshold } from "../../../Helpers/ahiValues";

const isPlural = (num) => (num > 1 || num === 0 ? "s" : "");

const useStyles = makeStyles((theme) => ({
  title: {
    //boxSizing: "border-box",
    width: "100%",
    marginTop: "-1.5rem",
    marginLeft: "-1.5rem",
    fontFamily: "Radikal"
  },
}));

export const FarmFieldsList = ({
  farmFields,
  selectField,
  options,
  selectedCustAccount,
}) => {
  const dispatch = useTrueCauseStressItemFilterDispatch();
  const onMouseEnter = (id) =>
    dispatch({ type: "image-hover", payload: { id } });
  const onMouseLeave = () => dispatch({ type: "image-off-hover" });
  const classes = useStyles();
  return (
    <ul className={styles.FarmFieldsList} onClick={(e) => e.stopPropagation()}>
      {farmFields ? (
        farmFields.map((farmField, i) => {
          const { size, flightCount, issueCount, reportCount } = farmField;
          const ahi = farmField.seasons.length > 0 ? farmField.seasons[0].enrolledInAhi && farmField.seasons[0].ahi[0] : null
          // let ahiColor = null;
          // let ahiTrend = null;
          let ahiClassName = null;
          let ahiDate = null;
          if (ahi) {
            let ahiColor = styles.Ahi_Red
            let ahiValue = ahi.fieldAhi ? parseFloat(ahi.fieldAhi) : 0
            if (ahiValue > yellowThreshold) ahiColor = styles.Ahi_Yellow
            if (ahiValue > greenThreshold) ahiColor = styles.Ahi_Green
            let ahiTrend = styles.Arrow_Up
            if (ahi.fieldTrend < trendRightThreshold) ahiTrend = styles.Arrow_Right
            if (ahi.fieldTrend < trendDownThreshold) ahiTrend = styles.Arrow_Down
            ahiClassName = `${ahiColor} ${ahiTrend}`
            let date = ahi.observedAt.split('T')[0]
            ahiDate = `${date}`
          }
          if (
            !selectedCustAccount ||
            selectedCustAccount === farmField.customerAccountName
          ) {
            return (
              <li
                id={farmField.id}
                onMouseEnter={() => onMouseEnter(farmField.id)}
                onMouseLeave={onMouseLeave}
                key={i}
                className={`${styles.ListItem} ${options.border && styles.Border
                  }`}
                onClick={() => selectField(farmField)}
              >
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  className={styles.Left}
                >
                  <Grid
                    container
                    direction="column"
                    alignItems="flex-start"
                    className={styles.Grid}
                  >
                    <Flex
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="flex-start"
                      className={styles.Left}
                    >
                      <Typography variant="h1" className={classes.title}>
                        {farmField.title}
                      </Typography>
                    </Flex>
                    {ahiClassName && (
                      <Flex
                        justifyContent="space-between"
                        className={styles.Health}
                        alignItems="flex-start"
                        flexDirection="row"
                      >
                        {ahiDate && (`${ahiDate}`)}
                        <ArrowRightSolid className={`${ahiClassName}`} />
                      </Flex>
                    )}

                  </Grid>
                  <p>{`${size} acre${isPlural(
                    size
                  )}, ${flightCount} aerial image${isPlural(
                    flightCount
                  )}, ${issueCount} TrueCause, ${reportCount} report${isPlural(
                    reportCount
                  )}`}</p>
                  {/* <Flex alignItems="center" className={styles.Health}>
                    <i className="fas fa-bug"></i>
                    <i className="fas fa-bacteria"></i>
                    <EcoIcon />
                    <RemoveCircleOutlineIcon />
                    <SpaIcon />
                  </Flex> */}
                </Flex>
              </li>
            );
          } else {
            return null;
          }
        })
      ) : (
        <ListSkeleton
          options={{ border: true }}
          listCount={7}
          styleH1={{ width: "80%" }}
          styleH5={{ width: "50%" }}
          styleP={{ width: "60%" }}
        />
      )}
    </ul>
  );
};
