import { createTheme } from "@material-ui/core/styles";

const akerColors = {
  white: "#FFFFFF", // 255, 255, 255
  gray240: "#F0F0F0", // 240, 240, 240
  gray226: "#E2E2E2", // 226, 226, 226
  gray225: "#E1E1E1", // 225, 225, 225
  gray222: "#DEDEDE", // 222, 222, 222
  gray209: "#D1D1D1", // 209, 209, 209
  gray205: "#CDCDCD", // 205, 205, 205
  gray165: "#A5A5A5", // 165, 165, 165
  gray128: "#808080", // 128, 128, 128
  gray116: "#747474", // 116, 116, 116
  gray86: "#747474", // 86, 86, 86
  gray70: "#464646", // 71, 70, 70
  gray57: "#393939", // 57, 57, 57
  gray48: "#303030", // 48, 48, 48
  gray46: "#2E2E2E", // 46, 46, 46
  gray45: "#2D2D2D", // 45, 45, 45
  gray32: "#202020", // 32, 32, 32
  gray30: "#1E1E1E", // 30, 30, 30
  gray21: "#151515", // 21, 21, 21
  gray19: "#131313", // 19, 19 19
  black: "#000000", // 0, 0, 0
  blue: "#25D6F5", // 37, 214, 245
};

const akerDarkPalette = {
  type: "dark",
  colors: {
    ...akerColors,
  },
  background: {
    primary: akerColors.gray19,
    secondary: akerColors.gray32,
    tertiary: akerColors.gray46,
    alternate: akerColors.gray57,
    quaternary: akerColors.gray57,
    quinary: akerColors.gray70,
    primary75opaque: "rgba(13, 13, 13, 0.75)",
  },
  borderColor: {
    primary: akerColors.gray57
  },
  text: {
    primary: akerColors.gray226,
    secondary: akerColors.gray165,
  },
  primary: {
    main: akerColors.blue,
  },
  secondary: {
    light: akerColors.gray165,
    main: akerColors.gray57,
  },
};

const akerLightPalette = {
  type: "light",
  colors: {
    ...akerColors,
  },
  background: {
    primary: akerColors.gray240,
    secondary: akerColors.gray225,
    tertiary: akerColors.gray205,
    alternate: akerColors.gray165,
    quaternary: akerColors.gray165,
    quinary: akerColors.gray116,
    primary75opaque: "rgba(240, 240, 240, 0.75)",

  },
  borderColor: {
    primary: akerColors.gray116
  },
  text: {
    primary: akerColors.gray46,
    secondary: akerColors.gray32,
  },
  primary: {
    main: akerColors.blue,
  },
  secondary: {
    light: akerColors.gray165,
    main: akerColors.gray57,
  },
};

export const makeAkerTheme = (isDark = true) => {
  return createTheme({
    overrides: {
      MuiSelect: {
        icon: {
          color: akerDarkPalette.text.secondary,
        },
      },
      MuiList: {
        root: {
          backgroundColor: akerDarkPalette.background.alternate,
        },
      },
      MuiListItem: {
        root: {
          "&$selected": {
            backgroundColor: akerDarkPalette.background.alternate,
          },
        },
      },
      MuiSwitch: {
        root: {
          bottom: "2px",
        },
        track: {
          backgroundColor: akerDarkPalette.text.secondary,
        }
      },
      MuiFormLabel: {
        root: {
          color: akerDarkPalette.text.secondary,
        },
      },
      MuiFilledInput: {
        underline: {
          "&&&&:hover:before": {
            borderBottomColor: akerDarkPalette.primary.main,
          },
          "&:after": {
            borderBottomColor: akerDarkPalette.primary.main,
          },
        },
      },
      MuiButton: {
        root: {
          height: '3rem',
          textTransform: 'none',
          fontFamily: ['"RadikalMedium"', "Open Sans"].join(","),
        },
        outlined: {
          borderColor: akerColors.gray226,
        },
      },
      MuiIconButton: {
        root: {
          color: akerDarkPalette.text.primary
        }
      },
      MuiInputBase: {
        input: {
          background: akerDarkPalette.background.alternate,
        },
      },
      MuiTableCell: {
        root: {
          borderBottomColor: akerDarkPalette.background.alternate,
        },
      },
      MuiSvgIcon: {
        root: {
          fontSize: '1.5rem',
          // setting color here messes with Checkbox color
        },
        colorPrimary: {
          color: akerDarkPalette.text.secondary
        }
      },
      MuiCheckbox: {
        root: {
          bottom: "1px",
          height: "40px",
          width: "40px",
          // color: akerDarkPalette.background.alternate,
          "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0,
          },
          "&:not($checked) .MuiIconButton-label:after": {
            content: '""',
            left: 4,
            top: 4,
            height: 15,
            width: 15,
            position: "absolute",
            // changes inner color of unchecked icon
            backgroundColor: 'transparent',
            zIndex: -1,
          },
        },
      },
      MuiSlider: {
        root: {
          '@media (pointer: coarse)': {
            padding: "13px 0"
            //paddingTop: 0
          },
          "& .MuiSlider-root.Mui-disabled": {
            height: 2,
          },
          "& .MuiSlider-thumb.Mui-disabled": {
            height: 12,
            width: 12,
            padding: 0,
            margin: "-5px -6px"
          }
        },
        mark: {
          top: 11,
          height: 6,
          width: 6,
          borderRadius: 3,
          opacity: 0.75,
          //color: "black"
          //padding: 1,
        },
        markActive: {
          backgroundColor: akerColors.blue, //isDark ? akerDarkPalette.background.primary : akerLightPalette.background.primary,
          opacity: 0.75
        },
      },
    },
    spacing: factor => `${1 * factor}rem`,
    typography: {
      fontFamilyLightest: ['"RadikalThin"', "Open Sans"].join(","),
      fontFamilyLight: ['"RadikalLight"', "Open Sans"].join(","),
      fontFamily: ['"Radikal"', "Open Sans"].join(","),
      fontFamilyMedium: ['"RadikalMedium"', "Open Sans"].join(","),
      fontFamilyBold: ['"RadikalBold"', "Open Sans"].join(","),

      h1: {
        fontFamily: ['"RadikalBold"', "Open Sans"].join(","),
        fontSize: "1.75rem",
      },
      h2: {
        fontFamily: ['"RadikalBold"', "Open Sans"].join(","),
        fontSize: "1.5rem",
      },
      h3: {
        fontFamily: ['"RadikalMedium"', "Open Sans"].join(","),
        fontSize: "1.25rem",
      },
      h4: {
        fontFamily: ['"RadikalMedium"', "Open Sans"].join(","),
        fontSize: "1.125rem",
      },
      h5: {
        fontFamily: ['"RadikalMedium"', "Open Sans"].join(","),
        fontSize: "1rem",
      },
      body1: {
        fontFamily: ['"Radikal"', "Open Sans"].join(","),
        fontSize: "1rem",
      },
      body2: {
        fontFamily: ['"Radikal"', "Open Sans"].join(","),
        fontSize: "0.875rem",
      },
      subtitle2: {
        fontFamily: ['"Radikal"', "Open Sans"].join(","),
        fontSize: "0.75rem",
      },
      caption: {
        fontSize: "0.625rem",
        color: isDark ? akerDarkPalette.text.secondary : akerLightPalette.text.secondary
      }
    },
    palette: {
      ...(isDark ? akerDarkPalette : akerLightPalette),
      spacing: {
        navWidth: "6.25rem",
        navHeight: "6rem",
        inputHeight: "3rem",
        shelfWidth: "24rem",
      },
    },
  })
};
