import React from "react";
import { Input } from "../../../Helpers/Input/Input";
import { Button } from "../../../Helpers/Button/Button";
import ClearIcon from "@material-ui/icons/Clear";
import NotificationsIcon from "@material-ui/icons/NotificationsNone";
import { Grid, Typography } from "@material-ui/core";

export const AddUserPermissionsPanel = ({ onAddUser, onCancel }) => {
  const [userEmail, setUserEmail] = React.useState("");

  return (
    <Grid container item direction="column" spacing={4}>
      <Grid
        container
        item
        direction="column"
        justifyContent="center"
        style={{ height: "4rem" }}
      >
        <Grid item>
          <Typography style={{ fontSize: "1.25rem" }} variant="h2">
            Add User Permissions
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Typography>
          If this person has a user email under this customer account, they will
          be notified when they've been given access. Otherwise, an email will
          be sent inviting them to join Aker.
        </Typography>
      </Grid>

      <Grid container item spacing={4}>
        <Grid item xs={4}>
          <Input
            placeholder="New User Email Address"
            onChange={(e) => setUserEmail(e.target.value)}
            name="emailAddress"
            value={userEmail}
          />
        </Grid>
        <Grid item>
          <Button
            icon={<NotificationsIcon stroke="none" />}
            click={async () => {
              if (userEmail === "") return;
              onAddUser && (await onAddUser(userEmail));
              setUserEmail("");
            }}
            text="Notify User"
            type="button"
          />
        </Grid>
        <Grid item>
          <Button
            icon={<ClearIcon stroke="none" />}
            click={() => onCancel && onCancel()}
            text="Cancel Add User"
            type="button"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
