import * as React from "react";
import cn from "clsx";
import GridOnIcon from "@material-ui/icons/GridOn";
import FilterIcon from "@material-ui/icons/Filter";

import { ImageReviewerDeleteAction } from "../../actions/ImageReviewerDeleteAction/ImageReviewerDeleteAction";
import { ImageReviewerShowDeletedAction } from "../../actions/ImageReviewerShowDeletedAction/ImageReviewerShowDeletedAction";
import { ImageReviewerUnDeleteAction } from "../../actions/ImageReviewerUnDeleteAction/ImageReviewerUnDeleteAction";
import { ImageReviewerSelectAllAction } from "../../actions/ImageReviewerSelectAllAction/ImageReviewerSelectAllAction";
import { ImageReviewerViewModeAction } from "../../actions/ImageReviewerViewModeAction/ImageReviewerViewModeAction";
import styles from "./BulkImageReviewerActions.module.scss";

export const BulkImageReviewerActions = ({showDeleted, setShowDeleted}) => {

//const [showDeleted, setShowDeleted] = React.useState(false);

  return (
    <div className={cn(styles.BulkImageReviewerActions)}>
      <div className={styles.BulkImageReviewerActions__Body}>
        Please select the images below that you would like to delete from the
        database upon initial review.
      </div>

      <div className={styles.BulkImageReviewerActions__Actions}>

      <ImageReviewerShowDeletedAction showDeleted={showDeleted} setShowDeleted={setShowDeleted} />

        <ImageReviewerSelectAllAction  />
        <div>
          { !showDeleted && ( <ImageReviewerDeleteAction />) }
          { showDeleted && ( <ImageReviewerUnDeleteAction  />) }

          <ImageReviewerViewModeAction viewMode="grid">
            <GridOnIcon />
          </ImageReviewerViewModeAction>

          <ImageReviewerViewModeAction viewMode="preview">
            <FilterIcon />
          </ImageReviewerViewModeAction>
        </div>
      </div>
    </div>
  );
};
