import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { drawStyles } from "./drawStyles";
import * as turf from "@turf/turf";
import Swal from "sweetalert2";
import { getCookie } from "../Helpers";

export const DrawControls = ({
  map,
  drawingEnabled,
  geometryToEdit,
  setGeometryToEdit,
  removeLayer,
}) => {
  const [drawControl, setDrawControl] = useState(null);

  useEffect(() => {
    if (!map) return;
    if (drawingEnabled && !drawControl) {
      const draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: true,
          trash: true,
          combine_features: true,
          uncombine_features: true,
        },
        styles: drawStyles,
      });
      setDrawControl(draw);
    }
    // eslint-disable-next-line
  }, [map, drawingEnabled]);

  const updateArea = (e) => {
    var moreThanOnePolygonDrawn = false;
    try {
      const allItems = drawControl.getAll()
      moreThanOnePolygonDrawn = allItems?.features.length > 2;
    }
    catch {
      moreThanOnePolygonDrawn = false;
    }

    if (moreThanOnePolygonDrawn) {
      if (!(getCookie("showCombineRegionAlert") === "false")) {
        Swal.fire({
          title: "Multi-polygon added",
          text:
            "You must use the combine tool in the lower right to save a multi-polygon region.",
          icon: "warning",
          input: "checkbox",
          inputPlaceholder: "Don't show this again",
        }).then(function (result) {
          if (result?.value) {
            document.cookie = "showCombineRegionAlert=false";
          }
        });
      }
    }
    const sqMetersPerAcre = 4046.85642245;
    if (e.features?.length > 0) {
      const bbox = turf.bbox(e.features[0]);
      const bboxPolygon = turf.bboxPolygon(bbox).geometry.coordinates[0];
      setGeometryToEdit({
        ...geometryToEdit,
        geometry: e.features[0].geometry,
        acres: +(
          turf.area(e.features[0].geometry) / sqMetersPerAcre
        ).toFixed(2),
        polygon: {
          type: e.features[0].type,
          geometry: e.features[0].geometry,
        },
        bounds: [bboxPolygon[0], bboxPolygon[2]],
      });
    } else if (e.createdFeatures) {
      // This is what we get when we combine features (i.e. multipolygon)
      const bbox = turf.bbox(e.createdFeatures[0]);
      const bboxPolygon = turf.bboxPolygon(bbox).geometry.coordinates[0];
      setGeometryToEdit({
        ...geometryToEdit,
        geometry: e.createdFeatures[0].geometry,
        acres: +(
          turf.area(e.createdFeatures[0].geometry) / sqMetersPerAcre
        ).toFixed(2),
        polygon: {
          type: e.createdFeatures[0].type,
          geometry: e.createdFeatures[0].geometry,
        },
        bounds: [bboxPolygon[0], bboxPolygon[2]],
      });
    } else {
      console.error("error with draw feature");
    }
  };

  const addControls = () => {
    if (drawControl) {
      map.addControl(drawControl, "bottom-right");

      const layerId = geometryToEdit.id ? `polygon-${geometryToEdit.id}` : "";
      removeLayer(layerId + "-fill");
      removeLayer(layerId + "-line");

      if (geometryToEdit?.id && geometryToEdit?.geometry) {
        drawControl.add({
          id: layerId,
          type: "Feature",
          properties: {},
          geometry: geometryToEdit?.geometry,
        });
      }
      map.on("draw.create", (e) => updateArea(e));
      map.on("draw.delete", (e) => updateArea(e));
      map.on("draw.update", (e) => updateArea(e));
      map.on("draw.combine", (e) => updateArea(e));
    }
  };

  const removeControls = () => {
    // map.off("draw.create");
    // map.off("draw.delete");
    // map.off("draw.update");
    // map.off("draw.combine");
    map.removeControl(drawControl);
    setDrawControl(null);
  };

  useEffect(() => {
    if (drawingEnabled && drawControl && !map.hasControl(drawControl)) {
      addControls();
    } else if (!drawingEnabled && drawControl) {
      removeControls();
    }

    // creating NEW region or polygon
    if (
      drawControl &&
      geometryToEdit &&
      geometryToEdit.geometry &&
      geometryToEdit.id &&
      !map.getSource(`polygon-${geometryToEdit.id}`)
    ) {
      if (map.hasControl(drawControl)) {
        drawControl.add({
          id: `polygon-${geometryToEdit.id}`,
          type: "Feature",
          properties: {},
          geometry: geometryToEdit.geometry,
        });
      }
    }
    // DON'T add drawControl in dependency array. It will cause crash
    // eslint-disable-next-line
  }, [
    drawControl,
    drawingEnabled,
    geometryToEdit,
    addControls,
    removeControls,
  ]);

  return null;
};

DrawControls.propTypes = {
  map: PropTypes.object,
};
