import * as React from "react";
import cn from "clsx";

//import { Img } from "../ImageGrid/ImageGrid";
import { useImageReviewerSelectedImage } from "../../contexts/ImageReviewerContext/ImageReviewerContext";
import styles from "./ProveImagePreview.module.scss";
import { Grid } from "@material-ui/core";
import { useTrueCauseStressItemFilterDispatch } from "../../../Field/FieldShelf/TrueCause/FilterTrueCauseStressItem";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import InnerImageZoom from 'react-inner-image-zoom'

export const BaseProbeImagePreview = React.memo(
  ({ variant = "contained", ...props }) => {
    const dispatch = useTrueCauseStressItemFilterDispatch();
    const onMouseEnter = () => dispatch({ type: 'image-hover', payload: { id: props.id } });
    const onMouseLeave = () => dispatch({ type: 'image-off-hover' })
    return (
      <Grid
        container
        alignItems="center"
        className={cn(
          styles.ProbeImagePreview,
          props.className,
          styles[`ProbeImagePreview--${variant}`]
        )}
        id="ProbeImage"
      >
        <InnerImageZoom
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          moveType="drag"         
          src={props.src}
          {...props}
          className={cn(
            styles.ProbeImagePreview__Image,
            styles[`ProbeImagePreview__Image--${variant}`]
          )}
        />
      </Grid>
    );
  }
);
BaseProbeImagePreview.displayName = "BaseProbeImagePreview";

export const ProbeImagePreview = React.memo((props) => {
  // It shouldn't be possible to have no selected images since by
  // default we'll automatically select the first image returned by
  // the collection and from there on we won't allow the user to have
  // less than 1 image selected.
  const selectedImage = useImageReviewerSelectedImage();

  if (!selectedImage) {
    return null;
  }

  return (
    <BaseProbeImagePreview
      id={selectedImage.id}
      src={selectedImage.imagePath}
      key={selectedImage.imagePath}
      {...props}
    />
  );
});
ProbeImagePreview.displayName = "ProbeImagePreview";
