import React from "react";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { Input } from "../../Helpers/Input/Input";
import { Flex } from "../../Layouts/Flex";
import ClearIcon from "@material-ui/icons/Clear";
import { MuiButton } from "../../Helpers";
import { addSeason, editSeason, getSeason } from "../../../controllers/seasons";
import { useAuth } from "../../../hooks/useAuth";
import { NewDatePicker } from "../../Helpers/DatePicker/DatePicker";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete2 } from "../../Helpers/Autocomplete/Autocomplete";
import { useField } from "../../../globalState/fieldContext";
import Swal from "sweetalert2";
import cn from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: "1rem",
  },
  datePicker: {
    "& .MuiInputBase-root, .MuiInputBase-input": {
      backgroundColor: theme.palette.background.tertiary,
    },
  },
  addWorkOrderRoot: {
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    overflow: "scroll",
  },
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: "left",
  },
  item: {
    marginTop: theme.spacing(1),
    opacity: 0.8,
  },
  tertiaryBackground: {
    background: theme.palette.background.tertiary,
  },
  label: {
    marginTop: theme.spacing(0.5),
  },
  buttons: {
    marginTop: theme.spacing(1),
  },
  button: {
    width: "40%",
  },
  blocks: {
    marginRight: theme.spacing(2),
  },
  input: {
    marginTop: "0.5rem",
    marginBottom: "1.5rem",
  },
  cropInput: {
    marginBottom: "1.5rem",
    marginTop: "0.5rem"
  },
  targetYieldInput: {
    marginTop: "1rem",
    marginTBottom: "1.5rem",
    paddingLeft: 0, //theme.spacing(2),
    borderLeft: "1rem",
    textOffset: "1rem",
  },
}));

/**
 * Component for creating a new season
 * @param field the field for the planting season
 * @param crops the list of available crops
 * @param onCancel a callback that gets invoked. Useful for closing the form when the `cancel` button is pressed.
 * @returns {JSX.Element}
 */

export const AddOrEditSeason = ({ onCancel, crops, seasonId, possibleMaturities, possibleRowSpacings }) => {
  const { token } = useAuth();
  const classes = useStyles();
  const [allMaturities] = React.useState(possibleMaturities);
  const [allRowSpacings] = React.useState(possibleRowSpacings.map(String));
  const {
    field,
    selectedSeason,
    setSelectedSeason,
    updateSeasons,
  } = useField();
  const [crop, setCrop] = React.useState("");
  const [previousCrop, setPreviousCrop] = React.useState("");
  const [seasonDate, setSeasonDate] = React.useState("");
  const [geoRegion, setGeoRegion] = React.useState("");
  const [maturity, setMaturity] = React.useState("");
  const [rowSpacing, setRowSpacing] = React.useState("");
  const [standCount, setStandCount] = React.useState("");
  const [targetYield, setTargetYield] = React.useState("");
  const [targetPopulation, setTargetPopulation] = React.useState("");
  const [hybridVariety, setHybridVariety] = React.useState("");
  // Status can be "Saving" | "Success" | "Error" | "New"
  const [saveStatus, setSaveStatus] = React.useState({ status: "Save" });
  // Status can be "Saving" | "Success" | "Error" | "Loading"
  const [seasonData, setSeasonData] = React.useState({
    status: "Loading",
    season: {},
    error: {},
  });
  const [enableAHI, setEnableAHI] = React.useState(false);
  const [availableMaturities, setAvailableMaturities] = React.useState(null);

  function parseMaturities(maturities, id) {
    setMaturity({ 'name': '' }) // clear it
    const list = maturities.filter(m => id === m.cropId).map(n => n.name);
    const maturitiesList = [];
    for (var i = 0; i < list.length; i++) {
      maturitiesList.push({ "name": list[i] })
    }
    return maturitiesList.sort();
  }

  function sortByKey(array, key) {
    return array.sort((a, b) => {
      let x = a[key];
      let y = b[key];

      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  // function parseRowSpacings(rowSpacings, id) {
  //   const rowSpacingList = [];
  //   for (var i = 0; i < rowSpacings.length; i++) {
  //     rowSpacingList.push({ "name": list[i] })
  //   }
  //   return maturitiesList;
  // }

  // Retrieve the season data and set the initial values for crop and plantDate.
  React.useEffect(() => {
    if (seasonData.status === "Loading" && seasonId) {
      getSeason(token, seasonId).then((resp) => {
        setSeasonData({
          status: "Success",
          season: resp,
          error: undefined,
        });
        setSeasonDate(resp.plantDate);
        setCrop({ name: resp.crop.name, id: resp.crop.id });
        setPreviousCrop({ name: resp.crop.prevName, id: resp.crop.prevId });
        setGeoRegion(resp.geoRegion);
        setEnableAHI(resp.services.enrolledInAhi);
        setMaturity({ 'name': resp.crop.maturity }); // needs to be set after the crop so we select it once the crop changes
        setTargetYield(resp.crop.targetYield);
        setTargetPopulation(resp.crop.targetPopulation);
        setStandCount(resp.crop.standCount);
        setRowSpacing(resp.crop.rowSpacing?.toString())
        setHybridVariety(resp.crop.hybridVariety)
      });
    } else setSeasonData({ ...seasonData, status: "Loaded" });
    // eslint-disable-next-line
  }, [token, seasonId, seasonData.status]);

  React.useEffect(() => {
    if (crop.id) {
      const maturities = parseMaturities(allMaturities, crop.id)
      const sorted = sortByKey(maturities, 'name')
      setAvailableMaturities(sorted.sort())
    };
  }, [crop.id, allMaturities]);

  const handleSaveForm = async () => {
    const invalidContent = !crop ? "crop" : !seasonDate ? "date" : false;
    if (invalidContent) {
      Swal.fire({
        title: `Please select a ${invalidContent}`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        // dangerMode: true,
        // closeOnConfirm: true,
      });
    }
    if (!invalidContent) {
      setSaveStatus({ status: "Saving" });
      let response = {};
      if (seasonId) {
        response = await editSeason(token, seasonId, {
          cropType: crop.id,
          previousCropType: previousCrop.id,
          plantDate: seasonDate,
          enableAhi: enableAHI,
          geoRegion,
          crop_maturity: maturity.name,
          target_yield: targetYield,
          target_population: targetPopulation,
          row_spacing: Number(rowSpacing),
          stand_count: standCount,
          hybrid_variety: hybridVariety,
        });
      } else {
        response = await addSeason(token, {
          cropId: crop.id,
          previousCropId: previousCrop.id,
          fieldId: field[0].id,
          plantDate: seasonDate,
          enableAhi: enableAHI,
          geoRegion,
          crop_maturity: maturity.name,
          target_yield: targetYield,
          target_population: targetPopulation,
          row_spacing: Number(rowSpacing),
          stand_count: standCount,
          hybrid_variety: hybridVariety,
        });
      }
      setSaveStatus({ status: "Saved" });
      if (!selectedSeason?.id) setSelectedSeason(response);
      updateSeasons();
      onCancel();
    }
  };

  if (seasonData.status === "Loading") {
    return (
      <span style={{ marginTop: "1rem" }}>Loading Season information....</span>
    );
  }

  return (
    <Flex
      flexDirection="column"
      alignItems="stretch"
      className={classes.container}
    >
      <Typography variant="h4" color="primary" className={classes.item}>
        {seasonId ? "Edit Season" : "Add Season"}
      </Typography>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSaveForm();
        }}
      >
        <Typography variant="body2" className={classes.label}>
          Crop type*
        </Typography>
        <Autocomplete2
          options={crops ?? []}
          value={crop || null}
          getOptionLabel={(o) => o.name}
          onChange={(_, v) => setCrop(v)}
          labelText=""
          inputClassName={classes.cropInput}
        />
        <Typography variant="body2" className={classes.label}>
          Planting date*
        </Typography>
        <NewDatePicker
          value={seasonDate}
          disablePast={false}
          onChange={(newValue) => setSeasonDate(newValue)}
        />
        <Typography variant="body2" className={classes.label}>
          Previous crop type:
        </Typography>
        <Autocomplete2
          options={crops ?? []}
          value={previousCrop || null}
          getOptionLabel={(o) => o.name}
          onChange={(_, v) => setPreviousCrop(v)}
          labelText=""
          inputClassName={classes.cropInput}
        />
        <Typography variant="body2" className={classes.label}>
          Hybrid/Variety:
        </Typography>
        <Input
          className={cn(classes.input)}
          type="text"
          defaultValue={hybridVariety || null}
          onChange={(e) => setHybridVariety(e.target.value)}
        />
        <Typography variant="body2" className={classes.label}>
          Crop maturity type:
        </Typography>
        <Autocomplete2
          options={availableMaturities ?? []}
          value={maturity || null}
          getOptionLabel={(o) => o.name}
          onChange={(_, v) => setMaturity(v)}
          labelText=""
          inputClassName={classes.cropInput}
        />
        <Typography variant="body2" className={classes.label}>
          Row spacing (inches):
        </Typography>
        <Autocomplete2
          options={allRowSpacings ?? []}
          value={rowSpacing || null}
          getOptionLabel={(o) => o.toString()}
          onChange={(_, v) => setRowSpacing(v)}
          labelText=""
          inputClassName={classes.cropInput}
        />
        <Typography variant="body2" className={classes.label}>
          Target yield (bu/ac):
        </Typography>
        <Input
          placeholder="Yield"
          type="number"
          value={targetYield}
          //step="any"
          className={cn(classes.input)}
          onChange={(e) => {
            setTargetYield(+e.currentTarget.value);
          }}
        />
        <Typography variant="body2" className={classes.label}>
          Target population (plants/ac):
        </Typography>
        <Input
          placeholder="Population"
          type="number"
          value={targetPopulation}
          //step="any"
          className={cn(classes.input)}
          onChange={(e) => {
            setTargetPopulation(+e.currentTarget.value);
          }}
        />
        <Typography variant="body2" className={classes.label}>
          Stand count (plants/ac):
        </Typography>
        <Input
          placeholder="StandCount"
          type="number"
          value={standCount}
          className={cn(classes.input)}
          onChange={(e) => {
            setStandCount(+e.currentTarget.value);
          }}
        />
        {field[0]?.permissions.enrolledAhi &&
          <FormControlLabel
            onClick={() => {
              setEnableAHI(!enableAHI);
            }}
            control={<Checkbox
              checked={enableAHI}
              style={{ color: "var(--fontColorMain)", marginTop: '0.4rem', marginLeft: '2rem' }}
            />}
            label={"Enroll in AHI"}
            style={{ color: "var(--fontColorMain)", marginTop: '-1rem' }}
          />
        }

        {/* <Typography variant="body2" className={classes.label}>
          Please enter a District:
        </Typography>
        <NewInput
          placeholder="District"
          value={geoRegion}
          onChange={(e) => setGeoRegion(e.target.value)}
          className={classes.input}
        /> */}
        <div style={{
          marginTop: '1.5rem',
          marginBottom: '1rem',
        }} >
          <Flex justifyContent="space-between" className={classes.buttons}>
            <MuiButton
              text="Cancel"
              icon={<ClearIcon />}
              className={classes.button}
              onClick={onCancel}
            />
            <MuiButton
              type="submit"
              text={saveStatus.status}
              icon={
                saveStatus.status === "Saving" ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : null
              }
              className={classes.button}
              variant="contained"
              color="primary"
            />
          </Flex>
        </div>
      </form>
    </Flex>
  );
};
