import React from "react";
import { Button } from "../../Helpers";
import { Flex } from "../../Layouts/Flex";
import styles from "./Home.module.scss";

export const SignUp = () => {
  const { location } = window;
  return (
    <Flex className={styles.SignUp}>
      <div className={styles.BgImgCover} />
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        className={styles.Info}
      >
        <h1>New Here?</h1>
        <span>
          Enter your details to start your journey with us, it's free!
        </span>
        <Button
          className={styles.SignUpBtn}
          text="Sign Up"
          click={() => location.assign("/sign-up")}
        />
      </Flex>
    </Flex>
  );
};
