import React from "react";
import { Input } from "../../Helpers/Input/Input";
import { Flex } from "../../Layouts/Flex";
import { Button } from "../../Helpers";
import Swal from "sweetalert2";
import styles from "./Home.module.scss";
import { ACTIONS } from "./Home";
import { resetPassword } from "../../../controllers/auth";

export const ForgotPasswordForm = ({ userData, dispatch }) => {
  const { location } = window;

  const handleResetPassword = async (e) => {
    e.preventDefault();
    await resetPassword(userData.email)
      .then(async () => {
        await Swal.fire({
          title: "Success!",
          text: "If you have an account in our system, you will receive an email shortly.",
        });
        location.assign("/");
      })
      .catch(async () => {
        await Swal.fire({
          icon: "error",
          title: "Uh oh!",
          text: "It's not you, it's us. An error occured with your request",
        });
      });
  };

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className={styles.ForgotPasswordForm}
    >
      <h1>Forgot Password?</h1>
      <span>
        Enter the email address associated with your account. We will email you
        a link to reset your password.
      </span>
      <form className={styles.Form} onSubmit={(e) => handleResetPassword(e)}>
        <Flex className={`${styles.FormRow} ${styles.ForgotPasswordInput}`}>
          <Input
            type="email"
            placeholder="Email Address *"
            value={userData.email}
            onChange={(e) =>
              dispatch({
                type: ACTIONS.UPDATE_FIELD,
                payload: { field: "email", value: e.target.value },
              })
            }
            autofocus
            required
          />
        </Flex>
        <Button
          className={styles.ForgotPasswordBtn}
          type="submit"
          text="Request Reset Link"
        />
      </form>
    </Flex>
  );
};
