import React from "react";
import Swal from "sweetalert2";
import { Grid, Typography, FormControlLabel } from "@material-ui/core";
import { Textarea } from "../../../../Helpers/Input/Textarea";
import { Input } from "../../../../Helpers/Input/Input";
import { Checkbox } from "../../../../Helpers/Checkbox/Checkbox";
import { Button } from "../../../../Helpers/Button/Button";
import { useAuth } from "../../../../../hooks/useAuth";
import { useField } from "../../../../../globalState/fieldContext";
import { updateRegion } from "../../../../../controllers/regions";

export const EditRegion = ({ classes, setEditRegion }) => {
  const { token } = useAuth();
  const {
    geometryToEdit,
    setGeometryToEdit,
    setDrawingEnabled,
    setField,
    selectedSeason,
    refreshRegions,
    refreshMapDrawing,
  } = useField();

  const [name, setName] = React.useState("");
  const [cropYield, setCropYield] = React.useState(0);
  const [notes, setNotes] = React.useState("");
  const [exclusion, setExclusion] = React.useState(false);
  const [untreated, setUntreated] = React.useState(false);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    setName(geometryToEdit?.name ?? "");
    setCropYield(geometryToEdit?.yield ?? 0);
    setNotes(geometryToEdit?.notes ?? "");
    setExclusion(geometryToEdit?.exclusion ?? false);
    setUntreated(geometryToEdit?.untreated ?? false);
  }, [geometryToEdit]);

  const handleCancelRegion = () => {
    setEditRegion(false);
    setGeometryToEdit(null);
    setDrawingEnabled(false);
    setField((field) => [{ ...field[0], show: true }]);
  };

  const handleSubmitRegion = async (region) => {
    const regionData = {
      seasonId: selectedSeason.id,
      name: name,
      exclusion: exclusion,
      untreated: untreated,
      cropYield: cropYield,
      notes: notes || region.notes,
      polygon: JSON.stringify({
        type: "Feature",
        geometry: geometryToEdit?.geometry ?? region.geometry,
      }),
      bounds: geometryToEdit.bounds,
      size: geometryToEdit.size,
    };
    const updatedRegion = await updateRegion(
      token,
      regionData,
      geometryToEdit.id
    ).catch(() => {
      setError(true);
    });

    if (updatedRegion) {
      setError(false);
      refreshRegions();
      refreshMapDrawing();
      setEditRegion(false);
      setField((field) => [{ ...field[0], show: true }]);
      await Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Your region was edited.",
      });
    }
  };

  return (
    <Grid container>
      <Input
        value={name}
        onChange={(e) => {
          setName(e.currentTarget.value);
        }}
        placeholder="Region Name"
        className={classes.newRegionControl}
      />
      <Grid className={classes.newRegionControl}>
        <FormControlLabel
          value={exclusion}
          onClick={() => {
            setExclusion(!exclusion);
          }}
          control={<Checkbox checked={exclusion} />}
          label={"Exclude region from reporting?"}
        />
        <FormControlLabel
          value={untreated}
          onClick={() => {
            setUntreated(!untreated);
          }}
          control={<Checkbox checked={untreated} />}
          label={"Untreated?"}
        />
      </Grid>
      <Input
        value={cropYield}
        placeholder="Yield"
        type="number"
        step="any"
        className={classes.newRegionControl}
        onChange={(e) => {
          setCropYield(+e.currentTarget.value);
        }}
      />
      <Textarea
        value={notes}
        placeholder="Notes"
        rows={6}
        variant="filled"
        onChange={(e) => {
          setNotes(e.currentTarget.value);
        }}
        className={classes.newRegionNotes}
      />
      {error && (
        <Typography color="error" variant="subtitle1">
          The region you have drawn is either outside of the bounds of the field
          or intersects itself.
        </Typography>
      )}
      <Grid container item justifyContent="space-around">
        <Button
          text="Submit"
          type="submit"
          className={classes.newRegionActionBtn}
          onClick={handleSubmitRegion}
        />
        <Button
          text="Cancel"
          className={classes.newRegionActionBtn}
          variant="outlined"
          onClick={handleCancelRegion}
        />
      </Grid>
    </Grid>
  );
};
