import * as React from "react";

export const initialState = {
  selectedImagesById: {},
  selectedImages: [],
  thumbnailImages: {},
};

// Holds the internal state of the page. Things like selected images,
// etc.
export const ImageReviewerStateContext = React.createContext(initialState);
export const ImageReviewerDispatchContext = React.createContext(() => { });
export const setThumbnailContent = (data) => {
  ImageReviewerStateContext._currentValue.thumbnailImages = data.groupedImages;
}
// Holds the raw response from the server as is.
export const ImageReviewerResponseContext = React.createContext({});

// Holds the derived `content` (from response and possibly
// other data sources) that is shown in screen (like table, image
// grids, etc)
export const ImageReviewerContentContext = React.createContext([]);

// Custom hooks for consuming the Contexts on this file.
export const useImageReviewerState = () => {
  const state = React.useContext(ImageReviewerStateContext);

  return state;
};

export const useImageReviewerDispatch = () => {
  const dispatch = React.useContext(ImageReviewerDispatchContext);

  return dispatch;
};

// Expose the raw response from the server.
export const useImageReviewerResponse = () => {
  const data = React.useContext(ImageReviewerResponseContext);

  return data;
};

// `content` is derived from `data` (data coming from the server).
// this is used for things like infinite scrolling, table data,
// metadata, etc.
export const useImageReviewerContent = () => {
  const data = React.useContext(ImageReviewerContentContext);
  return data;
};

export const useImageReviewerSelectedImage = () => {
  const { selectedImages } = useImageReviewerState();
  const { images } = React.useContext(ImageReviewerContentContext);

  return React.useMemo(() => {
    if (!images) {
      return null;
    }

    // change this to look at the last element of the array instead of the first
    // since we are pushing to the array on selection, the last element is the last selected image
    return images.find(
      (i) => i.id === selectedImages[selectedImages.length - 1]
    );
  }, [selectedImages, images]);
};

export const useImageReviewerRefresh = () => {
  const { mutate } = React.useContext(ImageReviewerResponseContext);

  return (data, shouldRevalidate) => mutate(data, shouldRevalidate);
};

// function partitionPhotos(images) {
//   if (images) {
//     const x = images.reduce(function (memo, x) {
//       if (!memo[x.label - 1]) { memo[x.label - 1] = []; }
//       memo[x.label - 1].push(x);
//       return memo;
//     }, {});
//     return x
//   }
// }

export const useInitSelectedImage = (images) => {
  const { selectedImages } = useImageReviewerState();
  const dispatch = useImageReviewerDispatch();

  React.useEffect(() => {
    // We're only going to try to run this when:
    // 1. we have the api data for `images`
    // 2. there are no previously selected images
    // 3. the selected image is not in the group of images (like on a filter change)
    const selectedId = selectedImages.length
      ? selectedImages[selectedImages.length - 1]
      : null;
    if (images && !selectedId) {
      let firstImage;
      if (images.every((image) => !image.label)) {
        [firstImage] = images;
      } else {
        const groups = images.map((a) => a.label);
        const minimumLabel = groups.sort((a, b) => a.label <= b.label ? a.label : -1)[0]
        //Math.min(...images.map((a) => +a.label));
        firstImage = images.filter(
          (image) => image.label === minimumLabel
        )[0];
      }
      if (firstImage)
        dispatch({
          type: "image-press",
          payload: {
            ...firstImage,
            id: firstImage.id,
            action: "select",
          },
        });
    }
  }, [dispatch, images, selectedImages]);
};
