import { makeStyles } from "@material-ui/core";
import * as React from "react";
import {
  useSelectedCustomerAccount,
  useSelectedCustomerAccountDispatch,
} from "../../../globalState";
import { Select } from "../Select/Select";
import { Skeleton } from "../Skeletons/Skeleton";
import { useUser } from "../../../hooks/useUser";

const useStyles = makeStyles((theme) => ({
  accountSelector: {
    // height: "100%",
    width: "22.5rem",
  },
}));


export const CustomerAccountManagerSelector = ({ handleSelectedAccount }) => {
  const classes = useStyles();
  const dispatch = useSelectedCustomerAccountDispatch();
  const selectedCustomerAccount = useSelectedCustomerAccount();
  const [selectedAccount, setSelectedAccount] = React.useState({});
  const user = useUser();

  const content = React.useMemo(() => {
    if (!user?.currentUser.customerAccounts) {
      return null;
    }

    return user.currentUser.customerAccounts
      .map((item) => {
        return {
          title: item.name,
          id: item.id,
          label: item.name,
          value: item.id,
        };
      })
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
  }, [user]);

  React.useEffect(() => {
    if (selectedCustomerAccount) {
      setSelectedAccount(
        content.find((a) => a.id === selectedCustomerAccount.id)
      );
    } else {
      setSelectedAccount({});
    }
  }, [selectedCustomerAccount, content]);

  if (!content) {
    return <Skeleton width="300px" height="70px" />;
  }

  const handleSelect = (option) => {
    setSelectedAccount(content.find((a) => a.label === option.label));
    if (typeof handleSelectedAccount === "function") { handleSelectedAccount(content.find((a) => a.label === option.label), content) }
    dispatch({
      type: "custom-account-select",
      payload: {
        id: option.id,
      },
    });
  };

  return (
    <Select
      options={content}
      defaultLabel={"Select customer account"}
      selectOption={(option) => handleSelect(option)}
      selectedOption={selectedAccount}
      wrapperClassName={classes.accountSelector}
    />
  );
};
