import React, { useState } from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { dateToString, Input } from "../../../../../Helpers";
import cn from "clsx";
import { DatePicker } from "../../../../../Helpers/DatePicker/DatePicker";

const useStyles = makeStyles((theme) => ({
  detailsInputs: {
    width: "46.5rem",
    "& input:last-of-type": {
      marginBottom: 0,
    },
  },
  datePicker: {
    position: "absolute",
    top: "3.5rem",
    left: 0,
    backgroundColor: theme.palette.background.alternate,
    zIndex: 9,
    "& .DayPicker-wrapper:focus": {
      border: "none",
    },
    "& .DayPicker-Day:hover": {
      backgroundColor: `${theme.palette.colors.blue} !important`,
    },
    "& .DayPicker-Day--today": {
      color: theme.palette.colors.blue,
      "&:hover": {
        color: theme.palette.text.primary,
      },
    },
  },
}));

export const Details = ({ parentClasses, newReport, updateNewReport }) => {
  const classes = useStyles();
  const [showDatePicker, setShowDatePicker] = useState(false);

  const {
    location,
    seedType,
    applicationDate,
    productHeader,
    template,
  } = newReport;
  const {
    hideProductHeader,
    hideLocation,
    hideSeedType,
    hideApplicationDate,
  } = template;

  if (
    !hideProductHeader &&
    !hideLocation &&
    !hideSeedType &&
    !hideApplicationDate
  ) {
    return (
      <Grid
        container
        item
        direction="column"
        className={cn(classes.detailsInputs, parentClasses.section)}
      >
        <Typography variant="h4">Enter Field and Test Details</Typography>
        {!hideProductHeader && (
          <Input
            className={parentClasses.input}
            placeholder="Chemical Used"
            value={productHeader ? productHeader : ""}
            onChange={(e) => updateNewReport("productHeader", e.target.value)}
          />
        )}
        {!hideLocation && (
          <Input
            className={parentClasses.input}
            placeholder="Location"
            value={location ? location : ""}
            onChange={(e) => updateNewReport("location", e.target.value)}
          />
        )}
        {!hideSeedType && (
          <Input
            className={parentClasses.input}
            placeholder="Hybrid / Variety"
            value={seedType ? seedType : ""}
            onChange={(e) => updateNewReport("seedType", e.target.value)}
          />
        )}
        {!hideApplicationDate && (
          <Grid style={{ position: "relative", marginTop: "1.5rem" }}>
            <Input
              className={parentClasses.input}
              style={{ marginBottom: 0 }}
              placeholder="Application Date"
              value={dateToString(applicationDate) ?? ""}
              onChange={() => {}}
              onClick={() => setShowDatePicker(!showDatePicker)}
            />
            {showDatePicker && (
              <DatePicker
                className={classes.datePicker}
                onChange={(date) => {
                  updateNewReport("applicationDate", dateToString(date));
                  setShowDatePicker(false);
                }}
              />
            )}
          </Grid>
        )}
      </Grid>
    );
  }
  return null;
};
