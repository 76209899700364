import React from 'react';
import { TrueCauseItemFilters } from '../Field/FieldShelf/TrueCause/FilterTrueCauseStressItem';
import { WorkOrders } from './WorkOrders';

/**
 * Work Orders Component Wrapper
 * 
 * @param {*} props 
 * @description Wraps the TrueCauseItemFilter context around the WorkOrders component
 * which handles the highlighting, tooltips, and rendering of the markers
 * when corresponding work order rows are interacted with 
 * @returns JSX.Element
 */
export const WorkOrdersWrapper = (props) => {
    // const [showEditTaskModal, setShowEditTaskModal] = React.useState(false);
    // const [taskModalData, setTaskModalData] = React.useState({ editableId: null });

    // const handleCloseEditTaskModal = () => {
    //     setShowEditTaskModal(false);
    // }

    // const allProps = {
    //     ...props,
    //     taskModalData,
    //     setTaskModalData,
    //     setShowEditTaskModal,
    // }

    const render = () => (
        <TrueCauseItemFilters>
            <WorkOrders {...props} />
        </TrueCauseItemFilters>
    )
    return render();
}