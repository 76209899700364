import * as React from "react";
import cn from "clsx";
import { Table } from "../../../../Helpers/Table/Table";
import { Container } from "../../../../Layouts/Container";
import { BulkImageReviewerActions } from "../../components/BulkImageReviewerActions/BulkImageReviewerActions";
import { ImageGrid } from "../../components/ImageGrid/ImageGrid";
import { IMAGE_REVIEWER_BASE_COLUMNS } from "../../config/ImageReviewer.shared";
import {
  ImageReviewerContentContext,
  ImageReviewerDispatchContext,
  ImageReviewerResponseContext,
  ImageReviewerStateContext,
  initialState as baseInitialState,
  useImageReviewerState,
} from "../../contexts/ImageReviewerContext/ImageReviewerContext";
import styles from "./BulkImageReviewer.module.scss";
import { ProbeImageThumb } from "../../components/ProbeImage/ProbeImage";
import {
  multipleImageSelect,
  selectUnselectAll,
} from "../../utils/imageGridState";
import { ProbeImagePreview } from "../../components/ProbeImagePreview/ProbeImagePreview";
import { ProbeImageSkeleton } from "../../components/ProbeImage/ProbeImageSkeleton";
import { useFetchImageReview } from "../../hooks/useFetchImageReview";
import { ImageReviewerFiltersController } from "../../components/ImageReviewerFilters/ImageReviewerFilters";

const initialState = {
  ...baseInitialState,
  viewMode: "grid", // "grid" | "preview"
};

const columns = [
  ...IMAGE_REVIEWER_BASE_COLUMNS,
  {
    Header: () => null,
    id: "bulk-review-blank",
    Cell: () => {
      return <span></span>;
    },
  },
].map((col) => {
  return {
    ...col,
    sortable: false,
  };
});

// Used for storing the properties we're interested in saving about an
// image
const getSelectedImageProps = (image) => {
  return {
    imagePath: image.imagePath,
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case "image-press": {
      return multipleImageSelect(state, action, getSelectedImageProps);
    }
    case "unselect-all":
      return {
        ...state,
        selectedImagesById: { [null]: {} },
        selectedImages: [null],
      };
    case "image-delete-success":
    case "select-unselect-all": {
      return selectUnselectAll(state, action, getSelectedImageProps);
    }

    case "view-mode": {
      return {
        ...state,
        viewMode: action.payload,
      };
    }

    default:
      return state;
  }
};

const BulkImageReviewerContent = () => {
  const { selectedImagesById, viewMode } = useImageReviewerState();
  const { response, onLoad, paginationStatus } = useFetchImageReview();
  const { data, isValidating } = response;
  
  const [showDeleted, setShowDeleted] = React.useState(false);

  const content = React.useMemo(() => {
    if (!data) {
      return {};
    }

    const { probeImages, ...restData } = data[0];

    return {
      pagination: {
        totalImageCount: restData.totalImageCount,
        totalImagePageCount: restData.totalImagePageCount,
      },
      table: [restData],
      images: data.reduce((reducer, d) => {
        const { probeImages } = d;

        return [...reducer, ...probeImages];
      }, []),
    };
  }, [data]);

  const viewModeStyles = React.useMemo(() => {
    if (viewMode === "preview") {
      return {
        "--image-grid-col-count": 3,
      };
    }

    return {};
  }, [viewMode]);

  if (!data) {
    return <Container>Loading</Container>;
  }

  return (
    <ImageReviewerContentContext.Provider value={content}>
      <ImageReviewerResponseContext.Provider value={response}>
        <Container className={styles.BulkImageReviewer} style={viewModeStyles}>
          <div className={styles.BulkImageReviewer__Table}>
            <Table
              columns={columns}
              data={content.table}
              dataLoaded={!!content}
            />
          </div>

          <div className={styles.BulkImageReviewer__Actions}>
            <BulkImageReviewerActions showDeleted={showDeleted} setShowDeleted={setShowDeleted} />
          </div>

          <div
            className={cn(
              styles["BulkImageReviewer__Content"],
              viewMode === "preview" &&
                styles["BulkImageReviewer__Content--preview"]
            )}
          >
            {viewMode === "preview" && (
              <div>
                <ProbeImagePreview />
              </div>
            )}

            <div className={cn(styles.BulkImageReviewer__Grid)}>
              <ImageGrid>
                {content.images.map((image, i) => {
                  const selected = selectedImagesById[image.id];

                  return (
                    <ProbeImageThumb
                      key={image.id}
                      selected={selected}
                      {...image}
                    />
                  );
                })}

                {isValidating && <ProbeImageSkeleton />}
                {paginationStatus === "enabled" && (
                  <ImageGrid.Sentinel onVisible={onLoad} />
                )}
              </ImageGrid>
            </div>
          </div>
        </Container>
      </ImageReviewerResponseContext.Provider>
    </ImageReviewerContentContext.Provider>
  );
};

export const BulkImageReviewer = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <ImageReviewerFiltersController>
      <ImageReviewerDispatchContext.Provider value={dispatch}>
        <ImageReviewerStateContext.Provider value={state}>
          <BulkImageReviewerContent />
        </ImageReviewerStateContext.Provider>
      </ImageReviewerDispatchContext.Provider>
    </ImageReviewerFiltersController>
  );
};
