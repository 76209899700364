import * as React from "react";
import MuiChip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.colors.gray45,
    height: "auto",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

export const ChipSquare = (props) => {
  const classes = useStyles();

  return <MuiChip classes={classes} deleteIcon={<ClearIcon />} {...props} />;
};
