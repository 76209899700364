import React, { useState } from "react";
import { Chip, Stack, Typography } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import styles from "./ChipFilter.module.scss";

export const ChipFilter = ({
    items,
    spacing = 2,
    label = "Filter Items",
    selected = items,
    setFilteredItems,
}) => {
    const [selectedItems, setSelected] = useState(selected);

    React.useEffect(() => {
        setFilteredItems(selectedItems);
    }, [selectedItems, setFilteredItems]);

    return (
        <>
            <Typography className={styles.header}>
                {label}
            </Typography>
            <Stack direction="row" spacing={spacing} className={styles.header}>
                {items.map((item) => {
                    const selected = selectedItems.includes(item);

                    return (
                        <Chip
                            key={item}
                            selected={selected}
                            icon={selected ? <DoneIcon /> : <></>}
                            label={item}
                            style={{ backgroundColor: 'var(--logoColor)' }}

                            color={"primary"}
                            onClick={() =>
                                setSelected((prevSelected) => {
                                    if (prevSelected.includes(item)) {
                                        return prevSelected.filter((it) => it !== item);
                                    }

                                    return [...prevSelected, item];
                                })
                            }
                        />
                    );
                })}
            </Stack>
        </>
    );
}
