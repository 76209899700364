import React, { useEffect, useState } from "react";
import { ShelfLabel } from "../../../../Helpers/ShelfLabel/ShelfLabel";
import {
    Grid,
    Button,
    makeStyles,
    Typography,
    FormControlLabel,
    IconButton,
    LinearProgress,
} from "@material-ui/core";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { ResultsDateSlider } from "../../SoilSampleDateSlider/SoilSampleDateSlider";
import { Autocomplete2 } from "../../../../Helpers/Autocomplete/Autocomplete";
import { useField } from "../../../../../globalState/fieldContext";
import { Switch } from "../../../../Helpers/Switch/Switch";
import { CreateSoilSampleTaskPopup } from "./CreateSoilSampleTaskPopup";
import { ConfirmSoilPointPopup } from "./ConfirmSoilPointPopup";
import * as axios from "axios";
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SampleLegend } from "../../SampleLegends/SampleLegend";
import * as turf from "@turf/turf";
import { soilSampleFillColors } from "../../../../../styles/colors";

const useStyles = makeStyles((theme) => ({
    createButton: {
        height: "3rem",
        width: "100%",
        marginTop: "1rem",
        fontSize: theme.typography.body1.fontSize,
        fontFamily: theme.typography.fontFamilyMedium,
        borderColor: theme.palette.text.primary,
    },
    selectNutrient: {
        marginBottom: "1.5rem",
        marginTop: "1rem"
    },
}))

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButton-root': {
        backgroundColor: 'var(--bgColorSecondary)',
        color: 'var(--fontColorSecondary)',
        border: '1px solid var(--fontColorSecondary)',
        '&:hover': {
            backgroundColor: 'var(--bgColorSecondary)',
            color: 'var(--fontColorSecondary)',
        },
        '&.Mui-selected': {
            backgroundColor: 'var(--logoColor)',
            color: 'black',
            '&:hover': {
                backgroundColor: 'var(--logoColor)',
                color: 'black',
            }
        },
    }
}));

const StyledToggleButton = styled(ToggleButton)({
    outline: '1px solid var(--fontColorSecondary)',
    boxShadow: 'none',
});

export const SoilSamplesDrawer = ({
    useDrawerStyles,
    setSoilSampleData,
    setShowSoilSample,
    showSoilSample,
    setShowSoilSampleHeatmap,
    showSoilSampleHeatmap,
    setActivityData,
    setSelectedNutrient: setSelectedNutrientName,
    imageToDisplay,
    setImageToDisplay,
    soilSampleValuesToDisplay,
    setSoilSampleValuesToDisplay,
}) => {
    const [showOptions, setShowOptions] = useState(false);
    const [nutrient, setNutrient] = useState('')
    const [nutrientsList, setNutrientsList] = useState([])
    const [sliderDate, setSliderDate] = useState("")
    const [soilDates, setSoilDates] = useState([])
    const [pendingTasks, setPendingTasks] = useState(false)
    const [openCreateSoil, setOpenCreateSoil] = useState(false)
    const drawerClasses = useDrawerStyles();
    const classes = useStyles();
    const { field, selectedSeason } = useField();
    const [confirmSoilPoints, setConfirmSoilPoints] = useState(false)
    const [workOrderId, setWorkOrderId] = useState();
    const [lbPerAcre, setLbPerAcre] = React.useState(true);
    const [labels, setLabels] = React.useState({});

    const calculateAcres = () => {
        const sqMetersPerAcre = 4046.85642245;
        let acres = [0, 0, 0, 0, 0];
        fetch(imageToDisplay?.geotiffUrl)
            .then(response => response.json())
            .then(geojson => {
                geojson.features.forEach((feature, index) => {
                    const area = turf.area(feature) / sqMetersPerAcre;
                    acres[index] = area.toFixed(1);
                    //console.log(`Area of feature ${feature.properties['ID']}: ${area} acres`);
                });
                setLabels({ text: acres, units: 'ac', colors: soilSampleFillColors });
            });
    }

    useEffect(() => {
        const sortedSoilDates = getSoilDates()
        setSliderDate(sortedSoilDates[0])
        setSoilDates(sortedSoilDates)
        setPendingTasks(pendingSoilsampleTasks())
        setNutrientsList(getNutrientsList()?.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (nutrient) {
            selectSoilSampleData(nutrient)
        } else {
            setSoilSampleData(() => getSampleLocation())
        }
        // eslint-disable-next-line
    }, [sliderDate, nutrient, lbPerAcre])

    useEffect(() => {
        if (selectedSeason && showSoilSampleHeatmap) {
            var heatmap = selectedSeason?.heatmaps.find(hm => hm.name === 'Soil Sample' && hm.observedAt.includes(sliderDate));
            const param = nutrient['name'].replace(/\s+/g, '');
            const selected_heatmap = { ...heatmap, geotiffUrl: heatmap.geotiffUrl.replace("{param}", param) };
            setImageToDisplay(selected_heatmap)
        } else {
            setImageToDisplay(null);
        }
        // eslint-disable-next-line
    }, [nutrient, selectedSeason, showSoilSampleHeatmap, sliderDate])

    useEffect(() => {
        if (showSoilSampleHeatmap && imageToDisplay) {
            calculateAcres();
        }
        // eslint-disable-next-line
    }, [showSoilSampleHeatmap, imageToDisplay])

    const [downloading, setDownloading] = useState([]);
    const fieldContext = useField();
    const downloads3ToClient = (heatmap) => {
        const { name } = heatmap;
        return axios.default
            .get(`${heatmap.shapefileUrl}`, {
                headers: {
                    Accept: "*/*",
                },
                responseEncoding: "binary",
                responseType: "arraybuffer",
                onDownloadProgress: function (progressEvent) {
                    const { loaded, total } = progressEvent;
                    const percent = Math.floor((loaded * 100) / total);
                    setDownloading((downloading) => [
                        ...downloading.map((a) =>
                            a.name === name ? { name, progress: percent } : a
                        ),
                    ]);
                },
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                // We will build a filename to store imagery that "makes sense"
                // Currently: fieldID_flight-date_wo_type.tif
                const fieldId = fieldContext.field[0].id;
                const dateFlown = heatmap.observedAt.split('T')[0]
                // const flightDayOfMonth = dateFlown.getDate();
                // const flightMonth = dateFlown.getMonth(); // Be careful! January is 0, not 1
                // const flightYear = dateFlown.getFullYear();
                // const flightDate = flightYear + '-' + (flightMonth + 1) + "-" + flightDayOfMonth;
                const localFile = `${fieldId}_${dateFlown}_SOIL_SAMPLES.zip`;
                link.href = url;
                link.setAttribute("download", localFile); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
            });
    };

    const downloadCallback = async (toggledImage) => {
        // dont allow for redownloading files while download in progress
        if (downloading.some((a) => a.name === toggledImage.name)) return;
        setDownloading([
            ...downloading.filter((a) => a.name !== toggledImage.name),
            { name: toggledImage.name, progress: 0 },
        ]);
        await downloads3ToClient(toggledImage).finally(() => {
            setDownloading(
                downloading.filter((file) => file.name !== toggledImage.name)
            );
        });
    };

    const findProgressValue = () => {
        const value = downloading[0];
        return value?.progress;
    };

    const getSoilDates = () => {
        return field[0]?.sampleCollections?.map((obj) => {
            return obj.collectedAt.split('T')[0]
        })?.sort((a, b) => a < b ? 1 : -1)
    }

    const getNutrientsList = () => {
        return field[0]?.sampleCollections[0]?.samples[0]?.results?.map((nut) => {
            return {
                id: nut.value,
                name: nut.label,
                displayName: nut.label.split('-')[0],
                units: nut.units,
            }
        })
    }

    const getSampleLocation = () => {
        return field[0]?.sampleCollections?.filter(sc => {
            return sc.collectedAt.split('T')[0] === sliderDate
        })[0]?.samples?.map((sam, index) => {
            return {
                id: sam.id,
                location: sam.location,
                value: index + 1,
                results: sam.results
            }
        })
    }

    const selectSoilSampleData = () => {
        const data = field[0]?.sampleCollections?.filter(sc => {
            return sc.collectedAt.split('T')[0] === sliderDate
        })[0];
        const depthScale = data?.depth / 6.0;
        setSoilSampleData(data.samples?.map((sam) => {
            // eslint-disable-next-line
            const item = sam?.results?.filter(res => {
                if (res?.label === nutrient?.name) {
                    return res
                }
            })[0]
            const nutrientValue = item?.value
            const nutrientUnits = item?.units
            let value = nutrientValue;
            if (nutrientUnits === 'ppm') {
                value = lbPerAcre ? nutrientValue * 2 * depthScale : nutrientValue
            } else if (nutrientUnits === 'lb/acre') {
                value = lbPerAcre ? nutrientValue : nutrientValue / (2 * depthScale)
            }
            return {
                id: sam?.id,
                location: sam?.location,
                value: value,
                results: sam.results
            }
        }))
    }

    const selectNutrient = (v) => {
        setNutrient(v)
        setSelectedNutrientName(v.name)
    }

    const ImageControl = ({
        checkOnLoadFlag,
        onChangeFn,
        disabled = false
    }) => {
        return (
            <div>
                <FormControlLabel
                    control={<Switch checked={checkOnLoadFlag} />}
                    onChange={onChangeFn}
                    disabled={disabled}
                />
            </div>
        );
    }

    const pendingSoilsampleTasks = () => {
        const current_season = field[0]?.seasons?.filter(season => {
            return season.current === true
        })[0];
        if (current_season) {
            const orders = current_season?.timeline?.filter(item => (item.type === 'orderRequested' && item.workOrder.service.id === 51));
            if (orders) {
                const completed = orders.map(function (orders) { return current_season?.timeline?.some(item => item.type === 'orderComplete' && item.workOrder.id === orders.workOrder.id) });
                return completed.some(item => item === false);
            }
        }
        return false;
    }

    const handleToggleShowSample = () => {
        setShowSoilSample(!showSoilSample)
        // setSoilSampleData(() => getSampleLocation())
        // setNutrient('')
    }

    const handleToggleShowHeatmap = () => {
        setShowSoilSampleHeatmap(!showSoilSampleHeatmap)
    }

    const handleCreateSoilSampleToggle = () => {
        setOpenCreateSoil(true)
    }

    const handleClose = async (workOrderid) => {
        setOpenCreateSoil(false);
        // if (workOrderid) {
        //     const curentField = field[0];
        //     await updateSeasons();
        //     history.push(`/farm/${curentField.farm.id}/field/${curentField.id}/work-order/${workOrderid}`);
        // }
    };

    const handleOpenConfirmSoilPopup = (workOrder) => {
        setWorkOrderId(workOrder);
        setConfirmSoilPoints(true)
    }

    const handleConfirmClose = () => {
        setConfirmSoilPoints(false)
    }

    const handleUnitChange = (e, v) => {
        setLbPerAcre(e.target.ariaLabel === 'lbac');
    }

    const makeLabel = (o) => {
        if ((o.units !== 'ppm') && (o.units !== 'lb/ac')) {
            return `${o.displayName} (${o.units})`
        } else {
            return `${o.displayName}`;
        }
    }

    return (
        <>
            <CreateSoilSampleTaskPopup
                open={openCreateSoil}
                handleClose={handleClose}
                handleOpen={() => setOpenCreateSoil(true)}
                setActivityData={setActivityData}
                handleOpenConfirmSoilPopup={handleOpenConfirmSoilPopup}
            />
            <ConfirmSoilPointPopup open={confirmSoilPoints} onClose={handleConfirmClose} workOrderId={workOrderId} />
            {/* <Button onClick={handleOpenConfirmSoilPopup}>Open Modal</Button> */}
            <Grid container
                direction="column"
                justifyContent="center"
                className={drawerClasses.root}>
                <ShelfLabel
                    label={`Soil Samples`}
                    showOptions={showOptions}
                    select={() => {
                        setShowOptions(!showOptions)
                    }}
                />
                {showOptions && (
                    <Grid className={drawerClasses.options}>
                        {pendingTasks ? (
                            <Typography color="textSecondary"
                                style={{ marginBottom: "1.5rem" }}>
                                You have pending sample tasks. Check Field History for more details.
                            </Typography>
                        ) : null
                        }
                        {soilDates?.length > 0 ?
                            (<div>
                                <Grid
                                    key={'heatmap-date'}
                                    container
                                    item
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <div className="soilSampleDateSliderWrapper">
                                        {soilDates?.length === 1 ? sliderDate : <ResultsDateSlider sliderDates={soilDates} sliderDate={sliderDate} setSliderDate={setSliderDate} />}
                                    </div>
                                    <IconButton
                                        onClick={() => downloadCallback(selectedSeason?.heatmaps.find(hm => hm.name === 'Soil Sample' && hm.observedAt.includes(sliderDate)))}
                                        aria-label="download samples"
                                        //visible={image.downloadable}
                                        disabled={selectedSeason?.heatmaps?.length === 0}

                                    >
                                        <GetAppOutlinedIcon sx={{ marginLeft: '1rem' }} color="primary" />
                                    </IconButton>
                                    {(downloading.length > 0) && (
                                        <div className={classes.loadingContainer}>
                                            <LinearProgress
                                                variant="determinate"
                                                value={findProgressValue()}
                                            />
                                        </div>
                                    )}

                                </Grid>
                                <div className="soilSampleToggleWrapper">
                                    <ImageControl
                                        checkOnLoadFlag={showSoilSample}
                                        onChangeFn={() => handleToggleShowSample()}
                                    />
                                    {showSoilSample ? <Typography color="textSecondary">
                                        Hide Samples
                                    </Typography> : <Typography color="textSecondary">
                                        Show Samples
                                    </Typography>}
                                </div>
                                <div className="soilSampleToggleWrapper">
                                    <ImageControl
                                        checkOnLoadFlag={showSoilSampleHeatmap}
                                        onChangeFn={() => handleToggleShowHeatmap()}
                                        disabled={selectedSeason?.heatmaps?.length === 0 || !nutrient}
                                    />
                                    {showSoilSampleHeatmap ? <Typography color="textSecondary">
                                        Hide Heatmap
                                    </Typography> : <Typography color="textSecondary">
                                        Show Heatmap
                                    </Typography>}
                                </div>
                                <Autocomplete2
                                    options={nutrientsList ?? []}
                                    value={nutrient || null}
                                    getOptionLabel={(o) => makeLabel(o)}
                                    onChange={(_, v) => selectNutrient(v)}
                                    labelText="Select Nutrient"
                                    inputClassName={classes.selectNutrient}
                                />
                                <Grid container justifyContent="left" alignItems="center">
                                    <Grid item xs={4} sx={{ textAlign: 'center' }}>
                                        <Typography color="textSecondary">Select Units</Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <StyledToggleButtonGroup
                                            value={lbPerAcre ? 'lbac' : 'ppm'}
                                            exclusive
                                            onChange={handleUnitChange}
                                        >
                                            <StyledToggleButton value='lbac' aria-label="lbac"  >Lb/ac</StyledToggleButton>
                                            <StyledToggleButton value='ppm' aria-label="ppm" >ppm</StyledToggleButton>
                                        </StyledToggleButtonGroup>
                                    </Grid>
                                </Grid>
                            </div>
                            ) :
                            (
                                <Typography color="textSecondary"
                                    style={{ marginBottom: "1.5rem" }}>
                                    You have no current sample results.
                                </Typography>
                            )
                        }
                        <Button
                            variant="outlined"
                            startIcon={<AddRoundedIcon />}
                            className={classes.createButton}
                            onClick={handleCreateSoilSampleToggle}
                        >
                            Create Sample Task
                        </Button>
                        {showSoilSampleHeatmap && (<SampleLegend labels={labels} type={"soil-sample-legend"} />)}
                    </Grid>
                )}
            </Grid>
        </>
    );
}