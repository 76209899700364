import React from "react";
import styles from "./Input.module.scss";
import cn from "clsx";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

export const Textarea = (props) => {
  return <textarea {...props} className={cn(styles.Input, props.className)} />;
};

const useTextareaStyles = makeStyles((theme) => ({
  tertiaryBackground: {
    background: theme.palette.background.tertiary,
  },
}));

const useInputLabelStyles = makeStyles((theme) => ({
  shrink: {
    transform: `translate(0px, -18px) scale(0.75) !important`,
  },
}));

export const TextareaWithLabel = ({ label, onChange, value, ...props }) => {
  const classes = useTextareaStyles();
  const inputLabelClasses = useInputLabelStyles();
  const cn = clsx(
    classes.tertiaryBackground,
    props.className && props.className
  );
  return (
    <TextField
      multiline
      label={label}
      variant={props.variant || "outlined"}
      margin={props.margin || "normal"}
      rows={props.rows || 4}
      className={cn}
      InputProps={{
        notched: false,
        className: classes.tertiaryBackground,
        ...(props.InputProps ? props.InputProps : {}),
      }}
      inputProps={{
        className: classes.tertiaryBackground,
        ...(props.inputProps ? props.inputProps : {}),
      }}
      InputLabelProps={{
        classes: inputLabelClasses,
        ...(props.InputLabelProps ? props.InputLabelProps : {}),
      }}
      onChange={onChange}
      value={value}
      {...props}
    />
  );
};
