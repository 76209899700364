import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { MuiButton } from "../../Helpers";
import { useAuth } from "../../../hooks/useAuth";
import { FileUploader } from "react-drag-drop-files";
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { soilSampleCollection } from "../../../hooks/useApi";
import Swal from "sweetalert2";
import { NewInput } from '../../Helpers/Input/Input';

const useStyles = makeStyles((theme) => ({
  addFarmField: {
    border: 'none',
  },
  textField: {
    marginTop: "-1rem",
    marginBottom: '1rem',
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
  },
  heading: {
    marginBottom: '0',
    marginTop: "2rem",
    marginLeft: "-1rem",
  },
  label: {
    marginBottom: '0',
    marginTop: "2rem",
  },

}));

interface Props {
  taskId: number | string;
  workOrderId: number | string;
  fieldName: string;
  fieldId: number | string;
  hideHeading: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  fileTypes: string[];
  uploadType: string;
  completed: boolean;
}

export const UploadFiles = (props: Props) => {
  const { token } = useAuth();
  const classes = useStyles();
  const [file, setFile] = React.useState<File>();
  const [fileSelected, setFileSelected] = React.useState<boolean>(false);
  const supportedLabs = [{ key: 'ChrysaLabs', value: 'clabs_probe' }, { key: 'KSI', value: 'ksi_labs' }];
  const [selectedLab, setSelectedLab] = React.useState('');
  const [uploading, setUploading] = React.useState(false);
  const [confirmUpload, setConfirmUpload] = React.useState(false);
  const [uploadConfirmed, setUploadConfirmed] = React.useState(false);
  const [soilSampling, setSoilSampling] = React.useState(false);
  const [sampleDepth, setSampleDepth] = React.useState(6.0);

  const handleChangeLab = (event: SelectChangeEvent) => {
    setSelectedLab(event.target.value);
  };

  const handleFileChange = (file: File) => {
    setFile(file);
    setFileSelected(true);
  };

  const handleCancel = () => {
    if (confirmUpload) {
      setUploadConfirmed(false);
      setConfirmUpload(false);
    } else {
      props.handleClose();
    }
  };

  const handleUpload = async () => {
    if (!confirmUpload && props.completed) {
      setConfirmUpload(true);
    } else {
      if (confirmUpload || !props.completed) {
        setUploadConfirmed(true);
      }
    }
  };

  useEffect(() => {
    switch (props.uploadType) {
      case 'Soil Sampling':
        setSoilSampling(true);
        break;
      // case 'Field Collection':
      //   const { data: taskData } = useApi(`/api/v1/tasks/${props.taskId}`);
      //   data, success = await fieldCollection(props.fieldId, selectedLab, file, token);
      //   break;
      default:
        break;
    }
  }, [props.uploadType])

  useEffect(() => {
    if (uploadConfirmed) {
      handleConfirmedUpload();
    } else {
      setConfirmUpload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadConfirmed]);

  const handleConfirmedUpload = async () => {
    let errors = false;
    let errorTitle = "";
    let errorMessage = "";

    if (file) {
      var success;
      var data;

      setUploading(true)
      if (soilSampling) {
        const result = await soilSampleCollection(props.fieldId, selectedLab, file, props.taskId, token, sampleDepth);
        success = result['success']
        data = result['data']
      }
      //   const { data: taskData } = useApi(`/api/v1/tasks/${props.taskId}`);
      //   data, success = await fieldCollection(props.fieldId, selectedLab, file, token);
      setUploading(false)
      if (!success) {
        errorTitle = "Upload Failed";
        errorMessage = data;
        errors = true
      };
    } else {
      errorTitle = "No File Selected";
      errorMessage = "You must select a file to upload";
      errors = true
    }
    // need to close the popup even if there is an error else the popup will be behind it
    props.handleClose();
    if (errors) {
      await Swal.fire({
        title: errorTitle,
        text: errorMessage,
        icon: "error",
        confirmButtonText: "Ok",
      });
      // repoen the dialog if we had an error so we can fix it
      props.handleOpen();
    }
  };

  useEffect(() => {
    if (supportedLabs && !selectedLab) {
      setSelectedLab(supportedLabs[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supportedLabs]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      style={{ alignSelf: "flex-start", padding: "2rem 1.5rem" }}
    >
      {!props.hideHeading && <Typography variant="h3" className={classes.heading}>Edit Farm</Typography>}
      <Grid style={{ margin: "0 0 2rem -1rem" }}>
        <Typography color="textSecondary">{props.fieldName} </Typography>
        <Typography color="textSecondary" style={{ marginTop: "0.25rem", marginBottom: "1rem" }}>Task ID: {props.workOrderId} </Typography>
        {fileSelected && soilSampling ? (
          <div>
            <Button onClick={() => { setFile(undefined); setFileSelected(false) }} style={{ color: "var(--fontColorMain)" }} startIcon={<DeleteIcon />}>
              {file?.name} ready for upload
            </Button>
            {uploading && (
              <Box sx={{ width: '100%' }}>
                <LinearProgress sx={{
                  backgroundColor: "var(--bgColorTertiary)",
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: "var(--logoColor)"
                  }
                }} />
              </Box>)
            }
            <InputLabel id="select-lab-label" style={{ color: "var(--fontColorMain)" }}>Select Laboratory</InputLabel>
            <Select
              labelId="select-lab-label"
              id="selected-lab"
              value={selectedLab}
              onChange={handleChangeLab}
              required sx={{ m: 1, minWidth: 250, backgroundColor: 'var(--bgColorSecondary)', color: "var(--fontColorMain)" }}
            >
              {
                supportedLabs.map((lab) => {
                  return <MenuItem value={lab.value} key={lab.key}>{lab.key}</MenuItem>
                })
              }
            </Select>
            <InputLabel id="sample-depth-label" style={{ color: "var(--fontColorMain)" }}>Sample Depth (in)</InputLabel>
            <NewInput id="sample-depth" type="number"
              value={sampleDepth}
              onChange={(e: any) => setSampleDepth(parseFloat(e.target.value))}
            />
          </div>
        ) : (
          <div>
            <FileUploader
              multiple={false}
              handleChange={handleFileChange}
              name="file"
              types={props.fileTypes}
            />
          </div>
        )}
        {confirmUpload &&
          <Typography style={{ color: "red", marginTop: "1rem", marginBottom: "1rem" }}>
            This order has already been completed. Are you sure you want to upload data, again?
            It will replace the currently uploaded data. This is not reversible.
          </Typography>
        }
        <Grid container justifyContent="space-between" style={{ width: "100%", marginTop: "1rem", marginLeft: "-0.5rem" }}>
          <MuiButton
            text="Cancel"
            style={{ width: "45%" }}
            onClick={handleCancel}
          />
          <MuiButton
            text={confirmUpload ? "Confirm Upload" : "Upload"}
            style={{ width: "45%" }}
            variant="contained"
            color="primary"
            onClick={handleUpload}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
