import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as turf from "@turf/turf";
import { Shelf } from "../../Helpers";
import { Button } from "../../Helpers";
import { getFarmsFields } from "../../../controllers/farms";
import { Map } from "../../Map/Map";
import { FarmShelf } from "./FarmShelf/FarmShelf";
import { Flex } from "../../Layouts/Flex";
import { Container } from "../../Layouts/Container";
import styles from "./Farm.module.scss";
import { useAuth } from "../../../hooks/useAuth";
import { useLazyApi } from "../../../hooks/useApi";
import { FarmContext, useFarms } from "../../../globalState/farmContext";
import { TrueCauseItemFilters } from "../Field/FieldShelf/TrueCause/FilterTrueCauseStressItem";
import { IconButton } from "@material-ui/core";
import * as Feather from "react-feather";
// ismport { EditFarm } from "./FarmShelf/EditFarm";
import { yellowThreshold, greenThreshold } from "../../Helpers/ahiValues";
import { useCustomerAccounts } from "../../../globalState/customerAccountContext";
import { EditFarmPopup } from "./FarmShelf/EditFarmPopup";

export const Farm = () => {
  const history = useHistory();
  const location = useLocation();
  const { token } = useAuth();
  const [searchValue, setSearchValue] = useState("");
  const [farmFields, setFarmFields] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [farmBounds, setFarmBounds] = useState(null);
  const [fieldStats, setFieldStats] = useState({ acres: 0, fieldCount: 0 });
  const filterOptions = [
    { name: "insect damage", icon: "" },
    { name: "nutrient deficiency", icon: "" },
    { name: "disease", icon: "" },
    { name: "weeds - broadleaf", icon: "" },
    { name: "weeds - grasses", icon: "" },
  ];
  const farmId = location.pathname.replace("/farm/", "");
  const [getFarmsData] = useLazyApi(`/api/v1/farms/${farmId}`);
  const farmContext = React.useContext(FarmContext);
  // const [showFarmEdit, setShowFarmEdit] = useState(false);
  const { currentFarm } = useFarms();

  const customerAccounts = useCustomerAccounts();
  const farmHands = customerAccounts?.find(
    (act) => act?.id === currentFarm?.customerAccountId
  )?.farmHands;

  const findMe = document.querySelector('.mapboxgl-ctrl-geolocate')
  if (findMe) {
    findMe.removeAttribute('disabled')
  }

  const [open, setOpen] = React.useState(false);

  const handleEditOpen = () => {
    setOpen(true);
  };

  const handleEditClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (token) {
      getFarmsFields(token, farmId).then(async (res) => {
        if (res.length > 0) {
          let centers = [];
          const formattedFarmFields = res.map((field) => {
            const formattedBounds = field.bounds.map((point) => [
              +point[1],
              +point[0],
            ]);
            const point1 = turf.point(formattedBounds[0]);
            const point2 = turf.point(formattedBounds[1]);
            const midpoint = turf.midpoint(point1, point2).geometry.coordinates;
            centers.push(midpoint);
            let ahiColor = 'white'
            let ahiValue = 0.0;
            let ahiTrend = 0.0;
            let opacity = null;
            let selectedOpacity = null;
            if (field.seasons.length > 0 && field.seasons[0].enrolledInAhi && field.seasons[0].ahi.length > 0) {
              ahiValue = field.seasons[0].ahi[0].fieldAhi ? parseFloat(field.seasons[0].ahi[0].fieldAhi) : 0
              ahiTrend = field.seasons[0].ahi[0].trend ? parseFloat(field.seasons[0].ahi[0].trend) : 0
              opacity = 0.7;
              selectedOpacity = 0.7;
              ahiColor = 'red'
              if (ahiValue > yellowThreshold) ahiColor = 'yellow'
              if (ahiValue > greenThreshold) ahiColor = 'green'
            }
            return {
              ...field,
              center: midpoint,
              showMarker: true,
              show: true,
              color: ahiColor,
              ahiValue: ahiValue,
              ahiTrend: ahiTrend,
              opacity: opacity,
              selectedOpacity: selectedOpacity,
            };
          });
          centers.push(centers[0]);
          const bounds = turf.bbox(turf.lineString(centers));
          setFarmBounds(bounds);
          formattedFarmFields.sort((a, b) => {
            if (a.title > b.title) {
              return 1;
            }
            if (a.title < b.title) {
              return -1;
            }
            return 0;
          });
          setFarmFields(formattedFarmFields);
          setFieldStats((stats) => ({
            fieldCount: formattedFarmFields.length,
            acres: stats.acres,
          }));
        } else {
          setFarmFields([]);
        }
      });
      // Set the current farm in the Farm Context so that its data can be retrieved
      // in other components.
      getFarmsData().then((response) => {
        farmContext.setCurrentFarm(response);
        setFieldStats((stats) => ({
          fieldCount: stats.fieldCount,
          acres: response.totalAcres.toFixed(2),
        }));
      });
    }

    return () => {
      setFarmFields(null);
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [token]);

  const selectField = (field) => {
    history.push(`/farm/${field.farm.id}/field/${field.id}`);
  };

  const handleFilterSelect = (index) => {
    let newSelectedFilters = [];
    if (selectedFilters.includes(index)) {
      newSelectedFilters = selectedFilters.filter((indx) => indx !== index);
    } else {
      newSelectedFilters = [...selectedFilters];
      newSelectedFilters.push(index);
    }
    setSelectedFilters(newSelectedFilters);
  };

  const toggleEditFarm = () => {
    handleEditOpen();
    //setShowFarmEdit(!showFarmEdit);
  };

  const numericFormatOpts = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  return (
    <TrueCauseItemFilters>
      <Container>
        <EditFarmPopup
          open={open}
          handleClose={handleEditClose}
          handleOpen={handleEditOpen}
          farmHands={farmHands}
          currentFarm={currentFarm}
        />


        <Flex flexDirection="column" className={styles.Farm}>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            className={styles.Header}
          >
            <Flex
              flexDirection="column"
              alignItems="flex-start"
              className={styles.Title}
            >
              <h1>Fields</h1>
              <h5>
                {currentFarm.title} — {fieldStats.fieldCount} fields,{" "}
                {Number(fieldStats.acres).toLocaleString(
                  "en",
                  numericFormatOpts
                )}{" "}
                acres
              </h5>
            </Flex>
            <Flex>
              <IconButton
                disabled={!currentFarm?.permissions?.canManage}
                onClick={toggleEditFarm}
                className={styles.EditFarmBtn}
              >
                <Feather.Edit3 />
              </IconButton>
              <Button
                className={styles.AddFieldBtn}
                text="+ New Field"
                disabled={!currentFarm?.permissions?.canManage}
                onClick={() => history.push(`/farm/${farmId}/create-field`)}
              />
            </Flex>
          </Flex>

          <Flex className={styles.Body}>
            <Shelf>
              {/* {!showFarmEdit ? ( */}
              <FarmShelf
                farmFields={farmFields}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                filterOptions={filterOptions}
                selectField={selectField}
                handleFilterSelect={handleFilterSelect}
                selectedFilters={selectedFilters}
              />
              {/* ) : (
                <EditFarm
                  farmId={farmId}
                  farmName={currentFarm.title}
                  toggleEditFarm={toggleEditFarm}
                  farmHands={farmHands}
                />
              )} */}
            </Shelf>
            <Map
              markerScrollIntoView
              markerTooltipKey={"title"}
              onMarkerClick={selectField}
              containerId="map"
              fields={farmFields}
              mapBounds={farmBounds}
              mapOptions={{
                maxZoom: 16,
              }}
            />
          </Flex>
        </Flex>
      </Container>
    </TrueCauseItemFilters>
  );
};
