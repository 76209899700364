import * as React from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import cn from "clsx";

import styles from "./Disclosure.module.scss";

const initialState = {
  status: "closed",
};

const DisclosureStateContext = React.createContext(initialState);
const DisclosureDispatchContext = React.createContext(() => {});

const reducer = (state, action) => {
  switch (action.type) {
    case "disclosure-toggle":
      return {
        ...state,
        status: state.status === "opened" ? "closed" : "opened",
      };

    case "disclosure-close":
      return {
        ...state,
        status: "closed",
      };

    default:
      return state;
  }
};

export const Disclosure = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <DisclosureDispatchContext.Provider value={dispatch}>
      <DisclosureStateContext.Provider value={state}>
        {children}
      </DisclosureStateContext.Provider>
    </DisclosureDispatchContext.Provider>
  );
};

export const DisclosureButton = ({ className, children, ...restProps }) => {
  const dispatch = useDisclosureDispatch();

  return (
    <button
      {...restProps}
      onClick={() => {
        dispatch({ type: "disclosure-toggle" });
      }}
      className={cn("Disclosure__Button", styles.Disclosure__Button, className)}
    >
      {children}
    </button>
  );
};

export const DisclosurePanel = ({ className, children, ...restProps }) => {
  const state = useDisclosureState();
  const dispatch = useDisclosureDispatch();
  const ref = useOnclickOutside(
    () => {
      dispatch({ type: "disclosure-close" });
    },
    {
      ignoreClass: "Disclosure__Button",
    }
  );

  if (state.status === "closed") {
    return null;
  }

  return (
    <div {...restProps} className={cn(className)} ref={ref}>
      {children}
    </div>
  );
};

export const useDisclosureDispatch = () => {
  const dispatch = React.useContext(DisclosureDispatchContext);

  return dispatch;
};

export const useDisclosureState = () => {
  const state = React.useContext(DisclosureStateContext);

  return state;
};
