import React from "react";
import { Grid, makeStyles, Slider, Typography } from "@material-ui/core";
import { useField } from "../../../../globalState/fieldContext";
import { format } from "date-fns";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    top: "10rem",
    marginRight: "-.35rem",
    height: "88px",
    right: "1rem",
    width: "21rem",
    padding: "9px 14px",
    backgroundColor: theme.palette.background.primary,
    borderRadius: "4px",
    boxSizing: "border-box",
  },
  //header: { marginBottom: "1rem" },
  text: { opacity: "0.87" },
}));

export const TrueCauseDateSlider = ({
  imageryDates,
  setSliderDate,
  sliderDate,
  drawerState,
  maps,
  setStressMapToDisplay,
}) => {
  const classes = useStyles();

  const { trueCauseMarkersEditMode } = useField();

  const isTrueCauseData = imageryDates?.length && imageryDates?.length > 0;

  const updateValue = (value) => {
    const newSliderDate = imageryDates[imageryDates.length - value];
    const selectedImageType = drawerState.trueCause.selectedMapType;
    setSliderDate(newSliderDate);
    let tempMapToDisplay = null;
    maps.forEach((map) => {
      const date = new Date(map.observedAt);
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
      const mapDate = format(date, "MM/dd/yy");
      const mapType = map.name?.toUpperCase() ?? "";
      if (mapDate === newSliderDate && mapType === selectedImageType) {
        tempMapToDisplay = map;
      }
    });

    if (tempMapToDisplay) {
      setStressMapToDisplay(tempMapToDisplay);
    } else {
      setStressMapToDisplay(null);
    }
  };

  if (
    imageryDates &&
    imageryDates.length &&
    imageryDates.length > 1 &&
    !trueCauseMarkersEditMode
  ) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="flex-start"
        className={`${classes.container} slider`}
        background="primary"
      >
        {isTrueCauseData ? (
          <>
            <Grid
              container
              item
              justifyContent="space-between"
              className={classes.header}
            >
              <Typography variant="body2" className={classes.text}>
                TrueCause Date: {moment(sliderDate).format("YYYY-MM-DD")}
              </Typography>
              <Slider
                //style={{ marginTop: 15 }}
                aria-labelledby="discrete-slider"
                valueLabelDisplay="off"
                value={imageryDates.length - imageryDates.indexOf(sliderDate)}
                step={1}
                marks
                onChange={(_e, v) => updateValue(v)}
                min={1}
                max={imageryDates.length}
              />
              {/* <Grid container justifyContent="space-between" item className={classes.header}>
                <Typography style={{ position: "relative" }} variant="caption">
                  {sliderDate}
                </Typography>
              </Grid> */}
            </Grid>
          </>
        ) : (
          <>
            <Typography variant="body2" className={classes.text}>
              No TrueCause data found
            </Typography>
          </>
        )}
      </Grid>
    );
  } else return null;
};
