import React from "react";
import { Grid, makeStyles, Slider, Typography } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    top: "4rem",
    marginRight: "-0.35rem",
    marginBottom: "-0.75rem",
    right: "1rem",
    width: "18rem",
    padding: "9px 14px",
    backgroundColor: theme.palette.background.primary,
    borderRadius: "4px",
    boxSizing: "border-box",
    height: "88px",
  },
  text: { opacity: "0.87" },
}));


export const ResultsDateSlider = ({ sliderDate, sliderDates, setSliderDate }) => {
  const classes = useStyles("soilDates", sliderDates);

  const updateValue = (value) => {
    const newSliderDate = sliderDates[sliderDates.length - value];
    setSliderDate(newSliderDate);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
      className={`${classes.container} slider`}
      id="SoilSampleDateSlider"
      background="primary"
    >
      <Grid
        container
        item
        justifyContent="space-between"
        className={classes.header}
      >
        <Typography variant="body2" className={classes.text}>
          {moment(sliderDate)?.format("YYYY-MM-DD")}
        </Typography>
        <Slider
          aria-labelledby="discrete-slider"
          valueLabelDisplay="off"
          value={sliderDates.length - sliderDates.indexOf(sliderDate)}
          step={1}
          marks
          min={1}
          max={sliderDates.length}
          onChange={(_e, v) => updateValue(v)}
        />
      </Grid>
    </Grid>
  )
}