import * as React from "react";
import cn from "clsx";

import styles from "./Skeleton.module.scss";

export const Skeleton = React.forwardRef(function Skeleton(props, ref) {
  const {
    className,
    component: Component = "span",
    height,
    style,
    variant = "text",
    animation = "wave",
    width,
    ...other
  } = props;

  return (
    <Component
      ref={ref}
      className={cn(
        styles.Skeleton,
        styles[`Skeleton--${variant}`],
        styles[`Skeleton--${animation}`],
        className
      )}
      {...other}
      style={{
        width,
        height,
        ...style,
      }}
    />
  );
});
