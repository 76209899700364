import React from "react";
import { Input } from "../../Helpers/Input/Input";
import { Flex } from "../../Layouts/Flex";
import { Button } from "../../Helpers";
import Swal from "sweetalert2";
import { logUserIn } from "../../../controllers/auth";
import styles from "./Home.module.scss";
import { ACTIONS } from "./Home";
import { useAuth } from "../../../hooks/useAuth";
import useCookies from "../ImageReviewer/hooks/useCookies";

export const SignInForm = ({
  userData,
  dispatch,
  title = "Sign in to Aker",
  subTitle,
  hideForgotPassword,
  redirect = "/farms",
}) => {
  const { setCookie } = useCookies();
  const { location } = window;
  const { setToken, setUserId } = useAuth();
  const RolesToRedirect = ["Pilot", "Admin"];
  const PILOT_REDIRECT_URL = process.env.REACT_APP_PILOT_REDIRECT_URL;
  const ADMIN_REDIRECT_URL = process.env.REACT_APP_ADMIN_REDIRECT_URL;

  const login = async (e) => {
    e.preventDefault();
    await logUserIn(userData)
      .then(async (resUserData) => {
        if (resUserData) {
          if (
            resUserData?.role &&
            RolesToRedirect.some((role) => role === resUserData.role) &&
            PILOT_REDIRECT_URL &&
            ADMIN_REDIRECT_URL
          ) {
            const result = await Swal.fire({
              title: "You're trying to login to the wrong system",
              icon: "info",
              text: "You are now being redirected",
              confirmButtonText: "OK",
            });

            if (result.isConfirmed) {
              switch (resUserData?.role) {
                case "Pilot":
                  location.href = PILOT_REDIRECT_URL;
                  break;
                case "Admin":
                  location.href = ADMIN_REDIRECT_URL;
                  break;
                default:
                  break;
              }
            }
          } else {
            setCookie({ key: "authToken", value: resUserData.token });
            setCookie({ key: "userId", value: resUserData.id });
            setUserId(resUserData.id);
            setToken(resUserData.token);
            window.location.assign(redirect);
          }
        } else {
          alert();
        }
      })
      .catch(async (e) => {
        alert();
      });
  };

  const alert = async () => {
    await Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Incorrect Email or Password!",
    });
  };

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className={styles.SignInForm}
    >
      <h1>{title}</h1>
      {subTitle && <span>{subTitle}</span>}
      <form className={styles.Form} onSubmit={(e) => login(e)}>
        <Flex className={`${styles.FormRow} ${styles.SignInInputs}`}>
          <Input
            type="email"
            placeholder="Email Address *"
            value={userData.email}
            onChange={(e) =>
              dispatch({
                type: ACTIONS.UPDATE_FIELD,
                payload: { field: "email", value: e.target.value },
              })
            }
            autoFocus
            required
          />
        </Flex>
        <Flex className={`${styles.FormRow} ${styles.SignInInputs}`}>
          <Input
            type="password"
            placeholder="Password *"
            value={userData.password}
            onChange={(e) =>
              dispatch({
                type: ACTIONS.UPDATE_FIELD,
                payload: { field: "password", value: e.target.value },
              })
            }
            required
          />
        </Flex>
        {!hideForgotPassword && (
          <Button
            className={styles.ForgotPassword}
            type="button"
            text="Forgot your password?"
            click={() => location.assign("/forgot-password")}
          />
        )}
        <Button className={styles.SignInBtn} type="submit" text="Sign In" />
      </form>
    </Flex>
  );
};
