import axios from "axios";
const isStagingSite = process.env.REACT_APP_ENV.includes("staging");
const isDevSite = process.env.REACT_APP_ENV.includes("development");
const baseUrl = isDevSite
  ? process.env.REACT_APP_BACK_OFFICE_URL //"http://172.19.169.48:3000/" //"https://api-dev01.services.aker.ag"
  : isStagingSite
    ? process.env.REACT_APP_BACK_OFFICE_URL_STAGING
    : process.env.REACT_APP_BACK_OFFICE_URL_PRODUCTION;

//const { baseUrl } = process.env;
export const logUserIn = async userData => {
  const userEnteredData = {
    email: userData.email,
    password: userData.password
  };
  return await axios({
    method: "POST",
    url: `${baseUrl}/api/v1/login`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    data: { data: userEnteredData }
  })
    .then(async res => {
      const userData = res.data.data;
      return await appendAuthentication(userData);
    })
    .catch(err => {
      console.log("err", err);
      return false;
    });
};

export const appendAuthentication = async (userData) => {
  return await axios({
    method: 'GET',
    url: `${baseUrl}/api/v1/authentication`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Token ${userData.token}`,
    },
  }).then(res => {
    delete userData.token
    delete userData.lastSignIn
    return { ...res.data.data, ...userData }
  })
    .catch(err => {
      console.log("err", err);
      return false;
    });
}

export const createUser = async userData => {
  userData.customerAccountName = "Aker Self-Provisioned Accounts";
  return await axios({
    method: "POST",
    url: `${baseUrl}/api/v1/users`,
    headers: {
      "Content-Type": "application/json"
    },
    data: { data: userData }
  })
    .then(async userRes => {
      return userRes.data;
    })
    .catch(err => {
      console.log("err", err);
      return false;
    })
    .catch(err => {
      console.log("err", err);
      return false;
    });
};

export const getCurrentUser = async (userId, authToken) => {
  return await axios({
    method: "GET",
    url: `${baseUrl}/api/v1/users/${userId}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Token ${authToken}`
    }
  })
    .then(async res => {
      const userData = res.data.data;
      return await appendAuthentication({ ...userData, token: authToken });
    })
    .catch(err => console.error(err));
};

export const resetPassword = async (email, redirect) => {
  const _baseUrl = `${window.location.origin}/reset-password`;
  const url = redirect ? `${_baseUrl}/${redirect}` : _baseUrl;

  return await axios({
    method: "POST",
    url: `${baseUrl}/api/v1/password-reset-email`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    data: { data: { email, url } }
  })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.log("err", err);
      return false;
    });
};

export const changePassword = async (token, password, passwordConfirmation) => {
  return await axios({
    method: "POST",
    url: `${baseUrl}/api/v1/change-password`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json"
    },
    data: {
      data: {
        password,
        passwordConfirmation,
        token
      }
    }
  })
    .then(res => res.data)
    .catch((err) => {
      console.error(err);
      return false;
    });
}

export const fetchInvitation = async (invitationToken) => {
  return await axios({
    method: "GET",
    url: `${baseUrl}/api/v1/invitation/${invitationToken}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
  })
    .then(res => res.data)
    .catch((err) => {
      console.error(err);
      return false;
    });
}

export const updateSignUp = async (data, userId, token) => {
  const auth_token = data.token
  data.token = token
  return await axios({
    method: "PUT",
    url: `${baseUrl}/api/v1/users/${userId}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Token ${auth_token}`
    },
    data: {
      data: data
    }
  })
    .then(res => res.data)
    .catch((err) => {
      console.error(err);
      return false;
    });
}