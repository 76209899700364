import { useEffect } from "react";
import mapboxgl from "mapbox-gl";
import { makeStyles } from "@material-ui/core";
import { standCountColors, standCountTextColors } from "../../styles/colors";

const useMarkerStyles = makeStyles(theme => ({
    StandCountMarker: {
        backgroundColor: "#22a78f",
        paddingTop: "2px",
        paddingLeft: "8px",
        paddingBottom: "2px",
        paddingRight: "8px",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        borderBottomRightRadius: "10px",
        borderBottomLeftRadius: "10px",
        fontSize: "var(--fontSmall)",
        fontFamily: "var(--fontFamilyMedium)"
    },
}))

let oneMarker
let currentMarkers = []

const StandCountLayer = ({ map, standCounts, showStandCount, activityData }) => {

    function nearestLessThan(arr, num) {
        return arr.reduce((prev, curr, index) => {
            if (curr <= num && curr > arr[prev]) {
                return index;
            } else {
                return prev;
            }
        }, 0);
    }

    const classes = useMarkerStyles();
    useEffect(() => {

        if (showStandCount) {
            if (currentMarkers?.length > 0) {
                for (var y = currentMarkers.length - 1; y >= 0; y--) {
                    currentMarkers[y].remove();
                }
            }
            const targetPopulation = standCounts.targetPopulation;
            const targetCounts = [Number.NEGATIVE_INFINITY, 0.75 * targetPopulation, 0.89 * targetPopulation,]
            // We may have multiple work orders that were combined so we need to find the one
            // that has the standCountMap
            let standCountMap;
            for (const item of standCounts?.imagery) {
                if (item.standCountMap) {
                    standCountMap = item.standCountMap;
                    break;
                }
            }
            standCountMap?.features?.map((sc) => {
                var el = document.createElement('div');
                el.className = classes.StandCountMarker;
                el.innerHTML = '<span><b>' + (sc.properties.plants_ac / 1000).toFixed(1) + 'K</b></span>'
                el.id = `stand-count-marker-${sc.properties.filename}`
                const colorIdx = nearestLessThan(targetCounts, sc.properties.plants_ac.toFixed(0));
                el.style.backgroundColor = standCountColors[colorIdx];
                el.style.color = standCountTextColors[colorIdx];
                let data = `<div style="width: 600px; height: 400px; text-align: left;">
                            <img src="https://aker-flights.s3.amazonaws.com/stand-count/${standCounts.imagery[0].workOrderId}/${sc.properties.filename}" style="max-width: 100%; max-height: 100%;" alt="${sc.properties.filename}">    
                            `
                for (let key in sc.properties) {
                    switch (key) {
                        case 'median_area_ac':
                        case 'area_ac':
                            break;
                        case 'plants_ac':
                            const plants_ac = (sc.properties[key] / 1000).toFixed(1);
                            data = data + `<p style="margin-top: 0;">plants/ac: ${plants_ac}K</p>`;
                            break;
                        case 'filename':
                            //data = data + `<li>filename: <a href="https://aker-flights.s3.amazonaws.com/stand-count/31112/${sc.properties[key]}"${sc.properties[key]}</a></li>`;
                            break;
                        default:
                            data = data + `<p style="margin-top: 0;">${key}: ${sc.properties[key]}</p>`;
                    }
                }
                data = data + `</div>`
                oneMarker = new mapboxgl.Marker(el)
                    .setLngLat(sc.geometry.coordinates)
                    .setPopup(
                        new mapboxgl.Popup({
                            offset: 25,
                        })
                            .setHTML(`${data}`)
                    )
                    .addTo(map)
                currentMarkers.push(oneMarker);
                return currentMarkers;
            })
        } else {
            for (let x = currentMarkers.length - 1; x >= 0; x--) {
                currentMarkers[x].remove();
            }
            return currentMarkers;
        }
        // eslint-disable-next-line
    }, [map, standCounts, showStandCount])

    return null
}

export default StandCountLayer