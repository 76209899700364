import React from "react";

export const PlusIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      className={className || "plus_icon"}
    >
      <g id="Group_1" data-name="Group 1" transform="translate(2 -183.049)">
        <line
          id="Line_6"
          data-name="Line 6"
          y2="15"
          transform="translate(6.169 183.549)"
        />
        <line
          id="Line_7"
          data-name="Line 7"
          x1="15"
          transform="translate(-1.5 190.891)"
        />
      </g>
    </svg>
  );
};
