import React, { useRef, useState } from "react";
import { makeStyles, IconButton, Grid } from "@material-ui/core";
import { pdfjs, Document, Page } from "react-pdf";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import cn from "clsx";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const useStyles = makeStyles((theme) => ({
  reportViewer: {
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
    width: `calc(100% - ${theme.palette.spacing.shelfWidth})`,
    backgroundColor: theme.palette.background.tertiary,
    zIndex: 9,
  },
  pdfViewerDoc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    color: "black",
    backgroundColor: "white",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  docLoaded: {
    display: "block",
  },
  pdfPage: {},
  closeBtn: {
    position: "absolute",
    top: "1rem",
    right: "1rem",
    fontSize: theme.typography.subtitle2.fontSize,
    zIndex: "9",
    backgroundColor: theme.palette.colors.gray165,
    opacity: "0.8",
    "&:hover": {
      backgroundColor: theme.palette.colors.gray205,
    },
  },
}));

export const PdfViewer = ({ reportToView, setReportToView }) => {
  const pdfViewer = useRef();
  const classes = useStyles();
  const [offsetWidth, setOffsetWidth] = useState(null);
  const [isDocLoaded, setIsDocLoaded] = useState(false);

  const onDocumentLoadSuccess = ({ numPages }) => {
    const el = document.getElementById("pdfArea");
    setOffsetWidth(el.offsetWidth);
    setIsDocLoaded(true);
  };

  return (
    <Grid id="pdfArea" className={classes.reportViewer} ref={pdfViewer}>
      <IconButton
        className={classes.closeBtn}
        size="small"
        onClick={() => setReportToView(null)}
      >
        <CloseRoundedIcon />
      </IconButton>
      <Document
        className={cn(classes.pdfViewerDoc, isDocLoaded && classes.docLoaded)}
        file={reportToView}
        onLoadSuccess={onDocumentLoadSuccess}
        loading="LOADING PDF, PLEASE WAIT..."
        noData="Sorry, there is no PDF available."
      >
        <Page
          className={classes.pdfPage}
          pageNumber={1}
          renderMode="canvas"
          renderAnnotationLayer={false}
          width={offsetWidth}
        />
        {/* {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))} */}
      </Document>
    </Grid>
  );
};
