import React from "react";
import { AccountSettings } from "./AccountSettings";
import { CustomerSettings } from "./CustomerSettings";
import { ImageReviewAccess } from "./ImageReviewAccess/ImageReviewAccess";
import { FarmFieldsAccess } from "./FarmsFieldsAccess/FarmFieldsAccess";
import { FarmHands } from "./FarmHands";
import { NotificationsPreferences } from "./NotificationsPreferences";

import { UserContext } from "../../../globalState/userContext";

import { useRouteMatch, Route, Redirect, Switch } from "react-router-dom";

import { Grid, makeStyles, Typography } from "@material-ui/core";
import { InternalLink } from "../../Helpers/InternalLink/InternalLink";

const SECTIONS = {
  "Account Settings": {
    path: "/account-settings",
    component: AccountSettings
  },
  "Customer Settings": {
    path: "/customer-settings",
    component: CustomerSettings
  },
  "Images Review Access": {
    path: "/images-review-access",
    component: ImageReviewAccess
  },
  "Farm/Fields Access": {
    path: "/farm-fields-access",
    component: FarmFieldsAccess
  },
  "Farm Hands": {
    path: "/farm-hands",
    component: FarmHands
  },
  "Notifications Preferences": {
    path: "/notifications-preferences",
    component: NotificationsPreferences
  }
};

const useHeaderStyles = makeStyles(theme => ({
  container: { padding: "0.75rem 0 2rem 0" },
  title: { fontSize: "1.75rem", fontWeight: "bold" }
}));

const Header = () => {
  const { currentUser } = React.useContext(UserContext);
  const classes = useHeaderStyles();
  return (
    <Grid container item justifyContent="space-between" className={classes.container}>
      <Grid item>
        <Typography variant="h1" className={classes.title}>
          Settings
        </Typography>
      </Grid>

      <Grid item>
        <Typography>
          You are currently logged in as {currentUser.email}
        </Typography>
      </Grid>
    </Grid>
  );
};

const useSideMenuStyles = makeStyles(theme => ({
  container: {
    padding: "2rem",
    backgroundColor: theme.palette.background.tertiary
  },
  linkContainer: { margin: "1rem" }
}));

const SideMenu = props => {
  let { url } = useRouteMatch();
  const classes = useSideMenuStyles();

  return (
    <Grid
      container
      item
      direction="column"
      className={classes.container}
      {...props}
    >
      {Object.entries(SECTIONS).map(([k, v], index) => {
        return (
          <Grid item key={k} className={classes.linkContainer}>
            <InternalLink to={`${url}${v.path}`}>{k}</InternalLink>
          </Grid>
        );
      })}
    </Grid>
  );
};

const useContentStyles = makeStyles(theme => ({
  container: {
    padding: "2rem",
    backgroundColor: theme.palette.background.alternate
  }
}));

const Content = props => {
  let { path } = useRouteMatch();
  const classes = useContentStyles();

  return (
    <Grid container item className={classes.container} {...props}>
      <Switch>
        <Route exact path={`${path}`}>
          <Redirect to={`${path}${SECTIONS["Account Settings"].path}`} />
        </Route>
        {Object.entries(SECTIONS).map(([_, v], index) => {
          return (
            <Route
              path={`${path}${v.path}`}
              component={v.component}
              key={index}
            />
          );
        })}
      </Switch>
    </Grid>
  );
};

const useLayoutStyles = makeStyles(theme => ({
  outerContainer: {
    backgroundColor: theme.palette.background.primary
  },
  innerContainer: {
    margin: "2.75rem",
    width: "100%"
  }
}));

export const Account = () => {
  const classes = useLayoutStyles();

  return (
    <Grid container className={classes.outerContainer}>
      <Grid item className={classes.innerContainer}>
        <Header />
        <Grid container item>
          <SideMenu xs={2} />
          <Content xs={10} />
        </Grid>
      </Grid>
    </Grid>
  );
};

const useSettingsHeadingStyles = makeStyles(theme => ({
  title: { fontSize: "1.75rem" }
}));

export const SettingsHeading = ({ text }) => {
  const classes = useSettingsHeadingStyles();

  return (
    <Typography variant="h1" className={classes.title}>
      {text}
    </Typography>
  );
};
