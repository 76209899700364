import { useEffect } from "react";
import { Marker } from "mapbox-gl";
import styles from "./Markers.module.scss";
import { useTrueCauseStressItemFilterState } from "../Views/Field/FieldShelf/TrueCause/FilterTrueCauseStressItem";

export const Markers = ({
  scrollIntoView,
  tooltipKey,
  map,
  locations,
  containerId,
  onMarkerClick,
  tooltipLabel,
  mapStyle,
}) => {
  const state = useTrueCauseStressItemFilterState();

  const selectField = (field) => {
    if (onMarkerClick) {
      onMarkerClick(field);
    }
  };

  /* Make the markers support updating rendering of state if the locations passed in change */
  useEffect(() => {
    const allMarkers = document.querySelectorAll(".fas.fa-map-marker-alt");
    allMarkers.forEach((elm) => {
      const locationId = elm.id.substring(
        elm.id.lastIndexOf("-") + 1,
        elm.id.length
      );
      // For AHI markers, we want to keep their colors
      let markerClassname =
        mapStyle === "satellite"
          ? styles.Marker
          : mapStyle === "basicDark"
            ? styles.Marker_Bright
            : mapStyle === "basic"
              ? styles.Marker_Dull
              : "";
      let hoveredMarkerClassname =
        mapStyle === "satellite"
          ? styles.Marker__Hovered
          : mapStyle === "basicDark"
            ? styles.Marker_Bright__Hovered
            : mapStyle === "basic"
              ? styles.Marker_Dull__Hovered
              : "";
      if (elm.className.includes('Marker_Red')) {
        markerClassname = styles.Marker_Red;
        hoveredMarkerClassname = styles.Marker_Red_Hovered;
      }
      if (elm.className.includes('Marker_Yellow')) {
        markerClassname = styles.Marker_Yellow;
        hoveredMarkerClassname = styles.Marker_Yellow_Hovered;
      }
      if (elm.className.includes('Marker_Green')) {
        markerClassname = styles.Marker_Green;
        hoveredMarkerClassname = styles.Marker_Green_Hovered;
      }
      // if the marker is no longer in the locations array axe it
      if (!(locations || [])?.some((l) => l.farmId ? l.farmId === +locationId : l.id === +locationId)) elm.remove();
      else if (state.currentHovered && state.currentHovered === +locationId)
        elm.className = `fas fa-map-marker-alt ${styles.MarkerBase} ${hoveredMarkerClassname}`;
      else
        elm.className = `fas fa-map-marker-alt ${styles.MarkerBase} ${markerClassname}`;
    });
  }, [state, locations, containerId, mapStyle]);

  useEffect(() => {
    if (map && locations) {
      for (let location of locations) {
        if (location.showMarker) {
          const markerWithPopup = document.createElement("div");
          const marker = document.createElement("i");
          const popup = document.createElement("div");
          const popupLabel = document.createElement("h5");
          const popupArrow = document.createElement("i");

          markerWithPopup.className = styles.MarkerWithPopup;
          let style = null
          if (location.color) {
            switch (location.color) {
              case 'red': style = styles.Marker_Red; break;
              case 'yellow': style = styles.Marker_Yellow; break;
              case 'green': style = styles.Marker_Green; break;
              default: style =
                mapStyle === "satellite"
                  ? styles.Marker
                  : mapStyle === "basicDark"
                    ? styles.Marker_Bright
                    : mapStyle === "basic"
                      ? styles.Marker_Dull
                      : "";;
            }
          }
          // if we have a farm ID, use it as this is the farm view
          const useId = location.farmId ? location.farmId : location.id;
          marker.className = `fas fa-map-marker-alt ${styles.MarkerBase} ${style}`;
          marker.id = `${containerId}-marker-${useId}`;

          popup.className = styles.Popup;
          popup.id = `${containerId}-popup-${useId}`;

          popupLabel.innerText = location.title;
          popupArrow.className = `fas fa-chevron-right ${styles.MarkerBase} ${style}`;

          popup.appendChild(popupLabel);
          popup.appendChild(popupArrow);
          markerWithPopup.appendChild(marker);
          markerWithPopup.style.cursor = "pointer";
          if (tooltipKey && location[tooltipKey]) {
            const tipLabel = tooltipLabel
              ? `${tooltipLabel} ${location[tooltipKey]}`
              : location[tooltipKey];
            markerWithPopup.setAttribute("title", tipLabel);
          }
          const markerOptions = { element: markerWithPopup };

          markerWithPopup.addEventListener("mouseenter", (e) => {
            popup.style.display = "flex";

            if (scrollIntoView) {
              // filter locations to those whom show a marker
              const filteredLocations = locations.filter((l) => l.showMarker);
              // if we have a farm ID, use it as this is the farm view
              const useId = location.farmId ? location.farmId : location.id;
              // find the elements index which was hovered
              const elementIndex = filteredLocations.findIndex(
                (loc) => loc.id === useId
              );
              //let element = filteredLocations[elementIndex - 1],   //Previous code
              let element = filteredLocations[elementIndex],
                elementId = useId;
              if (element && elementIndex !== 0) elementId = element.id;

              let elementToScrollTo = document.getElementById(elementId);
              // for work orders we are scrolling within another div, so check if this element exists
              // and handle custom behavior for it
              const tableELm = document.querySelector("#work-orders-table");
              if (tableELm) {
                tableELm.scrollTop = elementToScrollTo.offsetTop;
              } else {
                // otherwise default to scrolling into view
                elementToScrollTo?.scrollIntoView({ behavior: "smooth" });
              }
            }
          });
          markerWithPopup.addEventListener("mouseleave", (e) => {
            popup.style.display = "none";
          });

          markerWithPopup.addEventListener("click", (e) => {
            selectField(location);
          });

          new Marker(markerOptions).setLngLat(location.center).addTo(map);
        }
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [map, locations, containerId]);

  return null;
};
