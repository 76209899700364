import axios from "axios";
const isStagingSite = process.env.REACT_APP_ENV.includes("staging");
const isDevSite = process.env.REACT_APP_ENV.includes("development");
const baseUrl = isDevSite
    ? process.env.REACT_APP_BACK_OFFICE_URL
    : isStagingSite
        ? process.env.REACT_APP_BACK_OFFICE_URL_STAGING
        : process.env.REACT_APP_BACK_OFFICE_URL_PRODUCTION;

export const getWorkOrder = async (authToken, workOrderId) => {
    const result = await axios({
        method: 'GET',
        url: `${baseUrl}/api/v1/work_orders/${workOrderId}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
        },
    })
    return result.data.data
}

export const getWorkOrderTasks = async (authToken, workOrderId) => {
    const result = await axios({
        method: 'GET',
        url: `${baseUrl}/api/v1/work_orders/${workOrderId}/tasks`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
        },
    })
    return result.data.data
}

export const editWorkOrder = async (token, workOrderId, data) => {
    const result = await axios({
        method: "PATCH",
        url: `${baseUrl}/api/v1/work_orders/${workOrderId}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
        data: { data },  
    });
    return result.data.data;
};

export const deleteWorkOrder = async (authToken, workOrderId) => {
    const result = await axios({
        method: 'DELETE',
        url: `${baseUrl}/api/v1/work_orders/${workOrderId}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
        },
    })
    return result
}

export const generateFlags = async (token, workOrderId) => {
    const result = await axios({
        method: "POST",
        url: `${baseUrl}/api/v1/work_orders/${workOrderId}/generate_probe_flight_plan_flags`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        },
    });
    return result.data.data;
};