import { sub } from "date-fns";

const useCookies = () => {
  const setCookie = ({ key, value, path = "/", expires }) => {
    var expiration = expires;
    // To enable default expiration, uncomment the following lines
    // Expiration is in minutes

    // if ( !expires ) {
    //   expiration = expirationString(24*60) // testing:default to 24 hours
    // }

    document.cookie = `${key}=${value};path=${path};expires=${expiration}`;
  };

  const getCookieKey = (cookie) => {
    const indx = cookie.indexOf("=");
    return cookie.substring(0, indx).trim();
  };

  const removeCookie = (cookieName) => {
    document.cookie.split(";").forEach((cookie) => {
      const key = getCookieKey(cookie);
      if (key === cookieName) {
        const date = sub(new Date(), { days: 1 });
        document.cookie = `${key}=;path=/;expires=${date}`;
      }
    });
  };

  const removeAuthCookies = () => {
    document.cookie.split(";").forEach((cookie) => {
      const key = getCookieKey(cookie);
      if (key === "userId" || key === "authToken") {
        const date = sub(new Date(), { days: 1 });
        document.cookie = `${key}=;path=/;expires=${date}`;
      }
    });
  };

  const expirationString = (minutes) => {
    if (minutes) {
      var date = new Date();
      date.setTime(date.getTime() + minutes * 60 * 1000);
      return date.toUTCString();
    }
  };

  const getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  return {
    setCookie,
    getCookie,
    removeCookie,
    expirationString,
    removeAuthCookies,
  };
};

export default useCookies;
