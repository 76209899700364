import React, { useState, useMemo } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  FormControlLabel,
} from "@material-ui/core";
import { Switch, OldSelect, dateToString } from "../../../../../../Helpers";
import { ShelfLabel } from "../../../../../../Helpers/ShelfLabel/ShelfLabel";
import { IssueImageCard } from "./IssueImageCard";
import { useApi } from "../../../../../../../hooks/useApi";
//import { utcDateToString } from "../../../../../../Helpers/utcDateToString";

const useStyles = makeStyles((theme) => ({
  body: {
    position: "relative",
    width: "46.5rem",
    marginTop: "1.5rem",
  },
  select: {
    marginBottom: "1.5rem",
  },
  typeDrawer: {
    marginTop: "1.5rem",
  },
  optionLabel: {
    fontFamily: theme.typography.fontFamilyBold,
    fontSize: theme.typography.body1.fontSize,
  },
  stressItemContainer: {
    padding: "0 1.5rem",
    marginTop: ".8rem",
    "& > p": {
      marginBottom: "0.5rem",
      fontFamily: theme.typography.fontFamilyMedium,
    },
  },
  issueCardsGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(3, calc(calc(100% - 2rem) / 3))",
    gap: "1rem",
    width: "100%",
    padding: "0 1rem",
  },
}));

export const TrueCauseIssues = ({
  parentClasses,
  newReport,
  updateNewReport,
  selectedSeasonId,
  setNewReport,
}) => {
  const classes = useStyles();
  const [showIssues, setShowIssues] = useState(true);
  const [showOption, setShowOption] = useState("");
  const seasonId = newReport.seasonId ? newReport.seasonId : selectedSeasonId;
  const { data: favoritedObservations } = useApi(
    `/api/v1/seasons/${seasonId}/flagged_probe_observations`
  );

  // USE THIS ONCE isFavorited ATTRIBUTE IS CORRECT - THEN UNCOMMENT THE CONDITIONAL IN RETURN IssueImageCard
  // const selectedDateObservations = trueCauseObservations.filter((obs) =>
  //   newReport.observationDate
  //     ? dateToString(obs.observedAt) === newReport.observationDate
  //     : false
  // )[0];
  const observations = useMemo(() => {
    return favoritedObservations
      ? favoritedObservations.filter((obs) =>
        newReport.observationDate
          ? dateToString(obs.observedAt) ===
          dateToString(newReport.observationDate)
          : false
      )[0]
      : { cropStressTypes: [] };
  }, [favoritedObservations, newReport.observationDate]);

  const selectImage = (imageId) => {
    let isNew = true;
    let newCropStressFindings = [];
    if (newReport.cropStressFindings) {
      newCropStressFindings = newReport.cropStressFindings.filter((finding) => {
        const matching = finding.id === imageId;
        if (matching) isNew = false;
        return !matching;
      });
    }
    if (isNew) {
      newCropStressFindings.push({ id: imageId });
    }
    updateNewReport("cropStressFindings", newCropStressFindings);
  };

  const updateNote = (imageId, input) => {
    let isNew = true;
    let newCropStressFindings = [];
    if (newReport.cropStressFindings) {
      newCropStressFindings = newReport.cropStressFindings.map((finding) => {
        const matching = finding.id === imageId;
        if (matching) {
          finding.notes = input;
          isNew = false;
        }
        return finding;
      });
    }
    if (isNew) {
      newCropStressFindings.push({ id: imageId, notes: input });
    }
    updateNewReport("cropStressFindings", newCropStressFindings);
  };

  const generateCropStressFinding = (image) => {
    if (newReport.cropStressFindings) {
      const matchingFinding = newReport.cropStressFindings.filter(
        (finding) => finding.id === image.id
      )[0];
      if (matchingFinding) {
        return matchingFinding;
      } else {
        return {};
      }
    } else return {};
  };

  const toggleIssues = () => {
    if (showIssues) {
      setNewReport({
        ...newReport,
        observationDate: null,
        cropStressFindings: null,
      });
    }
    setShowOption("");
    setShowIssues(!showIssues);
  };

  if (!newReport.template.hideObservationData) {
    return (
      <Grid container item direction="column" className={parentClasses.section}>
        <FormControlLabel
          className={parentClasses.headerWithSwitch}
          control={<Switch checked={showIssues} />}
          label={<Typography variant="h4">Add TrueCause Issues</Typography>}
          onChange={toggleIssues}
        />
        {showIssues && (
          <Grid className={classes.body}>
            <OldSelect
              defaultLabel="Date of TrueCause Observation"
              options={
                favoritedObservations &&
                favoritedObservations.map((obs) =>
                  // options={trueCauseObservations.map((obs) =>
                  dateToString(obs.observedAt)
                )
              }
              selectedOption={
                newReport.observationDate
                  ? dateToString(newReport.observationDate)
                  : ""
              }
              setSelectedOption={(date) =>
                updateNewReport("observationDate", date)
              }
            />
            <Grid>
              {observations &&
                observations.cropStressTypes.map((stressType, i) => {
                  const show = showOption === stressType.name;
                  return (
                    <Grid key={i} className={classes.typeDrawer}>
                      <ShelfLabel
                        label={stressType.name}
                        showOptions={show}
                        select={() =>
                          setShowOption(show ? "" : stressType.name)
                        }
                        classes={classes.optionLabel}
                      />
                      {show &&
                        stressType.cropStressItems.map((item, i) => {
                          return (
                            <Grid
                              key={i}
                              className={classes.stressItemContainer}
                            >
                              <Typography>{item.name}</Typography>
                              <div className={classes.issueCardsGrid}>
                                {item.issues.map((issue, i) => {
                                  return issue.images.map((image, i) => {
                                    return (
                                      image.isFavorited && (
                                        <IssueImageCard
                                          i={i}
                                          image={image}
                                          selectImage={selectImage}
                                          updateNote={updateNote}
                                          cropStressFinding={generateCropStressFinding(
                                            image
                                          )}
                                        />
                                      )
                                    );
                                  });
                                })}
                              </div>
                            </Grid>
                          );
                        })}
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
  return null;
};
