import React from "react";
import cn from "clsx";

import styles from "./Chip.module.scss";

export const Chip = ({
  children,
  className,
  status,
  variant,
  ...restProps
}) => {
  return (
    <button
      className={cn(
        styles.Chip,
        className,
        status === "selected" && styles["Chip--selected"],
        status === "unselected" && styles["Chip--unselected"],
        variant === "contained" && styles["Chip--contained"]
      )}
      {...restProps}
    >
      <span className={styles.Chip__Content}>{children}</span>
    </button>
  );
};
