import * as React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const Accordion = withStyles((theme) => ({
  root: {
    background: theme.palette.background.secondary,
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
}))(MuiAccordion);

const useSummaryStyles = makeStyles((theme) => ({
  root: {
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
  expandIcon: {
    color: theme.palette.text.primary
  },
}));

const AccordionSummary = (props) => {
  const classes = useSummaryStyles();

  return (
    <MuiAccordionSummary
      classes={classes}
      expandIcon={<KeyboardArrowDownIcon />}
      {...props}
    >
      <Typography variant="body1">{props.children}</Typography>
    </MuiAccordionSummary>
  );
};

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

export { Accordion, AccordionSummary, AccordionDetails };
