import {
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import { ShelfLabel } from "../../../../Helpers/ShelfLabel/ShelfLabel";
import { Switch } from "../../../../Helpers/Switch/Switch";
import { textNums } from "../../../../Helpers/textNums";
import axios from "axios";
import {
  useTrueCauseStressItemFilterDispatch,
  useTrueCauseStressItemFilterState,
} from "./FilterTrueCauseStressItem";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { TrueCauseFilterStressItemMenu } from "./TrueCauseFilterStressItemMenu";
import { useField } from "../../../../../globalState/fieldContext";
//import { useMap } from "../../../../../globalState";
import { TrueCauseDateSlider } from "../../TrueCauseDateSlider/TrueCauseDateSlider";

const useOptionStyles = makeStyles((theme) => ({
  flag: {
    marginBottom: "1.5rem",
  },
  flagHeader: { marginBottom: "1rem", "& p": { opacity: "0.87" } },
  flagHeaderChip: {
    height: "1.5rem",
    width: "1.5rem",
    marginRight: "0.5rem",
    paddingTop: "2px",
    fontSize: theme.typography.body2,
    color: theme.palette.colors.black,
    borderRadius: "50px",
    backgroundColor: theme.palette.colors.blue,
    boxSizing: "border-box",
  },
  cardsGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "12px",
  },
  card: {
    position: "relative",
    minHeight: "2rem",
    border: `1px solid ${theme.palette.colors.gray70}`,
    borderRadius: "4px",
    cursor: "pointer",
    overflow: "hidden",
  },
  cardImage: {
    height: "70%",
    objectFit: "cover",
  },
  aiBadge: {
    position: "absolute",
    top: 5,
    right: 5,
    width: 16,
    height: 16,
    borderRadius: "50%",
    background: theme.palette.primary.main,
    fontSize: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.colors.black,
  },
  aiBadgeHidden: {
    display: "none"
  },
  imagesIcon: {
    position: "absolute",
    top: ".9rem",
    right: ".9rem",
    fontSize: "1.3rem",
    color: theme.palette.colors.white,
  },
  cardInfo: {
    height: "30%",
    padding: "0.5rem 1rem",
    boxSizing: "border-box",
  },
  filterContainer: {
    position: "relative",
    marginTop: "1.65rem",
    marginBottom: "1rem",
  },
  mapsContainer: {
    marginBottom: "1rem",
  },
  images: {},
  loadingContainer: {
    position: "relative",
    bottom: theme.spacing(0.5),
    left: 0,
    width: "100%",
  },
}));

export const TrueCauseDrawer = ({
  useDrawerStyles,
  probeObsByPin,
  trueCauseSliderDate,
  setImagesToView,
  drawerState,
  setDrawerState,
  toggleShowObservation,
  toggleShowFlagLocations,
  stressMapsByDate,
  stressMapToDisplay,
  setStressMapToDisplay,
  imageryDates,
  sliderDate,
  setSliderDate,
  maps
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const drawerClasses = useDrawerStyles();
  const optionClasses = useOptionStyles();
  const [downloading, setDownloading] = useState([]);
  const fieldContext = useField();
  const state = useTrueCauseStressItemFilterState();
  const dispatch = useTrueCauseStressItemFilterDispatch();
  const imageFilter = React.useMemo(() => {
    if (!state.client) return [];
    return Object.keys(state.client).filter((key) => !!state.client[key]);
  }, [state]);

  useEffect(() => {
    setDrawerState((state) => ({
      ...state,
      trueCause: {
        ...(state.trueCause || {}),
        isOpen: showOptions,
      },
    }));
    //eslint-disable-next-line
  }, [showOptions]);

  const imageData = React.useMemo(() => {
    const images = [];
    if (showOptions && probeObsByPin) {
      probeObsByPin?.forEach((pin) => {
        pin.issues.forEach((issue) => {
          const allImages = {
            probeImages: issue.images.map((image, index) => ({
              ...image,
              customerReviewStressItems: [{ name: issue.name }],
              agronomyReviewStressItems: [],
            })),
          };
          images.push(allImages);
        });
      });
    }
    return images;
  }, [showOptions, probeObsByPin]);

  const filterCard = (issue) =>
    !imageFilter.length || imageFilter.includes(issue.name);

  const filterFlags = (pin) =>
    pin?.issues.some(
      (issue) => !imageFilter.length || imageFilter.includes(issue.name)
    );

  const onChangeFilter = React.useCallback(
    (action) => {
      toggleShowObservation(action?.payload?.name);
      dispatch(action);
    },
    [dispatch, toggleShowObservation]
  );

  const onMouseEnter = (id) =>
    dispatch({ type: "image-hover", payload: { id } });
  const onMouseLeave = () => dispatch({ type: "image-off-hover" });

  const handleToggleStressMap = async (toggledImage) => {
    let copyDrawerState = { ...drawerState };
    if (stressMapToDisplay?.id === toggledImage.id) {
      setStressMapToDisplay(null);
      copyDrawerState.trueCause.selectedMapType = "";
      copyDrawerState.trueCause.selectedMapId = "";
    } else {
      copyDrawerState.trueCause.selectedMapType = toggledImage.name.toUpperCase();
      copyDrawerState.trueCause.selectedMapId = toggledImage.id;
      setStressMapToDisplay(toggledImage);
    }
    setDrawerState(copyDrawerState);
  };

  const downloads3ToClient = (toggledImage, fileType) => {
    const { name } = toggledImage;
    // const fileName = toggledImage.mapDownloadUrl.split("/")[
    //   toggledImage.mapDownloadUrl.split("/").length - 1
    // ];
    return axios.default
      .get(`${toggledImage.mapDownloadUrl}`, {
        headers: {
          Accept: "*/*",
        },
        responseEncoding: "binary",
        responseType: "arraybuffer",
        onDownloadProgress: function (progressEvent) {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          setDownloading((downloading) => [
            ...downloading.map((a) =>
              a.name === name ? { name, progress: percent } : a
            ),
          ]);
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");

        const fieldId = fieldContext.field[0].id;
        const dateFlown = new Date(toggledImage.observedAt)
        const flightDayOfMonth = dateFlown.getDate();
        const flightMonth = dateFlown.getMonth(); // Be careful! January is 0, not 1
        const flightYear = dateFlown.getFullYear();
        const flightDate = flightYear + '-' + (flightMonth + 1) + "-" + flightDayOfMonth;
        const localFile = `${fieldId}_${flightDate}_${toggledImage.name}_shp.${fileType}`;
        link.href = url;
        link.setAttribute("download", localFile); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  };

  const downloadCallback = async (toggledImage, fileType) => {
    // dont allow for redownloading files while download in progress
    if (downloading.some((a) => a.name === toggledImage.name)) return;
    setDownloading([
      ...downloading.filter((a) => a.name !== toggledImage.name),
      { name: toggledImage.name, progress: 0 },
    ]);
    await downloads3ToClient(toggledImage, fileType).finally(() => {
      setDownloading(
        downloading.filter((file) => file.name !== toggledImage.name)
      );
    });
  };

  const findProgressValue = (image) => {
    const value = downloading.find((a) => a.name === image.name);
    return value?.progress;
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      className={drawerClasses.root}
    >
      <ShelfLabel
        label={`TrueCause Observations`}
        showOptions={showOptions}
        select={() => setShowOptions(!showOptions)}
        classes={showOptions && drawerClasses.showOptions}
      />

      {showOptions && (
        <>
          <div className="trueCauseDateSliderWrapper">
            {!!imageryDates?.length && (
              <TrueCauseDateSlider
                imageryDates={imageryDates}
                sliderDate={sliderDate}
                setSliderDate={setSliderDate}
                drawerState={drawerState}
                setStressMapToDisplay={setStressMapToDisplay}
                maps={maps}
              />
            )}
          </div>
          <Grid
            container
            justifyContent="flex-end"
            className={optionClasses.filterContainer}
          >
            {/* <Typography>{trueCauseSliderDate}</Typography> */}
            <TrueCauseFilterStressItemMenu
              onChange={onChangeFilter}
              imageData={imageData}
            />
          </Grid>
          <Grid>
            <div>
              <FormControlLabel
                control={<Switch checked={probeObsByPin.some((probeObs) => probeObs.show === true)} />}
                label={"Flag Locations"}
                onChange={() => toggleShowFlagLocations()}
              />
            </div>
          </Grid>

          <Grid className={optionClasses.mapsContainer}>
            {stressMapsByDate.length > 0 && (
              <>
                <Typography color="textSecondary">Stress Maps</Typography>
                {stressMapsByDate.map((imagesByDate, i) => {
                  return (
                    <Grid
                      key={i}
                      container
                      item
                      direction="column"
                      alignItems="flex-start"
                      className={optionClasses.images}
                      xs={12}
                    >
                      {imagesByDate.imagery.map((image, i) => {
                        const shouldCheck = image?.id === stressMapToDisplay?.id;
                        return (
                          <>
                            <Grid
                              key={i}
                              container
                              item
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <FormControlLabel
                                control={<Switch checked={shouldCheck} />}
                                label={image.name.toUpperCase()}
                                onChange={() => handleToggleStressMap(image)}
                              />
                              <IconButton
                                onClick={() => downloadCallback(image, '.zip')}
                                aria-label="download imagery"
                              >
                                <GetAppOutlinedIcon color="primary" />
                              </IconButton>
                              {downloading.some(
                                (file) => file.name === image.name
                              ) && (
                                  <div className={optionClasses.loadingContainer}>
                                    <LinearProgress
                                      variant="determinate"
                                      value={findProgressValue(image)}
                                    />
                                  </div>
                                )}
                            </Grid>
                          </>
                        );
                      })}
                    </Grid>
                  );
                })}
              </>
            )}
          </Grid>
          <Grid className={drawerClasses.options}>
            {probeObsByPin?.length > 0 ? (
              probeObsByPin?.filter(filterFlags).map((pin, i) => {
                return (
                  <>
                    <Grid
                      container
                      justifyContent="flex-end"
                      style={{ position: "relative" }}
                    ></Grid>
                    <Grid key={i} className={optionClasses.flag}>
                      <Grid
                        container
                        item
                        alignItems="center"
                        className={optionClasses.flagHeader}
                      >
                        <Grid
                          container
                          item
                          justifyContent="center"
                          alignItems="center"
                          className={optionClasses.flagHeaderChip}
                        >
                          {pin.pinNumber}
                        </Grid>
                        <Typography variant="body2">{`Flag ${textNums[pin.pinNumber]
                          }`}</Typography>
                      </Grid>

                      <div className={optionClasses.cardsGrid}>
                        {pin.issues.filter(filterCard).map((issue, i) => {
                          if (typeof issue.images === "object") {
                            return (
                              <Grid
                                key={i}
                                container
                                className={optionClasses.card}
                                onClick={() => setImagesToView(issue.images)}
                                onMouseEnter={() =>
                                  onMouseEnter(issue.images[0].id)
                                }
                                onMouseLeave={onMouseLeave}
                              >

                                <img
                                  id={`img-${issue.images[0].id}`}
                                  src={issue.images[0].thumbnailPath}
                                  alt={`${issue.name}`}
                                  className={optionClasses.cardImage}
                                />
                                {/*2574 is the magic ID of the AI user */}
                                <div className={issue.images[0].observedBy === 2574 ? optionClasses.aiBadge : optionClasses.aiBadgeHidden}>AI</div>
                                {issue.images.length > 1 && (
                                  <PhotoLibraryIcon
                                    className={optionClasses.imagesIcon}
                                  />
                                )}
                                <Grid
                                  container
                                  item
                                  className={optionClasses.cardInfo}
                                >
                                  <Typography
                                    color="textSecondary"
                                    variant="subtitle2"
                                  >
                                    Stressor Type:
                                  </Typography>
                                  <Typography variant="subtitle2">
                                    {issue.name}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          } else {
                            return <Typography>Error with image.</Typography>;
                          }
                        })}
                      </div>
                    </Grid>
                  </>
                );
              })
            ) : (<>
              <Typography color="textSecondary">
                No observations available
              </Typography>

            </>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};
