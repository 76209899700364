import * as React from "react";
import cn from "clsx";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import Swal from "sweetalert2";

import { Chip } from "../../../../Helpers/Chip/Chip";
import {
  useImageReviewerState,
  useImageReviewerRefresh,
  useImageReviewerResponse,
  useImageReviewerSelectedImage,
} from "../../contexts/ImageReviewerContext/ImageReviewerContext";
import { useLazyApi } from "../../../../../hooks/useApi";

export const ImageReviewerFavoriteAction = React.memo(({ className }) => {
  const { selectedImages } = useImageReviewerState();
  const selectedImage = useImageReviewerSelectedImage();
  const refresh = useImageReviewerRefresh();
  const { data } = useImageReviewerResponse();

  const [executeCall] = useLazyApi(
    "/api/v1/favorite_images/toggle_probe_image",
    {
      method: "POST",
    }
  );

  const disabled = selectedImages.length !== 1;

  const isFavorited = selectedImage?.isFavorited;
  const isPublished = selectedImage?.visible;

  const onClick = React.useCallback(async () => {
    try {
      // Optimistic update of the UI
      refresh(
        data.map((page) => {
          return {
            ...page,
            probeImages: page.probeImages.map((img) => {
              if (selectedImages.includes(img.id)) {
                return {
                  ...img,
                  isFavorited: !isFavorited,
                  visible: !isFavorited ? true : isPublished,
                };
              }

              return img;
            }),
          };
        }),
        false
      );

      await executeCall({
        data: {
          url: selectedImage?.imagePath,
        },
      });
    } catch (e) {
      new Swal({
        title: "Something went wrong",
        html:
          "We're sorry but we couldn't complete your request. Please try again and if the problem persists let us know.",
        icon: "error",
      });

      refresh();
    }
  }, [
    data,
    executeCall,
    isFavorited,
    refresh,
    selectedImage?.imagePath,
    selectedImages,
    isPublished,
  ]);

  return (
    <Chip
      title={isFavorited ? "Unfavorite" : "Favorite"}
      disabled={disabled}
      onClick={onClick}
      className={cn(className)}
    >
      {isFavorited ? <FavoriteIcon /> : <FavoriteBorderIcon />}
    </Chip>
  );
});

ImageReviewerFavoriteAction.displayName = "ImageReviewerFavoriteAction";
