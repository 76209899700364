import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Swal from "sweetalert2";

import {
  useImageReviewerContent,
  useImageReviewerRefresh,
  useImageReviewerResponse,
  useImageReviewerSelectedImage,
  useImageReviewerState,
} from "../../contexts/ImageReviewerContext/ImageReviewerContext";
import { ChipSquare } from "../../../../Helpers/ChipSquare/ChipSquare";
import { useLazyApi } from "../../../../../hooks/useApi";
import { useImageReviewerCropSelectionState } from "../../contexts/ImageReviewerCropsContext/ImageReviewerCropsContext";

const useStyles = makeStyles((theme) => ({
  shelfStressItemsContainer: {
    padding: theme.spacing(2),
    display: "grid",
    gridAutoFlow: "row",
    gap: theme.spacing(1),
    gridAutoRows: "min-content",
  },
}));

export const ShowImageReviewerShelfAppliedStressItems = () => {
  const { selectedImages } = useImageReviewerState();
  const { images } = useImageReviewerContent();
  const classes = useStyles();

  const selectedImage = React.useMemo(() => {
    return images.find((i) => i.id === selectedImages[0]);
  }, [images, selectedImages]);

  if (
    !selectedImage ||
    !selectedImage.customerReviewStressItems ||
    selectedImage.customerReviewStressItems.length === 0
  ) {
    return null;
  }

  return (
    <div className={classes.shelfStressItemsContainer}>
      {selectedImage.customerReviewStressItems.map((stressItem, i) => {
        return (
          <StressItemAction key={`${stressItem.id}-${i}`} {...stressItem} />
        );
      })}
    </div>
  );
};

const StressItemAction = (props) => {
  const refresh = useImageReviewerRefresh();
  const { data } = useImageReviewerResponse();
  const { meta } = useImageReviewerContent();
  const selectedImage = useImageReviewerSelectedImage();
  const { rereview } = useImageReviewerCropSelectionState();

  const [executeCall] = useLazyApi(`/api/v1/image_review/${meta.id}`, {
    method: "PUT",
  });

  const { id: stressItemId } = props;
  const onDelete = React.useCallback(async () => {
    try {
      // Optimistic update of the UI
      refresh(
        data.map((page) => {
          return {
            ...page,
            probeImages: page.probeImages.map((img) => {
              if (selectedImage.id === img.id) {
                return {
                  ...img,
                  customerReviewStressItems: img.customerReviewStressItems.filter(
                    (item) => {
                      return item.id !== stressItemId;
                    }
                  ),
                };
              }

              return img;
            }),
          };
        }),
        false
      );

      const cropStressFindingAttributes = selectedImage.customerReviewStressItems
        .filter((finding) => {
          return finding.id !== stressItemId;
        })
        .map((finding) => ({
          cropStressItemId: finding.id,
          taggedById: finding.taggedBy.id,
        }));
      await executeCall({
        data: {
          probeImageId: selectedImage.id,
          observedCropId: selectedImage.observedCrop.id,
          cropStressFindingAttributes,
          rereview,
        },
      });
    } catch (e) {
      new Swal({
        title: "Something went wrong",
        html:
          "We're sorry but we couldn't complete your request. Please try again and if the problem persists let us know.",
        icon: "error",
      });

      refresh();
    }
  }, [executeCall, data, refresh, selectedImage, stressItemId, rereview]);

  return (
    <span>
      <ChipSquare label={<StressItemLabel {...props} />} onDelete={onDelete} />
    </span>
  );
};

const useItemLabelStyles = makeStyles((theme) => ({
  labelContainer: {
    display: "grid",
    gap: `${theme.spacing(1)}px`,
  },
  labelSubtitle: {
    color: theme.palette.text.secondary,
  },
}));

const StressItemLabel = (props) => {
  const classes = useItemLabelStyles();

  return (
    <span className={classes.labelContainer}>
      <span>{props.name}</span>
      {props.taggedBy.email && (
        <em className={classes.labelSubtitle}>{props.taggedBy.email}</em>
      )}
    </span>
  );
};
