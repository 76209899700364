import React from "react";
import { Link } from "react-router-dom";

export const IMAGE_REVIEWER_BASE_COLUMNS = [
  {
    Header: "Work Order ID",
    id: "workOrderId",
    sortable: true,
    accessor: "workOrderId",
    Cell: ({ row }) => {
      return (
        <Link to={`/image-reviewer/${row.original.id}`}>{row.original.workOrderId}</Link>
      );
    },
  },
  {
    Header: "Field",
    accessor: "fieldName",
    Cell: ({ row, value }) => {
      return (
        <Link to={`/image-reviewer/${row.original.id}`}>
          {value}
        </Link>
      )
    },
  },
  {
    Header: "Field Id",
    accessor: "fieldID",
    Cell: ({ row, value }) => {
      return (
        <Link to={`/image-reviewer/${row.original.id}`}>
          {value}
        </Link>
      )
    },
  },
  // {
  //   Header: "Reviewer",
  //   accessor: "reviewedBy",
  // },
  {
    Header: "# of Images",
    accessor: "totalImageCount",
  },
  {
    Header: "% Complete",
    accessor: (c) => {
      if (!c.totalImageCount) {
        return "";
      }
      if (c.totalImageCount <= 0) {
        return 0;
      }

      return `${(
        ((c.totalImageCount - c.unreviewedImageCount) / c.totalImageCount) *
        100
      ).toFixed()}%`;
    },
  },
  {
    Header: "Updated On",
    accessor: (c) => {
      return c.recentProbeBatchDate;
    },
    Cell: ({ row }) => {
      if (!row.original.recentProbeBatchDate) {
        return "";
      }

      return (
        <>
          {new Intl.DateTimeFormat("sv-SE").format(
            new Date(row.original.recentProbeBatchDate)
          )}
        </>
      );
    },
  },
];
