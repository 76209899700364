import * as React from "react";
import cn from "clsx";

import styles from "./ImageGrid.module.scss";
import { AspectRatio } from "../../../../Helpers/AspectRatio/AspectRatio";
import { useIntersectionObserver } from "../../../../../hooks/useIntersectionObserver";
import { Skeleton } from "../../../../Helpers/Skeletons/Skeleton";

export const ImageGrid = ({ children, onLoad, page }) => {
  return <div className={styles.ImageGrid}>{children}</div>;
};

const imgCache = {};
const noop = () => {};

export const Img = ({ src, ...props }) => {
  const seenBefore = imgCache[src];
  const [status, setStatus] = React.useState(seenBefore ? "loaded" : "loading");
  const imageRef = React.useRef(null);

  const onLoad = React.useCallback(
    (e) => {
      // if the image is `complete` without a `naturalWidth` is likely
      // to be broken
      if (
        imageRef.current &&
        imageRef.current.complete &&
        imageRef.current.naturalWidth
      ) {
        imgCache[src] = true;
        setStatus("loaded");
      } else {
        setStatus("error");
      }
    },
    [src]
  );

  return (
    <>
      {status !== "loaded" && (
        <Skeleton
          width="100%"
          height="100%"
          style={{ position: "absolute", top: 0, left: 0 }}
          variant="rect"
        />
      )}

      <img
        ref={imageRef}
        alt=""
        src={src}
        onLoad={seenBefore ? noop : onLoad}
        aria-hidden={status !== "loaded"}
        style={{
          opacity: status !== "loaded" ? 0 : 1,
          height: status !== "loaded" ? 0 : "auto",
          position: "absolute",
        }}
        {...props}
        className={cn(styles.Img, props.className)}
      />
    </>
  );
};

const ImageGridSentinel = React.memo(
  ({
    status,
    id = "image-grid-sentinel",
    style,
    className,
    children,
    rootMargin = "0px",
    threshold = 0,
    onVisible,
    disabled,
  }) => {
    const elRef = React.useRef(null);
    const { isIntersecting } = useIntersectionObserver({
      elRef,
      options: { threshold, rootMargin },
    });

    React.useEffect(() => {
      if (isIntersecting) {
        onVisible();
      }
    }, [isIntersecting, onVisible]);

    return (
      <div
        id={id}
        ref={elRef}
        style={{
          width: "20px",
          height: "20px",
        }}
      />
    );
  }
);

ImageGridSentinel.displayName = "ImageGridSentinel";

const ImageGridItem = React.memo(
  ({
    ratio = 4 / 3,
    onClick,
    className,
    selected,
    variant,
    src,
    onMouseEnter = noop,
    onMouseLeave = noop,
    id,
  }) => {
    const Comp = typeof onClick === "function" ? "button" : "div";

    return (
      <Comp
        id={id}
        className={cn(
          styles.ImageGridItem,
          variant === "blurry" && styles["ImageGridItem--blurry"],
          className
        )}
        onClick={onClick}
      >
        <AspectRatio
          value={ratio}
          className={cn(
            selected && styles["ImageGridItem--selected"],
            onClick && styles["ImageGridItem--clickable"]
          )}
        >
          <Img
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            src={src}
          />
          <div
            className={
              variant === "unhealthy"
                ? styles["Div--unhealthy"]
                : styles["Div--AI"]
            }
          >
            AI
          </div>
        </AspectRatio>
      </Comp>
    );
  }
);
ImageGridItem.displayName = "ImageGridItem";

ImageGrid.Item = ImageGridItem;
ImageGrid.Sentinel = ImageGridSentinel;
