import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { MapContext } from "../../globalState";
import styles from "./Map.module.scss";
import { Flex } from "../Layouts/Flex";
import cn from "clsx";

const MapLayerToggle = ({ selectedMapStyle, setSelectedMapStyle }) => {
  const { mapStyles, updatePreferredMapStyle } = useContext(MapContext);
  const [showSelector, setShowSelector] = useState(false);
  const toggleMapStyle = (type) => {
    updatePreferredMapStyle(type);
    if (type !== selectedMapStyle) setSelectedMapStyle(type);
    setShowSelector(false);
  };
  const [mapStylesArray, setMapStylesArray] = useState([]);

  useEffect(() => {
    let newMapStylesArray = [];
    for (let key in mapStyles) {
      newMapStylesArray.push({ ...mapStyles[key], key });
    }
    setMapStylesArray(newMapStylesArray);
  }, [mapStyles]);

  return (
    <Flex className={styles.MapLayerToggle}>
      {showSelector && (
        <Flex className={styles.LayerSelector}>
          {mapStylesArray.map((style, i) => {
            if (!style.label.includes("Blank")) {
              return (
                <Flex
                  key={i}
                  flexDirection="column"
                  className={cn(
                    styles.MapThumbnail,
                    selectedMapStyle === style.key && styles.SelectedStyle
                  )}
                  onClick={() => toggleMapStyle(style.key)}
                >
                  <Flex className={styles.MapImageContainer}>
                    <img
                      src={`https://api.mapbox.com/styles/v1/akerag/${style.styleId}/static/-94.552691,39.377833,11/300x300?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
                      alt={style.key}
                    />
                  </Flex>
                  <span>{style.label}</span>
                </Flex>
              );
            } else return null;
          })}
        </Flex>
      )}
      <Flex
        justifyContent="center"
        alignItems="center"
        className={styles.LayerIconContainer}
        onClick={() => setShowSelector(!showSelector)}
      >
        <i className={`fas fa-layer-group ${styles.LayerIcon}`} />
      </Flex>
    </Flex>
  );
};

export default MapLayerToggle;

MapLayerToggle.propTypes = {
  setSelectedMapStyle: PropTypes.func.isRequired,
};
