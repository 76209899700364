import React, { useEffect, useState } from "react";
import moment from "moment";
import { getSeasonReports } from "../../../controllers/fields";
import { Map } from "../../Map/Map";
import { FieldShelf } from "./FieldShelf/FieldShelf";
import { Shelf } from "../../Helpers";
import styles from "./Field.module.scss";
import { Container } from "../../Layouts/Container";
import { Flex } from "../../Layouts/Flex";
import { useAuth } from "../../../hooks/useAuth";
import * as Feather from "react-feather";
import { HealthLegend } from "./HealthLegend/HealthLegend";
//import { AhiDateSlider } from "./AhiDateSlider/AhiDateSlider";
import { utcDateToString, getDateFromString } from "../../Helpers/utcDateToString";
import { TrueCauseImageViewer } from "./FieldShelf/TrueCause/TrueCauseImageViewer";
import { PdfViewer } from "../../Helpers/PdfViewer/PdfViewer";
import { EditReport } from "./FieldShelf/ReportDrawer/EditReport/EditReport";
import { FarmContext } from "../../../globalState/farmContext";
import { TrueCauseItemFilters } from "./FieldShelf/TrueCause/FilterTrueCauseStressItem";
import { useFieldToggleSliderFilterState } from "./FieldShelf/ToggleSliders/useFieldToggleSliders";
// import { ToggleFieldFilters } from "./FieldShelf/ToggleSliders/ToggleSliders";
import { useField } from "../../../globalState/fieldContext";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { deleteField } from "../../../hooks/useApi";
import * as turf from "@turf/turf";
import { EditField } from "./EditField";
import {
  IconButton,
  Tooltip,
  Button as MuiButton,
} from "@material-ui/core";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import html2canvas from 'html2canvas';
import {
  IObservation,
  IPin,
  IRegionByType,
  IReport,
  IImagery,
  IImageryByDate,
  IDrawerState,
  ISeason,
} from "./FieldInterfaces";
import { fixTiledriverURL } from "../../Helpers/fixTiledriverURL";
import { yellowThreshold, greenThreshold, trendRightThreshold, trendDownThreshold } from "../../Helpers/ahiValues";
import { ReactComponent as ArrowRightSolid } from "../../../assets/icons/ArrowRightSolid.svg";
import { downloadShp, downloadKML } from "../../../hooks/useApi";

export const Field = (props: any) => {
  const history = useHistory();
  const {
    field,
    setField,
    regions,
    getRegionsByType,
    fieldMap,
    mapboxLogo,
    seasons,
    setSeasons,
    selectedSeason,
    setSelectedSeason,
    geometryToEdit,
    setGeometryToEdit,
    setDrawingEnabled,
    drawingEnabled,
  } = useField();

  const { setCurrentFarm } = React.useContext(FarmContext);
  const { token } = useAuth();
  const [imagery, setImagery] = useState<IImagery>([]);
  const [imageryByDate, setImageryByDate] = useState<IImageryByDate[] | []>([]);

  // const [standCounts, setStandCounts] = useState<IImagery>([]);
  const [standCountByDate, setStandCountByDate] = useState<IImageryByDate[] | []>([]);

  const [ahiByDate, setAhiByDate] = useState<IImageryByDate[] | []>([]);
  const [stressMaps, setStressMaps] = useState<IImagery>([]);
  const [stressMapsByDate, setStressMapsByDate] = useState<IImageryByDate[] | []>([]);
  const [imageToDisplay, setImageToDisplay] = useState<IImagery>([]);
  const [ahiToDisplay, setAhiToDisplay] = useState<IImagery>([]);
  const [ahiValuesToDisplay, setAhiValuesToDisplay] = useState<IImagery>([]);
  const [soilSampleValuesToDisplay, setSoilSampleValuesToDisplay] = useState<IImagery>([]);
  const [soilSampleHeatmapToDisplay, setSoilSampleHeatmapToDisplay] = useState(null);
  const [stressMapToDisplay, setStressMapToDisplay] = useState(null);
  const [fieldTitle, setFieldTitle] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [currentSeason, setCurrentSeason] = useState<ISeason>();
  const [drawerState, setDrawerState] = useState<IDrawerState>({
    seasons: null,
    regions: null,
    aerialImagery: {
      selectedImageType: "",
      selectedImageId: 0,
      initialImage: null
    },
    ahiImagery: {
      selectedAhiType: "",
      selectedAhiId: 0,
      ahiValuesToDisplay: [1, 2, 5, 6],
      initialImage: null
    },
    trueCause: {
      selectedMapType: "",
      selectedMapId: "",
      isOpen: false,
    },
    soilSamples: {
      soilSampleValuesToDisplay: [0, 1, 2, 3, 4],
    },
    countsImagery: {
      initialImage: null
    },

  });
  const [imageryDates, setImageryDates] = useState<IImagery[] | null>(null);
  //const [ahiDates, setAhiDates] = useState<IImagery[] | null>(null);
  const [standCountDates, setStandCountDates] = useState<IImagery[] | null>(null);

  //eslint-disable-next-line
  const [stressMapDates, setStressMapDates] = useState<IImagery[] | null>(null);
  const [flightDateSliderDate, setFlightDateSliderDate] = useState<string>("");
  const [ahiDateSliderDate, setAhiDateSliderDate] = useState<string>("");
  //const [standCountDateSliderDate, setStandCountDateSliderDate] = useState<string>("");
  const [observationDates, setObservationDates] = useState<string[] | null>(
    null
  );
  const [observationDateSlider, setObservationDateSlider] = useState<string>('');
  const [imagesToView, setImagesToView] = useState(null);
  const [selectedSeasonReports, setSelectedSeasonReports] = useState<
    IReport | []
  >([]);
  const [reportToView, setReportToView] = useState(null);
  const [reportToEdit, setReportToEdit] = useState<IReport | null>(null);
  const [mapBounds, setMapBounds] = useState<number[] | null>(null);
  const [regionsByType, setRegionsByType] = useState<IRegionByType[] | null>(
    null
  );
  const [probeObsByPin, setProbeObsByPin] = useState<IPin[] | null>(null);
  const [filteringObs, setFilteringObs] = useState<string[]>([]);
  const selectedProbeObs: IObservation = React.useMemo(() => {
    const bySliderDate = (obs: IObservation) =>
      obs.observedAt ===
      moment(observationDateSlider)?.format("YYYY-MM-DD");
    return (
      selectedSeason?.probeObservations?.filter(bySliderDate)[0] ??
      ({} as IObservation)
    );
  }, [selectedSeason, observationDateSlider]);

  const [loadedOnce, setLoadedOnce] = useState(false)

  const [ahiClassName, setAhiClassName] = useState<string>('');
  const [ahiDate, setAhiDate] = useState<string>('');
  const [soilSampleData, setSoilSampleData] = useState([])
  const [showSoilSample, setShowSoilSample] = useState(false)
  const [selectedNutrient, setSelectedNutrient] = useState<string>('')
  const [showSoilSampleHeatmap, setShowSoilSampleHeatmap] = useState(false)
  const [standCountData, setStandCountData] = useState([])
  const [showStandCountSample, setShowStandCountSample] = useState(false)
  const [showStandCountHeatmap, setShowStandCountHeatmap] = useState(false)
  const [standCountToDisplay, setStandCountToDisplay] = useState()

  const [activityData, setActivityData] = useState([])

  React.useEffect(() => {
    // Formatting probe observations for shelf and map
    let obsByPin = [];

    if (selectedProbeObs?.cropStressTypes) {
      for (let type of selectedProbeObs.cropStressTypes) {
        for (let stressItem of type.cropStressItems) {
          for (let issue of stressItem.issues) {
            const indexOfPin = obsByPin.findIndex(
              (pin) => +pin.pinNumber === +issue.pinNumber
            );
            const pinData: IPin = obsByPin[indexOfPin] ?? {
              pinNumber: issue.pinNumber,
              issues: [],
              show: true,
              location: [issue.lng, issue.lat],
            };

            const indexOfIssue = pinData.issues.findIndex(
              (iss) => iss.name === stressItem.name
            );
            if (indexOfIssue > -1) {
              pinData.issues[indexOfIssue].images = [
                ...(pinData.issues[indexOfIssue]?.images ?? []),
                ...issue.images,
              ];
            } else {
              pinData.issues.push({
                name: stressItem.name,
                images: issue.images,
              });
            }

            if (indexOfPin > -1) {
              obsByPin.splice(indexOfPin, 1, pinData);
            } else {
              obsByPin.push(pinData);
            }
          }
        }
      }
    }

    setProbeObsByPin(
      obsByPin.sort((a, b) => (a.pinNumber < b.pinNumber ? -1 : 1))
    );
  }, [selectedProbeObs]);

  useEffect(() => {
    if (regions) setRegionsByType(getRegionsByType([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regions]);

  useEffect(() => {
    if (!!field.length) setFieldTitle(field[0].title);

    if (!!field.length && !seasons) {
      setCurrentFarm(field[0].farm);
    }
  }, [field, seasons, setSeasons, setSelectedSeason, setCurrentFarm, token]);

  useEffect(() => {
    if (selectedSeason) {
      // only check this on first load of new season
      if ((observationDateSlider === '') || (selectedSeason !== currentSeason)) {
        setCurrentSeason(selectedSeason)
        if (selectedSeason.probeObservations) {
          formatObservationData();
        }
      } else { // once the observationDateSlider is set, we can set the images
        updateAhi();
        updateStandCounts();
        updateImagery(utcDateToString(observationDateSlider));
        updateStressMaps();
        updateSeasonReports();
        setLoadedOnce(true)
      }
    } else {
      setSelectedSeasonReports([]);
    }
    const ahi = selectedSeason && selectedSeason.enrolledInAhi ? selectedSeason.ahi[0] : null
    setAhiClassName('');
    if (ahi) {
      let ahiColor = styles.Ahi_Red
      let ahiValue = ahi.fieldAhi ? ahi.fieldAhi : 0
      if (ahiValue > yellowThreshold) ahiColor = styles.Ahi_Yellow
      if (ahiValue > greenThreshold) ahiColor = styles.Ahi_Green
      let ahiTrend = styles.Arrow_Up
      if (ahi.fieldTrend < trendRightThreshold) ahiTrend = styles.Arrow_Right
      if (ahi.fieldTrend < trendDownThreshold) ahiTrend = styles.Arrow_Down
      setAhiClassName(`${ahiColor} ${ahiTrend}`)
      setAhiDate(ahi.observedAt)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedSeason, currentSeason, observationDateSlider]);

  React.useEffect(() => {
    if (loadedOnce) {
      setLoadedOnce(false)
      if (drawerState.ahiImagery.initialImage) {
        drawerState.ahiImagery.selectedAhiType = drawerState.ahiImagery.initialImage.name.toUpperCase();
        drawerState.ahiImagery.selectedAhiId = drawerState.ahiImagery.initialImage.id;
        setAhiToDisplay(drawerState.ahiImagery.initialImage);
      }
      else if (drawerState.aerialImagery.initialImage) {
        drawerState.aerialImagery.selectedImageType = drawerState.aerialImagery.initialImage.name.toUpperCase();
        drawerState.aerialImagery.selectedImageId = drawerState.aerialImagery.initialImage.id;
        setImageToDisplay(drawerState.aerialImagery.initialImage);
      }
      setSoilSampleValuesToDisplay(drawerState.soilSamples.soilSampleValuesToDisplay);
    }
  }, [loadedOnce, drawerState]);

  const updateImagery = (date: string = '') => {
    let tempImagery: IImagery[] = [];
    let tempImageryDates: string[] = [];
    let tempImageryByDate: { [key: string]: IImageryByDate } = {};

    selectedSeason?.mapLayers?.forEach((image) => {
      //ADO-1530 - FOR NOW, filter out flight imagery of types: elevation,svmrgb,orthomosaic
      if (
        (image.viewable || image.downloadable) &&
        !image.type.includes("Map")
      ) {
        tempImagery.push({ ...image });
        const formattedDate = utcDateToString(image.dateFlown);
        if (!tempImageryDates.includes(formattedDate)) {
          tempImageryDates.push(formattedDate);
          tempImageryByDate[formattedDate] = {
            dateFlown: formattedDate,
            imagery: [{ ...image }],
          };
        } else {
          const copyImagery = [...tempImageryByDate[formattedDate].imagery];
          copyImagery.push({ ...image });
          tempImageryByDate[formattedDate].imagery = copyImagery;
        }
      }
    });


    let flightSliderDate: string = '';

    tempImageryDates.sort(function (a, b) { return new Date(b).getTime() - new Date(a).getTime(); });
    if (date !== '') {
      // find  string in tempImageryDates less than or equal to date
      flightSliderDate = tempImageryDates.find((d: string) => d <= date)!;
      if (flightSliderDate === undefined) {
        flightSliderDate = tempImageryDates[0];  // should never happen!
      }
    }

    setImageryDates(tempImageryDates);
    if (flightSliderDate === '') {
      flightSliderDate = tempImageryDates[0];
    }
    setFlightDateSliderDate(flightSliderDate);
    setImagery(tempImagery);
    // sort imageries for each date alphabetically by service name
    const withAlphabeticalService = Object.fromEntries(
      Object.entries(tempImageryByDate).map(([dateKey, obj]) => {
        const imageryArraySorted = Array.from(obj.imagery).sort(
          (img1, img2) => {
            return img1.name.toLowerCase() < img2.name.toLowerCase() ? -1 : 1;
          }
        );
        return [
          dateKey,
          {
            dateFlown: dateKey,
            imagery: imageryArraySorted,
          },
        ];
      })
    );
    const sortedImagery = Object.values(withAlphabeticalService)
    setImageryByDate(sortedImagery);
    if (sortedImagery.length > 0) {
      const imagerySelected = sortedImagery.find((i) => i.dateFlown === flightSliderDate)!;
      let initialImage = tempImageryByDate[flightSliderDate].imagery.find(i => i.name.toLowerCase().includes('ndvi'))
      if (!initialImage) initialImage = imagerySelected.imagery.find(i => i.name.toLowerCase().includes('ndre'))
      if (!initialImage) initialImage = imagerySelected.imagery.find(i => i.name.toLowerCase().includes('rgb'))!
      if (initialImage) drawerState.aerialImagery.initialImage = fixTiledriverURL(initialImage)
    }
  };

  const updateAhi = () => {
    let tempImagery: IImagery[] = [];
    let tempImageryDates: string[] = [];
    let tempImageryByDate: { [key: string]: IImageryByDate } = {};

    if (!selectedSeason?.enrolledInAhi) return;

    selectedSeason?.ahi?.forEach((image) => {
      tempImagery.push({ ...image });
      const formattedDate = moment(image.observedAt).format("YYYY-MM-DD"); // utcDateToString(image.observedAt);
      if (!tempImageryDates.includes(formattedDate)) {
        tempImageryDates.push(formattedDate);
        tempImageryByDate[formattedDate] = {
          dateFlown: formattedDate,
          imagery: [{ ...image }],
        };
      } else {
        const copyImagery = [...tempImageryByDate[formattedDate].imagery];
        copyImagery.push({ ...image });
        tempImageryByDate[formattedDate].imagery = copyImagery;
      }
    });


    tempImageryDates.sort(function (a, b) { return new Date(b).getTime() - new Date(a).getTime(); });

    // We don't use the slider any more, but it's used to handle the AHI drawer state
    // TODO: clean this up if we want to remove the slider, permanently
    setAhiDateSliderDate(tempImageryDates[0]);
    setFlightDateSliderDate(tempImageryDates[0]);
    //setAhiDates(tempImageryDates);
    //setAhi(tempImagery);
    // sort imageries for each date alphabetically by service name
    const withAlphabeticalService = Object.fromEntries(
      Object.entries(tempImageryByDate).map(([dateKey, obj]) => {
        const imageryArraySorted = Array.from(obj.imagery).sort(
          (img1, img2) => {
            return img1.name.toLowerCase() < img2.name.toLowerCase() ? -1 : 1;
          }
        );
        return [
          dateKey,
          {
            dateFlown: dateKey,
            imagery: imageryArraySorted,
          },
        ];
      })
    );
    const sortedImagery = Object.values(withAlphabeticalService)
    setAhiByDate(sortedImagery);
    if (sortedImagery.length > 0) {
      var initialImage = tempImageryByDate[sortedImagery[0].dateFlown].imagery.find(i => i.name.toLowerCase().includes('grid'))
      if (initialImage) drawerState.ahiImagery.initialImage = initialImage
    }
  };

  const updateStandCounts = () => {
    let tempCounts: IImagery[] = [];
    let tempCountsDates: string[] = [];
    let tempCountsByDate: { [key: string]: IImageryByDate } = {};
    const targetPopulation = selectedSeason?.crop?.targetPopulation;

    selectedSeason?.standCountLayers?.forEach((standCount) => {
      tempCounts.push({ ...standCount });
      const formattedDate = getDateFromString(standCount.dateFlown);
      if (!tempCountsDates.includes(formattedDate)) {
        tempCountsDates.push(formattedDate);
        tempCountsByDate[formattedDate] = {
          dateFlown: formattedDate,
          imagery: [{ ...standCount }],
          targetPopulation: targetPopulation
        };
      } else {
        const copyCounts = [...tempCountsByDate[formattedDate].imagery];
        copyCounts.push({ ...standCount });
        tempCountsByDate[formattedDate].imagery = copyCounts;
      }
    });


    tempCountsDates.sort(function (a, b) { return new Date(b).getTime() - new Date(a).getTime(); });
    setStandCountDates(tempCountsDates);


    const withAlphabeticalService = Object.fromEntries(
      Object.entries(tempCountsByDate).map(([dateKey, obj]) => {
        const countsArray = Array.from(obj.imagery).sort(
          (img1, img2) => {
            return img1.updatedAt.toLowerCase() >= img2.updatedAt.toLowerCase() ? -1 : 1;
          }
        );
        return [
          dateKey,
          {
            dateFlown: dateKey,
            targetPopulation: targetPopulation,
            // in the case we merged work orders, we have multiple counts for the same date
            // we only want the one with the results so that we have the correct path for 
            // finding the imagery.
            imagery: countsArray.filter((img) => img.standCountMap !== null),
          },
        ];
      })
    );
    const sortedCounts = Object.values(withAlphabeticalService);
    setStandCountByDate(sortedCounts);
    if (sortedCounts.length > 0) {
      var initialImage = tempCountsByDate[sortedCounts[0].dateFlown].imagery[0]
      if (initialImage) drawerState.countsImagery.initialImage = initialImage
    }

  };

  const updateStressMaps = () => {
    let tempImagery: IImagery[] = [];
    let tempImageryDates: string[] = [];
    let tempImageryByDate: { [key: string]: IImageryByDate } = {};

    selectedSeason?.heatmaps?.forEach((image) => {
      tempImagery.push({ ...image });
      const formattedDate = utcDateToString(image.observedAt);
      if (!tempImageryDates.includes(formattedDate)) {
        tempImageryDates.push(formattedDate);
        tempImageryByDate[formattedDate] = {
          dateFlown: formattedDate,
          imagery: [{ ...image }],
        };
      } else {
        const copyImagery = [...tempImageryByDate[formattedDate].imagery];
        copyImagery.push({ ...image });
        tempImageryByDate[formattedDate].imagery = copyImagery;
      }
    });

    tempImageryDates.sort(function (a, b) { return new Date(b).getTime() - new Date(a).getTime(); });
    setStressMapDates(tempImageryDates);
    setStressMaps(tempImagery);
    // sort imageries for each date alphabetically by service name
    const withAlphabeticalService = Object.fromEntries(
      Object.entries(tempImageryByDate).map(([dateKey, obj]) => {
        const imageryArraySorted = Array.from(obj.imagery).sort(
          (img1, img2) => {
            return img1.name.toLowerCase() < img2.name.toLowerCase() ? -1 : 1;
          }
        );
        return [
          dateKey,
          {
            dateFlown: dateKey,
            imagery: imageryArraySorted,
          },
        ];
      })
    );
    setStressMapsByDate(Object.values(withAlphabeticalService));
  };

  const updateSeasonReports = () => {
    if (selectedSeason?.id) {
      getSeasonReports(token, selectedSeason?.id).then((res) => {
        setSelectedSeasonReports(res.reports);
      });
    }
  };

  const formatObservationData = () => {
    const observations: {
      [date: string]: {
        pins: {
          [pinNumber: number]: {
            types: { name: string; images: object[] }[];
            location: number[][];
            pinNumber: number;
          };
        };
      };
    } = {};
    if (selectedSeason?.probeObservations)
      for (let obs of selectedSeason?.probeObservations) {
        const date = utcDateToString(obs.observedAt);
        if (!observations[date]) {
          observations[date] = { pins: {} };
        }
        for (let type of obs.cropStressTypes) {
          for (let item of type.cropStressItems) {
            for (let issue of item.issues) {
              const { pins } = observations[date];
              const newType = {
                name: item.name,
                images: issue.images.map((image) => image),
              };
              if (!pins[issue.pinNumber]) {
                pins[issue.pinNumber] = {
                  types: [newType],
                  location: [issue.lng, issue.lat],
                  pinNumber: issue.pinNumber,
                };
              } else {
                let shouldAddNewName = true;
                for (
                  let i = 0;
                  shouldAddNewName && i < pins[issue.pinNumber].types.length;
                  i++
                ) {
                  const currentType = pins[issue.pinNumber].types[i];
                  if (currentType.name === item.name) {
                    // currentType.images = Array.prototype.push.apply(
                    //   currentType.images,
                    //   issue.images
                    // );
                    currentType.images = [
                      ...currentType.images,
                      ...issue.images,
                    ];
                    shouldAddNewName = false;
                  }
                }
                if (shouldAddNewName) {
                  pins[issue.pinNumber].types.push(newType);
                }
              }
            }
          }
        }
      }
    const tempObservationDates = Object.keys(observations).sort(function (a, b) { return new Date(b).getTime() - new Date(a).getTime(); });
    setObservationDates(tempObservationDates);
    setObservationDateSlider(tempObservationDates[0]);
  };

  const fieldName = !!field.length ? field[0].title : "";
  const farmName = !!field.length ? field[0].farm.title : "";

  const filterState = useFieldToggleSliderFilterState();
  const isSliderStateToggled = React.useMemo(() => filterState.isToggled, [
    filterState,
  ]);

  // const onTrueCauseFlagHover = (flag: IPin) => {
  //   const elementToScrollTo = document.getElementById(
  //     `img-${flag?.issues[0]?.images[0].id}`
  //   );
  //   if (elementToScrollTo)
  //     elementToScrollTo.scrollIntoView({ behavior: "smooth" });
  // };

  const toggleShowAllPinsToTrue = () => {
    setFilteringObs([]);
    const newProbeObsByPin = probeObsByPin?.map((obs) => {
      return { ...obs, show: true };
    });
    setProbeObsByPin(newProbeObsByPin ?? null);
  };

  const toggleShowTrueCausePins = () => {
    setFilteringObs([]);
    const newProbeObsByPin = probeObsByPin?.map((obs) => {
      return { ...obs, show: !obs.show };
    });
    setProbeObsByPin(newProbeObsByPin ?? null);
  };

  const toggleShowObservation = (name: string) => {
    const newFilteringObs: string[] = [...filteringObs];
    const indexOfName = newFilteringObs.indexOf(name);
    if (indexOfName >= 0) {
      newFilteringObs.splice(indexOfName, 1);
    } else {
      newFilteringObs.push(name);
    }
    if (!newFilteringObs.length || !name) return toggleShowAllPinsToTrue();

    const newProbeObsByPin = probeObsByPin?.map((pin) => {
      const show = pin.issues.some((issue) => {
        if (newFilteringObs.includes(issue.name)) return true;
        return false;
      });
      return { ...pin, show };
    });

    setProbeObsByPin(newProbeObsByPin ?? null);
    setFilteringObs(newFilteringObs);
  };

  // create an in-memory canvas
  var buffer = document.createElement('canvas');
  var b_ctx = buffer.getContext('2d');
  // const buffer = useRef<HTMLCanvasElement>(null);
  // const b_ctx: CanvasRenderingContext2D | null = buffer.current ? buffer.current..getContext('2d') : null;

  async function CreatePNG() {
    //const map = theMap
    const canvas = fieldMap.getCanvas()
    var scale = window.devicePixelRatio;
    const shelf = document.querySelector('.Shelf_Shelf__15BZ6')
    // @ts-ignore
    const left = shelf ? (shelf.offsetWidth + shelf.offsetLeft) * scale : 350
    if (b_ctx) b_ctx.globalAlpha = 0.8;
    crop(canvas, left, 0, canvas.width - left, canvas.height)
    textbox();
    logo();
    await healthLegend();
    await soilSampleLegend();
    await standCountLegend();
    await flags(left);
    const a = document.createElement('a');
    a.href = buffer ? buffer.toDataURL() : '';
    a.download = fieldName.concat('.png');
    document.body.appendChild(a);
    a.click();
  }

  function crop(canvas: any, offsetX: number, offsetY: number, width: number, height: number) {

    // set its width/height to the required ones
    if (buffer) {
      buffer.width = width;
      buffer.height = height;
      if (b_ctx) {
        b_ctx.drawImage(canvas, offsetX, offsetY, width, height,
          0, 0, buffer.width, buffer.height);
      }
      //return buffer;
    }
  }

  function textbox() {
    // OpenStreetMap and Mapbox attribution are required by
    // the Mapbox terms of service: https://www.mapbox.com/tos/#[YmdMYmdM]
    // set text sizing
    var text = '© Mapbox © OpenStreetMap';
    if (buffer) {
      if (buffer.width < 300) text = '© OSM';
      var fontsize = 14;
      var height = fontsize + 6;
      var width = ((text.length + 3) / fontsize * 100);

      // draw attribution to canvas
      if (b_ctx) {
        b_ctx.fillStyle = 'rgba(255,255,255,0.6)';
        b_ctx.fillRect(buffer.width - width, buffer.height - height, width, height);
        b_ctx.font = fontsize + 'px Arial';
        b_ctx.fillStyle = 'black';
        b_ctx.textAlign = 'left';
        b_ctx.textBaseline = 'bottom';
        b_ctx.fillText(text, buffer.width - width + 5, buffer.height - 5);
      }
    }
  }

  function logo() {
    // OpenStreetMap and Mapbox attribution are required by
    // the Mapbox terms of service: https://www.mapbox.com/tos/#[YmdMYmdM]
    // use the Mapbox logo within the LogoControl as the source image          
    const scale = 30.0 / mapboxLogo.height
    if (b_ctx && buffer) {
      b_ctx.drawImage(mapboxLogo, 5, buffer.height - mapboxLogo.height * scale - 5, mapboxLogo.width * scale, mapboxLogo.height * scale)
    }
  }

  async function healthLegend() {

    let healthLegend = document.querySelector('.health-legend');
    if (healthLegend) {
      // @ts-ignore
      await html2canvas(healthLegend).then(function (canvas) {
        if (b_ctx && buffer) {
          let left = (buffer.width - canvas.width) / 2;
          b_ctx.drawImage(canvas, left, buffer.height - canvas.height - 5, canvas.width, canvas.height)
        }
      });
    }
  }

  async function soilSampleLegend() {

    let soilSampleLegend = document.querySelector('.soil-sample-legend');
    if (soilSampleLegend) {
      // @ts-ignore
      await html2canvas(soilSampleLegend).then(function (canvas) {
        if (b_ctx && buffer) {
          b_ctx.drawImage(canvas, 30, 30, canvas.width, canvas.height)
        }
      });
    }
  }

  async function standCountLegend() {

    let standCountLegend = document.querySelector('.stand-count-legend');
    if (standCountLegend) {
      // @ts-ignore
      await html2canvas(standCountLegend).then(function (canvas) {
        if (b_ctx && buffer) {
          b_ctx.drawImage(canvas, 30, 320, canvas.width, canvas.height)
        }
      });
    }
  }

  const labelFont = '30px RadikalMedium';
  function drawTrueCauseFlags(shapes: any) {
    for (var i = 0; i < shapes.length; i++) {
      var shape = shapes[i];
      if (shape.radius) {
        // it's a circle
        if (b_ctx) {
          b_ctx.beginPath();
          // circle
          b_ctx.arc(shape.x, shape.y, shape.radius, 0, Math.PI * 2, false);
          b_ctx.fillStyle = "white";
          b_ctx.fill();
          // with border
          b_ctx.lineWidth = 1;
          b_ctx.strokeStyle = "black"
          b_ctx.stroke();
          // and text
          b_ctx.font = labelFont;
          b_ctx.fillStyle = 'black';
          b_ctx.textAlign = 'center';
          b_ctx.textBaseline = 'middle';
          b_ctx.fillText(shape.label, shape.x, shape.y + 2);
        }
      }
    }
  }

  function drawSoilSamples(shapes: any) {
    if (b_ctx) {
      const borderRadius = 15 // Math.floor(10 * shape.scale);
      const padding = {
        top: 2,
        left: 6,
        bottom: 2,
        right: 6
      };
      b_ctx.font = labelFont;
      b_ctx.textAlign = 'center';
      b_ctx.textBaseline = 'middle';
      for (var i = 0; i < shapes.length; i++) {
        const shape = shapes[i];
        const height = shape.radius * 2;

        const textWidth = b_ctx.measureText(shape.label).width;
        const width = Math.ceil(textWidth * shape.scale + padding.left + padding.right);
        const x = shape.x - width / 2;
        const y = shape.y - height / 2;

        b_ctx.beginPath();
        b_ctx.fillStyle = "black";
        b_ctx.roundRect(x, y, width, height, [borderRadius]);
        b_ctx.fill();
        // and text
        b_ctx.fillStyle = "white";
        b_ctx.fillText(shape.label, shape.x, shape.y + 4);
      }
    }
  }

  function drawStandCounts(shapes: any) {
    if (b_ctx) {
      const borderRadius = 15
      const padding = {
        top: 2,
        left: 6,
        bottom: 2,
        right: 6
      };
      b_ctx.font = '20px RadikalMedium';;
      b_ctx.textAlign = 'center';
      b_ctx.textBaseline = 'middle';
      for (var i = 0; i < shapes.length; i++) {
        const shape = shapes[i];
        const height = shape.radius * 2;

        const textWidth = b_ctx.measureText(shape.label).width;
        const width = Math.ceil(textWidth * shape.scale + padding.left + padding.right);
        const x = shape.x - width / 2;
        const y = shape.y - height / 2;

        b_ctx.beginPath();
        b_ctx.fillStyle = shape.color;
        b_ctx.roundRect(x, y, width, height, [borderRadius]);
        b_ctx.fill();
        // and text
        b_ctx.fillStyle = "black";
        b_ctx.fillText(shape.label, shape.x, shape.y + 4);
      }
    }
  }

  function flags(offset: number) {
    var tcFlags = [];
    var soilMapFlags = [];
    var standCountFlags = [];

    const flags = document.querySelectorAll<HTMLElement>('.mapboxgl-marker');
    const scale = window.devicePixelRatio;
    const translateRegex = /translate\(([-.\d]+)px,\s*([-.\d]+)px\)/;
    for (var f = 0; f < flags.length; f++) {
      if (flags[f]) {
        // @ts-ignore
        let attributes = flags[f].attributes['style'].value;
        let translate = attributes.match(translateRegex);
        let x = parseInt(translate[1]) - offset / scale;
        let y = parseInt(translate[2]);
        if (flags[f].id.includes('soil-marker')) {
          soilMapFlags.push({ x: x * scale, y: y * scale, radius: 11 * scale, scale: scale, label: flags[f].textContent });
        } else if (flags[f].id.includes('field-map-flag')) {
          tcFlags.push({ x: x * scale, y: y * scale, radius: 15 * scale, label: flags[f].textContent });
        } else if (flags[f].id.includes('stand-count-marker')) {
          const style = flags[f].style;
          standCountFlags.push({ x: x * scale, y: y * scale, radius: 11 * scale, scale: scale, label: flags[f].textContent, color: style.backgroundColor });
        }
      }
    }
    drawTrueCauseFlags(tcFlags);
    drawSoilSamples(soilMapFlags);
    drawStandCounts(standCountFlags);
  }

  // function firefoxBugFix(dataURL, height, width) {
  //   // Firefox requires SVG to have height and width specified
  //   // in the root SVG object when drawing to canvas

  //   // get raw SVG markup by removing the data type, charset, and escaped quotes
  //   var svg = unescape(dataURL.replace('data:image/svg+xml;charset=utf-8,', '').replace(/\\'/g, '"'));
  //   var newHeader = "height= \"" + height + "px\" width= \"" + width + "px\"";
  //   var newSvg = svg.replace('<svg', '<svg ' + newHeader);
  //   var newBase64 = btoa(newSvg);
  //   var newDataURL = 'data:image/svg+xml;base64,' + newBase64;

  //   return newDataURL;
  // }

  const onDownloadFieldClick = () => {
    CreatePNG();
  };

  const onDownloadShapefileClick = () => {
    const downloadFileName = `${fieldName}.zip`;
    const payload = {
      data: {
        geometries: [{
          id: field[0].id,
          type: "field",
        }]
      },
    };
    downloadShp(field[0].id, payload, token, downloadFileName);
  }


  const onDownloadKMLfileClick = () => {
    const downloadFileName = `${fieldName}.kml`;
    downloadKML(field[0].id, token, downloadFileName);
  }

  const onEditFieldClick = () => {
    const polygon = {
      polygon: {
        id: field[0].id,
        type: "Feature" as const,
        geometry: field[0].geometry,
        properties: null,
      },
    };
    setDrawingEnabled(true);
    setField([{ ...field[0], show: false, wasEdited: false }]);
    setGeometryToEdit({ ...polygon, ...polygon.polygon });
    const bounds = turf.bbox(polygon.polygon);
    setIsEditing(true);
    setMapBounds(bounds);
    const newRegionsByType = regionsByType?.map((type) => ({
      ...type,
      regions: type.regions.map((r) => ({ ...r, show: false })),
    }));
    setRegionsByType(newRegionsByType ?? []);
    const findMe = document.querySelector('.mapboxgl-ctrl-geolocate')
    if (findMe) {
      findMe.setAttribute('disabled', 'true')
    }
  };

  const showDeleteField = async () => {
    const alertRes = await Swal.fire({
      icon: "question",
      title: "Delete Field",
      html: `Are you sure you want to delete <small>${field && field[0] ? field[0].title : "this field"
        }</small>?`,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#ff4444",
    });

    if (alertRes.isConfirmed) {
      const { success } = await deleteField(field[0].id, token);
      if (success) {
        history.push(`/farm/${field[0].farm.id}`);
        Swal.fire({
          toast: true,
          timer: 5000,
          timerProgressBar: true,
          position: "bottom-right",
          title: "Successfully deleted " + field[0].title,
          icon: "success",
        });
      } else {
        Swal.fire({
          toast: true,
          timer: 5000,
          timerProgressBar: true,
          title: "An error occurred",
          text:
            "An error occurred while trying to delete the field. Please try again.",
          position: "bottom-right",
          icon: "error",
        });
      }
    }
  };

  const types_for_health = ["NDVI (SAT)", "NDRE (SAT)", "NDVI (UAV)", "NDRE (UAV)"]

  return (
    <Container>
      <Flex flexDirection="column" className={styles.Field}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          className={styles.Header}
        >
          <Flex
            flexDirection="column"
            alignItems="flex-start"
            className={styles.Title}
          >
            <h1>{fieldName} {!!field.length ? `(${field[0].acres} A) ` : ""}
              {ahiClassName && (<ArrowRightSolid className={`${ahiClassName}`} />)}
              <span className={styles.ahi_date}>{ahiDate && (' ' + ahiDate.split('T')[0])}</span>
            </h1>
            <h5 className={styles.FieldCropTitle}>
              {!!field.length
                ? `${field[0].plantDate
                  ? moment(field[0].plantDate).format("YYYY-MM-DD") + ": "
                  : ""
                }${field[0].crop.name ? field[0].crop.name : ""}`
                : ""
              }
              {!!selectedSeason
                ? `${selectedSeason.crop.growthStage
                  ? " - Estimated Growth Stage: " + selectedSeason.crop.growthStage + " (" + Math.round(selectedSeason.cumulativeGrowthDegreeUnits) + " GDD)"
                  : ""}`
                : ""
              }
            </h5>
          </Flex>
          <Flex justifyContent="space-between" className={styles.Actions}>
            <Tooltip title="Download KML Field Boundary">
              <MuiButton
                variant="text"
                startIcon={<GetAppOutlinedIcon />}
                onClick={onDownloadKMLfileClick}
              >
                kml
              </MuiButton>
            </Tooltip>
            <Tooltip title="Download Shapefile Field Boundary">
              <MuiButton
                variant="text"
                startIcon={<GetAppOutlinedIcon />}
                onClick={onDownloadShapefileClick}
              >
                shp
              </MuiButton>
            </Tooltip>
            <Tooltip title="Screen Capture">
              <IconButton
                style={{ marginRight: "0.5rem" }}
                onClick={onDownloadFieldClick}
              >
                <Feather.Camera className="feather-icon" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit Field">
              <IconButton
                style={{ marginRight: "0.5rem" }}
                onClick={onEditFieldClick}
              >
                <Feather.Edit3 className="feather-icon" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Field">
              <IconButton onClick={showDeleteField}>
                <Feather.Trash2 className="feather-icon" />
              </IconButton>
            </Tooltip>
          </Flex>
        </Flex>
        <Flex className={styles.Body}>
          <TrueCauseItemFilters>
            <Shelf>
              {!isEditing && field && (
                <FieldShelf
                  imageryByDate={imageryByDate?.filter(
                    (date) => flightDateSliderDate === date.dateFlown
                  )}
                  imageToDisplay={imageToDisplay}
                  setImageToDisplay={setImageToDisplay}
                  ahiByDate={ahiByDate?.filter(
                    (date) => ahiDateSliderDate === date.dateFlown
                  )}
                  ahiToDisplay={ahiToDisplay}
                  setAhiToDisplay={setAhiToDisplay}
                  ahiValuesToDisplay={ahiValuesToDisplay}
                  setAhiValuesToDisplay={setAhiValuesToDisplay}
                  // REPLACE imageryByDate IN heatMapImagesByDate WITH HEAT MAP DATA FROM BO
                  stressMapsByDate={stressMapsByDate?.filter(
                    (date) => observationDateSlider === date.dateFlown
                  )}
                  stressMapToDisplay={stressMapToDisplay}
                  setStressMapToDisplay={setStressMapToDisplay}
                  probeObsByPin={probeObsByPin}
                  trueCauseSliderDate={utcDateToString(
                    observationDateSlider,
                    // "en-US"
                  )}
                  setImagesToView={setImagesToView}
                  drawerState={drawerState}
                  setDrawerState={setDrawerState}
                  selectedSeasonReports={selectedSeasonReports}
                  setReportToView={setReportToView}
                  setReportToEdit={setReportToEdit}
                  updateSeasonReports={updateSeasonReports}
                  regionsByType={regionsByType}
                  setRegionsByType={setRegionsByType}
                  toggleShowObservation={toggleShowObservation}
                  toggleShowTrueCauseFlagLocations={toggleShowTrueCausePins}
                  imageryDates={imageryDates}
                  sliderDate={flightDateSliderDate}
                  setSliderDate={setFlightDateSliderDate}
                  imagery={imagery}
                  observationDates={observationDates}
                  observationDateSlider={observationDateSlider}
                  setObservationDateSlider={setObservationDateSlider}
                  maps={stressMaps}
                  setSoilSampleData={setSoilSampleData}
                  setShowSoilSample={setShowSoilSample}
                  showSoilSample={showSoilSample}
                  setShowSoilSampleHeatmap={setShowSoilSampleHeatmap}
                  showSoilSampleHeatmap={showSoilSampleHeatmap}
                  setSelectedNutrient={setSelectedNutrient}
                  soilSamplesToDisplay={soilSampleHeatmapToDisplay}
                  setSoilSamplesToDisplay={setSoilSampleHeatmapToDisplay}
                  soilSampleValuesToDisplay={soilSampleValuesToDisplay}
                  setSoilSampleValuesToDisplay={setSoilSampleValuesToDisplay}
                  workOrderToEdit={props.match.params.workOrderId}
                  setActivityData={setActivityData}
                  setShowStandCountHeatmap={setShowStandCountHeatmap}
                  showStandCountHeatmap={showStandCountHeatmap}

                  setShowStandCountSample={setShowStandCountSample}
                  showStandCountSample={showStandCountSample}

                  standCountDates={standCountDates}
                  setStandCountData={setStandCountData}
                  standCountByDate={standCountByDate}
                  setStandCountToDisplay={setStandCountToDisplay}
                  standCountToDisplay={standCountToDisplay}
                />
              )}
              {field && !!isEditing && (
                <EditField
                  styles={styles}
                  geometryToEdit={geometryToEdit}
                  fieldTitle={fieldTitle}
                  setDrawingEnabled={setDrawingEnabled}
                  setGeometryToEdit={setGeometryToEdit}
                  setIsEditing={setIsEditing}
                  setFieldTitle={setFieldTitle}
                />
              )}
            </Shelf>
            <Map
              mapBounds={mapBounds}
              containerId="field-map"
              drawingEnabled={drawingEnabled}
              geometryToEdit={geometryToEdit}
              setGeometryToEdit={setGeometryToEdit}
              fields={field}
              regions={(regionsByType || []).flatMap((type) => [
                ...(type?.regions || []),
              ])}
              markerTooltipKey="title"
              imageToDisplay={imageToDisplay}
              ahiToDisplay={ahiToDisplay}
              ahiValuesToDisplay={ahiValuesToDisplay}
              stressMapToDisplay={stressMapToDisplay}
              trueCauseFlags={probeObsByPin}
              //onTrueCauseFlagHover={onTrueCauseFlagHover}
              soilSampleData={soilSampleData}
              soilSamplesToDisplay={soilSampleHeatmapToDisplay}
              soilSampleValuesToDisplay={soilSampleValuesToDisplay}
              showSoilSample={showSoilSample}
              activityData={activityData}
              selectedNutrient={selectedNutrient}

              standCountToDisplay={standCountToDisplay}
              standCountData={standCountData}
              showStandCountSample={showStandCountSample}
            />

            {isSliderStateToggled && (
              <>
                {types_for_health.some(
                  (type) => type === drawerState.aerialImagery.selectedImageType
                ) && (
                    <HealthLegend
                      isVisible={types_for_health.some(
                        (type) =>
                          type === drawerState.aerialImagery.selectedImageType
                      )}
                    />
                  )}
              </>
            )}

            {imagesToView && (
              <TrueCauseImageViewer
                images={imagesToView}
                setImagesToView={setImagesToView}
              />
            )}

            {reportToView && (
              <PdfViewer
                reportToView={reportToView}
                setReportToView={setReportToView}
              />
            )}

            {reportToEdit && (
              <EditReport
                reportToEdit={{ ...reportToEdit, farmName }}
                setReportToEdit={setReportToEdit}
                imageryDates={imageryDates ? [...imageryDates] : []}
                imageryByDate={imageryByDate}
                updateSeasonReports={updateSeasonReports}
              />
            )}
          </TrueCauseItemFilters>
        </Flex>
      </Flex>
    </Container>
  );
};
