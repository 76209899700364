import * as React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";

import { FilterStreesItemMenu } from "./FilterStressItemMenu";
import { FilterStatusMenu } from "./FilterStatusMenu";

const useStyles = makeStyles((theme) => ({
  filterMenuContainer: {
    position: "relative",
  },
  filterMenu: {
    height: "var(--image-reviewer-sidebar-actions-height)",
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "200px 40px",
    position: "relative",
  },
  loadingContainer: {
    position: "absolute",
    bottom: theme.spacing(0.5),
    left: 0,
    width: "100%",
  },
}));

export const FilterMenu = ({ isValidating }) => {
  const classes = useStyles();

  return (
    <div className={classes.filterMenuContainer}>
      <div className={classes.filterMenu}>
        <div>
          <FilterStatusMenu />
        </div>

        <FilterStreesItemMenu />
      </div>

      {isValidating && (
        <div className={classes.loadingContainer}>{<LinearProgress />}</div>
      )}
    </div>
  );
};
