import React from "react";
import { makeStyles } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Flex } from "../../Layouts/Flex";
import styles from '../Home/Home.module.scss'

import akerLogo from "../../../assets/aker-pattern-x2.png";
import { MuiButton } from "../../Helpers";

const useStyles = makeStyles((theme) => ({
    h1: {
        fontSize: '5em',
        marginBottom: theme.spacing(1)
    },
    subheader: {
        fontSize: '1.5em !important'
    },
    bg: {
        height: '100%',
        padding: '0px 100px',
        backgroundImage: `url(${akerLogo})`,
        backgroundColor: 'var(--bgColorTertiary)',
        backgroundSize: 'cover',
    },
    paragraph: {
        width: '80%',
        margin: 'auto',
        textAlign: 'center'
    },
    button: {
        width: '100%',
        border: `1px solid ${theme.palette.text.primary} !important`,
        background: `transparent`
    },
    fullWidth: {
        width: '100% !important',
        position: 'absolute',
        left: 0,
        bottom: 0,
        backgroundColor: theme.palette.primary.light
    }
}))

export const Error = () => {
    const { status } = useParams();
    const classes = useStyles();
    const getStatusText = () => {
        if (status === '403') return 'The page or resource you are trying to reach is not accessible to you. Check the URL and/or contact your account administrator for help.';
        if (status === '404') return 'The page or resource you are trying to reach could not be found. Check the URL or with whomever sent you here.';
        if (status === '401') return 'The page or resource you are trying to reach is unauthorized for your access. Try logging out/in again and then contact your account administrator for help.'
        return 'The page or resource you are trying to reach is not available';
    }

    const getStatusHeader = () => {
        if (status === '403') return 'Access Denied';
        if (status === '404') return 'Not Found';
        if (status === '401') return 'Not Authorized';
        return '';
    }

    return (
        <div className={`${classes.bg}`}>

            <Flex className={`${styles.SignIn} ${styles.FormRow}`}>
                <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <h1 className={classes.h1}>Oops!</h1>
                    <span className={classes.subheader}>{status} - {getStatusHeader()}</span>
                    <p className={classes.paragraph}>{getStatusText()}</p>
                    <br />
                    <MuiButton className={classes.button} style={{ marginTop: 15, paddingTop: 50 }} onClick={() => window.location.href = '/farms'} text="Back to Farms Page" />

                </Flex>

            </Flex>

        </div>
    );
}