import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { Input, MuiButton } from "../../../Helpers";
import { updateFarm } from "../../../../controllers/farms";
import { useAuth } from "../../../../hooks/useAuth";
import { useFarms } from "../../../../globalState/farmContext";
import { Autocomplete2 } from "../../../Helpers/Autocomplete/Autocomplete";
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  addFarmField: {
    border: 'none',
  },
  textField: {
    marginTop: "-1rem",
    marginBottom: '1rem',
    '& fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
    },
  },
  heading: {
    marginBottom: '0',
    marginTop: "2rem",
    marginLeft: "-1rem",
  },
  label: {
    marginBottom: '0',
    marginTop: "2rem",
  },
}));

interface Props {
  farmId: number | string;
  farmName: string;
  farmHands: [];
  toggleEditFarm: () => void;
  hideHeading: boolean;
}

interface FarmHand {
  userId: number;
  fullName: string;
}

interface FarmHandListItem {
  label: string;
  value: number;
}

interface Farm {
  [key: string]: any;
}

export const EditFarm = (props: Props) => {
  const { token } = useAuth();
  const { currentFarm, setCurrentFarm } = useFarms();
  const [newFarmName, setNewFarmName] = React.useState(props.farmName);

  const [farmHand, setFarmHand] = React.useState<FarmHandListItem>({ label: "Unassigned", value: 0 })
  const [farmHandsList, setFarmHandsList] = React.useState<FarmHandListItem[] | []>([])

  const classes = useStyles();

  const handleSave = async () => {
    const newFarm = await updateFarm(token, {
      farmId: props.farmId,
      farmName: newFarmName,
      // 0 is a special case to let us know to remove the farm hand
      defaultFarmHandId: farmHand.label === 'Unassigned' ? 0 : farmHand.value
    });
    if (newFarm.success) {
      setCurrentFarm(newFarm.data);
      props.toggleEditFarm();
    }
  };

  const formatAccountForMenu = (item: FarmHand) => ({ label: item.fullName, value: item.userId });
  React.useEffect(() => {
    if (props.farmHands) {
      const hands = props.farmHands.map((item) => formatAccountForMenu(item));
      hands.sort((a, b) => a.label.localeCompare(b.label));
      hands.unshift({ label: "Unassigned", value: 0 });
      setFarmHandsList(hands);
    }
  }, [props.farmHands]);

  React.useEffect(() => {
    const farm: Farm = currentFarm
    const id = farm.defaultFarmHand?.id || 0;
    const current_farm_hand = farmHandsList?.find((item) => item.value === id) || { label: 'Unassigned', value: 0 };
    setFarmHand(current_farm_hand)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmHandsList])

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      style={{ alignSelf: "flex-start", padding: "2rem 1.5rem" }}
    >
      {!props.hideHeading && <Typography variant="h3" className={classes.heading}>Edit Farm</Typography>}
      <Grid style={{ margin: "2.5rem 0 2rem -1rem" }}>
        <Typography color="textSecondary">Farm Name</Typography>
        <Input
          placeholder="Farm Name"
          value={newFarmName}
          onChange={(e: any) => setNewFarmName(e.target.value)}
        />
        <Typography color="textSecondary" className={classes.label}>Default Farm Hand</Typography>
        <Autocomplete2
          className={clsx(classes.addFarmField, classes.textField)}
          options={farmHandsList}
          getOptionLabel={o => o?.label}
          labelText={""}
          value={farmHand || { label: 'Unassigned', value: 0 }}
          onChange={(_: any, v: FarmHandListItem) => setFarmHand(v)}
          popupIcon={""}
        />
      </Grid>
      <Grid container justifyContent="space-between" style={{ width: "100%", marginLeft: "-0.5rem" }}>
        <MuiButton
          text="Cancel"
          style={{ width: "45%" }}
          onClick={props.toggleEditFarm}
        />
        <MuiButton
          text="Save"
          style={{ width: "45%" }}
          variant="contained"
          color="primary"
          onClick={handleSave}
        />
      </Grid>
    </Grid>
  );
};
