import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container } from "../../Layouts/Container";
import { SearchWithIcon } from "../../Helpers";
import { FarmsList } from "./FarmsList/FarmsList";
import { AddFarm } from "./AddFarm/AddFarm";
import { CustomerAccountsSelector } from "../../Helpers/CustomerAccountsSelector/CustomerAccountsSelector";
import {
  useCustomerAccounts,
  useSelectedCustomerAccount,
  useSelectedCustomerAccountDispatch,
} from "../../../globalState";
import { Typography } from "@material-ui/core";
import { canManage } from "../../../lib/customerAccount";
import { useFarms } from "../../../globalState/farmContext";
import { TrueCauseItemFilters } from "../Field/FieldShelf/TrueCause/FilterTrueCauseStressItem";
import styles from "./Farms.module.scss";
import { Map } from "../../Map/Map";
import { Flex } from "../../Layouts/Flex";
import { Button } from "../../Helpers";
import { Shelf } from "../../Helpers";
import * as turf from "@turf/turf";
import { yellowThreshold, greenThreshold } from "../../Helpers/ahiValues";

// Chetu Function import
import Box from '@material-ui/core/Box';
import LinearProgress from "@material-ui/core/LinearProgress";
import { getFarmsPagination, getFarms } from "../../../controllers/farms";
import { useAuth } from "../../../hooks/useAuth";

export const Farms = () => {
  const history = useHistory();
  const { token } = useAuth();
  const [searchValue, setSearchValue] = useState("");
  const [farmFields, setFarmFields] = useState([]);
  // eslint-disable-next-line
  const [fieldStats, setFieldStats] = useState({ acres: 0, fieldCount: 0 });
  const [farmBounds, setFarmBounds] = useState(null);
  const selectedCustomerAccount = useSelectedCustomerAccount();
  const customerAccounts = useCustomerAccounts();
  const dispatch = useSelectedCustomerAccountDispatch();
  const { loadingFarms } = useFarms();
  const [showAddFarm, setShowAddFarm] = useState(false);
  const [farms, setFarms] = useState([]);
  // Chetu Pagination and Progressbar constant defined
  const min = 0;
  const [max, setMax] = useState(0);
  const normalize = value => Math.round((value * 100) / max);
  const [progress, setProgress] = React.useState(min);
  const [selectedAccount, setSelectedAccount] = React.useState(undefined);
  const farmsPerPage = 4;

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${props.value}%`}</Typography>
        </Box>
      </Box>
    );
  }
  const handleSelectedAccount = ((select) => {
    setSelectedAccount(select);
  });

  const selectedCustomerAccountName =
    customerAccounts && selectedAccount
      ? customerAccounts.find((a) => a.id === selectedCustomerAccount.id)?.name
      : "";

  useEffect(() => {
    if (selectedCustomerAccount && !selectedAccount) {
      setSelectedAccount(selectedCustomerAccount.id ? selectedCustomerAccount : undefined);
    }
    if (farms && selectedAccount && farms[0]?.fields[0]?.bounds) {
      // setFarms(allFarms);
      let tmpFormField = [farmFields];
      //let tmpBound = [...farmBounds]
      var centers = [];
      // eslint-disable-next-line
      farms?.map((item) => {
        const formattedFarmFields = [...item?.fields?.map((field) => {
          const formattedBounds = field.bounds.map((point) => [
            +point[1],
            +point[0],
          ]);
          const point1 = turf.point(formattedBounds[0]);
          const point2 = turf.point(formattedBounds[1]);
          const midpoint = turf.midpoint(point1, point2).geometry.coordinates;
          centers.push(midpoint);
          let ahiColor = 'white'
          let opacity = null;
          let selectedOpacity = null;
          if (field.currentSeason?.enrolledInAhi && Object.keys(field.currentSeason).length > 0 && field.currentSeason.ahi.length > 0) {
            let ahi = parseFloat(field.currentSeason.ahi[0].ahiAverage);
            opacity = 0.7;
            selectedOpacity = 0.7;
            ahiColor = 'red'
            if (ahi > yellowThreshold) ahiColor = 'yellow'
            if (ahi > greenThreshold) ahiColor = 'green'
          }
          return {
            ...field,
            center: midpoint,
            showMarker: true,
            show: true,
            color: ahiColor,
            opacity: opacity,
            selectedOpacity: selectedOpacity,
            title: 'Farm Name: ' + item.title + '\nField Name: ' + field.title,
            id: `${item.id}-${field.id}`,
            farmId: item.id,
            fieldId: field.id
          };
        })];
        if (centers.length > 0) {
          centers.push(centers[0]);
          const bounds = turf.bbox(turf.lineString(centers));
          setFarmBounds(bounds);
        }
        formattedFarmFields.sort((a, b) => {
          if (a.title > b.title) {
            return 1;
          }
          if (a.title < b.title) {
            return -1;
          }
          return 0;
        });
        tmpFormField.push(...formattedFarmFields);
      })
      setFarmFields(tmpFormField);
      // setFieldStats((stats) => ({
      //   fieldCount: tmpFormField.length,
      //   acres: stats.acres,
      // }));
    }
    // eslint-disable-next-line
  }, [farms]);

  // this replaces code that was removed as of 4ed07ee4f5e1fdbcbb739bf4388730558ce12262
  const sortedCustomerAccountsList = customerAccounts
    ? customerAccounts.sort((a1, a2) => (a1.name < a2.name ? -1 : 1))
    : [];

  const handleSearch = (e) => {
    setSearchValue(e)
  }

  const selectField = (field) => {
    history.push(`/farm/${field.farmId}/field/${field.fieldId}`);
  };

  const findMe = document.querySelector('.mapboxgl-ctrl-geolocate')
  if (findMe) {
    findMe.removeAttribute('disabled')
  }

  // Chetu Pagination function
  const paginate = (page) => {
    return new Promise((resolve, reject) => {
      getFarmsPagination(token, selectedAccount?.id, farmsPerPage, page).then(async (res) => {
        setFarms(prev => [...prev, ...res.data]);
        setProgress(res.page);
        resolve();
      });
    })
  }
  useEffect(() => {
    async function getSelectedFarms() {
      if (token) {
        getFarmsPagination(token, selectedAccount?.id, farmsPerPage, 1).then(async (res) => {
          setFarms(res.data);
          setMax(res.pageCount);
          setProgress(1);
          for (let i = 2; i <= res.pageCount; i++) {
            await paginate(i);
          }
        });
      }
    }

    async function getAllFarms() {
      if (token) {
        const allFarms = await getFarms(token)
        setFarms(allFarms)
      }
    }

    if (selectedAccount) {
      getSelectedFarms();
    } else {
      getAllFarms()
    }
    // eslint-disable-next-line
  }, [token, selectedAccount]);

  return (
    <TrueCauseItemFilters>
      <Container>
        <Flex flexDirection="column" className={styles.Farms}>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            className={styles.Header}
          >
            <Flex
              flexDirection="column"
              alignItems="flex-start"
              className={styles.Title}
            >
              <h1 className="margin-0">Farms</h1>
              <Typography style={{ marginBottom: "3.0rem" }}>
                Select a customer account:
              </Typography>
            </Flex>
            <Flex
              className="custom-new-flex"
            >
              <CustomerAccountsSelector
                handleSelectedAccount={handleSelectedAccount}
              />
              <SearchWithIcon
                searchValue={searchValue}
                updateSearchValue={(e) => handleSearch(e.target.value)}
                style={{
                  width: "22.5rem",
                  marginLeft: "1.5rem",
                }}
              />
              {/* {showAddFarm && canManage(sortedCustomerAccountsList) && (
                <AddFarm className="custom-class"
                  customerAccountsList={sortedCustomerAccountsList}
                  setShowAddFarm={setShowAddFarm}
                />
              )} */}
            </Flex>
            <Flex>
              {!showAddFarm && canManage(sortedCustomerAccountsList) && (
                <Button
                  className={styles.AddFarmBtn}
                  text="+ New Farm"
                  onClick={() => setShowAddFarm(true)}
                />
              )}
            </Flex>
          </Flex>
          {progress < max && (
            <Box className="widthfixed" sx={{ width: '100%' }}>
              <LinearProgressWithLabel value={normalize(progress) || 0} style={{ height: "15px", color: "#25d6f5" }} />
            </Box>
          )}
          <Flex className={styles.Body}>
            <Shelf>
              {!showAddFarm ?
                (
                  <FarmsList
                    farms={farms.filter((farm) =>
                      farm.title.toLowerCase().includes(searchValue.toLowerCase())
                    )}
                    loadingFarms={loadingFarms}
                    options={{ border: true }}
                    selectedCustAccount={selectedCustomerAccountName || ""}
                    customerAccountsList={sortedCustomerAccountsList}
                    setFarms={setFarms}
                    clearCustomerAccountSelection={() => {
                      dispatch({
                        type: "clear-customer-account-selection",
                      });
                    }}
                  />
                ) : (
                  <>
                    {showAddFarm && canManage(sortedCustomerAccountsList) && (
                      <div className="add_farm_wrapper">
                        <AddFarm className="custom-class"
                          customerAccountsList={sortedCustomerAccountsList}
                          setShowAddFarm={setShowAddFarm}
                        />
                      </div>
                    )}
                  </>
                )}
            </Shelf>
            {
              (typeof selectedAccount?.id !== "undefined") ? (<Map
                markerScrollIntoView
                markerTooltipKey={"title"}
                onMarkerClick={selectField}
                containerId="map"
                fields={farmFields}
                mapBounds={farmBounds}
                mapOptions={{
                  maxZoom: 16,
                }}
              />) :
                (<>
                  <Flex
                    justifyContent="content"
                    alignItems="center"
                    className={styles.Header}
                  ></Flex>
                  <h1 style={{ marginLeft: "-60%" }}>Select Customer Account or Farm to enable map view.</h1>
                </>)
            }
          </Flex>
        </Flex>
      </Container>
    </TrueCauseItemFilters>
  );
};
