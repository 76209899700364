import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import { ContextProvider } from "./globalState/combiner";
import "./assets/fonts/RadikalThin.ttf";
import "./assets/fonts/RadikalLight.ttf";
import "./assets/fonts/RadikalRegular.ttf";
import "./assets/fonts/RadikalMedium.ttf";
import "./assets/fonts/RadikalBold.ttf";
import App from "./App";
import "./styles/main.scss";
import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <IntlProvider locale="en">
      <BrowserRouter>
        <ContextProvider>
          <App />
        </ContextProvider>
      </BrowserRouter>
    </IntlProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
