import { makeStyles } from "@material-ui/core";
import * as React from "react";
import {
  useCustomerAccounts,
  useSelectedCustomerAccount,
  useSelectedCustomerAccountDispatch,
} from "../../../globalState";
import { Select } from "../Select/Select";
import { Skeleton } from "../Skeletons/Skeleton";

const useStyles = makeStyles((theme) => ({
  accountSelector: {
    // height: "100%",
    width: "22.5rem",
  },
}));

export const CustomerAccountsSelector = ({ handleSelectedAccount }) => {
  const classes = useStyles();
  const data = useCustomerAccounts();
  const dispatch = useSelectedCustomerAccountDispatch();
  const selectedCustomerAccount = useSelectedCustomerAccount();
  const [selectedAccount, setSelectedAccount] = React.useState({});

  const content = React.useMemo(() => {
    if (!data) {
      return null;
    }

    return data
      .map((item) => {
        return {
          title: item.name,
          id: item.id,
          label: item.name,
          value: item.id,
        };
      })
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
  }, [data]);

  React.useEffect(() => {
    if (selectedCustomerAccount) {
      setSelectedAccount(
        content.find((a) => a.id === selectedCustomerAccount.id)
      );
    } else {
      if (content?.length === 1) {
        setSelectedAccount(content[0]);
        handleSelect(content[0]);
      } else {
        setSelectedAccount({});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomerAccount, content]);

  if (!content) {
    return <Skeleton width="300px" height="70px" />;
  }

  const handleSelect = (option) => {
    setSelectedAccount(content.find((a) => a.label === option.label));
    if (typeof handleSelectedAccount === "function") { handleSelectedAccount(content.find((a) => a.label === option.label), content) }
    dispatch({
      type: "custom-account-select",
      payload: {
        id: option.id,
      },
    });
  };

  return (
    <Select
      options={content}
      defaultLabel={content.length > 1 ? "All customer accounts" : ""}
      selectOption={(option) => handleSelect(option)}
      selectedOption={selectedAccount}
      wrapperClassName={classes.accountSelector}
    />
  );
};
