import React from "react";
import {
  Grid,
  Typography,
  makeStyles,
  FormControlLabel,
} from "@material-ui/core";
import { Input, Checkbox } from "../../../../../Helpers";
import { Map } from "../../../../../Map/Map";
import { useField } from "../../../../../../globalState/fieldContext";

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    position: "relative",
    height: "15rem",
    width: "46.5rem",
    marginBottom: "1.5rem",
    backgroundColor: theme.palette.background.tertiary,
  },
  regionOptions: {
    marginBottom: "1.5rem",
    marginRight: "1.5rem",
  },
  regionHeader: {
    marginBottom: "0.3rem",
    fontFamily: theme.typography.fontFamilyMedium,
  },
}));

export const AddFieldRegions = ({
  parentClasses,
  newReport,
  updateNewReport,
  regionsByType,
  setRegionsByType,
}) => {
  const classes = useStyles();
  const { field } = useField();

  const selectRegion = (e, regionSelected) => {
    e.preventDefault();
    setRegionsByType(toggleShowRegion(regionSelected));
  };

  const toggleShowRegion = (regionToUpdate) => {
    return regionsByType.map((type) => {
      for (let region of type.regions) {
        if (region.id === regionToUpdate.id) {
          region.show = !region.show;
        }
      }
      return type;
    });
  };

  return (
    <Grid container item direction="column" className={parentClasses.section}>
      <Typography variant="h4">Add Field Regions</Typography>
      <Grid item className={classes.mapContainer}>
        <Map
          containerId="regions-map"
          defaultStyle="blankDark"
          fields={field.map((f) => f)}
          regions={regionsByType.flatMap((type) => [...type.regions])}
          fitBounds={false}
          hideMapLayerToggle
          hideControls
          disableZoom
          disableDrag
        />
      </Grid>

      <Grid container item>
        {regionsByType.map(
          (type, i) =>
            type.regions.length > 0 && (
              <Grid key={i} item className={classes.regionOptions}>
                <Typography
                  variant="body1"
                  component="h6"
                  className={classes.regionHeader}
                >
                  {type.label}
                </Typography>
                {type.regions.map((region, i) => (
                  <Grid key={i} onClick={(e) => selectRegion(e, region)}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={region.show}
                          style={{ color: region.color }}
                        />
                      }
                      label={`${region.marker}) ${region.name}`}
                    />
                  </Grid>
                ))}
              </Grid>
            )
        )}
      </Grid>

      {!newReport.template.hideCommodityPrice && (
        <Grid container item alignItems="center">
          <Input
            placeholder="Commodity Price"
            className={parentClasses.input}
            value={newReport.commodityPrice ? newReport.commodityPrice : ""}
            style={{ marginBottom: 0, marginRight: "1.5rem" }}
            onChange={(e) => updateNewReport("commodityPrice", e.target.value)}
          />
          <Typography variant="subtitle2" color="textSecondary">
            bu/acre
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
