import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import cn from "clsx";

export const SampleLegend = ({ labels, type, ...props }) => {
  let height;
  let top;
  if (props.top) {
    top = props.top;
  } else {
    switch (type) {
      case "soil-sample-legend":
        top = "30px";
        break;
      case "stand-count-legend":
        top = "calc(8rem + 10px)";
        break;
      default:
        top = "30px";
    }
  }
  if (props.height) {
    top = props.height;
  } else {
    switch (type) {
      case "soil-sample-legend":
        height = "8rem";
        break;
      case "stand-count-legend":
        height = "6rem";
        break;
      default:
        height = "8rem";
    }
  }

  const useStyles = makeStyles((theme) => ({
    legend: {
      position: "absolute",
      height: height,
      width: "7.5rem",
      padding: "0.75rem 0.75rem 0.75rem 0.75rem",
      backgroundColor: theme.palette.background.primary75opaque,
      borderRadius: "4px",
      boxSizing: "border-box",
      top: top,
      left: props.left || "105%",
    },
    legend_fullScreen: {
      left: "1rem",
    },
    text: { color: theme.palette.text.primary }
  }));

  const classes = useStyles();

  const [colorSwatches, setColorSwatches] = React.useState([]);

  React.useEffect(() => {
    if (Object.keys(labels).length > 0) {
      const cs = labels['colors'].map((color, index) => (
        <Grid container spacing={1} alignItems="center"  >
          <Grid item>
            <div
              style={{
                width: 15,
                height: 15,
                backgroundColor: color,
                borderRadius: "4px",
                opacity: "0.66"
              }}
            />
          </Grid>
          <Grid item>
            <Typography variant="body2" className={classes.text}>{`${labels['text'][index]} ${labels['units']}`}</Typography>
          </Grid>
        </Grid>
      ));
      setColorSwatches(cs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
      className={cn(classes.legend, type)}
    >
      {colorSwatches}
    </Grid>
  );
};
