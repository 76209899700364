import React, { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import { Navigation } from "./Components";
import routes from "./routes";
import {
  ThemeContext,
  AuthProvider,
  UserProvider,
  CustomerAccountsProvider,
} from "./globalState";
import { makeAkerTheme } from "./theme";
import { FarmProvider } from "./globalState/farmContext";
import * as axios from "axios";
const THEME_DARK_NAME = "theme-dark";
const THEME_LIGHT_NAME = "theme-light";

const App = () => {
  const location = useLocation();
  const { darkTheme } = useContext(ThemeContext);
  axios.default.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error && error.response && error?.response?.status) {
        const { location } = window;
        location.href = `/error/${error?.response?.status}`;
      }
    }
  );

  if (location.pathname === '/farms') {
    let preFarm = JSON.parse(localStorage.getItem('slectedFarm'))
    localStorage.setItem('preFarm', JSON.stringify(preFarm))
    localStorage.setItem('goToField', JSON.stringify(false))
  }
  if (location.pathname.includes('/field')) {
    localStorage.setItem('goToField', JSON.stringify(true))
  }

  useEffect(() => {
    const { style } = document.documentElement;
    if (darkTheme) {
      document.body.classList.remove(THEME_LIGHT_NAME);
      document.body.classList.add(THEME_DARK_NAME);
      style.setProperty(`--navigationColor`, `#000000`);
      style.setProperty(`--bgColorMain`, `#131313`);
      style.setProperty(`--bgColorSecondary`, `#202020`);
      style.setProperty(`--bgColorTertiary`, `#2E2E2E`);
      style.setProperty(`--bgColorAlternate`, `#393939`);
      style.setProperty(`--fontColorMain`, `#E2E2E2`);
      style.setProperty(`--fontColorSecondary`, `#A5A5A5`);
      style.setProperty(`--fontColorTertiary`, `#777777`);
      style.setProperty(`--listBorderColor`, `#474646`);
    } else {
      document.body.classList.remove(THEME_DARK_NAME);
      document.body.classList.add(THEME_LIGHT_NAME);
      style.setProperty(`--navigationColor`, `white`);
      style.setProperty(`--bgColorMain`, `#f0f0f0`);
      style.setProperty(`--bgColorSecondary`, `white`);
      style.setProperty(`--bgColorTertiary`, `#dedede`);
      style.setProperty(`--bgColorAlternate`, `#e8e8e8`);
      style.setProperty(`--fontColorMain`, `#161515`);
      style.setProperty(`--fontColorSecondary`, `gray`);
      style.setProperty(`--fontColorTertiary`, `#999999`);
      style.setProperty(`--listBorderColor`, `#cdcdcd`);
    }

    // AHI Colors
    style.setProperty(`--ahiGreen`, `#69db70`);
    style.setProperty(`--ahiRed`, `#e74548`);
    style.setProperty(`--ahiYellow`, `#dddd2c`);

    // heatmap colors for soil samples
    style.setProperty(`--soilSample0`, `#D7191C`);
    style.setProperty(`--soilSample1`, `#FD7F0A`);
    style.setProperty(`--soilSample2`, `#FFFF0C`);
    style.setProperty(`--soilSample3`, `#17DD0D`);
    style.setProperty(`--soilSample4`, `#339CDD`);


    style.setProperty(`--logoColor`, `#00a3bc`);

  }, [darkTheme]);

  return (
    <ThemeProvider theme={makeAkerTheme(darkTheme)}>
      <CssBaseline />
      <div className="App">
        <AuthProvider>
          <UserProvider>
            <CustomerAccountsProvider>
              <FarmProvider>
                <Navigation pathname={location.pathname} />
                {routes}
              </FarmProvider>
            </CustomerAccountsProvider>
          </UserProvider>
        </AuthProvider>
      </div>
    </ThemeProvider>
  );
};

export default App;
