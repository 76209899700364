import React, { useState } from "react";
import { MuiButton, Select } from "../../../../Helpers";
import { ShelfLabel } from "../../../../Helpers";
import EditIcon from "@material-ui/icons/Edit";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { AddOrEditSeason } from "../../../AddOrEditSeason/AddOrEditSeason";
import { useField } from "../../../../../globalState/fieldContext";
import { useApi } from "../../../../../hooks/useApi";
import { getSeasonConfigs } from "../../../../../controllers/seasons";

const useStyles = makeStyles((theme) => ({
  seasonsButtons: {
    marginTop: "1rem",
  },
  editFlightPathContainer: {
    marginTop: "1rem",
  },
  editInstructionText: {
    marginBottom: "1rem",
  },
  button: {
    width: "calc(50% - 1rem)",
  },
  wideButton: {
    width: "100%",
  },
  addButton: {
    marginBottom: "1rem",
  },
}));

export const SeasonsDrawer = ({ useDrawerStyles }) => {
  const classes = useStyles();
  const [showOptions, setShowOptions] = useState(false);
  const [showAddEditSeason, setShowAddEditSeason] = useState("");
  const drawerClasses = useDrawerStyles();
  const { data: crops } = useApi("/api/v1/crops");
  const [possibleMaturities, setPossibleMaturities] = React.useState(null)
  const [possibleRowSpacings, setPossibleRowSpacings] = React.useState(null);

  const {
    seasons,
    selectedSeason,
    setSelectedSeason,
    trueCauseMarkersEditMode,
  } = useField();

  React.useEffect(() => {
    if (!possibleMaturities) {
      getSeasonConfigs().then((resp) => {
        setPossibleMaturities(resp.cropMaturityTypes)
        setPossibleRowSpacings(resp.rowSpacings)
      });
    }
  })

  return (
    <Grid container direction="column" className={drawerClasses.root}>
      <ShelfLabel
        label="Season"
        showOptions={showOptions}
        select={() => {
          setShowOptions(!showOptions);
          setShowAddEditSeason("");
        }}
      />

      {showOptions && !showAddEditSeason && (
        <Grid
          container
          item
          direction="column"
          alignItems="flex-start"
          className={drawerClasses.options}
        >
          {!trueCauseMarkersEditMode &&
            (seasons && seasons.length > 0 ? (
              <Select
                options={
                  seasons &&
                  seasons.length > 0 &&
                  seasons.map((season) => season)
                }
                selectedOption={selectedSeason}
                selectOption={(season) => setSelectedSeason(season)}
              />
            ) : (
              <Typography color="textSecondary">You have no seasons</Typography>
            ))}
          {!trueCauseMarkersEditMode && (
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="center"
              className={classes.seasonsButtons}
            >
              <MuiButton
                className={classes.button}
                startIcon={<AddRoundedIcon />}
                text="Add Season"
                onClick={() => setShowAddEditSeason("add")}
              />
              {selectedSeason && (<MuiButton
                className={classes.button}
                startIcon={<EditIcon />}
                text="Edit Season"
                onClick={() => setShowAddEditSeason("edit")}
                disabled={seasons?.length === 0}
              />)}
            </Grid>
          )}

        </Grid>
      )}
      {showOptions && showAddEditSeason && (
        <AddOrEditSeason
          onCancel={() => setShowAddEditSeason("")}
          seasonId={showAddEditSeason === "add" ? null : selectedSeason.id}
          crops={crops.map((c) => ({ id: c.id, name: c.name }))}
          possibleMaturities={possibleMaturities}
          possibleRowSpacings={possibleRowSpacings}
        />
      )}
    </Grid>
  );
};
