import React, { useEffect, useReducer } from "react";
import styles from "./Home.module.scss";
import { Flex } from "../../Layouts/Flex";
import { Container } from "../../Layouts/Container";
import { SignInForm } from "./SignInForm";
import { SignUp } from "./SignUp";
import { SignIn } from "./SignIn";
import { SignUpForm } from "./SignUpForm";
import { ForgotPasswordForm } from "./ForgotPasswordForm";
import { ResetPasswordForm } from "./ResetPasswordForm";
import { AccountSetup } from "../Account/AccountSetup";
import { Error } from "./Error";
import { appendAuthentication } from "../../../controllers/auth";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../../../hooks/useAuth";
import useCookies from "../ImageReviewer/hooks/useCookies";

export const ACTIONS = {
  UPDATE_FIELD: "update-field",
  RESET_FORM: "reset-form",
};

const formFields = {
  email: "",
  password: "",
  passwordConfirmation: "",
  firstName: "",
  lastName: "",
  farmTitle: "",
};

const reducer = (userData, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_FIELD:
      return { ...userData, [action.payload.field]: action.payload.value };
    case ACTIONS.RESET_FORM:
      return { ...formFields };
    default:
      return userData;
  }
};

export const Home = (props) => {
  const { location } = window;
  const { pathname } = location;
  const [userData, dispatch] = useReducer(reducer, { ...formFields });
  const { redirect } = props.match.params;
  const redirectUrl = window.sessionStorage.getItem("redirectUrl");

  const { authToken } = useParams();
  const { setToken, setUserId } = useAuth();
  const { setCookie } = useCookies();

  const authenticateAndLogIn = async () => {
    const userData = await appendAuthentication({ token: authToken });
    if (userData.token) {
      setCookie({ key: "authToken", value: userData.token });
      setCookie({ key: "userId", value: userData.id });
      setUserId(userData.id);
      setToken(userData.token);
      window.location.assign("/farms");
    }
  };

  useEffect(() => {
    if (pathname.includes("/login")) {
      authenticateAndLogIn();
    } else {
      dispatch({ type: ACTIONS.RESET_FORM });
    }
    // eslint-disable-next-line
  }, [pathname]);

  return (
    <Container>
      <Flex justifyContent="center" alignItems="center" className={styles.Home}>
        {pathname === "/" ? (
          <>
            <SignInForm
              userData={userData}
              dispatch={dispatch}
              redirect={redirectUrl ?? "/farms"}
            />
            <SignUp />
          </>
        ) : pathname === "/forgot-password" ? (
          <>
            <ForgotPasswordForm userData={userData} dispatch={dispatch} />
            <SignUp />
          </>
        ) : pathname === "/sign-up" ? (
          <>
            <SignIn />
            <SignUpForm userData={userData} dispatch={dispatch} />
          </>
        ) : pathname.includes("/reset-password") ? (
          <>
            <ResetPasswordForm
              userData={userData}
              dispatch={dispatch}
              props={props}
            />
            <SignUp />
          </>
        ) : pathname.includes("/reset-sign-in") ? (
          <>
            <SignInForm
              userData={userData}
              dispatch={dispatch}
              title="You're all set!"
              subTitle="Your password has been successfully reset. Please use your new password when signing in."
              hideForgotPassword={true}
              redirect={redirect && redirect.replace("_", "/")}
            />
            <SignUp />
          </>
        ) : pathname.includes("/account-setup") ? (
          <>
            <AccountSetup />
          </>
        ) : pathname.includes("error") ? (
          <>
            <Error />
          </>
        ) : null}
      </Flex>
    </Container>
  );
};
