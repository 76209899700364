import React from "react";
import { Grid, Button, Typography, makeStyles } from "@material-ui/core";
import AddPhotoAlternateOutlinedIcon from "@material-ui/icons/AddPhotoAlternateOutlined";
import { Input } from "../../../../../Helpers";
import { ChromePicker } from "react-color";
import { generateBorder } from "../../../../../../styles/generateStyles";

const useStyles = makeStyles((theme) => ({
  logoUpload: {
    marginBottom: "1.5rem",
    "& p": {
      width: "12rem",
      marginLeft: "1.5rem",
      padding: "0.9rem 0",
      borderBottom: generateBorder(2, theme.palette.borderColor.primary),
    },
  },
  chooseFileBtn: {
    position: "relative",
    height: "3rem",
    width: "9rem",
    fontSize: theme.typography.body2.fontSize,
    borderColor: theme.palette.text.secondary,
    overflow: "hidden",
    "& input": {
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
      paddingLeft: "100%",
      backgroundColor: "transparent",
      cursor: "pointer",
    },
    "& input::-webkit-file-upload-button": {
      visibility: "hidden",
    },
  },
  selected: {
    color: theme.palette.text.primary,
  },
  logoImg: {
    height: "3rem",
    marginLeft: "1.5rem",
  },
}));

export const CustomizeTemplate = ({
  parentClasses,
  newReport,
  updateNewReport,
  logoInput,
}) => {
  const classes = useStyles();

  const handleSelectColor = (color) => {
    updateNewReport("brandColor", color.hex);
  };

  const updateImageSource = () => {
    const logoImg = logoInput.current.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(logoImg);
    reader.onloadend = () => updateNewReport("companyLogo", reader.result);
  };

  const { title, farmName, brandColor, logoFile, companyLogo } = newReport;
  return (
    <Grid container item direction="column" className={parentClasses.section}>
      <Typography variant="h4">Template Customization</Typography>
      <Input
        placeholder="Report Name*"
        value={title ? title : ""}
        className={parentClasses.input}
        onChange={(e) => updateNewReport("title", e.target.value)}
        required
      />
      <Input
        placeholder="Farm Name"
        value={farmName ? farmName : ""}
        className={parentClasses.input}
        onChange={(e) => updateNewReport("farmName", e.target.value)}
      />
      <Typography style={{ marginBottom: "0.3rem" }}>Logo Upload</Typography>
      <Grid container item alignItems="center" className={classes.logoUpload}>
        <Button
          startIcon={<AddPhotoAlternateOutlinedIcon />}
          variant="outlined"
          className={classes.chooseFileBtn}
        >
          <input
            type="file"
            placeholder=""
            ref={logoInput}
            onChange={updateImageSource}
          />
          Choose File
        </Button>
        {companyLogo ? (
          <img
            src={companyLogo}
            alt="company logo"
            className={classes.logoImg}
          />
        ) : (
          <Typography variant="body2" style={{ marginBottom: 0 }}>
            {logoFile ? logoFile.name : "Upload your image"}
          </Typography>
        )}
      </Grid>
      <Typography style={{ marginBottom: "0.6rem" }}>Brand Color</Typography>
      <ChromePicker
        color={brandColor ? brandColor : ""}
        onChange={handleSelectColor}
      />
    </Grid>
  );
};
