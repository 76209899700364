import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  FormControlLabel,
} from "@material-ui/core";
import { utcDateToString } from "../../../../../Helpers/utcDateToString";
import { Switch } from "../../../../../Helpers";
import { MultiFlightSelect } from "./HealthImagerySelector/MultiFlightSelect";
import { BeforeAndAfter } from "./HealthImagerySelector/BeforeAndAfter";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  imageryContainer: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    columnGap: "1.5rem",
    rowGap: "3rem",
    width: "fit-content",
    marginTop: "1.5rem",
  },
  imageContainer: {
    position: "relative",
    width: "22.5rem",
  },
  removeFlightBtn: {
    position: "absolute",
    top: "-1.3rem",
    right: "-1.3rem",
    color: "red",
    opacity: 0.7,
  },
  imagery: {
    height: "22.5rem",
    width: "100%",
    backgroundColor: theme.palette.background.tertiary,
    marginBottom: "1.5rem",
    "& .MuiButton-startIcon > svg": {
      fontSize: "2rem",
      opacity: 0.7,
    },
  },
  select: {
    marginBottom: "1.5rem",
  },
}));

export const AddAerialImagery = ({
  parentClasses,
  newReport,
  setNewReport,
  imageryByDate,
}) => {
  const classes = useStyles();
  const [showPlantHealth, setShowPlantHealth] = useState(true);

  useEffect(() => {
    const copyFlights = newReport.flights ? [...newReport.flights] : [{}];
    setNewReport({ ...newReport, flights: copyFlights });
    // eslint-disable-next-line
  }, []);

  const selectFlightDate = (flight, index) => {
    let newFlights = [...newReport.flights];
    if (flight) {
      const date = flight.substring(0, 10).replace("-", "").trim();
      const imageryType = flight
        .replace(date, "")
        .replace(" ", "")
        .replace(" ", "")
        .replace("-", "");
      const matchingFlightDate = [...imageryByDate].filter(
        (image) =>
          format(new Date(image.dateFlown), "MM/dd/yy") ===
          format(new Date(date), "MM/dd/yy")
      )[0];
      const matchingImagery = matchingFlightDate.imagery.filter(
        (image) => image.name.toUpperCase() === imageryType
      )[0];
      newFlights[index] = {
        ...newFlights[index],
        ...matchingImagery,
        id: matchingImagery.flightId,
        position: index,
      };
      setNewReport({ ...newReport, flights: newFlights });
    } else {
      newFlights.splice(index, 1, {});
      setNewReport({ ...newReport, flights: newFlights });
    }
  };

  const formatImageryDates = (date, imageName = "") => {
    return `${date} - ${imageName.toUpperCase()}`;
  };

  let newImageryDates = [];
  imageryByDate.forEach((flight) => {
    // we will use the date the pilot entered, as-is, for the date flown
    const date = utcDateToString(flight.dateFlown);
    flight.imagery.forEach((img) =>
      newImageryDates.push(formatImageryDates(date, img.type))
    );
  });

  const togglePlantHealth = () => {
    if (showPlantHealth) {
      setNewReport({ ...newReport, flights: [{}] });
    }
    setShowPlantHealth(!showPlantHealth);
  };

  if (!newReport.template.hideAerialImageryData) {
    return (
      <Grid container item direction="column" className={parentClasses.section}>
        <FormControlLabel
          className={parentClasses.headerWithSwitch}
          control={<Switch checked={showPlantHealth} />}
          label={<Typography variant="h4">Add Aerial Imagery</Typography>}
          onChange={togglePlantHealth}
        />
        {showPlantHealth && (
          <div className={classes.imageryContainer}>
            {newReport.template.flightPicker ===
              "BeforeAndAfterFlightPicker" ? (
              <BeforeAndAfter
                classes={classes}
                newReport={newReport}
                setNewReport={setNewReport}
                imageryDates={newImageryDates}
                selectFlightDate={selectFlightDate}
                formatImageryDates={formatImageryDates}
              />
            ) : (
              newReport.flights &&
              newReport.flights.map((flight, i) => {
                const date = flight.dateFlown
                  ? formatImageryDates(
                    utcDateToString(flight.dateFlown),
                    flight.type
                  )
                  : null;

                return (
                  <MultiFlightSelect
                    i={i}
                    classes={classes}
                    flight={flight}
                    date={date}
                    newReport={newReport}
                    setNewReport={setNewReport}
                    imageryDates={newImageryDates}
                    selectFlightDate={selectFlightDate}
                  />
                );
              })
            )}
          </div>
        )}
      </Grid>
    );
  }
  return null;
};
