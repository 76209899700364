import React from "react";
import MUIRadioGroup from "@material-ui/core/RadioGroup";
import MUIRadio from "@material-ui/core/Radio";
import { makeStyles } from "@material-ui/core/styles";
import cn from "clsx";

export const RadioGroup = (props) => {
  return <MUIRadioGroup {...props} />;
};

const useRadioStyles = makeStyles((theme) => ({
  labeledBy: {
    "& > span": {
      "&::before": {
        content: 'var(--radio-label-text, "AI")',
        position: "absolute",
        bottom: 4,
        right: 4,
        width: 16,
        height: 16,
        borderRadius: "50%",
        background: theme.palette.primary.main,
        fontSize: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.colors.black,
      },
    },
  },
}));

export const Radio = ({ color = "primary", mode, ...props }) => {
  const classes = useRadioStyles();

  return (
    <MUIRadio
      color={color}
      {...props}
      className={cn(mode === "labeled" && classes.labeledBy, props.className)}
    />
  );
};
