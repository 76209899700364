import { useEffect } from "react";
import mapboxgl from "mapbox-gl";
import { makeStyles } from "@material-ui/core";
import { useField } from "../../globalState/fieldContext";

const useMarkerStyles = makeStyles(theme => ({
    soil_sample_marker: {
        backgroundColor: "var(--bgColorAlternate)",
        paddingTop: "2px",
        paddingLeft: "8px",
        paddingBottom: "2px",
        paddingRight: "8px",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        borderBottomRightRadius: "10px",
        borderBottomLeftRadius: "10px",
        fontSize: "var(--fontSmall)",
        fontFamily: "var(--fontFamilyMedium)"
    },
    createSoilMarker: {
        width: "0",
        height: "0",
        "& span": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxSizing: "border-box",
            width: "35px",
            height: "35px",
            color: "white",
            background: "var(--logoColor)",
            border: "solid 2px",
            borderRadius: "0 70% 70% 70%",
            boxShadow: "0 0 0 2px black",
            cursor: "pointer",
            transformOrigin: "0 0",
            transform: "rotateZ(-135deg)",
            paddingTop: "2px",
            paddingLeft: "8px",
            paddingBottom: "2px",
            paddingRight: "8px",
        },
        "& b": {
            transform: "rotateZ(135deg)",
            textAlign: "center",
            lineHeight: "12px"
        },
    }
}))

let oneMarker
let currentMarkers = []

let activityMarker
let activityMarkers = []

const SoilSampleLayer = ({ map, soilSampleData, showSoilSample, selectedNutrient, activityData }) => {

    const { soilEditMode } = useField()

    const classes = useMarkerStyles();
    useEffect(() => {

        if (showSoilSample) {
            if (currentMarkers !== null) {
                for (var y = currentMarkers.length - 1; y >= 0; y--) {
                    currentMarkers[y].remove();
                }
            }
            // eslint-disable-next-line
            soilSampleData?.map((da) => {
                var el = document.createElement('div');
                el.className = classes.soil_sample_marker;
                // round to two decimal places
                el.innerHTML = '<span><b>' + Math.round(da.value * 100) / 100 + '</b></span>'
                el.id = `soil-marker-${da.value}`
                let options = ''
                da?.results?.forEach(function (res) {
                    if (res.label === selectedNutrient) {
                        options = options + `<li style="color:var(--logoColor)">${res.label}-${res.value}</b></li>`
                    } else {
                        options = options + `<li>${res.label}-${res.value}</li>`
                    }
                })
                oneMarker = new mapboxgl.Marker(el)
                    .setLngLat([da.location.lng, da.location.lat])
                    .setPopup(new mapboxgl.Popup({
                        offset: 25,
                    })
                        .setHTML(`<ul>${options}</ul>`)).addTo(map)

                currentMarkers.push(oneMarker);
            })
        } else {
            for (let x = currentMarkers.length - 1; x >= 0; x--) {
                currentMarkers[x].remove();
            }
        }
        // eslint-disable-next-line
    }, [map, soilSampleData, showSoilSample])

    useEffect(() => {
        if (!showSoilSample && !soilEditMode && activityData?.length > 0) {
            if (activityMarkers !== null) {
                for (var r = activityMarkers.length - 1; r >= 0; r--) {
                    activityMarkers[r].remove();
                }
            }
            // eslint-disable-next-line
            activityData?.map((activity) => {
                var el = document.createElement('div');
                el.className = classes.createSoilMarker;
                el.innerHTML = '<span><b>' + (activity?.label) + '</b></span>'
                activityMarker = new mapboxgl.Marker(el)
                    .setLngLat([activity?.lng, activity?.lat]).addTo(map)
                activityMarkers.push(activityMarker);
            })
        } else {
            for (let p = activityMarkers.length - 1; p >= 0; p--) {
                activityMarkers[p].remove();
            }
        }
        // eslint-disable-next-line
    }, [activityData, soilEditMode])

    return null
}

export default SoilSampleLayer