import React, { useEffect } from "react";
import { Field } from "./Field";
import { FarmToggleSliderFilters } from "./FieldShelf/ToggleSliders/useFieldToggleSliders";
import { useAuth } from "../../../hooks/useAuth";
import { getField } from "../../../controllers/fields";
import { FieldProvider } from "../../../globalState/fieldContext";
import { IField } from "./FieldInterfaces";

export const FieldWrapper = (props: any) => {
  const { token } = useAuth();
  const [field, setField] = React.useState<IField[] | []>([]);

  useEffect(() => {
    if (token && !field.length) {
      getField(token, props.match.params.fieldId).then((res) => {
        setField([{ ...res, show: true }]);
      });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [token]);

  return (
    <FieldProvider field={field} setField={setField}>
      <FarmToggleSliderFilters>
        <Field {...props} />
      </FarmToggleSliderFilters>
    </FieldProvider>
  );
};
