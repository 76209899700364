import React, { useEffect, useState } from "react";
import { ShelfLabel } from "../../../../Helpers/ShelfLabel/ShelfLabel";
import {
  Grid,
  Button,
  makeStyles,
  Typography,
  FormControlLabel,
  IconButton,
  LinearProgress,
} from "@material-ui/core";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { ResultsDateSlider } from "../../SoilSampleDateSlider/SoilSampleDateSlider";
import { useField } from "../../../../../globalState/fieldContext";
import { Switch } from "../../../../Helpers/Switch/Switch";
import { CreateStandCountTaskPopup } from "./CreateStandCountTaskPopup";
import * as axios from "axios";
import { SampleLegend } from "../../SampleLegends/SampleLegend";
import { standCountColors, standCountFillColors } from "../../../../../styles/colors";
import * as turf from "@turf/turf";
import { ConfirmStandCountPopup } from "./ConfirmStandCountPopup";
const useStyles = makeStyles((theme) => ({
  newReportBtn: {
    height: "3rem",
    width: "100%",
    fontSize: theme.typography.body1.fontSize,
    fontFamily: theme.typography.fontFamilyMedium,
    borderColor: theme.palette.text.primary,
  },
  cropInput: {
    marginBottom: "1.5rem",
    marginTop: "0.5rem"
  },
}))


export const StandCountDrawer = ({
  useDrawerStyles,
  standCountDates, // array of stand count dates
  standCountByDate, // array of stand counts
  setStandCountData, // selected stand count
  setShowStandCountSample,
  showStandCountSample,
  setShowStandCountHeatmap,
  showStandCountHeatmap,
  imageToDisplay,
  setImageToDisplay,
  setActivityData,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [sliderDate, setSliderDate] = useState("")
  const [pendingTasks, setPendingTasks] = useState(false)
  const [openCreateStandCount, setOpenCreateStandCount] = useState(false)
  const drawerClasses = useDrawerStyles();
  const classes = useStyles();
  const { field, selectedSeason } = useField();
  const [labels, setLabels] = React.useState({});
  const [confirmFlightPoints, setConfirmFlightPoints] = useState(false)
  const [workOrderId, setWorkOrderId] = useState();

  const calculateAcres = () => {
    const sqMetersPerAcre = 4046.85642245;
    let acres = [0, 0, 0];
    fetch(imageToDisplay?.geotiffUrl)
      .then(response => response.json())
      .then(geojson => {
        geojson.features.forEach((feature, index) => {
          const area = turf.area(feature) / sqMetersPerAcre;
          acres[feature['properties']['ELEV_MAX'] - 1] = area.toFixed(1);
          //console.log(`Area of feature ${feature.properties['ID']}: ${area} acres`);
        });
        setLabels({ text: acres, units: 'ac', colors: standCountColors });
      });
  }

  useEffect(() => {
    if (standCountDates?.length > 0) {
      setSliderDate(standCountDates[0])
    }
    setPendingTasks(pendingStandCountTasks())
    // eslint-disable-next-line
  }, [standCountDates])

  useEffect(() => {
    if (standCountByDate?.length > 0) {
      setStandCountData(standCountByDate.find(sc => sc.dateFlown === sliderDate))
    }
    // eslint-disable-next-line
  }, [sliderDate])

  useEffect(() => {
    if (selectedSeason && showStandCountHeatmap) {
      var standCount = selectedSeason?.heatmaps.find(hm => hm.name === 'Stand Count' && hm.observedAt.includes(sliderDate));
      //const selected_standCount = { ...standCount, id: standCount.flightId, geotiffUrl: standCount.standCountUrl };
      setImageToDisplay(standCount)
    } else {
      setImageToDisplay(null);
    }
    // eslint-disable-next-line
  }, [selectedSeason, showStandCountHeatmap, sliderDate])

  useEffect(() => {
    if (showStandCountHeatmap && imageToDisplay) {
      calculateAcres();
    }
    // eslint-disable-next-line
  }, [showStandCountHeatmap, imageToDisplay])

  const [downloading, setDownloading] = useState([]);
  const fieldContext = useField();
  const downloads3ToClient = (heatmap) => {
    const { name } = heatmap;
    return axios.default
      .get(`${heatmap.shapefileUrl}`, {
        headers: {
          Accept: "*/*",
        },
        responseEncoding: "binary",
        responseType: "arraybuffer",
        onDownloadProgress: function (progressEvent) {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          setDownloading((downloading) => [
            ...downloading.map((a) =>
              a.name === name ? { name, progress: percent } : a
            ),
          ]);
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        // We will build a filename to store imagery that "makes sense"
        // Currently: fieldID_flight-date_wo_type.tif
        const fieldId = fieldContext.field[0].id;
        const dateFlown = heatmap.observedAt.split('T')[0]
        const localFile = `${fieldId}_${dateFlown}_STAND_COUNT.zip`;
        link.href = url;
        link.setAttribute("download", localFile); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  };

  const downloadCallback = async (toggledImage) => {
    // dont allow for redownloading files while download in progress
    if (downloading.some((a) => a.name === toggledImage.name)) return;
    setDownloading([
      ...downloading.filter((a) => a.name !== toggledImage.name),
      { name: toggledImage.name, progress: 0 },
    ]);
    await downloads3ToClient(toggledImage).finally(() => {
      setDownloading(
        downloading.filter((file) => file.name !== toggledImage.name)
      );
    });
  };

  const findProgressValue = () => {
    const value = downloading[0];
    return value?.progress;
  };

  const ImageControl = ({
    checkOnLoadFlag,
    onChangeFn,
    disabled = false
  }) => {
    return (
      <div>
        <FormControlLabel
          control={<Switch checked={checkOnLoadFlag} />}
          onChange={onChangeFn}
          disabled={disabled}
        />
      </div>
    );
  }

  const pendingStandCountTasks = () => {
    const current_season = field[0]?.seasons?.filter(season => {
      return season.current === true
    })[0];
    if (current_season) {
      const orders = current_season?.timeline?.filter(item => (item.type === 'orderRequested' && item.workOrder.service.id === 14));
      if (orders) {
        const completed = orders.map(function (orders) { return current_season?.timeline?.some(item => item.type === 'orderComplete' && item.workOrder.id === orders.workOrder.id) });
        return completed.some(item => item === false);
      }
    }
    return false;
  }

  const handleToggleShowSample = () => {
    setShowStandCountSample(!showStandCountSample)
  }

  const handleToggleShowHeatmap = () => {
    setShowStandCountHeatmap(!showStandCountHeatmap)
  }

  const handleCreateStandCountToggle = () => {
    setOpenCreateStandCount(true)
  }

  const handleClose = async (workOrderid) => {
    setOpenCreateStandCount(false);
  };

  const handleOpenConfirmStandCount = (workOrder) => {
    setWorkOrderId(workOrder);
    setConfirmFlightPoints(true)
  }

  const handleConfirmClose = () => {
    setConfirmFlightPoints(false)
  }

  return (
    <>
      <CreateStandCountTaskPopup
        open={openCreateStandCount}
        handleClose={handleClose}
        handleOpen={() => setOpenCreateStandCount(true)}
        setActivityData={setActivityData}
        handleOpenConfirmStandCountPopup={handleOpenConfirmStandCount}
      />
      <ConfirmStandCountPopup open={confirmFlightPoints} onClose={handleConfirmClose} workOrderId={workOrderId} />

      <Grid container
        direction="column"
        justifyContent="center"
        className={drawerClasses.root}>
        <ShelfLabel
          label={`Stand Count`}
          showOptions={showOptions}
          select={() => {
            setShowOptions(!showOptions)
          }}
        />
        {showOptions && (
          <Grid className={drawerClasses.options}>
            {pendingTasks ? (
              <Typography color="textSecondary"
                style={{ marginBottom: "1.5rem" }}>
                You have pending stand count tasks. Check Field History for more details.
              </Typography>
            ) : null
            }
            {standCountDates?.length > 0 ?
              (<div>
                <Grid
                  key={'heatmap-date'}
                  container
                  item
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <div className="soilSampleDateSliderWrapper">
                    {standCountDates?.length === 1 ? sliderDate : <ResultsDateSlider sliderDates={standCountDates} sliderDate={sliderDate} setSliderDate={setSliderDate} />}
                  </div>
                  <IconButton
                    onClick={() => downloadCallback(selectedSeason?.heatmaps.find(hm => hm.name === 'Stand Count' && hm.observedAt.includes(sliderDate)))}
                    aria-label="download standcount"
                    disabled={selectedSeason?.heatmaps?.length === 0}
                  >
                    <GetAppOutlinedIcon sx={{ marginLeft: '1rem' }} color="primary" />
                  </IconButton>
                  {(downloading.length > 0) && (
                    <div className={classes.loadingContainer}>
                      <LinearProgress
                        variant="determinate"
                        value={findProgressValue()}
                      />
                    </div>
                  )}
                </Grid>
                <div className="soilSampleToggleWrapper">
                  <ImageControl
                    checkOnLoadFlag={showStandCountSample}
                    onChangeFn={() => handleToggleShowSample()}
                  />
                  {showStandCountSample ? <Typography color="textSecondary">
                    Hide Samples
                  </Typography> : <Typography color="textSecondary">
                    Show Samples
                  </Typography>}
                </div>
                <div className="soilSampleToggleWrapper">
                  <ImageControl
                    checkOnLoadFlag={showStandCountHeatmap}
                    onChangeFn={() => handleToggleShowHeatmap()}
                    disabled={selectedSeason?.heatmaps?.length === 0}
                  />
                  {showStandCountHeatmap ? <Typography color="textSecondary">
                    Hide Heatmap
                  </Typography> : <Typography color="textSecondary">
                    Show Heatmap
                  </Typography>}
                </div>
              </div>
              ) :
              (
                <Typography color="textSecondary"
                  style={{ marginBottom: "1.5rem" }}>
                  You have no stand count results.
                </Typography>
              )
            }
            {/* <Button
              variant="outlined"
              startIcon={<AddRoundedIcon />}
              className={classes.newReportBtn}
              onClick={handleCreateStandCountToggle}
            >
              Create Stand Count Task
            </Button> */}
            {showStandCountHeatmap && (<SampleLegend labels={labels} type={"stand-count-legend"} />)}

          </Grid>
        )}
      </Grid>
    </>
  );
}