import React, { useMemo, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Map } from "../../../../../../Map/Map";
import { dateToString, OldSelect } from "../../../../../../Helpers";
import { useApi } from "../../../../../../../hooks/useApi";
import { useField } from "../../../../../../../globalState/fieldContext";

export const BeforeAndAfter = ({
  classes,
  newReport,
  setNewReport,
  imageryDates,
  selectFlightDate,
  formatImageryDates,
}) => {
  const { data: crops } = useApi(`/api/v1/crops`);
  const { field } = useField();
  const beforeFlight = newReport.flights[0] ?? {};
  const afterFlight = newReport.flights[1] ?? {};

  const beforeDate = useMemo(() => {
    return beforeFlight.dateFlown ? new Date(beforeFlight.dateFlown) : "";
  }, [beforeFlight.dateFlown]);
  const afterDate = useMemo(() => {
    return afterFlight.dateFlown ? new Date(afterFlight.dateFlown) : "";
  }, [afterFlight.dateFlown]);

  useEffect(() => {
    const copyFlights = newReport.flights ? [...newReport.flights] : [{}, {}];
    setNewReport({ ...newReport, flights: copyFlights });
    // eslint-disable-next-line
  }, []);

  const selectCropStage = (index, stage) => {
    const copyFlights = [...newReport.flights];
    copyFlights[index].growthStage = stage;
    copyFlights[index].position = index;
    setNewReport({ ...newReport, flights: copyFlights });
  };

  const cropStageOptions = useMemo(() => {
    if (crops) {
      return crops.filter((crop) => crop.name === newReport.crop)[0];
    }
  }, [newReport.crop, crops]);

  return (
    <>
      <Grid item className={classes.imageContainer}>
        <Grid className={classes.imagery}>
          <Map
            containerId="before-map"
            defaultStyle="blankDark"
            fields={field.map((f) => f)}
            fitBounds={false}
            imageToDisplay={beforeFlight.id ? beforeFlight : null}
            hideMapLayerToggle
            hideControls
            disableZoom
            disableDrag
          />
        </Grid>
        <OldSelect
          className={classes.select}
          defaultLabel="Before application flight date"
          options={imageryDates}
          selectedOption={
            beforeDate
              ? formatImageryDates(dateToString(beforeDate), beforeFlight.type)
              : ""
          }
          setSelectedOption={(flight) => selectFlightDate(flight, 0)}
        />
        <OldSelect
          defaultLabel="Crop Stage"
          options={cropStageOptions ? cropStageOptions.growthStages : []}
          selectedOption={beforeFlight ? beforeFlight.growthStage : ""}
          setSelectedOption={(stage) => selectCropStage(0, stage)}
        />
      </Grid>

      <Grid item className={classes.imageContainer}>
        <Grid className={classes.imagery}>
          <Map
            containerId="after-map"
            defaultStyle="blankDark"
            fields={field.map((f) => f)}
            fitBounds={false}
            imageToDisplay={afterFlight.id ? beforeFlight : null}
            hideMapLayerToggle
            hideControls
            disableZoom
            disableDrag
          />
        </Grid>
        <OldSelect
          className={classes.select}
          defaultLabel="After application flight date"
          options={imageryDates}
          selectedOption={
            afterDate
              ? formatImageryDates(dateToString(afterDate), afterFlight.type)
              : ""
          }
          setSelectedOption={(flight) => selectFlightDate(flight, 1)}
        />
        <OldSelect
          defaultLabel="Crop Stage"
          options={cropStageOptions ? cropStageOptions.growthStages : []}
          selectedOption={
            afterFlight && afterFlight ? afterFlight.growthStage : ""
          }
          setSelectedOption={(stage) => selectCropStage(1, stage)}
        />
      </Grid>
    </>
  );
};
