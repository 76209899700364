import React, { useContext } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useApi } from "../../../../../hooks/useApi";
import { Autocomplete2 } from "../../../../Helpers/Autocomplete/Autocomplete";
import { Flex } from "../../../../Layouts/Flex";
import clsx from "clsx";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { NewDatePicker } from "../../../../Helpers/DatePicker/DatePicker";
import { useButtonStyles } from "../../../../Helpers/Button/Button.jsx";
import omit from "../../../../../lib/omit";
import Swal from "sweetalert2";
import CloseIcon from "@material-ui/icons/Close";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { TextareaWithLabel } from "../../../../Helpers/Input/Textarea";
import { useField } from "../../../../../globalState/fieldContext";
import { NewInput } from "../../../..//Helpers/Input/Input";
import {
  getWorkOrder,
  editWorkOrder,
} from "../../../../../controllers/workOrder";
import { useAuth } from "../../../../../hooks/useAuth";
import { MuiButton } from "../../../../Helpers";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { generateFlags } from "../../../../../controllers/workOrder";
import { taskActivities } from "../../../../../hooks/useApi";
import { MapContext } from "../../../../../globalState";
import { DistanceInput } from "../../../../Helpers/DistanceInput/DistanceInput";
import { utcStringToDate } from "../../../../Helpers/utcStringToDate";

const useStyles = makeStyles((theme) => ({
  editWorkOrderRoot: {
    marginRight: "-179px",
  },
  header: {
    marginTop: theme.spacing(2),
    textAlign: "left",
  },
  item: {
    marginTop: theme.spacing(1),
  },
  contactSection: {
    color: "red",
    fontWeight: "bold"
  },
  itemWithExtraSpace: {
    marginTop: theme.spacing(1.5),
  },
  error: {
    color: theme.palette.error.main,
  },


  editFlightPathContainer: {
    marginTop: "1rem",
  },
  editInstructionText: {
    marginBottom: "1rem",
  },
  button: {
    width: "calc(50% - 1rem)",
  },
  wideButton: {
    width: "100%",
  },
  addButton: {
    marginBottom: "1rem",
  },

}));

const EditWorkOrderShelf = (props, { close }) => {
  const { token } = useAuth();
  const { data: allServices } = useApi("/api/v1/services");
  const services = allServices?.activeServices;
  const tcAboveConfigs = allServices?.activeTcAboveConfigurations;
  const { fieldMap } = useContext(MapContext);
  const { field, updateSeasons } = useField();
  const [tcAboveConfig, setTcAboveConfig] = React.useState();
  const [tcPoints, setTcPoints] = React.useState();
  const [tcHeightM, setTcHeightM] = React.useState();
  const [tcBufferM, setTcBufferM] = React.useState();
  const [workOrder, setWorkOrder] = React.useState([]);
  const [disableUpdateBtn, setDisableUpdateBtn] = React.useState(false);
  const [addFlagMode, setAddFlagMode] = React.useState(false);
  const addFlagModeRef = React.useRef(0);

  const currentSeasonExists = field[0].seasons.find((s) => s.current);
  const needNewSeason = !Boolean(currentSeasonExists);

  const tcAboveServiceId = services?.find((s) => s.shortName === "tcabove")?.id;
  const autoSoilMapServiceId = services?.find((s) => s.shortName === "asm")?.id;

  const initialSeasonData = {
    fieldId: field[0].id,
    cropId: undefined,
    plantDate: undefined,
  };

  const [seasonData, setSeasonData] = React.useState(initialSeasonData);
  const [seasonErrors, setSeasonErrors] = React.useState({});

  const initialWorkOrderData = {
    fieldId: field[0].id,
    serviceId: undefined,
    specialInstructions: "",
    timing: 3,
    requestedStartDate: undefined,
    requestedEndDate: undefined,
    isNewSeason: needNewSeason,
    parameters: {},
    status: "",
    service: "",
    tasks: [],
    flags: [],
  };

  const [workOrderData, setWorkOrderData] = React.useState(
    initialWorkOrderData
  );
  const [workOrderErrors, setWorkOrderErrors] = React.useState({});

  const classes = useStyles();
  const buttonClasses = useButtonStyles();

  const validateSeason = () => {
    if (!needNewSeason) return true;
    const newSeasonErrors = {};
    if (!seasonData.cropId) {
      newSeasonErrors.cropId = "Crop is required.";
    }
    if (!seasonData.plantDate) {
      newSeasonErrors.plantDate = "Plant date is required.";
    }
    setSeasonErrors(newSeasonErrors);
    return Object.keys(newSeasonErrors).length === 0;
  };

  const validateWorkOrder = () => {
    const newWorkOrderErrors = {};
    if (!workOrderData.serviceId) {
      newWorkOrderErrors.serviceId = "You must choose a service.";
    }
    if (workOrderData.serviceId !== autoSoilMapServiceId) {
      if (!workOrderData.requestedStartDate) {
        newWorkOrderErrors.dateRange =
          "You must specify a requested date.";
      }
    }
    setWorkOrderErrors(newWorkOrderErrors);
    return Object.keys(newWorkOrderErrors).length === 0;
  };

  const {
    seasons,
    // trueCauseMarkersEditMode,
    // setTrueCauseMarkersEditMode,
    // setAddedLngLats,
    // setEditedLngLats,
    // setDeletedPinNumbers,
    editedFlags,
    // setEditedFlags,
    // setSelectedWorkOrderId,
    pointEditMode,
    setPointEditMode,
    setSoilEditMode,
    activityEditData,
    setActivityEditData,
    setNewPointPrefix,
  } = useField();

  // const handleCancelEditFlightPath = () => {
  //   setTrueCauseMarkersEditMode(false);
  //   setEditedLngLats(null);
  //   setDeletedPinNumbers(null);
  //   setAddedLngLats(null);
  //   setEditedFlags(null);
  //   setDisableUpdateBtn(false)
  // };

  // const handleCompleteEditFlightPath = async () => {
  //   // here we saved things so need to refresh the state and cancel the edit
  //   handleCancelEditFlightPath();
  //   await updateSeasons();
  // }
  // const handleEditFlightPath = () => {
  //   setSelectedWorkOrderId(props.editableId);
  //   setTrueCauseMarkersEditMode(true);
  //   setDisableUpdateBtn(true)
  // };

  const handleCreateFlightPath = async () => {
    await generateFlags(token, props.editableId);
    await updateSeasons();
    setPointEditMode(true);
    await Swal.fire({
      icon: "success",
      title: "Success!",
      text: "Flight plan has been successfully generated",
    });
  };

  // const alert = async () => {
  //   await Swal.fire({
  //     icon: "error",
  //     title: "Oops...",
  //     text: "An error occured while updaing the flight path.\nContact support@aker.ag for assistance.",
  //   });
  // };
  const submitForm = async () => {
    //updateParams();
    const workOrderIsValid = validateWorkOrder();
    const seasonIsValid = validateSeason();
    if (workOrderIsValid && seasonIsValid) {
      // we no longer use the end date so set to the start date
      const editedWorkOrder = {
        ...workOrderData,
        specialInstructions: workOrderData.specialInstructions,
        requestedStartDate: workOrderData.requestedStartDate.toISOString().split("T")[0],
        requestedEndDate: workOrderData.requestedStartDate.toISOString().split("T")[0],
        parameters: workOrderData.parameters,
      };

      await editWorkOrder(token, props.editableId, editedWorkOrder)
        .then(async (_submitWorkOrderResponse) => {
          await Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Work order was updated",
          });
          await updateSeasons();
        })
        .catch((error) => {
          console.error(error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "An error occurred while trying to create your work order",
          });
        });
    }
  };

  const clearForm = () => {
    setSeasonData(initialSeasonData);
    setWorkOrderData(initialWorkOrderData);
    setSeasonErrors({});
    setWorkOrderErrors({});
  };

  React.useEffect(() => {
    if (tcAboveConfig) {
      let params = { height: tcHeightM, points: tcPoints, buffer: tcBufferM };
      params = {
        ...params,
        n90: tcAboveConfig.n90,
        n45: tcAboveConfig.n45,
        s45: tcAboveConfig.s45,
        w45: tcAboveConfig.w45,
        e45: tcAboveConfig.e45,
      };
      params = { ...params, name: tcAboveConfig.name, process_standcount: tcAboveConfig.processStandcount }
      setWorkOrderData({ ...workOrderData, parameters: params });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tcHeightM, tcBufferM, tcPoints]);

  React.useEffect(() => {
    if (token && !props.editableId.length) {
      getWorkOrder(token, props.editableId).then((res) => {
        setWorkOrder(res);
        setActivityEditData(res?.tasks[0]?.activities)
        setWorkOrderData({
          ...workOrderData,
          serviceId: res.serviceId,
          specialInstructions: res.specialInstructions,
          requestedStartDate: new Date(res.requestedStartDate.replace('Z', '')),
          requestedEndDate: new Date(res.requestedEndDate.replace('Z', '')),
          status: res.status,
          service: res.service,
          tasks: res.tasks,
          flags: res.flags,
          serviceName: res.parameters?.name,
          parameters: res.parameters,
        });
      });
      if (props.editSoilPoints) {
        handleEditPointLocation();
      }
    }
    if (props.editableId) {
      let elementToScrollTo = document.getElementById(props.editableId);
      // for work orders we are scrolling within another div, so check if this element exists
      // and handle custom behavior for it
      // const tableELm = document.querySelector("#work-orders-table");
      // if (tableELm) {
      //   tableELm.scrollTop = elementToScrollTo.offsetTop;
      // } else {
      // otherwise default to scrolling into view
      elementToScrollTo?.scrollIntoView({ behavior: "smooth" });
      // }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [token, props.editableId]);

  const handleClose = () => {
    props.data();
  };

  const dronePhotosNeeded = [7, 14].includes(workOrder.serviceId)
  const soilSampleNeeded = workOrder.serviceId === 51

  const activityType = () => {
    if (dronePhotosNeeded) {
      return {
        "id": 3,
        "type": "drone_photo",
        "notes": ""
      }
    }
    if (soilSampleNeeded) {
      return {
        "id": 1,
        "type": "soil_sample",
        "notes": ""
      }
    }
  }

  const addPointHandler = (e) => {
    fieldMap.off('click', addPointHandler); // only add one point at a time
    if (addFlagModeRef.current === 0) {
      return;
    }
    const newPointOrder = activityEditData?.sort((a, b) => b.order - a.order)[0]?.order + 1
    setActivityEditData([...activityEditData,
    {
      lng: e?.lngLat?.lng,
      lat: e?.lngLat?.lat,
      label: dronePhotosNeeded ? `Waypoint ${newPointOrder}` : `S${newPointOrder}`,
      order: newPointOrder,
      activities: [activityType()]
    },
    ]);
    addFlagModeRef.current = 0;
    setAddFlagMode(false);
  }

  React.useEffect(() => {
    if (activityEditData?.length > 0) {
      setTcPoints(activityEditData.length)
    }
  }, [activityEditData])

  React.useEffect(() => {
    if (!tcAboveConfig && workOrderData) {
      setTcAboveConfig(tcAboveConfigs?.find(e => e.name === workOrderData.serviceName));

      workOrderData.parameters?.points
        ? setTcPoints(workOrderData.parameters?.points)
        : setTcPoints(tcAboveConfigs?.find((e) => e.name === workOrderData.serviceName)?.max_points);

      if (workOrderData.parameters?.height) {
        setTcHeightM(workOrderData.parameters?.height)
      } else {
        setTcHeightM(tcAboveConfigs?.find((e) => e.name === workOrderData.serviceName)?.height);
      }

      if (workOrderData.parameters?.buffer) {
        setTcBufferM(workOrderData.parameters?.buffer)
      } else {
        setTcBufferM(tcAboveConfigs?.find((e) => e.name === workOrderData.serviceName)?.buffer);
      }
    }
    // eslint-disable-next-line 
  }, [tcAboveConfigs, workOrderData]);

  const handleEditPointLocation = () => {
    localStorage.setItem('soilEdit', true)
    setDisableUpdateBtn(true)
    setNewPointPrefix(soilSampleNeeded ? 'S' : 'W')
    setSoilEditMode(true)
    setPointEditMode(true)
  }

  const handleCancelEditPointLocation = () => {
    setPointEditMode(false);
    setDisableUpdateBtn(false)
  };

  const isScout = workOrderData.tasks.some((task) => {
    return task.type === "scout"
  });

  const workOrderEditable = () => {
    if (workOrderData && workOrderData.tasks.length > 0) {
      if (workOrderData.status === "scheduled") {
        const scheduled = utcStringToDate(workOrderData.tasks[0].scheduledDate).setUTCHours(0, 0, 0, 0);
        const today = (new Date()).setUTCHours(0, 0, 0, 0);
        const days = (scheduled - today) / (1000 * 3600 * 24);
        if (days > 3) {
          return true;
        }
      }
      if (workOrderData.status === "new") {
        return true;
      }
      if (isScout) {
        return true;
      }

    } else {
      return true; // if no work order data, assume editable so display looks good while loading
    }
    // if we have data and none of the cases are true, then we can't edit this work order
    return false;
  };

  const editMode = pointEditMode;

  const editPlanText = (season) => {
    let text = "";
    if (dronePhotosNeeded) {
      text = season.workOrders.find(wo => wo.id === props.editableId).flags.length === 0 ? "Create Flight Plan" : "Edit Flight Plan"
    }
    else {
      text = "Edit Point Locations"
    }
    return text;
  }

  return (
    <Flex
      flexDirection={"column"}
      alignItems={"stretch"}
      // className={classes.aditWorkOrderRoot}
      className={classes.editWorkOrderRoot}
    >
      {workOrderEditable() ? null : (
        <>
          <div className={`${classes.item} ${classes.contactSection}`}>Contact Us</div>
          <Typography variant="body1" color="textSecondary">
            This Work Order cannot be changed at this time. To request any changes to this work order, call 314-626-4307.
          </Typography>
        </>
      )}

      <Typography className={classes.header} variant={"h4"}>
        Select Service Type
      </Typography>

      <Autocomplete2
        options={services ? services : []}
        getOptionLabel={(o) => o.name}
        labelText={"Select Service *"}
        error={workOrderErrors.serviceId}
        onChange={(_reactEvent, choice) => {
          if (choice && workOrderErrors.serviceId) {
            setWorkOrderErrors(omit(workOrderErrors, "serviceId"));
          }
          setWorkOrderData({
            ...workOrderData,
            serviceId: choice ? choice.id : undefined,
          });
          if (choice.id !== tcAboveServiceId) {
            setTcAboveConfig(null);
          }
        }}
        value={
          services
            ? services.find((s) => s.id === workOrderData.serviceId) || null
            : null
        }
        disabled={true}
      />

      {workOrderData?.serviceId === tcAboveServiceId && (
        <>
          <Typography className={classes.header} variant={"h5"}>
            Select TrueCause Above Type
          </Typography>

          <Autocomplete2
            options={tcAboveConfigs ? tcAboveConfigs : []}
            getOptionLabel={(o) => o.name}
            labelText={"Select Type *"}
            error={workOrderErrors.serviceId}
            onChange={(_reactEvent, choice) => {
              if (choice) {
                setTcAboveConfig(choice);
                setTcPoints(choice.max_points);
                setTcHeightM(choice.default_height);
                setTcBufferM(choice.default_buffer);
              }
            }}
            value={
              tcAboveConfigs && tcAboveConfig
                ? tcAboveConfigs.find((s) => s.id === tcAboveConfig.id) || null
                : null
            }
          />
        </>
      )}
      {tcAboveConfig && (
        <Flex flexDirection="column" alignItems="stretch">
          <Typography variant="body2" className={classes.label}>
            Description:<p>{tcAboveConfig.description}</p>
            <br></br>
          </Typography>
          <Typography variant="body2" className={classes.label}>
            Number of Waypoints (max: {tcAboveConfig.max_points})
          </Typography>
          <NewInput
            placeholder="Waypoints"
            type="number"
            value={tcPoints}
            disabled={true}
            onChange={(e) => {
              if (e.currentTarget.value < 1) {
                setTcPoints(1);
              } else if (e.currentTarget.value <= tcAboveConfig.max_points) {
                setTcPoints(e.currentTarget.value);
              } else {
                setTcPoints(tcAboveConfig.max_points);
              }
            }}
          />
          <Typography variant="body2" className={classes.label}>
            Photo Height (default: {tcAboveConfig.default_height}m)
          </Typography>
          <DistanceInput
            value={tcHeightM}
            onChange={(value) => setTcHeightM(value)}
            disabled={false}
          />
          <Typography variant="body2" className={classes.label}>
            Field Edge Buffer (default: {tcAboveConfig.default_buffer}m)
          </Typography>
          <DistanceInput
            value={tcBufferM}
            onChange={(value) => setTcBufferM(value)}
            disabled={true}
          />
        </Flex>
      )}
      <TextareaWithLabel
        label={"Special Instructions"}
        value={workOrderData.specialInstructions}
        defaultValue={workOrder && workOrder.specialInstructions}
        onChange={(event) => {
          setWorkOrderData({
            ...workOrderData,
            specialInstructions: event.target.value,
          });
        }}
        className={classes.itemWithExtraSpace}
      />
      {workOrderData?.serviceId !== autoSoilMapServiceId && (
        <div className={classes.item}>
          <Typography
            className={clsx(
              classes.item,
              workOrderErrors.dateRange && classes.error
            )}
            color={"textSecondary"}
          >
            Select a requested date. *
          </Typography>
          <NewDatePicker
            value={workOrderData.requestedStartDate}
            onChange={(newValue) => {
              setWorkOrderData({ ...workOrderData, requestedStartDate: newValue });
            }}
          />
        </div>
      )}

      {/* <div className={classes.item}>
        {(Object.keys(seasonErrors).length > 0 ||
          Object.keys(workOrderErrors).length > 0) && (
            <Typography className={clsx(classes.error)}>
              Could not submit. Check your data for errors, above
            </Typography>
          )}
        {workOrderData && workOrderData.tasks.length > 0 && (workOrderData.service === "TrueCause™ Above"
          || workOrderData.service === "TrueCause™ Below" || workOrderData.service === "TrueCause™ Scout") &&
          workOrderData.tasks[0].type === "probe" ? (
          <>
            <Grid
              container
              item
              justifyContents="space-between"
              alignItems="center"
              className={classes.editFlightPathContainer}
            >
              {trueCauseMarkersEditMode ? (
                <>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.editInstructionText}
                    display="block"
                  >
                    1) To move flags, select and drag them.
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.editInstructionText}
                    display="block"
                  >
                    2) Right-click on a flag to select "delete"
                    to remove it.
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.editInstructionText}
                    display="block"
                  >
                    3) Click the "add" button to add a new flag.
                  </Typography>
                  <MuiButton
                    className={`${classes.wideButton} ${classes.addButton}`}
                    startIcon={<AddRoundedIcon />}
                    disabled={
                      editedFlags?.length >=
                      parseInt(process.env?.REACT_APP_MAX_TRUECAUSE_FLAGS)
                    }
                    text={"Add Point"}
                    onClick={() => {
                      const upperLeftCorner = field[0].bounds[0];
                      if (!addedLngLats) {
                        setAddedLngLats([
                          {
                            lng: upperLeftCorner[1],
                            lat: upperLeftCorner[0],
                          },
                        ]);
                      } else {
                        setAddedLngLats([
                          ...addedLngLats,
                          {
                            lng: upperLeftCorner[1],
                            lat: upperLeftCorner[0],
                          },
                        ]);
                      }
                    }}
                  />
                  <MuiButton
                    text={"Cancel"}
                    className={classes.button}
                    onClick={handleCancelEditFlightPath}
                  />
                  <MuiButton
                    text={"Save"}
                    className={classes.button}
                    onClick={async () =>

                      //await editFlightPath(token, selectedWorkOrderId, editedFlags)
                      await taskActivities(token, workOrder?.tasks[0]?.id, { data: { activities: activityEditData } })
                        .catch(async (e) => {
                          console.error(e);
                          alert();
                          handleCancelEditFlightPath();
                          console.error(e);
                        })
                        .then(async (res) => {
                          if (res.result === "success" && res.reprocessed) {
                            setDisableUpdateBtn(false)
                            handleCompleteEditFlightPath();
                          } else {
                            alert();
                            handleCancelEditFlightPath();
                          }
                        })
                    }
                  />
                </>
              ) : (
                <>
                  {workOrderData && workOrderData.status === "new" ? (
                    <MuiButton
                      text={seasons[0].workOrders.find(wo => wo.id === props.editableId).flags.length === 0 ? "Create Flight Plan" : "Edit Flight Plan"}
                      className={classes.wideButton}
                      disabled={!(process.env?.REACT_APP_ENABLE_FLAG_EDITING === "true")}
                      onClick={seasons[0].workOrders.find(wo => wo.id === props.editableId).flags.length === 0 ? handleCreateFlightPath : handleEditFlightPath}
                    />
                  ) : null}
                </>
              )}
            </Grid>

          </>
        ) : null}
      </div> */}

      <div className={classes.item}>
        {(Object.keys(seasonErrors).length > 0 ||
          Object.keys(workOrderErrors).length > 0) && (
            <Typography className={clsx(classes.error)}>
              Could not submit. Check your data for errors, above
            </Typography>
          )}
        {workOrderData && workOrderData.tasks.length > 0 &&
          ["scout", "probe"].includes(workOrderData.tasks[0].type) ? (
          <>
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="center"
              className={classes.editFlightPathContainer}
            >
              {editMode ? (
                <>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.editInstructionText}
                    display="block"
                  >
                    1) To move flags, select and drag them.
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.editInstructionText}
                    display="block"
                  >
                    2) Right-click on a flag to delete or edit it.
                    to remove it.
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    className={classes.editInstructionText}
                    display="block"
                  >
                    3) Click the "Add Point" button to add a new flag by clicking on the map where you want the new flag to be.
                  </Typography>
                  <MuiButton
                    className={`${classes.wideButton} ${classes.addButton}`}
                    startIcon={addFlagMode ? null : <AddRoundedIcon />}
                    variant={addFlagMode ? "contained" : "outlined"}
                    disabled={
                      editedFlags?.length >=
                      parseInt(process.env?.REACT_APP_MAX_TRUECAUSE_FLAGS)
                    }
                    text={addFlagMode ? "Cancel Add Point" : "Add Point"}
                    onClick={() => {
                      if (addFlagModeRef.current > 0) {
                        fieldMap.off('click', addPointHandler);
                        addFlagModeRef.current = 0;
                        setAddFlagMode(false);

                      } else {
                        fieldMap.on('click', addPointHandler);
                        addFlagModeRef.current = 1;
                        setAddFlagMode(true);
                      }
                    }}
                  />
                  <MuiButton
                    text={"Cancel"}
                    className={classes.button}
                    onClick={() => {
                      handleCancelEditPointLocation();
                      //fieldMap.getCanvas().style.cursor = "default";
                    }}
                  />
                  <MuiButton
                    text={"Save"}
                    className={classes.button}
                    onClick={async () => {
                      await taskActivities(token, workOrder?.tasks[0]?.id, { data: { activities: activityEditData } })
                        .catch(async (e) => {
                          handleCancelEditPointLocation();
                          console.error(e);
                        })
                        .then(async (res) => {
                          if (res.success === true) {
                            setActivityEditData(res?.data?.activities);
                            setDisableUpdateBtn(false);
                            handleCancelEditPointLocation();
                          } else {
                            handleCancelEditPointLocation();
                          }
                        })
                    }
                    }
                  />
                </>
              ) : (
                <>
                  {workOrderEditable ? (
                    <MuiButton
                      text={editPlanText(seasons[0])}
                      className={classes.wideButton}
                      disabled={!(process.env?.REACT_APP_ENABLE_FLAG_EDITING === "true")}
                      onClick={seasons[0].workOrders.find(wo => wo.id === props.editableId).tasks[0].activities
                        ? handleEditPointLocation : handleCreateFlightPath}
                    />
                  ) : null}
                </>
              )}

            </Grid>
          </>
        ) : null}
      </div>

      <div className={disableUpdateBtn ? "disable-click" : null}>
        <div className={classes.item}>
          {(Object.keys(seasonErrors).length > 0 ||
            Object.keys(workOrderErrors).length > 0) && (
              <Typography className={clsx(classes.error)}>
                Could not submit. Check your data for errors, above
              </Typography>
            )}
          {workOrderEditable ? (
            <>
              <Button
                className={clsx(buttonClasses.primaryAction, classes.item)}
                fullWidth
                disableRipple
                onClick={submitForm}
              >
                <CalendarTodayIcon style={{ marginRight: "4px" }} />
                Update
              </Button>
            </>
          ) : null}
        </div>
      </div>
      <div className={disableUpdateBtn ? "disable-click" : null}>
        <Flex justifyContent={"space-between"} className={classes.item}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "11px",
              cursor: "pointer",
            }}
            onClick={close}
          >
            <CloseIcon />
            <div style={{ marginTop: "4px" }} onClick={() => handleClose()}>
              Cancel
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "11px",
              cursor: "pointer",
            }}
            onClick={clearForm}
          >
            <DeleteOutlineIcon fontSize={"small"} />
            <div style={{ marginTop: "4px" }}>Clear form</div>
          </div>
        </Flex>
      </div>
    </Flex>
  );
};

export default EditWorkOrderShelf;
