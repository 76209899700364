import React from "react";
import styles from "./Home.module.scss";
import { Flex } from "../../Layouts/Flex";
import { Input } from "../../Helpers/Input/Input";
import { Button } from "../../Helpers";
import { ACTIONS } from "./Home";
import {changePassword} from "../../../controllers/auth";
import Swal from "sweetalert2";

export const ResetPasswordForm = ({ userData, dispatch, props }) => {
  const { location } = window;
  const { token, redirect } = props.match.params;

  const handlePasswordReset = (e) => {
    e.preventDefault();
    if(userData.password === userData.passwordConfirmation){
        changePassword(token, userData.password, userData.passwordConfirmation)
            .then(() => {
                location.assign(`/reset-sign-in${redirect ? `/${redirect}` : "" }`);
            });
    } else {
        Swal.fire({
            icon: "error",
            title: "Oh Oh!",
            text: "Your new password and your confirm password must match.",
        });
    }
  };

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className={styles.ResetPasswordForm}
    >
      <h1>Reset Password</h1>
      <span>
        Your new password must be 8 characters or longer.
      </span>
      <form className={styles.Form} onSubmit={(e) => handlePasswordReset(e)}>
        <Flex className={`${styles.FormRow} ${styles.ResetPasswordInputs}`}>
          <Input
            type="password"
            placeholder="New Password *"
            value={userData.password}
            onChange={(e) =>
              dispatch({
                type: ACTIONS.UPDATE_FIELD,
                payload: { field: "password", value: e.target.value },
              })
            }
            autofocus
            required
            minlength="8"
          />
        </Flex>
        <Flex className={`${styles.FormRow} ${styles.ResetPasswordInputs}`}>
          <Input
            type="password"
            placeholder="Confirm Password *"
            value={userData.passwordConfirmation}
            onChange={(e) =>
              dispatch({
                type: ACTIONS.UPDATE_FIELD,
                payload: {
                  field: "passwordConfirmation",
                  value: e.target.value,
                },
              })
            }
            required
            minlength="8"
          />
        </Flex>
        <Button
          className={styles.ResetPasswordBtn}
          type="submit"
          text="Reset Password"
        />
      </form>
    </Flex>
  );
};
