import * as React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";

import {
  useImageReviewerContent,
  useImageReviewerState,
} from "../../contexts/ImageReviewerContext/ImageReviewerContext";
import { useFarms } from "../../../../../globalState/farmContext";

const dense = true;

const useItemValueStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.text.secondary,
  },
}));

const useSubItemValueStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.text.secondary,
    fontSize: 11,
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 11,
  },
}));

const SummaryListItemValue = ({ title, value, href = "" }) => {
  const classes = useItemValueStyles();

  return (
    <>
      {href && (
        <a style={{ color: "inherit" }} href={href} alt={title}>
          <strong className={classes.title}>{title}</strong>:{" "}
          <span className={classes.text}>{value}</span>
        </a>
      )}
      {!href && (
        <>
          <strong className={classes.title}>{title}</strong>{" "}
          <span className={classes.text}>{value}</span>
        </>
      )}
    </>
  );
};
const SubListItemValue = ({ title, value, href = "" }) => {
  const classes = useSubItemValueStyles();

  return (
    <>
      {href && (
        <a href={href} alt={title}>
          <strong className={classes.title}>{title}</strong>:{" "}
          <span className={classes.text}>{value}</span>
        </a>
      )}
      {!href && (
        <>
          <strong className={classes.title}>{title}</strong>{" "}
          <span className={classes.text}>{value}</span>
        </>
      )}
    </>
  );
};

const useListItemStyles = makeStyles(() => ({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const SummaryListItem = ({ title, value = "N/A", href = "" }) => {
  const classes = useListItemStyles();

  return (
    <ListItem classes={classes}>
      <ListItemText
        primary={
          <SummaryListItemValue href={href} title={title} value={value} />
        }
      />
    </ListItem>
  );
};
const SubListItem = ({ title, value = "N/A", href = "" }) => {
  const classes = useListItemStyles();

  return (
    <ListItem dense={dense} classes={classes}>
      <ListItemText
        dense={dense.toString()}
        primary={<SubListItemValue href={href} title={title} value={value} />}
      />
    </ListItem>
  );
};

const getFormattedTemp = (temperature) => {
  return temperature ? `${temperature.toFixed(2)}°` : undefined;
};

const getFormattedHumidity = (humidity) => {
  return humidity ? humidity.toFixed(2) : undefined;
};

const getFormattedCo2 = (co2) => {
  return co2 ? `${co2}ppm` : undefined;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    background: theme.palette.background.secondary,
  },
}));

export const ShowImageReviewerShelfSummary = () => {
  const { selectedImages, selectedImagesById } = useImageReviewerState();
  const { meta } = useImageReviewerContent();
  const classes = useStyles();
  const { allFarmsDetails } = useFarms();
  const dense = true;
  const selectedImage = selectedImagesById[selectedImages[0]];
  //
  if (!selectedImage) {
    return null;
  }

  var usbCamera = "n/a";
  var xAcc = "n/a";
  var zAcc = "n/a";
  var yGyro = "n/a";
  if (selectedImage.mlMetaData) {
    if (selectedImage.mlMetaData.exif) {
      const exifData = selectedImage.mlMetaData.exif;
      if (exifData.camera_model) {
        if (exifData.camera_model === "5880") {
          usbCamera = "USB 3";
        } else {
          usbCamera = "USB 2";
        }
      }
      if (exifData.imu) {
        xAcc = exifData.imu[17];
        zAcc = exifData.imu[19];
        yGyro = exifData.imu[7] * (180.0 / 3.141592653589793238463);
      }
    }
  }

  const findFieldId = (fieldName) => {
    const farm = allFarmsDetails.find((farm) =>
      farm.fields.some((a) => a.title === fieldName)
    );
    const field = farm?.fields.find((a) => a.title === fieldName);
    return field?.id;
  };

  const findFarmId = (fieldName) => {
    const farm = allFarmsDetails.find((farm) =>
      farm.fields.some((a) => a.title === fieldName)
    );
    return farm?.id;
  };

  const { environmentalData } = selectedImage;
  return (
    <List dense={dense} classes={classes} disablePadding>
      <SummaryListItem
        href={`/farm/${findFarmId(meta.fieldName)}/field/${findFieldId(
          meta.fieldName
        )}`}
        title="Field"
        value={meta.fieldName}
      />
      <SummaryListItem title="Date" value={selectedImage.date} />
      <SummaryListItem
        title="Growth Stage"
        value={selectedImage.growthStage}
      />
      <SummaryListItem
        title="Temp"
        value={getFormattedTemp(environmentalData.tipCelsius)}
      />
      <SummaryListItem
        title="Humidity"
        value={getFormattedHumidity(
          environmentalData.externalHumidityPercentage
        )}
      />
      <SummaryListItem
        title="CO2"
        value={getFormattedCo2(environmentalData.carbonDioxidePpm)}
      />

      <SummaryListItem title="Camera" value={usbCamera} />
      <ListItem>
        <List dense={dense} classes={classes} disablePadding>
          <SubListItem title="X-Acc (m/s)" value={xAcc} />
          <SubListItem title="Z-Acc (m/s)" value={zAcc} />
          <SubListItem title="Y-Gyro (d/s)" value={yGyro} />
        </List>
      </ListItem>
    </List>
  );
};
