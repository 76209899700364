import * as React from "react";
import { useLocation } from "react-router-dom";
import { getCookie } from "../../src/Components/Helpers"

export const AuthContext = React.createContext({ token: '' });

const getAccessToken = () => {
  return getCookie("authToken")
};

const getUserId = () => {
  return getCookie("userId")
};

export const AuthProvider = props => {
  const location = useLocation();
  const { pathname } = location;
  const [token, setToken] = React.useState(null);
  const [userId, setUserId] = React.useState(null);

  React.useEffect(() => {
    const authToken = getAccessToken();
    setToken(authToken);
    setUserId(getUserId());

    if (
      (!authToken || authToken === "undefined") &&
      pathname !== "/" &&
      !pathname.includes("/sign-up") &&
      !pathname.includes("/forgot-password") &&
      !pathname.includes("/reset-sign-in") &&
      !pathname.includes("/reset-password") &&
      !pathname.includes('/account-setup')
    ) {
      window.sessionStorage.setItem('redirectUrl', location.pathname)
      window.location.assign(`/`);
    }
    // eslint-disable-next-line
  }, []);

  const value = React.useMemo(() => {
    return {
      token,
      setToken,
      userId,
      setUserId
    };
    // eslint-disable-next-line
  }, [token]);

  // TODO: validate that the cookie is still valid. Maybe by calling
  // an dummy /status endpoint.

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};
