import React, { useState, createContext, useEffect } from "react";
export const ThemeContext = createContext();

export const ThemeProvider = (props) => {
    const [darkTheme, setDarkThemeRaw] = useState(true);
    const [localStorageChecked, setLocalStorageChecked] = useState(false)

    // on page load wait to render anything until we check local storage 
    // for the theme (to prevent a flash). if it's not set in 
    // local, then default to dark mode will be lifted up to this context
    // from the default parameter of makeAkerTheme (as of 4/19/2021).
    useEffect(() => {
        if(Object.keys(window.localStorage).includes('darkMode')) {
            setDarkThemeRaw(JSON.parse(window.localStorage.darkMode))
        }
        setLocalStorageChecked(true)
    }, [])

    // modify the state setter to also update local storage
    const setDarkTheme = (darkMode) => {
        window.localStorage.setItem('darkMode', darkMode)
        setDarkThemeRaw(darkMode)
    }

    return (
        localStorageChecked
        ?
        <ThemeContext.Provider
            value={{
                darkTheme, setDarkTheme
            }}
        >
            {props.children}
        </ThemeContext.Provider>
        :
        <></>
    );
};
