import React from "react";
import { Grid, IconButton, Typography, makeStyles } from "@material-ui/core";
import templateOriginal from "../../../../../../assets/templates/template_original.jpg";
import templateRevx from "../../../../../../assets/templates/template_revx.jpg";
import templateBasf from "../../../../../../assets/templates/template_basf-wheat.jpg";
import templateModern from "../../../../../../assets/templates/template_modern.jpg";
import templateTrueCause from "../../../../../../assets/templates/template_truecause.jpg";
import cn from "clsx";
import { useField } from "../../../../../../globalState/fieldContext";
import { useCustomerAccounts } from "../../../../../../globalState";

const useStyles = makeStyles((theme) => ({
  templateImg: {
    height: "13.25rem",
    width: "10.875rem",
    borderRadius: "4px",
    opacity: 0.9,
  },
  selectedTemplate: {
    border: `3px solid  ${theme.palette.colors.blue}`,
    opacity: 1,
  },
}));

export const ChooseTemplate = ({ parentClasses, newReport, setNewReport }) => {
  const classes = useStyles();
  const { field } = useField();
  const customerAccounts = useCustomerAccounts();

  const reportTemplates = React.useMemo(() => {
    if (customerAccounts) {
      const selectedCustomer = customerAccounts.filter(
        (cust) => cust.id === field[0].farm.customerAccountId
      );
      return selectedCustomer[0].reportTemplates;
    }
  }, [customerAccounts, field]);

  const handleTemplateClick = (template) => {
    const tempNewReport = {
      ...newReport,
      template,
    };
    if (!newReport.flights.length) {
      tempNewReport.flights = [{}];
    }
    setNewReport(tempNewReport);
  };

  const templateData = {
    original: { label: "Original", img: templateOriginal },
    modern: { label: "Modern", img: templateModern },
    revx: { label: "Revx", img: templateRevx },
    RevXPreHarvestReport: {
      label: "Revx Pre-harvest Report",
      img: templateRevx,
    },
    "basf-wheat": { label: "BASF Wheat", img: templateBasf },
    TrueCause: { label: "TrueCause", img: templateTrueCause },
    "TrueCause Subscription": {
      label: "TrueCause Subscription",
      img: templateTrueCause,
    },
  };

  return (
    <Grid container item direction="column" className={parentClasses.section}>
      <Typography variant="h4">Choose a Template</Typography>
      <Typography
        color="textSecondary"
        component="span"
        style={{ marginBottom: "0.3rem" }}
      >
        Please select a report template from the options below:
      </Typography>
      <Grid container item>
        {reportTemplates.map((template, i) => {
          const thisTemplate = templateData[template.name];
          const templateClass =
            newReport.template && newReport.template.id
              ? newReport.template.id === template.id &&
              classes.selectedTemplate
              : null;

          return (
            <Grid key={i} item>
              <IconButton
                edge="start"
                onClick={() => handleTemplateClick(template)}
              >
                <img
                  src={thisTemplate.img}
                  alt={`${thisTemplate.label} template`}
                  className={cn(classes.templateImg, templateClass)}
                />
              </IconButton>
              <Typography variant="subtitle2" color="textSecondary">
                {thisTemplate.label}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
