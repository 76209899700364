import React from "react";
import Swal from "sweetalert2";
import { updateField } from "../../../controllers/fields";
import { Flex } from "../../Layouts/Flex";
import * as turf from "@turf/turf";
import { Button, Input } from "@material-ui/core";
import { useField } from "../../../globalState/fieldContext";
import { useAuth } from "../../../hooks/useAuth";
import styles from "./Field.module.scss";
import { IGeometryToEdit } from "./FieldInterfaces";

interface Props {
  geometryToEdit: IGeometryToEdit | null;
  setGeometryToEdit: (_: IGeometryToEdit | null) => void;
  fieldTitle: string;
  setFieldTitle: (_: string) => void;
  setDrawingEnabled: (_: boolean) => void;
  setIsEditing: (_: boolean) => void;
  styles: object;
}

export const EditField = ({
  geometryToEdit,
  fieldTitle,
  setDrawingEnabled,
  setGeometryToEdit,
  setIsEditing,
  setFieldTitle,
}: Props) => {
  const {
    field,
    setField,
  }: { field: any; setField: (_: any) => void } = useField();
  const { token } = useAuth();

  const saveFieldData = async () => {
    const bounds = turf.bbox(geometryToEdit!.polygon);
    const data = {
      fieldId: field[0].id,
      fieldTitle,
      polygon: JSON.stringify(geometryToEdit!.polygon),
      size: geometryToEdit!.acres,
      bounds: [
        [bounds[1], bounds[0]],
        [bounds[3], bounds[2]],
      ],
    };
    await updateField(token, field[0].id, data)
      .then((responseData) => {
        setField([{ ...responseData, show: true, wasEdited: true }]);
        resetFieldAndMap();
        Swal.fire({
          toast: true,
          title: "Updated field successfully",
          text: "Successfully updated field " + field[0].title,
          icon: "success",
          timerProgressBar: true,
          timer: 5000,
          position: "bottom-right",
        });
      })
      .catch((e) => {
        console.error(e);
        Swal.fire({
          title: "An issue occurred updating the field",
          text:
            "An issue occurred when saving the updated field data. Please try again later.",
          icon: "error",
        });
      });
    window.location.reload();
  };

  const onCancelEditField = () => {
    resetFieldAndMap();
    setField([{ ...field[0], show: true }]);
  };

  const resetFieldAndMap = () => {
    setDrawingEnabled(false);
    setGeometryToEdit(null);
    setIsEditing(false);
    const findMe = document.querySelector('.mapboxgl-ctrl-geolocate')
    if (findMe) {
      findMe.removeAttribute('disabled')
    }
  };

  return (
    <div className={styles.EditContainer}>
      <Flex justifyContent="start">
        <span
          className={styles.CursorPointer}
          onClick={() => onCancelEditField()}
        >
          <i className="fa fa-chevron-left"></i> {field[0].title}
        </span>
      </Flex>
      <hr />
      <Flex alignItems="start" className={styles.m15} flexDirection="column">
        <h1>Edit Field</h1>
      </Flex>
      <hr />

      <Flex className={styles.m15} flexDirection="column">
        <span>Field Name</span>
        <Input
          className={styles.input}
          type="text"
          defaultValue={field[0].title}
          onChange={(e) => setFieldTitle(e.target.value)}
        />
      </Flex>

      <Flex
        flexDirection="row"
        justifyContent="space-between"
        className={styles.w100}
      >
        <Button
          variant="outlined"
          color="inherit"
          className={styles.DeleteButton}
          onClick={() => onCancelEditField()}
        >
          Cancel
        </Button>
        <Button
          onClick={saveFieldData}
          variant="contained"
          color="primary"
          style={{ marginLeft: 15, width: "150px" }}
        >
          Save
        </Button>
      </Flex>
    </div>
  );
};
