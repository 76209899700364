import React from "react";
import cn from "clsx";
import { makeStyles, Button as MatUIButton } from "@material-ui/core";

import styles from "./Button.module.scss";

export const Button = ({
  text,
  icon = null,
  click = () => { },
  className = "",
  type = "",
  disabled = false,
  variant = "primary",
  ...restProps
}) => {
  const buttonStyle = disabled
    ? { cursor: "not-allowed", backgroundColor: "#ccc" }
    : {};
  return (
    <button
      style={buttonStyle}
      className={cn(styles.Button, className, styles[`Button--${variant}`])}
      onClick={click}
      type={type}
      disabled={disabled}
      {...restProps}
    >
      {icon && <i>{icon}</i>}
      {text}
    </button>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    height: "3rem",
  },
}));

export const MuiButton = ({ text, className = "", ...props }) => {
  const classes = useStyles();

  return (
    <MatUIButton
      variant="outlined"
      {...props}
      className={cn(classes, className)}
    >
      {text}
    </MatUIButton>
  );
};

export const useButtonStyles = makeStyles((theme) => ({
  primaryAction: {
    backgroundColor: theme.palette.colors.blue,
    color: "black",
    "&:hover": {
      backgroundColor: `${theme.palette.colors.blue}99`,
      "&:active": {
        backgroundColor: `${theme.palette.colors.blue}CC`,
      },
    },
  },
}));
