import axios from "axios";
const isStagingSite = process.env.REACT_APP_ENV.includes("staging");
const isDevSite = process.env.REACT_APP_ENV.includes("development");
const baseUrl = isDevSite
    ? process.env.REACT_APP_BACK_OFFICE_URL
    : isStagingSite
        ? process.env.REACT_APP_BACK_OFFICE_URL_STAGING
        : process.env.REACT_APP_BACK_OFFICE_URL_PRODUCTION;
const aws = require("aws-sdk");

//const { baseUrl } = process.env

export const getField = async (authToken, fieldId) => {
    const result = await axios({
        method: 'GET',
        url: `${baseUrl}/api/v1/fields/${fieldId}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
        },
    })
    return result.data.data
}

export const getFieldSeasons = async (authToken, fieldId) => {
    const result = await axios({
        method: 'GET',
        url: `${baseUrl}/api/v1/fields/${fieldId}/seasons`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
        },
    })
    return result.data.data
}

export const createField = async (authToken, field) => {
    return await axios({
        method: "POST",
        url: `${baseUrl}/api/v1/fields`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
        },
        data: { data: field }
    }).then(res => {
        return res.data
    }).catch(err => {
        console.log('err', err);
        return false;
    })
}

export const updateField = async (authToken, fieldId, data) => {
    return await axios({
        method: "PATCH",
        url: `${baseUrl}/api/v1/field/${fieldId}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
        },
        data: { data }
    }).then(res => {
        return res.data.data
    }).catch(err => {
        console.log('err', err);
        return false;
    })
}

export const getProbeData = async (authToken, seasonId) => {
    return await axios({
        method: "GET",
        url: `${baseUrl}/api/v1/seasons/${seasonId}/published_probe_observations`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
        },
    }).then(res => {
        return res.data.data
    }).catch(err => {
        console.log('err', err);
        return false;
    })
}

export const getSeasonReports = async (authToken, seasonId) => {
    return await axios({
        method: "GET",
        url: `${baseUrl}/api/v1/seasons/${seasonId}/reports`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
        },
    }).then(res => {
        return res.data.data
    }).catch(err => {
        console.log('err', err);
        return false;
    })
}

export const saveAerialImageryReport = async (authToken, reportData) => {
    const method = !reportData.id ? 'POST' : 'PUT'
    const url = !reportData.id ? 'aerial_imagery_reports' : `aerial_imagery_reports/${reportData.id}`
    if (typeof reportData.company_logo === 'object') {
        const fileSuffix = reportData.company_logo.name.match(/.(jpg|jpeg|png|gif)$/i)[0]
        const logoUrl = await new Promise((resolve, reject) => {
            aws.config = {
                region: "us-east-1",
                accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
                secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
            };
            const s3 = new aws.S3();
            let s3Params = {
                Bucket: 'customer_logos',
                Key: `uploads/uploads/${reportData.custId}${fileSuffix}`,
                Body: reportData.company_logo,
                ACL: "public-read",
            };
            var options = { partSize: 10 * 1024 * 1024, queueSize: 1 };
            s3.upload(s3Params, options, (err, data) => {
                if (err) console.log(err, err.stack);
                return resolve(data.Location)
            })
        })
        reportData.company_logo = logoUrl
    }

    return await axios({
        method,
        url: `${baseUrl}/api/v1/${url}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
        },
        data: { data: reportData }
    }).then(res => {
        return res.data.data
    }).catch(err => {
        console.log('err', err);
        return false;
    })
}

export const deleteReport = async (authToken, reportId) => {
    return await axios({
        method: "DELETE",
        url: `${baseUrl}/api/v1/aerial_imagery_reports/${reportId}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
        },
    }).then(res => {
        return res.data.data
    }).catch(err => {
        console.log('err', err);
        return false;
    })
}
