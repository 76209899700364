import { useEffect, useState } from "react";
import { Marker, Popup } from "mapbox-gl";
import styles from "./Markers.module.scss";
import { useTrueCauseStressItemFilterState } from "../Views/Field/FieldShelf/TrueCause/FilterTrueCauseStressItem";
import { useField } from "../../globalState/fieldContext";
import { useAuth } from "../../hooks/useAuth";
import { generateFlags } from "../../controllers/workOrder";

const noop = () => { };

export const TrueCauseFlags = ({
  map,
  flags: initialFlags,
  containerId,
  onFlagHover = noop,
}) => {
  const {
    trueCauseMarkersEditMode,
    selectedSeason,
    updateSeasons,
    editedLngLats,
    setEditedLngLats,
    deletedPinNumbers,
    setDeletedPinNumbers,
    setEditedFlags,
    addedLngLats,
    selectedWorkOrderId,
  } = useField();

  const { token } = useAuth();

  const [mapMarkersAdded, setMapMarkersAdded] = useState([]);

  const state = useTrueCauseStressItemFilterState();

  const addEditedLngLats = (lng, lat, id) => {
    if (editedLngLats)
      setEditedLngLats({ ...editedLngLats, [id]: { lng, lat } });
    else setEditedLngLats({ [id]: { lng, lat } });
  };

  const addDeletedPinNumbers = (id) => {
    if (deletedPinNumbers) setDeletedPinNumbers([...deletedPinNumbers, id]);
    else setDeletedPinNumbers([id]);
  };

  // console.log(editedFlags);

  const divElement = document.createElement("div");
  //divElement.style = "padding: 0.5rem;";
  const deleteButton = document.createElement("button");
  deleteButton.innerHTML = `<button class={styles.MapPopUpButton}>Delete Flag</button>`;
  divElement.appendChild(deleteButton);

  const popup = new Popup();

  const attachEventListener = (markerId, flagId) => {
    deleteButton.addEventListener("click", () => {
      addDeletedPinNumbers(flagId);
      popup.remove();
    });
  };

  useEffect(() => {
    let flags = initialFlags;
    let tempEditedFlags = [];

    if (trueCauseMarkersEditMode) {
      // get flags from selected work order
      let workOrder = selectedSeason.workOrders.find(({ id }) => id === selectedWorkOrderId);
      let workOrderFlags = workOrder?.flags || '';

      if ((workOrderFlags === [])) {
        (async function () {
          await generateFlags(token, selectedWorkOrderId);
          await updateSeasons();
          workOrder = selectedSeason.workOrders.find(({ id }) => id === selectedWorkOrderId);
          workOrderFlags = workOrder.flags;
        })();
      }

      for (let flag of workOrderFlags) {
        tempEditedFlags.push({
          pinNumber: flag.label,
          issues: [],
          show: true,
          location: [flag.location.lng, flag.location.lat],
        });
      }

      //Process added flags
      if (addedLngLats) {
        const nextPinNumber = Math.max(
          ...tempEditedFlags.map((flag) => flag.pinNumber)
        );
        for (let i = 0; i < addedLngLats.length; i++) {
          tempEditedFlags.push({
            pinNumber: nextPinNumber + i + 1,
            issues: [],
            show: true,
            location: [addedLngLats[i].lng, addedLngLats[i].lat],
          });
        }
      }

      //Process edited flags
      if (editedLngLats && Object.keys(editedLngLats)?.length) {
        for (let flag of tempEditedFlags) {
          if (editedLngLats[flag.pinNumber]) {
            flag.location = [
              editedLngLats[flag.pinNumber]?.lng,
              editedLngLats[flag.pinNumber]?.lat,
            ];
          }
        }
      }

      //Process deleted flags
      if (deletedPinNumbers) {
        tempEditedFlags = tempEditedFlags.filter((flag) => {
          return !deletedPinNumbers.includes(flag.pinNumber);
        });
      }

      flags = tempEditedFlags;

      let tempAddedFlags = [];
      for (let flag of tempEditedFlags) {
        tempAddedFlags.push({ lat: flag?.location[1], lng: flag?.location[0] });
      }

      setEditedFlags(tempAddedFlags);
    }

    if (map && flags) {
      if (flags && mapMarkersAdded.length > 0) {
        mapMarkersAdded.forEach((marker) => {
          marker.remove();
        });
      }

      let markersAdded = [];
      for (let flag of flags) {
        // adding markers is "show" === true, or "show" is not present (for code in image reviewer)
        if (flag.show || !Object.keys(flag).includes("show")) {
          let aiFlagShown = false;
          if (flag.types) {
            for (let extraData of flag.types) {
              if (extraData.images) {
                if (typeof extraData.images == "object") {
                  for (let imageData of extraData.images) {
                    if (imageData.mlMetaData) {
                      if (imageData.mlMetaData["prediction"]) {
                        if (
                          imageData.mlMetaData["prediction"].includes(
                            "_Disease"
                          ) ||
                          imageData.mlMetaData["prediction"].includes("_Pest")
                        ) {
                          aiFlagShown = true;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          const onFlagClick = (flag) => {
            let elementToScrollTo;
            try {
              // image reviewer
              elementToScrollTo = document.getElementById(
                `img-${flag?.types[0]?.images[0].id}`
              );
            } catch (e) {
              if (e instanceof TypeError) {
                // field view
                elementToScrollTo = document.getElementById(
                  `img-${flag?.issues[0]?.images[0].id}`
                );
              }
            }
            if (elementToScrollTo)
              elementToScrollTo.scrollIntoView({ behavior: "smooth" });
          };
          const marker = document.createElement("div");
          // if no pinNumber don't append  text
          if (flag.pinNumber) {
            marker.appendChild(document.createTextNode(flag.pinNumber));
            marker.setAttribute("title", `Flag ${flag.pinNumber}`);
          }
          if (aiFlagShown) {
            const aiFlag = document.createElement("div");
            aiFlag.appendChild(document.createTextNode("AI"));
            aiFlag.className = styles.Div__unhealthy;
            marker.appendChild(aiFlag);
          }
          // if flag click function add styling to indicate clickable, and event listener for callback
          if (onFlagClick) {
            marker.style.cursor = "pointer";
            marker.addEventListener("click", (e) => {
              onFlagClick(flag);
              e.stopPropagation();
            });
          }

          marker.id = `${containerId}-flag-marker-${flag.pinNumber}`;
          let isSelected = false;
          const flagData = flag.types ?? flag.issues;
          if (state.currentHovered && flagData && flagData.length) {
            isSelected = flagData.some(
              (issue) =>
                issue &&
                Array.isArray(issue.images) &&
                issue.images.some((image) => image.id === state.currentHovered)
            );
          }

          if (trueCauseMarkersEditMode) {
            marker.addEventListener("contextmenu", (e) => {
              e.preventDefault();
              e.stopPropagation();
              popup
                .setDOMContent(divElement)
                .setLngLat((editedLngLats == null) ? flag.location : editedLngLats[flag.pinNumber] || flag.location)
                .on("open", () => {
                  attachEventListener(marker.id, flag.pinNumber);
                })
                .addTo(map);
            });
          }

          marker.className = !isSelected
            ? styles.TrueCauseFlag
            : styles.TrueCauseFlag__Hovered;

          if (onFlagHover) {
            marker.style.cursor = "pointer";
            marker.addEventListener("mouseenter", () => onFlagHover(flag));
          }

          if (editedLngLats && editedLngLats[flag.pinNumber]) {
            marker.style.color = "red";
          }

          const markerOptions = {
            element: marker,
            draggable: trueCauseMarkersEditMode,
          };

          const TrueCauseMarker = new Marker(markerOptions)
            .setLngLat(flag.location)
            .addTo(map);

          TrueCauseMarker.on("dragend", () => {
            addEditedLngLats(
              TrueCauseMarker.getLngLat().lng,
              TrueCauseMarker.getLngLat().lat,
              flag.pinNumber
            );
          });

          TrueCauseMarker.on("dragstart", () => {
            popup.remove();
          });

          markersAdded.push(marker);
        }
        if (!flag.show) {
          // removing markers where "show" was changed to false
          mapMarkersAdded.forEach((marker) => {
            if (`${containerId}-flag-marker-${flag.pinNumber}` === marker.id)
              marker.remove();
          });
        }
      }

      setMapMarkersAdded(markersAdded);
    }
    //eslint-disable-next-line
  }, [
    map,
    state.currentHovered,
    trueCauseMarkersEditMode,
    editedLngLats,
    deletedPinNumbers,
    addedLngLats,
    initialFlags,
    selectedSeason?.flags,
  ]);

  return null;
};
