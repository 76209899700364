import * as React from "react";
import MUIAutocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    background: theme.palette.background.tertiary,
    ...theme.typography.body1,
  },
  input: {
    backgroundColor: theme.palette.background.tertiary,
  },
  listbox: {
    background: theme.palette.background.tertiary,
  },
  popupIndicator: {
    color: theme.palette.text.primary,
  },
  clearIndicator: {
    color: theme.palette.text.primary,
  },
}));

const defaultRenderInput = (params) => {
  return <AutocompleteInput {...params} variant="outlined" />;
};

const defaultGetOptionLabel = (option) => option.label;

export const Autocomplete = ({
  getOptionLabel = defaultGetOptionLabel,
  renderInput,
  popupIcon,
  ...props
}) => {
  const classes = useStyles();

  return (
    <MUIAutocomplete
      {...props}
      classes={classes}
      getOptionLabel={getOptionLabel}
      renderInput={renderInput || defaultRenderInput}
      popupIcon={popupIcon || <KeyboardArrowDownIcon />}
    />
  );
};

const AutocompleteInput = (props) => {
  return <TextField {...props} variant="outlined" />;
};

Autocomplete.Input = AutocompleteInput;

const useInputLabelStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
  },
  shrink: {
    transform: `translate(0px, -18px) scale(0.75) !important`,
  },
}));

/**
 * Renders a modified MUI Autocomplete field where the label when active or selected is
 * compeltely outside of (above) the text input, styled to match theme
 *
 * @param props.getOptionLabel (optional) function matching MUI getOption label signature, default is to use option.label (pass through)
 * @param props.popUpIcon (optional) endAdornment (icon to open menu) component, default is down arrow (pass through)
 * @param props.labelText (optional) label text to use as placeholder, or field label if active or selected, defaults to "Choose one..."
 * @param props.renderInput (optional) recommend against overriding, default is styled to match theme
 * @param props.inputClassName (optional) used to style the TextField
 * @param props (...props) (optional) passed through to MUI, be sure to include options!
 */

export const Autocomplete2 = ({
  getOptionLabel = defaultGetOptionLabel,
  popupIcon,
  labelText = "Choose one...",
  renderInput = undefined,
  inputClassName = "",
  ...props
}) => {
  const classes = useStyles();
  const inputLabelClasses = useInputLabelStyles();

  const getRenderInputWithLabel = (label) => {
    return (params) => {
      return (
        <TextField
          {...params}
          error={Boolean(props.error)}
          helperText={props.error}
          margin={"normal"}
          label={label}
          variant="outlined"
          InputProps={{ ...params.InputProps, notched: false }}
          InputLabelProps={{
            ...params.InputLabelProps,
            classes: inputLabelClasses,
          }}
          className={inputClassName}
          onKeyDown={e => { e.stopPropagation() }}
          onClick={e => { e.stopPropagation() }}
        />
      );
    };
  };

  return (
    <MUIAutocomplete
      {...props}
      classes={classes}
      getOptionLabel={getOptionLabel}
      renderInput={renderInput || getRenderInputWithLabel(labelText)}
      popupIcon={popupIcon || <KeyboardArrowDownIcon />}
      onOpen={e => { e.stopPropagation(); }}
      onClose={e => { e.stopPropagation(); }}
    />
  );
};
