import React from "react";
import { Link } from "react-router-dom";
import { Table } from "../../../Helpers/Table/Table";
import { useApi } from "../../../../hooks/useApi";
import { Grid } from "@material-ui/core";
import { SettingsHeading } from "../Account";

export const ImageReviewAccounts = (props) => {
  const { data } = useApi(`/api/v1/image_review/reviewer_count_summary`);

  return (
    <Grid container item direction="row" spacing={4}>
      <Grid item xs={12}>
        <SettingsHeading text="Image Reviewer Access" />
      </Grid>
      <Grid item xs={12}>
        <Table
          columns={[
            {
              Header: "Name",
              accessor: (c) => {
                return (
                  <Link to={`${props.match.path}customer-account/${c.id}`}>
                    {c.name}
                  </Link>
                );
              },
              sortable: false,
            },
            {
              Header: "# of users",
              accessor: (c) => {
                return (
                  <Link to={`${props.match.path}customer-account/${c.id}`}>
                    {c.reviewerCount}
                  </Link>
                );
              },
              sortable: false,
            },
          ]}
          data={
            (data && data.sort((a, b) => a.name.localeCompare(b.name))) || []
          }
          dataLoaded={!!data}
        ></Table>
      </Grid>
    </Grid>
  );
};
