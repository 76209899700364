import React from "react";
import PropTypes from "prop-types";
import * as turf from "@turf/turf";
import circleImage from '../../assets/roundImage.png'

export const GeometriesLayer = ({
  map,
  containerId,
  bounds,
  polygonBorderSize,
  geometries,
  removeLayer,
  drawingEnabled,
  refreshMap,
  setRefreshMap,
  selectedMapStyle,
  offset,
  showMarkers,
  mapLoaded,
  fitBounds = true,
}) => {

  React.useEffect(() => {
    if (geometries && mapLoaded) {
      for (let polygon of geometries) {
        const polygonId = `${containerId}-polygon-${polygon.id}`;
        const fillId = polygonId + "-fill";
        const lineId = polygonId + "-line";
        const symbolId = polygonId + "-symbol";
        const polygonPointId = polygonId + "-label-point";
        if (
          !polygon.show &&
          (map.getSource(polygonId) || map.getSource(symbolId))
        ) {
          // Remove polygon
          removeLayer(fillId);
          removeLayer(lineId);
          removeLayer(symbolId);
          removeLayer(polygonPointId);
          map.removeSource(polygonId);
        } else if (
          polygon.show &&
          !map.getSource(polygonId) &&
          !map.getSource(symbolId)
          // && !drawingEnabled
        ) {
          // Add polygon
          addSource(polygonId, polygon.geometry).then(() =>
            addPolygonLayerToMap(
              polygon,
              polygonId,
              fillId,
              lineId,
              symbolId,
              polygonPointId
            )
          );
        } else if (
          polygon.show &&
          map.getSource(polygonId) &&
          !drawingEnabled &&
          polygon.wasEdited
        ) {
          // Re-draw polygon
          // Must have to update polygon shape after editing
          removeLayer(fillId);
          removeLayer(lineId);
          removeLayer(symbolId);
          removeLayer(polygonPointId);
          map.removeSource(polygonId);
          addSource(polygonId, polygon.geometry).then(() =>
            addPolygonLayerToMap(
              polygon,
              polygonId,
              fillId,
              lineId,
              symbolId,
              polygonPointId
            )
          );
        }
      }

      setRefreshMap(false);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [geometries, drawingEnabled, refreshMap]);

  const hashCode = function (s) {
    return Math.abs(s.split("").reduce(function (a, b) { a = ((a << 5) - a) + b.charCodeAt(0); return a & a }, 0));
  }

  let clickedStateId = null;
  let clickedStatePolygonId = null;

  const addSource = async (id, geometry) => {
    await map.addSource(id, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry,
            properties: { selected: false },
            id: hashCode(id), //Math.random() * 1024 * 1024
          },
        ],
      },
    });
  };

  const addPolygonLayerToMap = (
    polygon,
    polygonId,
    fillId,
    lineId,
    symbolId,
    polygonPointId
  ) => {
    let opacity = polygon.opacity ? polygon.opacity : 0.1
    let selectedOpacity = polygon.selectedOpacity ? polygon.selectedOpacity : 0.7
    let geometryColor = polygon.color
      ? polygon.color
      : selectedMapStyle === "basic"
        ? "#1e1e1e"
        : "white";
    const paint = {
      "line-color": [
        "case",
        ["boolean", ["feature-state", "selected"], false],
        "white",
        geometryColor,
      ],
      "line-width": [
        "case",
        ["boolean", ["feature-state", "selected"], false],
        4,
        polygonBorderSize ? polygonBorderSize : 2,
      ],
    };
    map
      .addLayer({
        id: fillId,
        type: "fill",
        source: polygonId,
        minzoom: 13,
        paint: {
          "fill-color": geometryColor,
          "fill-opacity": [
            "case",
            ["boolean", ["feature-state", "selected"], false],
            selectedOpacity,
            opacity,
          ],
        },
      })
      .addLayer({
        id: lineId,
        type: "line",
        source: polygonId,
        minzoom: 13,
        paint: paint,
        ///paint: !polygon.color ? { ...paint } : paint,
      });

    // When the user clicks the mouse in the state-fill layer, we'll update the
    // feature state for the feature under the mouse.
    if (!polygon.farm) {
      map.on("click", fillId, (e) => {
        if (!e.originalEvent.defaultPrevented) {
          e.originalEvent.preventDefault();
          if (e.features.length > 0) {
            if (clickedStateId != null) {
              map.setFeatureState(
                { source: clickedStatePolygonId, id: clickedStateId },
                { selected: false }
              );
              if (clickedStateId === e.features[0].id) {
                clickedStateId = null;
              } else {
                if (!e.originalEvent.cancelBubble) {
                  clickedStateId = e.features[0].id;
                  clickedStatePolygonId = polygonId;
                  map.moveLayer(e.features[0].layer.id);
                  map.setFeatureState(
                    { source: polygonId, id: clickedStateId },
                    { selected: true }
                  );
                  e.originalEvent.cancelBubble = true;
                }
              }
            } else {
              clickedStateId = e.features[0].id;
              clickedStatePolygonId = polygonId;
              map.moveLayer(e.features[0].layer.id);
              map.setFeatureState(
                { source: polygonId, id: clickedStateId },
                { selected: true }
              );
            }
          }
        }
      });

      //   map.on('mousemove', fillId, (e) => {
      //     if (!clickedStateId) {
      //       if (e.features.length > 0) {
      //         if (hoveredStateId !== null) {
      //           map.setFeatureState(
      //             { source: polygonId, id: hoveredStateId },
      //             { selected: false }
      //           );
      //         }
      //         hoveredStateId = e.features[0].id;
      //         map.setFeatureState(
      //           { source: polygonId, id: hoveredStateId },
      //           { selected: true }
      //         );
      //       }
      //     }
      //   });

      //   // When the mouse leaves the state-fill layer, update the feature state of the
      //   // previously hovered feature.
      //   map.on('mouseleave', fillId, () => {
      //     if (!clickedStateId){
      //       if (hoveredStateId !== null) {
      //         map.setFeatureState(
      //           { source: polygonId, id: hoveredStateId },
      //           { selected: false }
      //         );
      //       }
      //       hoveredStateId = null;
      //   }
      //   });
    }

    // don't show soil markers
    if (showMarkers && polygon.geometry && polygon.type !== "soil") {
      // Make a point feature for displaying the text;
      const centroid = turf.centroid(polygon.geometry);
      if (polygon.marker && !map.getSource(polygonPointId)) {
        map.addSource(polygonPointId, {
          type: "geojson",
          data: centroid,
        });
      } else {
        // move marker to new location
        if ((centroid.geometry.coordinates[1] !== map.getSource(polygonPointId)._data.geometry.coordinates[1])
          || (centroid.geometry.coordinates[0] !== map.getSource(polygonPointId)._data.geometry.coordinates[0])) {
          map.removeSource(polygonPointId);
          map.addSource(polygonPointId, {
            type: "geojson",
            data: centroid,
          });
        }
      }

      if (!map.getSource(symbolId) && map.getSource(polygonPointId)) {

        // --------------------------------------------------

        map.loadImage(circleImage, function (error, image) {
          if (error) throw error;
          if (polygon.type !== "application") {
            map.addImage('circleImage', image, { sdf: true });
          }
          map.addLayer({
            id: symbolId,
            type: "symbol",
            source: polygonPointId,
            "layout": {
              'icon-allow-overlap': true,
              "icon-image": "circleImage",
              "icon-size": 6,
              "text-transform": "uppercase",
              "text-field": polygon.marker ?? "",
            },
            "paint": {
              "text-color": geometryColor,
              "icon-color": "rgba(34,34,34,0.75)",
              // "icon-halo-color": "rgba(34,34,34,0.75)",
              "icon-halo-width": 0.1
            }
          });
        });
      }
    }

    if (bounds) {
      const newBounds = bounds.map((point) => [point[1], point[0]]);
      if (fitBounds) {
        map.fitBounds(newBounds, {
          animate: false,
          padding: 50,
          offset: offset ? offset : [90, 0],
        });
      } else {
        map.fitBounds(newBounds, {
          animate: false,
        });
      }
    }
  };

  return null;
};

GeometriesLayer.propTypes = {
  map: PropTypes.object,
};
