import * as React from "react";
import cn from "clsx";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import Swal from "sweetalert2";

import { Chip } from "../../../../Helpers/Chip/Chip";
import {
  useImageReviewerState,
  useImageReviewerRefresh,
  useImageReviewerResponse,
  useImageReviewerContent,
  useImageReviewerSelectedImage,
} from "../../contexts/ImageReviewerContext/ImageReviewerContext";
import { useLazyApi } from "../../../../../hooks/useApi";

export const ImageReviewerPublishAction = ({ className }) => {
  const { selectedImages, selectedImagesById } = useImageReviewerState();
  const refresh = useImageReviewerRefresh();
  const { data } = useImageReviewerResponse();
  const { meta } = useImageReviewerContent();
  const selectedImage = useImageReviewerSelectedImage();

  const [executeCall] = useLazyApi(`/api/v1/image_review/${meta.id}`, {
    method: "PUT",
  });

  const disabled = selectedImages.length !== 1;

  const isPublished = selectedImage?.visible;

  const onClick = React.useCallback(async () => {
    try {
      // Optimistic update of the UI
      refresh(
        data.map((page) => {
          return {
            ...page,
            probeImages: page.probeImages.map((img) => {
              if (selectedImages.includes(img.id)) {
                return {
                  ...img,
                  visible: !isPublished,
                };
              }

              return img;
            }),
          };
        }),
        false
      );

      const selectedImage = selectedImagesById[selectedImages[0]];

      await executeCall({
        data: {
          probeImageId: selectedImage.id,
          observedCropId: selectedImage.observedCrop.id,
          visible: !isPublished,
        },
      });
    } catch (e) {
      new Swal({
        title: "Something went wrong",
        html:
          "We're sorry but we couldn't complete your request. Please try again and if the problem persists let us know.",
        icon: "error",
      });

      refresh();
    }
  }, [
    data,
    executeCall,
    isPublished,
    refresh,
    selectedImages,
    selectedImagesById,
  ]);

  return (
    <Chip
      title={isPublished ? "Unpublish" : "Publish"}
      disabled={disabled}
      onClick={onClick}
      className={cn(className)}
    >
      {isPublished ? <PlaylistAddCheckIcon /> : <PlaylistAddIcon />}
    </Chip>
  );
};
