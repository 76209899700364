import * as React from "react";

import styles from "./ShowImageReviewerActions.module.scss";
import {
  useImageReviewerContent,
  useImageReviewerState,
} from "../../contexts/ImageReviewerContext/ImageReviewerContext";
import { ImageReviewerDeleteAction } from "../../actions/ImageReviewerDeleteAction/ImageReviewerDeleteAction";
import { ImageReviewerUnDeleteAction } from "../../actions/ImageReviewerUnDeleteAction/ImageReviewerUnDeleteAction";
import { ImageReviewerFavoriteAction } from "../../actions/ImageReviewerFavoriteAction/ImageReviewerFavoriteAction";
import { ImageReviewerDownloadAction } from "../../actions/ImageReviewerDownloadAction/ImageReviewerDownloadAction";
import { ImageReviewerPublishAction } from "../../actions/ImageReviewerPublishAction/ImageReviewerPublishAction";
import { Grid } from "@material-ui/core";

const ShowImageReviewerOrderMeta = () => {
  const { meta, pagination } = useImageReviewerContent();
  const { selectedImages, selectedImagesById } = useImageReviewerState();

  const selectedImage = selectedImagesById[selectedImages[0]];

  return (
    <div className={styles.ShowImageReviewerOrderMeta}>
      <h1>Work Order {meta.workOrderId}</h1>

      <div className={styles.ShowImageReviewerOrderMeta__TextContainer}>
        {selectedImage && (
          <div className={styles.ShowImageReviewerOrderMeta__Text}>
            {selectedImage.date}
          </div>
        )}
        <div className={styles.ShowImageReviewerOrderMeta__Text}>
          {pagination.totalImageCount} photos
        </div>
      </div>
    </div>
  );
};

export const ShowImageReviewerActions = React.memo((props) => {
  const { showDeleted: propsShowDeleted } = props;
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={styles.ShowImageReviewerActions__Header}
    >
      <div className={styles.ShowImageReviewerActions__Body}>
        <ShowImageReviewerOrderMeta />
      </div>

      <div className={styles.ShowImageReviewerActions__Actions}>
        <ImageReviewerFavoriteAction />

        <ImageReviewerPublishAction />

        <ImageReviewerDownloadAction />

        {!propsShowDeleted && (<ImageReviewerDeleteAction />)}
        {propsShowDeleted && (<ImageReviewerUnDeleteAction />)}
      </div>
    </Grid>
  );
});
