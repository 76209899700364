import React from "react";
import akerLogo from "../../assets/logo-aker.png";
import akerLetters from "../../assets/logo-Aker-white.png";
import PinDropIcon from "@material-ui/icons/PinDrop";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { NavigationItem } from "./NavigationItem";
import { ThemeToggle } from "./ThemeToggle";
import { Flex } from "../Layouts/Flex";
import styles from "./Navigation.module.scss";
import { Breadcrumbs } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useFarms } from "../../globalState/farmContext";
import cn from "clsx";
import { useUser } from "../../hooks/useUser";
import useCookies from "../Views/ImageReviewer/hooks/useCookies";

export const Navigation = ({ pathname }) => {
  const { removeAuthCookies } = useCookies();
  // eslint-disable-next-line
  const showNavItems =
    pathname !== "/" &&
    !pathname.includes("/sign-up") &&
    !pathname.includes("/forgot-password") &&
    !pathname.includes("/reset-sign-in") &&
    !pathname.includes("/reset-password");

  const { currentFarm } = useFarms();
  const { currentUser } = useUser();

  // Retrieves the farm path if we are in a `farm` route. A farm route is
  // any route with the pattern /farm/**/*
  // If we are not within the farm path, an empty string is returned.
  const farmPath = (path) => {
    const pathElements = path.split("/");
    if (pathElements.length > 1 && pathElements[1] === "farm") {
      return pathElements[2];
    }
    return "";
  };

  const logout = () => {
    removeAuthCookies();
    window.sessionStorage.clear();
    localStorage.clear();
  };

  return (

    <Flex
      justifyContent="space-between"
      alignItems="center"
      className={styles.Navigation}
    >
      <Flex alignItems="center" className={styles.Logo}>
        <img className={styles.Logo} src={akerLogo} alt="Aker Logo" />
        {!showNavItems && <img src={akerLetters} alt="aker-letters" />}
        {showNavItems && farmPath(pathname).length > 0 && (
          <Flex>
            <Breadcrumbs separator={"/"}>
              <Link
                to="/farms"
                className={cn(styles.BreadcrumbItem, styles.BreadcrumbItemLink)}
              >
                Farms
              </Link>
              <Link
                to={`/farm/${currentFarm.id}`}
                className={cn(styles.BreadcrumbItem, styles.BreadcrumbItemLink)}
              >
                {currentFarm.title}
              </Link>
            </Breadcrumbs>
          </Flex>
        )}
      </Flex>
      {showNavItems && (
        <Flex>
          <NavigationItem
            icon={<PinDropIcon />}
            label="Farms"
            isSelected={pathname.includes("/farms")}
            redirect="/farms"
          />
          <NavigationItem
            icon={<InsertInvitationIcon />}
            label="Tasks"
            isSelected={pathname.includes("/tasks")}
            redirect="/tasks"
          />
          {currentUser?.isImageReviewer && (
            <NavigationItem
              icon={<CropOriginalIcon />}
              label="Reviewer"
              isSelected={pathname.includes("/image-reviewer")}
              redirect="/image-reviewer"
            />
          )}
          {/* <NavigationItem
            icon={<AlertsIcon />}
            label="Alerts"
            isSelected={selectedNav === "Alerts"}
            redirect="/alerts"
          /> */}
          <NavigationItem
            icon={<PersonOutlineIcon className={styles.SettingsIcon} />}
            label="Settings"
            isSelected={pathname.includes("/settings")}
            redirect="/settings"
          />
        </Flex>
      )}
      <Flex>
        {showNavItems && (
          <NavigationItem
            icon={<ExitToAppIcon />}
            label="Logout"
            onClick={logout}
          />
        )}
        <ThemeToggle />
      </Flex>
    </Flex>
  );
};
