import * as React from "react";
import cn from "clsx";

import styles from "./AspectRatio.module.scss";

export const AspectRatio = ({ value, children, className }) => {
  const style = React.useMemo(() => {
    return {
      "--aspect-ratio": value,
    };
  }, [value]);

  return (
    <div style={style} className={cn(className, styles["aspect-ratio"])}>
      {children}
    </div>
  );
};
