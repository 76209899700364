import React from "react";
import { Button, Input } from "../../../Helpers";
import { Flex } from "../../../Layouts/Flex";
import drawPolygonIcon from "../../../../assets/draw_polygon_icon.png";
import styles from "./AddFieldShelf.module.scss";
import { PlusIcon } from "../../../../assets/icons/PlusIcon";
import ClearIcon from "@material-ui/icons/Clear";
import { Chip, Stack } from "@mui/material";
import * as turf from "@turf/turf";
import parser from "fast-xml-parser";
import Swal from "sweetalert2";
import omit from "../../../../lib/omit";
import { read } from "shapefile";
import { feature } from "@turf/turf";

export const AddFieldShelf = ({
  polygonData,
  setFarmBounds,
  setPolygonData,
  newFieldData,
  setNewFieldData,
  createNewField,
  searchResults,
  selectSearchResult,
  showSearchResults,
  updateSearchAddress,
  searchAddress,
  createNewFieldFromKML,
}) => {

  const [uploadedBoundary, setUploadedBoundary] = React.useState(null);

  const sqMetersPerAcre = 4046.85642245;

  const importKML = (event) => {
    const fileInput = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileAsText = e.target.result;
      const outerBoundaryStart = fileAsText.indexOf("<outerBoundaryIs>");
      const outerBoundaryEnd = fileAsText.indexOf("</outerBoundaryIs>");
      const outerBoundaryText = fileAsText
        .slice(outerBoundaryStart, outerBoundaryEnd)
        .replace("<outerBoundaryIs>", "");
      const firstIndex = outerBoundaryText.indexOf("<coordinates>");
      const lastIndex = outerBoundaryText.indexOf("</coordinates>");
      const coordsAsText = outerBoundaryText
        .slice(firstIndex, lastIndex)
        .replace("<coordinates>", "");
      const parsedData = parser.parse(fileAsText);
      // check what the format is... some have altitudes, some don't
      var coordinates = {};
      if (coordsAsText.includes(",0 ")) {
        coordinates = coordsAsText.split("0 ").join("").split(",");
      } else {
        coordinates = coordsAsText.replace(/\s/g, ",").split(",");
      }
      if (!coordsAsText || coordinates.length < 4) {
        return Swal({
          title: "Error!",
          text:
            "Please make sure you are uploading a KML file of an entire field boundary. Contact support@aker.ag for more questions.",
          icon: "error",
          dangerMode: true,
          confirmButtonText: "OK",
          closeModal: true,
        });
      }
      const fieldName =
        parsedData.kml && parsedData.kml.Document
          ? parsedData.kml.Document.name
          : "Unknown";
      const formatedCoordinates = [];
      coordinates.forEach((point) => {
        const length = formatedCoordinates.length;
        const indx = length - 1;
        if (+point === 0) return;
        if (isNaN(+point)) {
          point = point.split(' ')[1]; // take the second value as the fist was an altitude we throw out
        }
        if (length === 0) {
          formatedCoordinates.push([+point]);
        } else {
          if (formatedCoordinates[indx].length === 1) {
            formatedCoordinates[indx].push(+point);
          } else if (formatedCoordinates[indx].length === 2) {
            formatedCoordinates.push([+point]);
          }
        }
      });
      //const fieldData = {}
      newFieldData.bounds = formatedCoordinates;
      newFieldData.acres = (
        turf.area(turf.polygon([formatedCoordinates])) / sqMetersPerAcre
      ).toFixed(2);
      newFieldData.geojson = {
        type: "Polygon",
        coordinates: [formatedCoordinates],
      };
      newFieldData.importedFieldId = 0;
      newFieldData.name = fieldName
        ? fieldName.replace(".kml", "").replace(/_|-/g, " ")
        : newFieldData?.title;
      newFieldData.title = fieldName
        ? fieldName.replace(".kml", "").replace(/_|-/g, " ")
        : newFieldData?.title;
      setFarmBounds(newFieldData.bounds);
      setPolygonData({
        polygon: {
          type: "Feature",
          geometry: { ...newFieldData.geojson },
        },
        ...omit(newFieldData, "geojson"),
      });
      setUploadedBoundary(newFieldData);
      setNewFieldData(newFieldData);
    };
    reader.readAsText(fileInput);
  };

  const importShapefile = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e) => {
      const geojson = await read(e.target.result);

      newFieldData.bounds = [[geojson.bbox[0], geojson.bbox[1]], [geojson.bbox[2], geojson.bbox[3]]];
      const turfFeature = feature(geojson.features[0].geometry)
      const acres = turf.area(turfFeature) / sqMetersPerAcre
      newFieldData.acres = acres.toFixed(2);
      newFieldData.geojson = geojson.features[0].geometry;
      newFieldData.importedFieldId = 0;
      newFieldData.name = newFieldData?.title;
      setFarmBounds(newFieldData.bounds);
      setPolygonData({ polygon: geojson.features[0] });
      setUploadedBoundary(newFieldData);
      setNewFieldData(newFieldData);
    }
    reader.readAsArrayBuffer(file);
  };

  const handleUploadClick = (which) => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    if (which === "kml") {
      fileInput.onchange = importKML;
    } else {
      fileInput.onchange = importShapefile;
    }
    fileInput.click();
  };

  return (
    <Flex
      flexDirection="column"
      alignItems="flex-start"
      className={styles.AddFieldShelf}
    >
      <h2>Instructions</h2>
      <div className={styles.Steps}>
        <p>
          Enter your address or use the map controls to navigate to your field:
        </p>
        <Flex className={styles.AddressInput}>
          <i className="fas fa-map-marker-alt" />
          <Input
            placeholder="Enter your address"
            value={searchAddress}
            onChange={(e) => updateSearchAddress(e.target.value)}
          />
          {showSearchResults && (
            <Flex
              flexDirection="column"
              alignItems="flex-start"
              className={styles.AddressSearchResults}
            >
              {searchResults.map((address, i) => (
                <span key={i} onClick={() => selectSearchResult(address)}>
                  {address.place_name} ({address.geometry.coordinates[1]},{" "}
                  {address.geometry.coordinates[0]})
                </span>
              ))}
            </Flex>
          )}
        </Flex>
        <h5>Step 1</h5>
        <p>
          In the bottom right of the map, click the polygon tool{" "}
          <img
            src={drawPolygonIcon}
            alt="drawPolygon"
            className={styles.DrawIcon}
          />{" "}
          to draw your field boundary
        </p>
        <p>OR</p>
        <p>Import a field file:</p>

        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Stack direction="row" spacing={2}>
            <Chip
              variant={"outlined"}
              label={"KML"}
              className={styles.chip}
              onClick={(e) => handleUploadClick('kml')}
            />
            <Chip
              variant={"outlined"}
              label={"Shapefile"}
              onClick={(e) => handleUploadClick('shapefile')}
            />
          </Stack>
        </Flex>

        <h5>Step 2</h5>
        <p>Enter the name of your new field:</p>
        <Input
          placeholder="Field Name"
          value={newFieldData && newFieldData.title && newFieldData.title}
          onChange={(e) =>
            setNewFieldData({ ...newFieldData, title: e.target.value })
          }
        />
        <span>{`(${polygonData?.acres ?? newFieldData?.acres} acres)`}</span>
        <p>Click "Create" and start managing your field!</p>
        <Button
          text="Create"
          icon={<PlusIcon />}
          className={styles.CreateBtn}
          disabled={!newFieldData.title || (!newFieldData.geojson && !polygonData.polygon.geometry)}
          click={() =>
            uploadedBoundary ? createNewFieldFromKML(newFieldData) : createNewField()
          }
        />
        <Button
          text="Cancel"
          icon={<ClearIcon />}
          click={() => window.history.back()}
          className={styles.CancelBtn}
        />
      </div>
    </Flex>
  );
};
