import React, { useState, createContext } from "react";
import useCookies from "../Components/Views/ImageReviewer/hooks/useCookies";
export const MapContext = createContext();
export const useMap = () => React.useContext(MapContext)
export const MapProvider = (props) => {
    const { setCookie, getCookie } = useCookies()
    const mapStyles = {
        basic: { url: "mapbox://styles/akerag/ckj1nsuuk1pw219pc8s3bd9ec", styleId: 'ckj1nsuuk1pw219pc8s3bd9ec', label: 'Basic' },
        basicDark: { url: "mapbox://styles/akerag/ckj1ntcc12p4219nxpyz50j5x", styleId: 'ckj1ntcc12p4219nxpyz50j5x', label: 'Dark' },
        satellite: { url: "mapbox://styles/akerag/ckg8f9hmk06e819mospasxho4", styleId: 'ckg8f9hmk06e819mospasxho4', label: 'Satellite' },
        blank: { url: "mapbox://styles/akerag/ckmv0b03p02c217s566hqj8i6", styleId: 'ckmv0b03p02c217s566hqj8i6', label: 'Blank' },
        blankDark: { url: "mapbox://styles/akerag/ckmv08sck029o17pahy5j1iog", styleId: 'ckmv08sck029o17pahy5j1iog', label: 'Blank Dark' },
    }
    const mapColors = [
        "#f26464",
        "#63bf93",
        "#f1bb3d",
        "#4c6686",
        "#aecc53",
        "#b674b6",
        "#00b0a9",
        "#d26937",
        "#84a230",
        "#f4a968",
        "#706ab8",
    ];
    const soilMapColors = [
        "#993404",
        "#b04308",
        "#c7530b",
        "#dc6410",
        "#e9791a",
        "#f78e24",
        "#fea43b",
        "#febc60",
        "#fed284",
        "#fee3a1",
        "#fff1ba",
        "#ffffd4",
    ];
    const [fullScreen, setFullScreen] = useState(false) // has more to do with Shelf and HealthLegend than Map

    const [preferredMapStyle, setPreferredMapStyle] = useState(getCookie('userDefaultMapStyle') ?? 'satellite')
    const [fieldMap, setFieldMap] = useState(null);
    const [mapboxLogo, setMapboxLogo] = useState(null);
    const [refreshMap, setRefreshMap] = useState(false);
    const [mapLoaded, setMapLoaded] = useState(false);
    const updatePreferredMapStyle = (mapStyle) => {
        setCookie({ key: 'userDefaultMapStyle', value: mapStyle })
        setPreferredMapStyle(mapStyle)
    }

    return (
        <MapContext.Provider
            value={{
                preferredMapStyle,
                updatePreferredMapStyle,
                mapStyles,
                mapColors,
                soilMapColors,
                fullScreen,
                setFullScreen,
                fieldMap,
                setFieldMap,
                mapboxLogo,
                setMapboxLogo,
                refreshMap,
                setRefreshMap,
                mapLoaded,
                setMapLoaded
            }}
        >
            {props.children}
        </MapContext.Provider>
    );
};
