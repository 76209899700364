import React from "react";
import cn from "clsx";

export const Container = ({ children, className = "", style = {} }) => {
  return (
    <div className={cn("Container", className)} style={style}>
      {children}
    </div>
  );
};
