import React from "react";
import { makeStyles } from "@material-ui/core";
import { SeasonsDrawer } from "./Season/SeasonsDrawer";
import { RegionsDrawer } from "./Regions/RegionsDrawer";
import { AerialImageryDrawer } from "./AerialImagery/AerialImageryDrawer";
import { AhiImageryDrawer } from "./AhiImagery/AhiImageryDrawer";
import { TrueCauseDrawer } from "./TrueCause/TrueCauseDrawer";
import { ReportDrawer } from "./ReportDrawer/ReportDrawer";
import WorkOrderDrawer from "./WorkOrderDrawer/WorkOrderDrawer";
import { Button } from "../../../Helpers";
import styles from "./FieldShelf.module.scss";
import { Flex } from "../../../Layouts/Flex";
import AddIcon from "@material-ui/icons/Add";
import AddWorkOrder from "./AddWorkOrder/AddWorkOrder";
import { useField } from "../../../../globalState/fieldContext";
import { SoilSamplesDrawer } from "./SoilSamples/SoilSamplesDrawer";
import { StandCountDrawer } from "./StandCount/StandCountDrawer";

export const useDrawerStyles = makeStyles((theme) => ({
  root: {
    padding: "1rem 1.25rem",
    boxSizing: "border-box",
  },
  options: {
    marginTop: "1rem",
    marginBottom: "0.5rem",
    marginRight: "0.5rem",
    "& > span": {
      lineHeight: "22px",
    },
  },
  showOptions: {
    fontFamily: theme.typography.fontFamilyMedium,
  },
}));

export const FieldShelf = ({
  imageryByDate,
  imageToDisplay,
  setImageToDisplay,
  probeObsByPin,
  trueCauseSliderDate,
  setImagesToView,
  drawerState,
  setDrawerState,
  selectedSeasonReports,
  setReportToView,
  setReportToEdit,
  updateSeasonReports,
  regionsByType,
  setRegionsByType,
  toggleShowObservation,
  toggleShowTrueCauseFlagLocations,
  stressMapsByDate,
  stressMapToDisplay,
  setStressMapToDisplay,
  ahiByDate,
  ahiToDisplay,
  setAhiToDisplay,
  ahiValuesToDisplay,
  setAhiValuesToDisplay,
  imageryDates,
  sliderDate,
  setSliderDate,
  imagery,
  observationDates,
  observationDateSlider,
  setObservationDateSlider,
  maps,
  workOrderToEdit,

  setSoilSampleData,
  setShowSoilSample,
  showSoilSample,
  setShowSoilSampleHeatmap,
  showSoilSampleHeatmap,
  soilSamplesToDisplay,
  setSoilSamplesToDisplay,
  soilSampleValuesToDisplay,
  setSoilSampleValuesToDisplay,
  setActivityData,
  setSelectedNutrient,

  standCountDates, // array of stand coutn dates
  standCountByDate, // array of stand counts
  setStandCountData, // selected stand count
  setShowStandCountSample,
  showStandCountSample,
  setShowStandCountHeatmap,
  showStandCountHeatmap,
  setStandCountToDisplay,
  standCountToDisplay,
}) => {
  const [showingNewOrderForm, setShowingNewOrderForm] = React.useState(false);
  const { seasons, regions, selectedSeason } = useField();

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      className={styles.FieldShelf}
    >
      {showingNewOrderForm ? (
        <AddWorkOrder close={() => setShowingNewOrderForm(false)} />
      ) : (
        <>
          <Flex flexDirection="column" className={styles.Header}>
            <Button
              className={styles.AddWorkOrderBtn}
              icon={<AddIcon />}
              text="New Task"
              click={() => setShowingNewOrderForm(true)}
            />
          </Flex>
          <Flex flexDirection="column" className={styles.Drawers}>
            <SeasonsDrawer useDrawerStyles={useDrawerStyles} />
            {!!regions &&
              !!imageryByDate &&
              !!ahiByDate &&
              !!probeObsByPin &&
              !!selectedSeasonReports &&
              !!selectedSeason &&
              seasons?.length > 0 && (
                <>
                  <RegionsDrawer
                    useDrawerStyles={useDrawerStyles}
                    regionsByType={regionsByType}
                    setRegionsByType={setRegionsByType}
                  />

                  <SoilSamplesDrawer
                    useDrawerStyles={useDrawerStyles}
                    setSoilSampleData={setSoilSampleData}
                    setShowSoilSample={setShowSoilSample}
                    showSoilSample={showSoilSample}
                    setShowSoilSampleHeatmap={setShowSoilSampleHeatmap}
                    showSoilSampleHeatmap={showSoilSampleHeatmap}
                    setActivityData={setActivityData}
                    setSelectedNutrient={setSelectedNutrient}
                    imageToDisplay={soilSamplesToDisplay}
                    setImageToDisplay={setSoilSamplesToDisplay}
                    soilSampleValuesToDisplay={soilSampleValuesToDisplay}
                    setSoilSampleValuesToDisplay={setSoilSampleValuesToDisplay}
                  />

                  <StandCountDrawer
                    useDrawerStyles={useDrawerStyles}
                    setStandCountData={setStandCountData}
                    setShowStandCountSample={setShowStandCountSample}
                    showStandCountSample={showStandCountSample}
                    setShowStandCountHeatmap={setShowStandCountHeatmap}
                    showStandCountHeatmap={showStandCountHeatmap}
                    setActivityData={setActivityData}
                    standCountDates={standCountDates}
                    standCountByDate={standCountByDate}
                    imageToDisplay={standCountToDisplay}
                    setImageToDisplay={setStandCountToDisplay}
                  />

                  <AerialImageryDrawer
                    useDrawerStyles={useDrawerStyles}
                    imageryByDate={imageryByDate}
                    imageToDisplay={imageToDisplay}
                    setImageToDisplay={setImageToDisplay}
                    drawerState={drawerState}
                    setDrawerState={setDrawerState}
                    stressMapToDisplay={stressMapToDisplay}
                    imageryDates={imageryDates}
                    sliderDate={sliderDate}
                    setSliderDate={setSliderDate}
                    imagery={imagery}
                  />
                  {(selectedSeason?.enrolledInAhi || (selectedSeason?.ahi.length > 0))
                    && (
                      <AhiImageryDrawer
                        useDrawerStyles={useDrawerStyles}
                        imageryByDate={ahiByDate}
                        imageToDisplay={ahiToDisplay}
                        setImageToDisplay={setAhiToDisplay}
                        drawerState={drawerState}
                        setDrawerState={setDrawerState}
                        stressMapToDisplay={stressMapToDisplay}
                        ahiValuesToDisplay={ahiValuesToDisplay}
                        setAhiValuesToDisplay={setAhiValuesToDisplay}
                      />)
                  }

                  <TrueCauseDrawer
                    setDrawerState={setDrawerState}
                    drawerState={drawerState}
                    useDrawerStyles={useDrawerStyles}
                    probeObsByPin={probeObsByPin}
                    trueCauseSliderDate={trueCauseSliderDate}
                    setImagesToView={setImagesToView}
                    toggleShowObservation={toggleShowObservation}
                    toggleShowFlagLocations={toggleShowTrueCauseFlagLocations}
                    stressMapsByDate={stressMapsByDate}
                    stressMapToDisplay={stressMapToDisplay}
                    setStressMapToDisplay={setStressMapToDisplay}
                    imageryDates={observationDates}
                    sliderDate={observationDateSlider}
                    setSliderDate={setObservationDateSlider}
                    maps={maps}
                  />
                  <ReportDrawer
                    useDrawerStyles={useDrawerStyles}
                    selectedSeasonReports={selectedSeasonReports}
                    setReportToView={setReportToView}
                    setReportToEdit={setReportToEdit}
                    updateSeasonReports={updateSeasonReports}
                  />
                  <WorkOrderDrawer
                    workOrderToEdit={workOrderToEdit}
                  />
                </>
              )}
          </Flex>
        </>
      )}
    </Flex>
  );
};
