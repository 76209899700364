import React from "react";
import { deleteFarm } from "../../../../controllers/farms";
import { Button, ListSkeleton } from "../../../Helpers";
import { Flex } from "../../../Layouts/Flex";
import styles from "./FarmsList.module.scss";
import Swal from "sweetalert2";
import { useAuth } from "../../../../hooks/useAuth";
import { AddFarm } from "../AddFarm/AddFarm";
import farmStyles from "../Farms.module.scss";
import { useHistory } from "react-router-dom";
import { useTrueCauseStressItemFilterDispatch } from "../../Field/FieldShelf/TrueCause/FilterTrueCauseStressItem";

export const FarmsList = ({
  loadingFarms,
  farms,
  options,
  selectedCustAccount,
  customerAccountsList,
  setFarms,
  clearCustomerAccountSelection,
  onBottom
}) => {
  const dispatch = useTrueCauseStressItemFilterDispatch();
  const { token } = useAuth();
  const history = useHistory();

  const [showAddFarm, setShowAddFarm] = React.useState(false);
  const [farmBeingDeleted, setFarmBeingDeleted] = React.useState();

  const selectFarm = (farm) => {
    localStorage.setItem("slectedFarm", JSON.stringify(farm.id))
    history.push(`/farm/${farm.id}`);
  };

  const handleDeleteFarm = async (e, farm) => {
    e.stopPropagation();
    e.preventDefault();
    const alertRes = await Swal.fire({
      icon: "question",
      title: "Confirm Deletions",
      text: `Are you sure you want to delete ${farm.title}?`,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#ff4444",
    });
    if (alertRes.isConfirmed) {
      setFarmBeingDeleted(farm.id);
      await deleteFarm(token, farm.id)
        .then(() => setFarmBeingDeleted(null))
        .then(() =>
          setFarms((farms) => farms.filter((item) => farm.id !== item.farmId))
        )
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Farm Deleted",
            text: `${farm.title} successfully deleted`,
            showConfirmButton: true,
            confirmButtonText: "Confirm",
          });
        });
      clearCustomerAccountSelection();
    } else {
      setFarmBeingDeleted(null);
    }
  };

  const canViewDelete = (farm) => {
    return (customerAccountsList || []).find(
      (act) => act.name === farm.customerAccountName
    )?.permissions?.canUpdate;
  };

  // remove the first slashes below if user should only be able to see farms where canManage is true
  const farmsUserCanSee = farms //.filter((f) => f.permissions.canManage);
  const onMouseEnter = (id) =>
    dispatch({ type: "image-hover", payload: { id } });
  const onMouseLeave = () => dispatch({ type: "image-off-hover" });
  return (
    <ul className={styles.FarmsList} onClick={(e) => e.stopPropagation()}>
      {!loadingFarms ? (
        farmsUserCanSee.length > 0 ? (
          Array.from(farmsUserCanSee)
            .sort((a, b) => (a.title > b.title ? 1 : -1))
            .map((farm, i) => {
              const totalAcres = farm.totalAcres.toFixed(2);
              if (
                !selectedCustAccount ||
                selectedCustAccount === farm.customerAccountName
              ) {
                return (
                  <li
                    key={i}
                    id={farm.id}
                    className={`${styles.ListItem} ${options.border && styles.Border
                      }`}
                    onClick={() => selectFarm(farm)}
                    onMouseEnter={(e) => { onMouseEnter(farm.id) }}
                    onMouseLeave={onMouseLeave}
                  >
                    <Flex
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="flex-start"
                      className={styles.Left}
                    >
                      <h1>{farm.title}</h1>
                      <h5>{farm.customerAccountName}</h5>
                      <p>{`${farm.fieldCount ?? 0
                        } Fields, ${totalAcres} acres`}</p>
                    </Flex>
                    <Flex
                      justifyContent="space-between"
                      className={styles.Right}
                    >
                      {canViewDelete(farm) && (
                        <Button
                          icon={
                            <i
                              className={
                                farmBeingDeleted && farmBeingDeleted === farm.id
                                  ? `fa fa-spinner fa-spin ${styles.spinner}`
                                  : `far fa-trash-alt ${styles.TrashIcon}`
                              }
                            />
                          }
                          className={styles.DeleteFarmBtn}
                          click={(e) => handleDeleteFarm(e, farm)}
                        />
                      )}
                      {/*<div className={styles.NotificationDot} />*/}
                    </Flex>
                  </li>
                );
              } else {
                return null;
              }
            })
        ) : (
          <Flex
            flexDirection="column"
            justifyContent="space-around"
            alignItems="flex-start"
            className={farmStyles.Top}
          >
            {!loadingFarms ? (
              <span className={styles.AddNewFarmLabel}>
                No Farms Found.
              </span>
            ) : (
              <span className={styles.AddNewFarmLabel}>
                You have not yet added any farms!
                <br />
                Add one now:
              </span>
            )}
            {!showAddFarm && loadingFarms && (
              <Button
                text="+ New Farm"
                click={() => setShowAddFarm(true)}
                className={styles.NewFarmBtn}
              />
            )}
            {showAddFarm && (
              <AddFarm
                customerAccountsList={customerAccountsList}
                setShowAddFarm={setShowAddFarm}
              />
            )}
          </Flex>
        )
      ) : (
        <ListSkeleton
          listCount={8}
          border={true}
          styleH1={{ width: "20rem" }}
          styleH5={{ width: "12rem" }}
          styleP={{ width: "16rem" }}
        />
      )}
    </ul>
  );
};
