import * as React from 'react';
// import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import { UploadFiles } from './UploadFiles';

const useStyles = makeStyles((theme) => ({
    text: {
        fontFamily: "var(--fontFamily) !important",
        color: "var(--fontColorMain) !important",
    },
}));

interface Props {
    open: boolean;
    handleClose: () => void;
    handleOpen: () => void;
    fieldName: string;
    fieldId: number | string;
    taskId: number | string;
    workOrderId: number | string;
    hideHeading: boolean;
    fileTypes: string[];
    uploadType: string;
    completed: boolean;
}

export const UploadFilesPopup = (props: Props) => {
    const classes = useStyles();

    return (
        <Dialog open={props.open}
            onClose={props.handleClose}
            PaperProps={{
                style: {
                    backgroundColor: "var(--bgColorMain)",
                    color: "var(--fontColorMain)",
                }
            }}
        >
            <DialogTitle >Upload Data</DialogTitle>
            <DialogContent >
                <DialogContentText className={classes.text}>
                    Upload data from your local machine.
                </DialogContentText>
                <UploadFiles
                    taskId={props.taskId}
                    workOrderId={props.workOrderId}
                    fieldName={props.fieldName}
                    fieldId={props.fieldId}
                    handleOpen={props.handleOpen}
                    handleClose={props.handleClose}
                    hideHeading={true}
                    fileTypes={props.fileTypes}
                    uploadType={props.uploadType}
                    completed={props.completed}
                />
            </DialogContent>
            {/* <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <Button onClick={props.handleClose}>Save</Button>
            </DialogActions> */}
        </Dialog>
    );
}
