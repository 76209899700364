import React from "react";
import { Button } from "../../Helpers";
import { Flex } from "../../Layouts/Flex";
import styles from "./Home.module.scss";

export const SignIn = () => {
  const { location } = window;
  
  return (
    <Flex className={styles.SignIn}>
      <div className={styles.BgImgCover} />
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        className={styles.Info}
      >
        <h1>Welcome Back!</h1>
        <span>
          To keep connected with us please log in with your personal info.
        </span>
        <Button
          className={styles.SignUpBtn}
          text="Sign In"
          click={() => location.assign("/")}
        />
      </Flex>
    </Flex>
  );
};
