import React, { useState } from "react";
import { ShelfLabel } from "../../../../Helpers";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { RegionOption } from "./RegionOption";
import {
  Grid,
  makeStyles,
  Typography,
  LinearProgress,
  Button as MuiButton,
} from "@material-ui/core";
import { useField } from "../../../../../globalState/fieldContext";
import { downloadShp } from "../../../../../hooks/useApi";
import { useAuth } from "../../../../../hooks/useAuth";
import { AddRegion } from "./AddRegion";
import { EditRegion } from "./EditRegion";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  regionOptionsContainer: {
    marginTop: "1rem",
  },
  regionsButtons: {
    width: "100%",
    marginTop: "1rem",
  },
  newRegionControl: {
    marginTop: "1rem",
  },
  newRegionNotes: {
    marginTop: "1rem",
    height: "auto",
  },
  newRegionPrompt: {
    margin: "2rem 0 1rem 0",
  },
  newRegionActionBtn: {
    marginTop: "2rem",
  },
  newRegionTypeLabel: {
    marginTop: "1rem",
  },
  newRegionType: {
    marginTop: "3.0rem",
    marginLeft: "-5.75rem",
    marginRight: "0rem",
    width: "21.0rem",

  },
  loadingContainer: {
    position: "absolute",
    bottom: theme.spacing(0.5),
    left: 0,
    width: "100%",
  },
}));

export const RegionsDrawer = ({
  useDrawerStyles,
  regionsByType,
  setRegionsByType,
}) => {
  const {
    regions,
    setGeometryToEdit,
    setDrawingEnabled,
    selectedSeason,
  } = useField();
  const classes = useStyles();

  const { trueCauseMarkersEditMode } = useField();

  const [isDownloading, setIsDownloading] = useState(false);
  const { token } = useAuth();
  const fieldContext = useField();
  const drawerClasses = useDrawerStyles();
  const [showOptions, setShowOptions] = useState(false);
  const [addRegion, setAddRegion] = useState(false);
  const [editRegion, setEditRegion] = useState(false);

  const handleAddRegionClick = () => {
    setDrawingEnabled(true);
    setGeometryToEdit({ id: "new" });
    setAddRegion(!addRegion);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      className={drawerClasses.root}
    >
      <ShelfLabel
        label={`Regions`}
        showOptions={showOptions}
        select={() => setShowOptions(!showOptions)}
      />
      {regions && showOptions && (
        <Grid
          container
          item
          direction="column"
          alignItems="flex-start"
          className={drawerClasses.options}
        >
          <Typography variant="body1" color="textSecondary">
            Add or select regions to include in your map view.
          </Typography>
          <Grid
            container
            direction="column"
            className={classes.regionOptionsContainer}
          >
            {regionsByType?.map(
              (type, i) =>
                type?.regions?.length > 0 && (
                  <RegionOption
                    i={i}
                    label={type.label}
                    typeRegions={type.regions}
                    regionsByType={regionsByType}
                    setRegionsByType={setRegionsByType}
                    editRegion={editRegion}
                    setEditRegion={setEditRegion}
                  />
                )
            )}
            {editRegion && (
              <EditRegion classes={classes} setEditRegion={setEditRegion} />
            )}
          </Grid>

          {!editRegion && (
            <Grid
              container
              item
              justifyContent="space-between"
              className={classes.regionsButtons}
            >
              <MuiButton
                disabled={
                  isDownloading ||
                  regionsByType.filter((r) => r.regions.some((a) => a.show))
                    .length === 0
                }
                variant="outlined"
                startIcon={<GetAppOutlinedIcon />}
                onClick={() => {
                  const stopLoad = () => setIsDownloading(false);
                  setIsDownloading(true);
                  const fieldId = fieldContext.field[0].id;
                  const regions = regionsByType.filter(
                    (r) => !!r.regions.some((a) => a.show)
                  );
                  const allRegions = regions.flatMap((a) =>
                    a.regions.filter((r) => r.show)
                  );

                  const regionTypes = regions.map((a) => a.label);

                  let downloadFileName = `${fieldContext.field[0].title}_`;
                  if (regionTypes.length > 1) {
                    const isLast = (index) =>
                      allRegions[allRegions.length - 1].id ===
                      allRegions[index].id;
                    regionTypes.forEach(
                      (type, index) =>
                      (downloadFileName += `${type}${isLast(index) ? "" : `_`
                        }`)
                    );
                  } else if (regionTypes.length === 1) {
                    downloadFileName += `${regionTypes[0]}`;
                  }
                  downloadFileName += ".zip";
                  const payload = {
                    data: {
                      geometries: allRegions.map(({ id }) => ({
                        id,
                        type: "region",
                      })),
                    },
                  };
                  downloadShp(fieldId, payload, token, downloadFileName).then(
                    stopLoad
                  );
                }}
              >
                Download (.shp)
              </MuiButton>
              <MuiButton
                disabled={
                  !selectedSeason ||
                  addRegion ||
                  editRegion ||
                  trueCauseMarkersEditMode
                }
                variant="outlined"
                startIcon={<AddRoundedIcon />}
                onClick={() =>
                  selectedSeason.id
                    ? handleAddRegionClick()
                    : Swal.fire({
                      title:
                        "You must have a season before you can create a region.",
                      icon: "warning",
                      showCancelButton: false,
                      dangerMode: true,
                      confirmButtonText: "OK",
                      closeOnConfirm: true,
                    })
                }
              >
                Add Region
              </MuiButton>
            </Grid>
          )}
          {addRegion && (
            <AddRegion classes={classes} setAddRegion={setAddRegion} />
          )}
        </Grid>
      )}

      {isDownloading && (
        <div className={classes.loadingContainer}>{<LinearProgress />}</div>
      )}
    </Grid>
  );
};
