import React from "react";
import { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import { LinearProgress } from '@mui/material';
import { Grid, Typography } from "@material-ui/core";
import { MuiButton } from '../../../../Helpers';
import { TextareaWithLabel } from '../../../../Helpers/Input/Textarea'
import { useField } from '../../../../../globalState/fieldContext';
import { NewDatePicker } from '../../../../Helpers/DatePicker/DatePicker'
import { NewInput } from "../../../../Helpers/Input/Input";
import { DistanceInput } from "../../../../Helpers/DistanceInput/DistanceInput";
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useApi, useLazyApi } from "../../../../../hooks/useApi";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
    text: {
        fontFamily: "var(--fontFamily) !important",
        color: "var(--fontColorMain) !important",
    }
}));

const sampleTypeLabelStyles = {
    color: "var(--fontColorMain)",
    fontFamily: "var(--fontFamily)",
    '&.Mui-focused': { color: "var(--fontColorMain)" },
}

export const CreateStandCountTaskPopup = (props) => {
    const [specialInstructions, setSpecialInstructions] = useState('')
    const [selectedDate, setSelectedDate] = useState(new Date())
    const { field, setSoilEditMode, updateSeasons } = useField();
    const [createWorkOrder] = useLazyApi("/api/v1/order", { method: "POST" });
    const { data: allServices } = useApi("/api/v1/services");
    const [loading, setLoading] = useState(false);
    const servicesList = allServices?.activeServices;
    const standCountServiceId = servicesList?.find(s => s.shortName === 'tcabove')?.id
    const configurations = allServices?.activeTcAboveConfigurations;
    const standCountConfiguration = configurations?.find(c => c.name === 'Stand Count');
    const [tcPoints, setTcPoints] = useState(0);
    const [tcHeightM, setTcHeightM] = useState(0);
    const [tcBufferM, setTcBufferM] = useState(0);

    useEffect(() => {
        if (standCountConfiguration) {
            setTcPoints(standCountConfiguration.max_points);
            setTcHeightM(standCountConfiguration.default_height);
            setTcBufferM(standCountConfiguration.default_buffer);
        }
    }, [standCountConfiguration]);

    const classes = useStyles();

    const createTheWorkOrder = async () => {
        var workOrderId;

        let params = { height: tcHeightM, points: tcPoints, buffer: tcBufferM };
        params = { ...params, n90: standCountConfiguration.n90, n45: standCountConfiguration.n45, s45: standCountConfiguration.s45, w45: standCountConfiguration.w45, e45: standCountConfiguration.e45 };
        params = { ...params, name: standCountConfiguration.name, process_standcount: standCountConfiguration.processStandcount }
        const workOrderData = {
            fieldId: field[0].id,
            serviceId: standCountServiceId,
            specialInstructions: specialInstructions,
            requestedStartDate: selectedDate,
            requestedEndDate: selectedDate,
            isNewSeason: false,
            parameters: params,
        }
        await createWorkOrder({ data: workOrderData })
            .then(async (response) => {
                workOrderId = response.id;
                await updateSeasons();
            })
            .catch((error) => {
                console.error(error);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "An error occurred while trying to create your task",
                });
            });;

        return workOrderId;
    }

    const handleSave = async () => {

        setSoilEditMode(false);
        setLoading(true);
        await createTheWorkOrder();
        setLoading(false);
        setSpecialInstructions('');
        props.handleClose();
        //props.handleOpenConfirmStandCountPopup(workOrderId)
    }

    const handleClose = () => {
        props.handleClose(null);
    }

    return (
        <Dialog open={props.open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    backgroundColor: "var(--bgColorMain)",
                    color: "var(--fontColorMain)",
                    minWidth: "30rem",
                }
            }}
        >
            <DialogTitle >Create Stand Count Task</DialogTitle>
            <DialogContent >
                <DialogContentText className={classes.text}>
                    Create a task to collect stand count.
                </DialogContentText>
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    style={{ alignSelf: "flex-start", padding: "2rem 1.5rem" }}
                >
                    <FormControl>
                        <FormLabel id="type-group-label" sx={sampleTypeLabelStyles}> Number of Waypoints (max: {standCountConfiguration?.max_points})</FormLabel>
                        <NewInput
                            placeholder="Waypoints"
                            type="number"
                            value={tcPoints}
                            onChange={(e) => {
                                if (e.currentTarget.value < 1) {
                                    setTcPoints(1)
                                }
                                else if (e.currentTarget.value <= standCountConfiguration?.max_points) {
                                    setTcPoints(e.currentTarget.value)
                                }
                                else {
                                    setTcPoints(standCountConfiguration?.max_points)
                                }
                            }}
                        />
                        <Typography variant="body2">
                            Photo Height (default: {standCountConfiguration?.default_height}m)
                        </Typography>
                        <DistanceInput
                            value={tcHeightM}
                            onChange={(value) => setTcHeightM(value)}
                        />
                        <Typography variant="body2">
                            Field Edge Buffer (default: {standCountConfiguration?.default_buffer}m)
                        </Typography>
                        <DistanceInput
                            value={tcBufferM}
                            onChange={(value) => setTcBufferM(value)}
                        />
                    </FormControl>
                    <TextareaWithLabel
                        label={"Special Instructions"}
                        value={specialInstructions}
                        onChange={(event) => { setSpecialInstructions(event.target.value) }}
                    />
                    <Typography color="textSecondary">Scheduled Date:</Typography>
                    <NewDatePicker
                        value={selectedDate}
                        onChange={(newValue) => {
                            setSelectedDate(newValue);
                        }}
                    />
                    {loading && (
                        <Dialog open={props.open}
                            PaperProps={{
                                style: {
                                    backgroundColor: "var(--bgColorMain)",
                                    color: "var(--fontColorMain)",
                                    minWidth: "30rem",
                                }
                            }}
                        >
                            <DialogTitle >Creating Task...</DialogTitle>
                            <DialogContent >
                                <LinearProgress />
                            </DialogContent>
                        </Dialog>
                    )}
                    <Grid container justifyContent="space-between" style={{ width: "100%", marginLeft: "-0.5rem", marginTop: "20px" }}>
                        <MuiButton
                            text="Cancel"
                            style={{ width: "45%" }}
                            onClick={handleClose}
                        />
                        <MuiButton
                            text="Save"
                            style={{ width: "45%" }}
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}



