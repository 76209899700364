import axios from "axios";
import * as React from "react";
import useSWR, { mutate, useSWRInfinite } from "swr";

import { useAuth } from "../hooks/useAuth";
const isStagingSite = process.env.REACT_APP_ENV.includes("staging");
const isDevSite = process.env.REACT_APP_ENV.includes("development");
const baseUrl = isDevSite
  ? process.env.REACT_APP_BACK_OFFICE_URL
  : isStagingSite
    ? process.env.REACT_APP_BACK_OFFICE_URL_STAGING
    : process.env.REACT_APP_BACK_OFFICE_URL_PRODUCTION;

const fetcher = (url, token, method = "GET", data, headers) => {
  if (headers && headers["Content-Type"] === "multipart/form-data") {
    const newData = new FormData();

    Object.entries(data).forEach(([k, v]) => newData.append(k, v));
    data = newData;
  }

  return axios({
    method,
    url: `${baseUrl}${url}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Token ${token}`,
      ...headers,
    },
    data: data || {},
  }).then((res) => {
    return res.data.data;
  });
};

export const useApi = (url, { data, headers, method, config = {} } = {}) => {
  const { token } = useAuth();
  const cfg = {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
    ...config,
  };

  const response = useSWR(
    token && url && [url, token, method, data, headers],
    fetcher,
    cfg
  );
  return response;

};

export const useLazyApi = (url, options = {}) => {
  const { headers, method, config } = options;
  const { token } = useAuth();
  const cfg = {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
    ...config,
  };

  const response = useSWR([url, token, method], () => null, cfg);

  return [
    async (data) => {
      return await mutate(url, fetcher(url, token, method, data, headers));
    },
    response,
  ];
};

export const useInfiniteApi = (
  getKey,
  { data, headers, method, config = {} } = {}
) => {
  const { token } = useAuth();
  const cfg = {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
    ...config,
  };

  const localFetcher = React.useCallback(
    (url) => {
      return fetcher(url, token, method, data, headers);
    },
    [data, headers, method, token]
  );

  const response = useSWRInfinite(token ? getKey : null, localFetcher, cfg);

  return response;
};

export const downloads3ToClient = (url, fileName = "") => {
  const downloadFileName = fileName
    ? fileName
    : url.split("/")[url.split("/").length - 1];
  return axios({
    method: "get",
    url: `${url}`,
    headers: {
      Accept: "*/*",
    },
    responseEncoding: "binary",
    responseType: "arraybuffer",
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", downloadFileName); //or any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
  });
};

export const downloadShp = (fieldId, payload, token, fileName) => {
  const fetch = async (url, token, data) =>
    await axios({
      method: "POST",
      url: `${baseUrl}${url}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
      data: data || {},
    });
  return new Promise((resolve, rej) =>
    fetch(`/api/v1/field/${fieldId}/shapefile`, token, payload).then((res) => {
      const response = res.data;
      const { success, data } = response;
      if (success) {
        downloads3ToClient(data.shapefile, fileName).then(() => resolve());
      } else resolve();
    })
  );
};

export const downloadKML = async (fieldId, token, fileName) => {
  const fetch = async (url, token) =>
    await await axios({
      method: "GET",
      url: `${baseUrl}/api/v1/field/${fieldId}/kml`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      }
    });
  return new Promise((resolve, rej) =>
    fetch(`/api/v1/field/${fieldId}/kml`, token).then((res) => {
      const success = res.status === 200;
      if (success) {
        const kml = res.data;
        // save kml to file
        const blob = new Blob([kml], { type: "text/plain" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.click();
        return true;
      } else resolve();
    })
  );
};

export const finishWorkOrderReview = async (taskId, token) => {
  const fetch = async (url, token, data) =>
    await axios({
      method: "POST",
      url: `${baseUrl}${url}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
      data: data || {},
    });

  return fetch(`/api/v1/tasks/${taskId}/review`, token).then((res) => {
    const { data: responseData } = res;
    const { data, success } = responseData;
    return { data, success };
  });
};

export const assignToTask = async (token, assignment) => {
  const fetch = async (url, token, data) =>
    await axios({
      method: "POST",
      url: `${baseUrl}${url}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
      data: data || {},
    });

  const taskId = assignment.data.id
  return fetch(`/api/v1/tasks/${taskId}/assignment`, token, assignment).then((res) => {
    const { data: responseData } = res;
    const { data, success } = responseData;
    return { data, success };
  });
};

export const probeTaskIdFromWorkOrderId = async (workOrderId, token) => {
  const fetch = async (url, token, data) =>
    await axios({
      method: "GET",
      url: `${baseUrl}${url}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
      data: data || {},
    });
  return fetch(`/api/v1/work_orders/${workOrderId}/tasks`, token).then((res) => {
    const { data: responseData } = res;
    const { data, success } = responseData;
    var task;
    if (success && data) {
      task = data.find((task) => task.type === "probe" && task.status !== "declined");
    }
    if (task) {
      return task.id;
    }
    return null;
  });
};

export const scoutTaskIdFromWorkOrderId = async (workOrderId, token) => {
  const fetch = async (url, token, data) =>
    await axios({
      method: "GET",
      url: `${baseUrl}${url}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
      data: data || {},
    });
  return fetch(`/api/v1/work_orders/${workOrderId}/tasks`, token).then((res) => {
    const { data: responseData } = res;
    const { data, success } = responseData;
    var task;
    if (success && data) {
      task = data.find((task) => task.type === "scout" && task.status !== "declined");
    }
    if (task) {
      return task.id;
    }
    return null;
  });
};

export const deleteField = (fieldId, token) => {
  const fetch = async (url, token, data) =>
    await axios({
      method: "DELETE",
      url: `${baseUrl}${url}`,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
      data: data || {},
    });

  return fetch(`/api/v1/field/${fieldId}`, token).then((res) => {
    const { data: responseData } = res;
    const { data, success } = responseData;
    return { data, success };
  });
};

export const taskActivities = async (token, taskId, data) => {
  const res = await axios({
    method: "PATCH",
    url: `${baseUrl}/api/v1/tasks/${taskId}`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Token ${token}`,
    },
    data: data || {},
  });

  return res?.data
};

export const soilSampleCollection = (fieldId, collectionType, csvFile, taskId, token, sampleDepth) => {
  const fetch = async (url, token, data) =>
    await axios({
      method: "POST",
      url: `${baseUrl}${url}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Token ${token}`,
      },
      data: data || {},
    });

  const body = new FormData();
  body.append("source", collectionType);
  body.append("file", csvFile);
  body.append("task_id", taskId);
  body.append("sample_depth", sampleDepth);

  return fetch(`/api/v1/field/${fieldId}/sample_collections/soil_sample_collection`, token, body).then((res) => {
    const { data: responseData } = res;
    const { data, success } = responseData;
    return { data, success };
  });
}
