import * as React from "react";
import { useParams } from "react-router-dom";

import { useInfiniteApi } from "../../../../hooks/useApi";
import { useImageReviewerFiltersState } from "../components/ImageReviewerFilters/ImageReviewerFilters";

export const useFetchImageReview = () => {
  const filters = useImageReviewerFiltersState();
  const params = useParams();
  const response = useInfiniteApi((index, prevData) => {
    // Don't keep trying to fetch after reaching the pagination
    // limit
    if (prevData && index >= prevData.totalImagePageCount) {
      return null;

      // If the user has selected some filters we'll serialize them
    } else if (filters?.remote && Object.keys(filters.remote).length > 0) {
      const searchParams = Object.entries(filters.remote || {}).reduce(
        (reducer, [key, value]) => {
          if (Array.isArray(value) && value.length > 0) {
            reducer.append(key, value.join(","));
          }

          return reducer;
        },
        new URLSearchParams()
      );

      searchParams.append("page", index + 1);

      return `/api/v1/image_review/${params.fieldId}?${searchParams}`;
    }

    return `/api/v1/image_review/${params.fieldId}?page=${index + 1}`;
  });
  // const deletedResponse = useInfiniteApi((index, prevData) => {
  //   return `/api/v1/image_review/${params.fieldId}?status=deleted&page=${
  //     index + 1
  //   }`;
  // });
  const { data, size, setSize, isValidating } = response;

  const isRefreshing = isValidating && data && data.length === size;

  const onLoad = React.useCallback(() => {
    if (!isRefreshing) {
      setSize((s) => s + 1);
    }
  }, [isRefreshing, setSize]);

  const paginationEnabled = data && size < data[0].totalImagePageCount;

  return React.useMemo(() => {
    return {
      response,
      onLoad,
      paginationStatus: paginationEnabled ? "enabled" : "disabled",
    };
  }, [onLoad, paginationEnabled, response]);
};
