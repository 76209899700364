/**
 * Indicates if a user has a customer account that they can manage.
 *
 * If there are no permissions the result is false.
 * It will always be true if the user can update or manage at least one account.
 * @param customerAccountsList
 * @returns {*}
 */
export function canManage(customerAccountsList) {
    return customerAccountsList.reduce((acc, v) => {
        if (!acc && v.permissions) {
            acc = v.permissions.canUpdate || v.permissions.canManage;
        }
        return acc;
    }, false);
}

/**
 * Returns a list of accounts that the user can manage.
 * @param {*} customerAccountsList 
 * @returns 
 */
export function managedAccounts(customerAccountsList) {
    return customerAccountsList.filter((acc) => {
        return acc && acc.permissions && acc.permissions.canManage;
    });
}

/**
 * Returns a list of accounts that the user can update.
 * @param {*} customerAccountsList 
 * @returns 
 */
export function updateableAccounts(customerAccountsList) {
    return customerAccountsList.filter((acc) => {
        return acc && acc.permissions && (acc.permissions.canUpdate || acc.permissions.canManage);
    });
}