import React, { useEffect, useState, useMemo } from 'react'
import cn from "clsx";
import {
    Grid, makeStyles, Typography, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Checkbox,
    TextField
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import NotificationsIcon from "@material-ui/icons/NotificationsNone";
import { CustomerAccountManagerSelector } from "../../Helpers/CustomerAccountManagerSelector/CustomerAccountManagerSelector";
import { Chip } from "../../Helpers/Chip/Chip";
import { Button } from "../../Helpers/Button/Button";
import { Autocomplete2 } from "../../Helpers/Autocomplete/Autocomplete";
import { useCustomerAccounts, useSelectedCustomerAccount } from "../../../globalState";
import { useLazyApi, useApi } from "../../../hooks/useApi";
import Swal from "sweetalert2";

const useHeaderStyles = makeStyles(theme => ({
    container: {
        padding: "0.75rem 0 2rem 0",
        width: '100%'
    },
    title: {
        fontSize: "1.75rem",
        fontWeight: "bold"
    },
    sectionContainer: {
        marginBottom: "2rem",
    },
    toolBarButton: {
        height: "2rem",
        backgroundColor: "inherit",

        "& i": {
            margin: "auto",
        },
    },
    buttonAdd: {
        color: theme.palette.text.primary,
        backgroundColor: "inherit",
        padding: 0,
    },
    buttonAddSelected: {
        color: theme.palette.primary.main,
    },
    buttonSelectAll: {
        backgroundColor: theme.palette.background.quinary,
    },
    buttonDelete: {
        color: theme.palette.primary.main,
        padding: 0,
    },
    buttonSelectAllSelected: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.background.primary,
    },
    muiIcon: {
        stroke: "initial",
        strokeWidth: "1px",
    },
    accountSelector: {
        width: "22.5rem",
    },
    textField: {
        width: "22.5rem",
        '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
        },
    },
    inputStyling: {
        borderRadius: 4,
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.tertiary
    },
    labelShrink: {
        transform: `translate(0px, -18px) scale(0.75) !important`,
    },
    cropInput: {
        marginTop: "1.5rem",
        width: "22.5rem"
    },
}));



export const FarmHands = () => {
    const { revalidate } = useApi(`/api/v1/customer_accounts`)
    const selectedCustomerAccount = useSelectedCustomerAccount();
    const customerAccounts = useCustomerAccounts();
    const [farmHands, setFarmHands] = useState([])
    const [isSelectAllMode, setIsSelectAllMode] = useState(false);
    // eslint-disable-next-line
    const [isSelect, setIsSelect] = useState(false);
    const [showAddUserPanel, setShowAddUserPanel] = useState(false)
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([])
    const [users, setUsers] = useState('')

    const [newUserEmail, setNewUserEmail] = useState("");

    const [isNotifying, setIsNotifying] = useState(false);
    const notifyText = useMemo(
        () => (isNotifying ? "Notifying User" : "Notify User"),
        [isNotifying]
    );


    useEffect(() => {
        getFarmHands()
        if (selectedCustomerAccount?.id) {
            onGetUsers()
        }
        // eslint-disable-next-line
    }, [selectedCustomerAccount, customerAccounts])


    const getFarmHands = () => {
        // if (selectedCustomerAccount?.id) {
        // eslint-disable-next-line
        let filteredFarmHand = customerAccounts?.filter(ca => {
            if (ca?.id === selectedCustomerAccount?.id) {
                return ca
            }
        })[0]?.farmHands
        filteredFarmHand = [
            ...new Map(filteredFarmHand?.map((item) => [item["userId"], item]))?.values(),
        ]
        setFarmHands(filteredFarmHand.map(ffh => {
            return { ...ffh, checked: false }
        }))
        // }
    }

    const classes = useHeaderStyles();

    const handleCheckBoxChange = (userId) => {
        let foundUserSelected = selectedUsers.includes(userId)
        setFarmHands(farmHands.map(fh => {
            if (fh.userId === userId) {
                return {
                    ...fh, checked: !fh.checked
                }
            } else {
                return fh
            }
        }))
        if (foundUserSelected) {
            setSelectedUsers(selectedUsers.filter(id => id !== userId))
        } else {
            setSelectedUsers([...selectedUsers, userId])
        }
    }

    const [addSubscription] = useLazyApi(`/api/v1/subscriptions/${selectedCustomerAccount?.id}/farm_hand?email=${newUserEmail.replace("+", "%2B")}`, {
        method: "POST",
    })

    const onAddUser = async () => {

        await addSubscription()
            .then(async (res) => {
                setIsNotifying(false);
                // await Swal.fire({
                //     icon: "success",
                //     title: "Success!",
                //     text: "User added",
                // });
                await revalidate()
                return res;
            })
            .catch(async (err) => {
                await Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "An error occurred while adding new farm hand",
                });
                console.error(err);
            });
    };

    const [removeSubscription] = useLazyApi(`/api/v1/customer_accounts/${selectedCustomerAccount?.id}/farm_hands`, {
        method: "DELETE",
    });

    const onDeleteUser = async () => {
        await removeSubscription({
            data: {
                "farmHands": selectedUsers
            },
        })
            .then(async () => {
                setSelectedUsers([])
                // await Swal.fire({
                //     icon: "success",
                //     title: "Success!",
                //     text: "Users removed",
                // });
                await revalidate()
            })
            .catch(async (err) => {
                await Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "An error occurred while removing users",
                });
                console.error(err);
            });
    }

    const [getUser] = useLazyApi(`/api/v1/customer_accounts/${selectedCustomerAccount?.id}/users`, {
        method: "GET",
    })

    const onGetUsers = async () => {
        await getUser().then((res) => {
            setAllUsers(res?.accountUsers)
        }).catch(err => {
            console.error(err)
        })
    }

    return (
        <>
            <Grid className={classes.container}>
                <Typography variant="h1" className={classes.title}>
                    Farm Hands
                </Typography>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start"
                }}>
                    <div>
                        <Typography style={{ marginBottom: "0.5rem", marginTop: "1.0rem" }}>
                            Select a customer account:
                        </Typography>
                        <CustomerAccountManagerSelector />
                    </div>
                    <Grid item container xs={2} justifyContent="center" direction="row">
                        <Grid item container wrap="nowrap" spacing={4} justifyContent="flex-end">
                            <Grid item>
                                <Chip
                                    className={cn(
                                        classes.toolBarButton,
                                        classes.buttonSelectAll,
                                        isSelectAllMode && classes.buttonSelectAllSelected
                                    )}
                                    onClick={() => {
                                        if (isSelectAllMode) {
                                            setSelectedUsers([])
                                            setFarmHands(farmHands.map(fh => {
                                                return {
                                                    ...fh, checked: false
                                                }
                                            }))
                                            setIsSelectAllMode(false);
                                            setIsSelect(false)
                                        } else {
                                            setSelectedUsers(farmHands.map(fh => fh.userId))
                                            setFarmHands(farmHands.map(fh => {
                                                return {
                                                    ...fh, checked: true
                                                }
                                            }))
                                            setIsSelectAllMode(true);
                                            setIsSelect(true)
                                        }
                                    }}
                                >
                                    Select all
                                </Chip>
                            </Grid>
                            {selectedUsers.length > 0 ? (
                                <Grid item>
                                    <Button
                                        click={() => {
                                            onDeleteUser()
                                        }
                                        }
                                        icon={
                                            <i className={cn("far", "fa-trash-alt", classes.muiIcon)} />
                                        }
                                        className={cn(classes.toolBarButton, classes.buttonDelete)}
                                    />
                                </Grid>
                            ) : null}

                            <Grid item>
                                <Button
                                    click={() => setShowAddUserPanel(!showAddUserPanel)}
                                    icon={
                                        showAddUserPanel ? (
                                            <ClearIcon className={classes.muiIcon} />
                                        ) : (
                                            <AddIcon className={classes.muiIcon} />
                                        )
                                    }
                                    className={cn(
                                        classes.toolBarButton,
                                        classes.buttonAdd,
                                        showAddUserPanel && classes.buttonAddSelected
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                {farmHands?.length > 0 ? (
                    <>
                        <div style={{ marginTop: "2.0rem" }}>
                            {
                                farmHands?.map((fh, index) => (
                                    <TableContainer key={index}>
                                        <Table className={classes.table} component={Paper}>
                                            <TableBody>
                                                <TableRow >
                                                    <TableCell align="center" padding="checkbox">
                                                        <Grid item>
                                                            <Checkbox color="primary"
                                                                checked={fh.checked}
                                                                onClick={() => handleCheckBoxChange(fh.userId)}
                                                            />
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Typography variant="h6">{fh.fullName}</Typography>
                                                        <Typography variant="body1">{fh.email}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ))
                            }
                        </div>
                    </>
                ) : (
                    <>
                        <Typography style={{ marginBottom: "1.5rem", marginTop: "2.0rem" }}>
                            Current account doesn't have any farm hand.
                        </Typography>
                    </>
                )}
                {showAddUserPanel ? (
                    <div>
                        <Typography style={{ marginBottom: "1.5rem", marginTop: "2.0rem" }}>
                            Add user to list of Farm Hands
                        </Typography>
                        <Typography style={{ marginBottom: "0.5rem", marginTop: "1.0rem", fontSize: "0.8rem" }}>
                            Select an existing user:
                        </Typography>
                        <Autocomplete2
                            options={allUsers ?? []}
                            value={users || null}
                            getOptionLabel={(o) => o.email}
                            onChange={(_, v) => {
                                setUsers(v)
                                setNewUserEmail(v.email)
                            }}
                            labelText="Select an existing user"
                            inputClassName={classes.cropInput}
                        />

                        <Typography style={{ marginBottom: "1.5rem", marginTop: "3.0rem" }}>
                            Add new user:
                        </Typography>
                        <TextField
                            variant={"outlined"}
                            label={"New User Email Address"}
                            classes={{ root: classes.textField }}
                            InputProps={{
                                notched: false,
                            }}
                            inputProps={{
                                className: classes.inputStyling
                            }}
                            InputLabelProps={{
                                classes: {
                                    shrink: classes.labelShrink
                                }
                            }}
                            onChange={(e) => {
                                setNewUserEmail(e.target.value)
                            }}
                            value={newUserEmail}
                        />
                        <Typography style={{ marginBottom: "0.5rem", marginTop: "1.0rem" }}>
                            If this person has a user email under this customer account, they will be notified when they've been given access. Otherwise an email will be sent inviting them to join Aker.
                        </Typography>
                        <Grid container item spacing={4}>
                            <Grid item>
                                <Button
                                    icon={
                                        isNotifying ? (
                                            <i className={"fa fa-spinner fa-spin"}></i>
                                        ) : (
                                            <NotificationsIcon stroke="none" />
                                        )
                                    }
                                    click={async () => {
                                        if (newUserEmail === "") return;
                                        onAddUser && setIsNotifying(true);
                                        onAddUser && (await onAddUser());
                                        setNewUserEmail("");
                                    }}
                                    text={notifyText}
                                    type="button"
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    icon={<ClearIcon stroke="none" />}
                                    click={() => setShowAddUserPanel(false)}
                                    text="Cancel Add User"
                                    type="button"
                                />
                            </Grid>
                        </Grid>
                    </div>
                ) : null}
            </Grid>
        </>
    )
}
