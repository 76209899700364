/*
This is some potentially confusing things, here, so a bit of history:
- Work Orders were devised when we had assignments, acceptance, rejection, etc. states.
- With the change to self-provisioning, we have some different meanings to these
- We need to maintain the existing functionality, but also add the new functionality
- So there is a bit of conversion going on to map ceratin states to the new meanings

Status: We map REJECTED => DELETED and ACCEPTED => ASSIGNED
Filters: We map DELETED => REJECTED and ASSIGNED => ACCEPTED

We allow the assignment to be made in any of the states ACCEPTED, NEW, or SCHEDULED as they are on the work order record. 

We will NOT show work orders that are of no interest to users: Auto Soil Maps, Satellite Imaging, etc.
*/
import React from "react";
import Grid from "@material-ui/core/Grid";
import { useAuth } from "../../../hooks/useAuth";
import { useApi, useLazyApi, assignToTask } from "../../../hooks/useApi";
import { Table } from "../../Helpers/Table/Table.jsx";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AddLocationIcon from "@material-ui/icons/AddLocation";
import {
  makeStyles,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Typography,
  IconButton,
} from "@material-ui/core";
import { Input } from "../../Helpers";
import { Map } from "../../Map/Map";
import { CustomerAccountsSelector } from "../../Helpers/CustomerAccountsSelector/CustomerAccountsSelector";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import * as turf from "@turf/turf";
import { useTrueCauseStressItemFilterDispatch } from "../Field/FieldShelf/TrueCause/FilterTrueCauseStressItem";
import { Flex } from "../../Layouts/Flex";
import { DateRangePicker } from "../../Helpers/DatePicker/DatePicker";
import { Autocomplete2 } from "../../Helpers/Autocomplete/Autocomplete";
import { useSelectedCustomerAccount } from "../../../globalState";
import Swal from "sweetalert2";
import { ChipFilter } from "../../Helpers/ChipFilter/ChipFilter";
import moment from "moment";
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.primary,
  },
}));

const useStepperConnectorStyles = makeStyles((theme) => ({
  line: {
    borderColor: theme.palette.primary.main,
    borderLeftWidth: 3,
  },
  vertical: {
    paddingTop: "5px",
    marginLeft: "10px",
  },
}));

// const uniqueBy = (array, key) => {
//   const retVal = [];
//   array.forEach((arrItem) => {
//     if (!retVal.includes(arrItem[key])) retVal.push(arrItem[key]);
//   });
//   return retVal;
// };

const WorkOrderDetail = ({ row, visibleColumns }) => {
  const { data } = useApi(`/api/v1/work_orders/${row.values.id}`);
  const { data: seasonData } = useApi(
    data && `/api/v1/seasons/${data.seasonId}`
  );


  const workOrderData =
    seasonData &&
    seasonData.workOrders &&
    seasonData.workOrders.find((wo) => wo.id === row.values.id);

  const classes = useStyles();
  const connectorClasses = useStepperConnectorStyles();

  const stepTypes = {
    orderRejected: (t) => (
      <Step key={t.id}>
        <StepLabel StepIconComponent={() => <AddToPhotosIcon />}>
          <Typography variant="body1">
            Order deleted ({new Date(t.date).toLocaleDateString()})
          </Typography>
        </StepLabel>
      </Step>
    ),
    orderComplete: (t) => (
      <Step key={t.id}>
        <StepLabel StepIconComponent={() => <AddToPhotosIcon />}>
          <Typography variant="body1">
            Order complete ({new Date(t.date).toLocaleDateString()})
          </Typography>
        </StepLabel>
      </Step>
    ),
    orderDataCollected: (t) => (
      <Step key={t.id}>
        <StepLabel StepIconComponent={() => <AddToPhotosIcon />}>
          <Typography variant="body1">
            Data collected ({new Date(t.date).toLocaleDateString()})
          </Typography>
        </StepLabel>
      </Step>
    ),
    orderDataProcessing: (t) => (
      <Step key={t.id}>
        <StepLabel StepIconComponent={() => <CheckBoxIcon />}>
          <Typography variant="body1">
            Data processing ({new Date(t.date).toLocaleDateString()})
          </Typography>
        </StepLabel>
      </Step>
    ),
    orderRequested: (t) => (
      <Step key={t.id}>
        <StepLabel StepIconComponent={() => <AddLocationIcon />}>
          <Typography variant="body1">
            Order requested ({new Date(t.date).toLocaleDateString()})
          </Typography>
        </StepLabel>
      </Step>
    ),
    orderScheduled: (t) => (
      <Step key={t.id}>
        <StepLabel StepIconComponent={() => <DateRangeIcon />}>
          <Typography variant="body1">
            Order scheduled ({new Date(t.date).toLocaleDateString()})
          </Typography>
        </StepLabel>
      </Step>
    ),
    orderAccepted: (t) => (
      <Step key={t.id}>
        <StepLabel StepIconComponent={() => <CheckBoxIcon />}>
          <Typography variant="body1">
            Order accepted ({new Date(t.date).toLocaleDateString()})
          </Typography>
        </StepLabel>
      </Step>
    ),
  };

  return (
    <tr className={classes.container}>
      <td colSpan={visibleColumns.length}>

        {!workOrderData && "Loading..."}
        {workOrderData && (
          <Grid container>
            <Stepper
              orientation="vertical"
              connector={<StepConnector classes={connectorClasses} />}
              style={{ backgroundColor: "inherit" }}
            >
              <Step>
                <StepLabel StepIconComponent={() => <Grid />}></StepLabel>
              </Step>

              {workOrderData.timeline.map((t, timelineIndex) => {
                return (

                  (stepTypes[t.type] && stepTypes[t.type](t)) || (
                    <Step key={timelineIndex}>
                      <StepLabel StepIconComponent={() => <Grid />}>
                        <Typography variant="body1">
                          Unknown? {t.type}
                        </Typography>
                      </StepLabel>
                    </Step>
                  )
                );
              })}
            </Stepper>
          </Grid>
        )}
      </td>
    </tr>
  );
};

export const WorkOrders = (props) => {
  const useStyles = makeStyles((theme) => ({
    workOrdersContainer: {
      width: "100%",
      height: `calc(100% - ${theme.palette.spacing.navHeight})`,
    },
    selectedFilter: {
      color: theme.palette.colors.blue,
    },
    primaryColor: {
      color: theme.palette.text.primary,
    },
    backdrop: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: "100%",
      height: "100%",
      opacity: 0.3,
      zIndex: 4,
      backgroundColor: "#222",
    },
    dialog: {
      border: "1px solid #eee",
      position: "absolute",
      right: "45%",
      zIndex: 5,
      bottom: "5%",
      backgroundColor: theme.palette.background.default,
      width: 350,
      borderRadius: 6,
      padding: 15,
    },
    mapContainerWrap: {
      position: "fixed",
      height: "45%",
      width: "100%",
      overflow: "hidden ",
    },
    mapContainer: {
      overflow: "hidden",
      position: "relative",
      width: "100%",
      height: "45%",
    },
    tableContainer: {
      width: "100%",
      height: "55%",
      padding: "2.375rem 1.5rem",
      zIndex: 9,
      overflow: "auto",
      backgroundColor: theme.palette.background.secondary,
    },
    searchInput: {
      display: "grid",
      gridTemplateColumns: "1.5rem auto",
      alignItems: "center",
      width: "22.5rem",
      margin: "0 1.5rem",
      paddingLeft: "0.4rem",
      borderRadius: "3px",
      backgroundColor: theme.palette.background.tertiary,
      boxSizing: "border-box",
    },
    backgroundColor: {
      backgroundColor: theme.palette.background.tertiary,
    },
    dayPicker: {
      "&.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover": {
        backgroundColor: theme.palette.background.tertiary,
      },
    },
    searchIcon: {
      position: "relative",
      left: "0.5rem",
      fontSize: "1.3rem",
    },
    fakeLink: {
      textDecoration: "underline",
      cursor: "pointer",
      "&:hover": {
        textDecoration: "none",
        color: theme.palette.colors.blue,
      },
    },
    spacing: {
      marginTop: theme.spacing(1),
      marginBotton: theme.spacing(1),
    },
    assignedTo: {
      marginBottom: "-0.5rem",
      marginTop: "-1rem",
      width: "200px",
    },
  }));
  const { token } = useAuth();
  const classes = useStyles();
  const mapContainerId = `work-order-maps`;
  const [selectedFilter, setSelectedFilter] = React.useState("");
  const [filteredItems, setFilteredItems] = React.useState([]);
  const [farmFields, setFarmFields] = React.useState([]);
  const [farmBounds, setFarmBounds] = React.useState(null);
  const [workOrdersPage, setWorkOrdersPage] = React.useState(1);
  const [gettingMore, setGettingMore] = React.useState(false);
  const [gotAllTheOrders, setGotAllTheOrders] = React.useState(false);
  const [ordersSoFar, setOrdersSoFar] = React.useState([]);

  const selectedCustomerAccount = useSelectedCustomerAccount();
  const customerAccountId = selectedCustomerAccount?.id;
  const [searchTerm, setSearchTerm] = React.useState("");
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [tmpStartDate, tmpSetStartDate] = React.useState(null);
  const [tmpEndDate, tmpSetEndDate] = React.useState(null);
  const [showDateFilter, setShowDateFilter] = React.useState(false);
  const [farmHands, setFarmHands] = React.useState([]);

  const interestingWorkOrderServices = [2, 3, 6, 7, 9, 10, 12, 13, 14, 17, 18, 51]

  const ourFiltereedItems = filteredItems.toString().toLowerCase().replace('deleted', 'rejected').replace('assigned', 'accepted')

  const endpoint = React.useMemo(
    () => {
      setOrdersSoFar([]);
      const filter = ourFiltereedItems
      return (!!customerAccountId
        ? `/api/v1/work_orders/by_customer_account/${customerAccountId}?filter=${filter}&services=${interestingWorkOrderServices}`
        : `/api/v1/work_orders?filter=${filter}&services=${interestingWorkOrderServices}`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customerAccountId, filteredItems]
  );
  const paginationEndpoint = React.useMemo(
    () => {
      const filter = ourFiltereedItems
      return (!!customerAccountId
        ? `/api/v1/work_orders/by_customer_account/${customerAccountId}?page=${workOrdersPage}&filter=${filter}&services=${interestingWorkOrderServices}`
        : `/api/v1/work_orders?page=${workOrdersPage}&filter=${filter}&services=${interestingWorkOrderServices}`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workOrdersPage]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const farmHandsEndpoint = React.useMemo(
    () =>
      `/api/v1/customer_accounts`
  );

  const [getMoreOrders] = useLazyApi(paginationEndpoint);
  const response = useApi(endpoint);
  const farmHandsResponse = useApi(farmHandsEndpoint);
  const { data, error: initialLoadError } = response;
  const loadingInitialData = !data && !initialLoadError;
  const farmFieldDisplay = (row) => row.farm.title + " : " + row.field.title;
  const dateRangeDisplay = (row) => {
    const start = moment(new Date(row.requestedStartDate)).format("YYYY-MM-DD")
    const end = moment(new Date(row.requestedEndDate)).format("YYYY-MM-DD")
    if (start === end) {
      return start;
    }
    return `${start} - ${end}`;
  }
  const setAssignedTo = (tasks, userId, idx) => {
    let assignments = []
    let t = 0
    const row = filteredData[idx]
    for (let task of tasks) {
      if (userId === 0) // unassigned so deal with that
      {
        assignments.push({ id: task.id, assigned_to_id: 0 });
        row.status = "new";
      } else {
        assignments.push({ id: task.id, assigned_to_id: userId, accept: true });
        row.status = "assigned";
      }
      row.tasks[t].pilotId = userId;
      t += 1;
    };
    const newData = filteredData.map((c, i) => {
      if (i === idx) {
        return row;
      }
      return c;
    });
    setOrdersSoFar(newData);
    for (let assignment of assignments) {
      const body = { data: assignment }
      if (body.data.id) {
        assignToTask(token, body).then(({ data, success }) => {
          if (!success) {
            new Swal({
              title: "An error occurred assigning to user",
              html:
                "We're sorry but we couldn't complete your request. Please try again and if the problem persists let us know.",
              icon: "error",
            });
          }
        });
      }
    }
  }
  const formatAccountForMenu = (item) => {
    const label = item.fullName ? item.fullName : item.email;
    return { label: label, value: item.userId };
  };
  const assignedTo = (row, idx) => {
    const currentId = row.tasks[0]?.pilotId ? row.tasks[0].pilotId : 0;
    const hands = farmHands.filter((f) => f.customerId === row.customerId);
    const assignedTo = hands[0]?.list.find((f) => f?.value === currentId || null) || { label: 'Unassigned', value: 0 }
    if (farmHands.length > 0 && ["ACCEPTED", "ASSIGNED", "NEW", "SCHEDULED"].some(s => row.status.toUpperCase().includes(s))) {
      return (<Autocomplete2
        labelText=""
        options={hands[0]?.list}
        minLength={0}
        // getOptionSelected={(option, value) => option.value === value.value}
        value={assignedTo}
        onChange={(_, v) => { if (v) { setAssignedTo(row.tasks, v.value, idx) } }}
        inputClassName={classes.assignedTo}
        getOptionLabel={(o) => o.label}
      />);
    } else {
      return (<>{assignedTo && assignedTo.label}</>)
    }
  }

  React.useEffect(() => {
    if (farmHandsResponse && farmHandsResponse.data) {
      const items = farmHandsResponse.data.map((item) => {
        const customerId = item.id;
        const farmHands = item.farmHands;
        var hands = farmHands.map((item) => formatAccountForMenu(item));
        hands.unshift({ label: "Unassigned", value: 0, customerId: customerId })
        return { list: hands, customerId: customerId }
      });

      setFarmHands(items);
    }
  }, [farmHandsResponse]);

  React.useEffect(() => {
    if (!loadingInitialData) {
      setOrdersSoFar(data);
    }
  }, [loadingInitialData, data]);

  React.useEffect(() => {
    if (gettingMore) {
      if (gotAllTheOrders) {
        // do nothing
      } else {
        getMoreOrders()
          .then((result) => {
            setGettingMore(false);
            setOrdersSoFar([...ordersSoFar, ...result]);
            if (result.length === 0) {
              setGotAllTheOrders(true);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }, [getMoreOrders, gettingMore, gotAllTheOrders, ordersSoFar]);

  React.useEffect(() => {
    resetPagination();
  }, [selectedCustomerAccount]);

  // memo the data with the filters && searchTerm
  const filteredData = React.useMemo(() => {
    if (ordersSoFar.length > 0) {
      let copyData = [...ordersSoFar];
      if (selectedFilter) {
        copyData = copyData?.filter(
          (a) => selectedFilter === a.status.toLowerCase()
        );
      }
      if (startDate && endDate) {
        copyData = copyData?.filter((a) => {
          let timelineItem = null,
            filterDate = null;
          // get max timeline item based on date
          if (
            a.timeline.some(
              (timelineItem) => timelineItem.type === "orderDataCollected"
            )
          ) {
            timelineItem = a.timeline.find(
              (timelineItem) => timelineItem.type === "orderDataCollected"
            );
            filterDate = new Date(timelineItem.date);
          } else {
            a.timeline.forEach((item) => {
              const date = new Date(item.date);
              if (!timelineItem) timelineItem = item;
              if (timelineItem && new Date(timelineItem.date) <= date)
                timelineItem = item;
            });
            // if timeline item was scheduled or completed, use that date
            if (
              ["orderDataCollected", "orderScheduled"].some(
                (type) => type === timelineItem.type
              )
            ) {
              filterDate = new Date(timelineItem.date);
            } else {
              // other wise use requested start date..
              filterDate = new Date(a.requestedStartDate);
            }
          }
          return (
            new Date(filterDate) >= new Date(startDate) &&
            new Date(endDate) >= new Date(filterDate)
          );
        });
      }

      return copyData.filter((a) => {
        return farmFieldDisplay(a)
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
    }
  }, [searchTerm, startDate, endDate, ordersSoFar, selectedFilter]);

  React.useEffect(() => {
    if (filteredData?.length === 0) setFarmFields([]);
    if (ordersSoFar.length && filteredData.length) {
      let centers = [];
      const formattedFarmFields = ordersSoFar
        .filter((a) => filteredData?.some((item) => item.id === a.id))
        .map(({ field, id }) => {
          const formattedBounds = field.bounds.map((point) => [
            +point[1],
            +point[0],
          ]);
          const point1 = turf.point(formattedBounds[0]);
          const point2 = turf.point(formattedBounds[1]);
          const midpoint = turf.midpoint(point1, point2).geometry.coordinates;
          centers.push(midpoint);

          return {
            ...field,
            id,
            center: midpoint,
            showMarker: filteredData.some((item) => item.id === id),
          };
        });
      centers.push(centers[0]);
      const bounds = turf.bbox(turf.lineString(centers));
      setFarmBounds(bounds);
      formattedFarmFields.sort((a, b) => a.title > b.title);
      setFarmFields(formattedFarmFields);
    }
  }, [filteredData, startDate, endDate, ordersSoFar]);

  // filter stuff
  // const filters = React.useMemo(() => {
  //   return uniqueBy(ordersSoFar || [], "status").map((status) => ({
  //     status: status,
  //     selected: status === selectedFilter,
  //   }));
  //   // eslint-disable-next-line
  // }, [ordersSoFar, filteredData, selectedFilter]);

  // // callback & helper functions
  // const getFilterCount = (status) =>
  //   ordersSoFar.filter((a) => a.status.toLowerCase() === status.toLowerCase())
  //     ?.length
  //     ? ordersSoFar.filter(
  //       (a) => a.status.toLowerCase() === status.toLowerCase()
  //     )?.length
  //     : "";

  const renderRowSubComponent = React.useCallback(
    ({ row, visibleColumns }) => (
      <WorkOrderDetail row={row} visibleColumns={visibleColumns} />
    ),
    []
  );

  const getNextPage = () => {
    if (!gettingMore) {
      if (!gotAllTheOrders) {
        setGettingMore(true);
        setWorkOrdersPage(workOrdersPage + 1);
      }
    }
  };

  const resetPagination = () => {
    setGettingMore(false);
    setWorkOrdersPage(1);
    setGotAllTheOrders(false);
    // setOrdersSoFar([]);
  };

  const getRetriever = () => (
    <>
      {ordersSoFar.length} orders retrieved so far.{" "}
      {gotAllTheOrders ? (
        <>That is all the orders for your customer account selection.</>
      ) : (
        <>
          <span className={classes.fakeLink} onClick={() => getNextPage()}>
            Get more.
          </span>
        </>
      )}
    </>
  );

  // Hover on workorder, highlight associated marker
  const trueCauseDispatch = useTrueCauseStressItemFilterDispatch();

  const onMouseEnter = (fieldId) =>
    trueCauseDispatch({ type: "image-hover", payload: { id: fieldId } });
  const onMouseLeave = () => trueCauseDispatch({ type: "image-off-hover" });

  const filterItems = ['Assigned', 'Completed', 'Deleted', 'New', 'Processing', 'Scheduled']

  return (
    <Grid container className={classes.workOrdersContainer}>
      <Grid id={mapContainerId} item xs={12} className={classes.mapContainer}>
        <div className={classes.mapContainerWrap}>
          <Map
            markerScrollIntoView
            // actually work order ID
            markerTooltipLabel={`Task ID:`}
            markerTooltipKey={"id"}
            fields={farmFields}
            farmBounds={farmBounds}
            containerId={mapContainerId}
            mapOptions={{
              center: [-95, 40],
            }}
          />
        </div>
      </Grid>

      <Grid item xs={12} className={classes.tableContainer}>
        <Grid item xs={12} style={{ width: "100%" }}>
          <Typography variant="h2">Tasks</Typography>
          <Typography>Customer account:</Typography>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          style={{
            height: "3rem",
            marginBottom: "1.5rem",
          }}
        >
          <CustomerAccountsSelector />
          {ordersSoFar.length > 0 && (
            <>
              <div className={classes.searchInput}>
                <SearchIcon className={classes.searchIcon} />
                <Input
                  className={classes.backgroundColor}
                  type="text"
                  placeholder={"Search"}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <IconButton
                  className={`fa fa-calendar ${!showDateFilter && classes.primaryColor
                    } ${showDateFilter && classes.selectedFilter}`}
                  onClick={() => {
                    setSelectedFilter("");
                    setShowDateFilter(!showDateFilter);
                  }}
                  style={{}}
                  iconClassName={"fa fa-calendar"}
                  isSelected={showDateFilter}
                />
              </div>
            </>
          )}
        </Grid>
        {showDateFilter && (
          <>
            <div className={classes.backdrop} />
            <div className={`${classes.dialog} democalendar`}>
              <IconButton
                style={{ color: "var(--fontColorMain)", float: "right" }}
                onClick={() => {
                  setShowDateFilter(false);
                }}
                className={`${classes.primaryColor} fa fa-times custumr8`}
              />
              <DateRangePicker
                className={classes.dayPicker}
                indefinite
                from={tmpStartDate}
                to={tmpEndDate}
                data={(isReset) => {
                  setFarmFields(farmFields);
                  setStartDate(undefined);
                  setEndDate(undefined);
                }}
                setDateRange={({ from, to }) => {
                  tmpSetStartDate(from);
                  tmpSetEndDate(to);
                }}
                onChange={(day) => { }}
                onUpdate={() => {
                  setStartDate(tmpStartDate);
                  setEndDate(tmpEndDate);
                }}
                numberOfMonths={2}
              />
            </div>
          </>
        )}

        <Grid item xs={13} style={{ padding: "9px 14px 12px 14px" }}>
          <Typography variant={"subtitle1"}>{getRetriever()}</Typography>
          {gettingMore && (
            <div>
              <LinearProgress />
            </div>
          )}
        </Grid>

        <ChipFilter
          items={filterItems}
          label={"Filter on Status"}
          selected={filterItems.filter((item) => !['Deleted', 'Completed'].includes(item))}
          setFilteredItems={setFilteredItems}
        />

        {/** Table */}
        <Grid item xs={12}>
          <>
            <Table
              id={"work-orders-table"}
              style={{ maxHeight: 500, overflow: "auto" }}
              stickyHeaders={true}
              onRowHoverExit={(row) => onMouseLeave()}
              onRowHoverEnter={(row) => onMouseEnter(row.values.id)}
              noResultsLabel={"No work orders found."}
              renderRowSubComponent={renderRowSubComponent}
              clickRowToExpand
              setShowEditTaskModal={props.setShowEditTaskModal}
              setTaskModalData={props.setTaskModalData}
              columns={[
                {
                  // These are actually work orders in the back end, but we call them tasks in the UI
                  Header: "Task ID",
                  sortable: true,
                  accessor: "id",
                  Cell: ({ value, row }) => {
                    return (
                      <div {...row.getToggleRowExpandedProps()}>{value}</div>
                    );
                  },
                },
                {
                  Header: "Farm Field",
                  sortable: true,
                  id: "farm",
                  accessor: (row, idx) => {
                    return farmFieldDisplay(row);
                  },
                },
                {
                  Header: "Assigned To",
                  sortable: false,
                  accessor: (row, idx) => {
                    return assignedTo(row, idx);
                  },
                },
                {
                  Header: "Scheduled Date",
                  sortable: true,
                  accessor: (row, idx) => {
                    return dateRangeDisplay(row);
                  },
                },
                {
                  Header: "Service Type",
                  sortable: true,
                  accessor: "service",
                },
                {
                  Header: "Status",
                  sortable: true,
                  accessor: (row, idx) => {
                    // if (row.tasks[0]?.pilotId) {
                    //   return "ASSIGNED"
                    // } else {
                    return row.status.toUpperCase().replace('REJECTED', 'DELETED').replace('ACCEPTED', 'ASSIGNED');
                    // }
                  },
                },
                {
                  sortable: false,
                  id: "expander",
                  Cell: ({ _value, row }) => (
                    <Box sx={{ marginLeft: "-10px" }}>
                      {row.isExpanded ? (
                        <ExpandMoreIcon />
                      ) : (
                        <ChevronRightIcon />
                      )}
                    </Box>
                  ),
                },
                {
                  sortable: false,
                  id: "icons",
                  Cell: ({ _value, _row }) => (
                    <div style={{ width: "50px" }}></div>
                  )
                },
              ]}
              data={filteredData || []}
              dataLoaded={!!data}
              updateData={setOrdersSoFar}
              onScroll={({
                target: { scrollHeight, scrollTop, clientHeight },
              }) => {
                if (scrollHeight - scrollTop === clientHeight) {
                  getNextPage();
                }
              }}
            ></Table>
            {gettingMore && (
              <Flex justifyContent={"center"}>
                <CircularProgress />
              </Flex>
            )}
          </>
        </Grid>
      </Grid>
    </Grid >
  );
};
