import * as React from "react";

import { useApi } from "../../../../../hooks/useApi";
import { usePrevious } from "../../../../../hooks/usePrevious";
import {
  useImageReviewerContent,
  useImageReviewerSelectedImage,
} from "../ImageReviewerContext/ImageReviewerContext";

const ImageReviewerCropsContext = React.createContext({});

// These contexts will be used for handling the selection of Crops and
// Stress Items. Once the selection has been saved in the BE we'll
// reset this state to avoid having to maintain to caches
const ImageReviewerCropsSelectionStateContext = React.createContext({});
const ImageReviewerCropsSelectionDispatchContext = React.createContext(
  () => { }
);

export const ImageReviewerCropsProvider = ({ children }) => {
  const { meta } = useImageReviewerContent();
  const { data } = useApi(

    meta.id ? `/api/v1/image_review/${meta.id}/crop_stress_items` : null
  );

  return (
    <ImageReviewerCropsContext.Provider value={data}>
      {children}
    </ImageReviewerCropsContext.Provider>
  );
};

export const useImageReviewerCropsStressItems = () => {
  const data = React.useContext(ImageReviewerCropsContext);

  if (!data || !data.crops) {
    return undefined;
  }

  return data.crops;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "reset":
      return initialState;

    case "select-crop":
      return {
        ...state,
        crop: `${action.payload}`,
        stressItems: {
          ...state.stressItems,
          // we'll reset any previous stress items
          [action.payload]: {},
        },
      };

    case "toggle-stress-item":
      return {
        ...state,
        stressItems: {
          ...state.stressItems,
          [action.payload.cropTypeId]: {
            ...state.stressItems[action.payload.cropTypeId],
            [action.payload.id]: state.stressItems[action.payload.cropTypeId][
              action.payload.id
            ]
              ? null
              : action.payload,
          },
        },
      };

    case "toggle-rereview-mode":
      return {
        ...state,
        rereview: !state.rereview,
      };

    case "init-stress-items":
      return {
        ...state,
        stressItems: {
          ...state.stressItems,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};

const initialState = {
  crop: undefined,
  stressItems: {},
  rereview: false,
};

const getInitialStressItemsSelection = (
  customerReviewStressItems,
  observedCrop
) => {
  return customerReviewStressItems.reduce(
    (reducer, i) => {
      reducer[observedCrop.id][i.id] = {
        ...i,
      };

      return reducer;
    },
    {
      [observedCrop.id]: {},
    }
  );
};

export const ImageReviewerCropsStressItemsSelectionProvider = ({
  children,
}) => {
  const { id, customerReviewStressItems, observedCrop } =
    useImageReviewerSelectedImage() || {};
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const prevId = usePrevious(id);
  const prevCustomerReviewStreessItems = usePrevious(customerReviewStressItems);

  // Every time the `selectedImage` change we'll reset the state so
  // previous selections won't carry over to new images.
  React.useEffect(() => {
    if (id && id !== prevId) {
      dispatch({ type: "reset" });
      dispatch({
        type: "init-stress-items",
        payload: getInitialStressItemsSelection(
          customerReviewStressItems,
          observedCrop
        ),
      });
    }
  }, [customerReviewStressItems, id, prevId, observedCrop]);

  // Once we have a reference to the `customerReviewStreessItems`
  // we'll initialize our store with those values. This is gonna
  // determine which vales are preselected on page load.
  React.useEffect(() => {
    if (
      !prevCustomerReviewStreessItems &&
      prevCustomerReviewStreessItems !== customerReviewStressItems
    ) {
      dispatch({
        type: "init-stress-items",
        payload: getInitialStressItemsSelection(
          customerReviewStressItems,
          observedCrop
        ),
      });
    }
  }, [customerReviewStressItems, prevCustomerReviewStreessItems, observedCrop]);

  return (
    <ImageReviewerCropsSelectionStateContext.Provider value={state}>
      <ImageReviewerCropsSelectionDispatchContext.Provider value={dispatch}>
        {children}
      </ImageReviewerCropsSelectionDispatchContext.Provider>
    </ImageReviewerCropsSelectionStateContext.Provider>
  );
};

export const useImageReviewerCropSelectionState = () => {
  const state = React.useContext(ImageReviewerCropsSelectionStateContext);

  return state;
};

export const useImageReviewerCropSelectionDispatch = () => {
  const dispatch = React.useContext(ImageReviewerCropsSelectionDispatchContext);

  return dispatch;
};

export const useImageReviewerSelectedCrop = () => {
  const cropsStressItems = useImageReviewerCropsStressItems();
  const state = useImageReviewerCropSelectionState();
  const selectedImage = useImageReviewerSelectedImage();

  if ((!state.crop && !selectedImage) || !cropsStressItems) {
    return null;
  }

  if (state.crop) {
    const crop = cropsStressItems.find((c) => c.id.toString() === state.crop);

    return crop;
  }
  // const initialCropID = selectedImage.collectedCrop.id//(true || selectedImage.observedCrop.id == 75) ? selectedImage.collectedCrop.id : selectedImage.observedCrop.id;
  const targetId = `${state.crop || selectedImage.observedCrop.id}`;

  return cropsStressItems.find((c) => c.id.toString() === targetId);
};
