import React, { useState } from "react";
import { Typography, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useApi, useLazyApi } from "../../../../../hooks/useApi";
import { Autocomplete2 } from "../../../../Helpers/Autocomplete/Autocomplete";
import { Flex } from "../../../../Layouts/Flex";
import clsx from "clsx";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { NewDatePicker } from "../../../../Helpers/DatePicker/DatePicker";
import { useButtonStyles } from "../../../../Helpers/Button/Button.jsx";
import omit from "../../../../../lib/omit";
import Swal from "sweetalert2";
import UndoIcon from "@material-ui/icons/Undo";
import CloseIcon from "@material-ui/icons/Close";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { TextareaWithLabel } from "../../../../Helpers/Input/Textarea";
import { useField } from "../../../../../globalState/fieldContext";
import { NewInput } from "../../../../Helpers/Input/Input";
import { useCustomerAccounts } from "../../../../../globalState";
import { DistanceInput } from "../../../../Helpers/DistanceInput/DistanceInput";

const useStyles = makeStyles((theme) => ({
  addWorkOrderRoot: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    overflow: "scroll",
  },
  header: {
    marginTop: theme.spacing(2),
    textAlign: "left",
  },
  item: {
    marginTop: theme.spacing(1),
  },
  itemWithExtraSpace: {
    marginTop: theme.spacing(1.5),
  },
  error: {
    color: theme.palette.error.main,
  },
}));

const AddWorkOrder = ({ close }) => {
  const { data: crops } = useApi("/api/v1/crops");
  const { data: allServices } = useApi("/api/v1/services");
  const servicesList = allServices?.activeServices;
  const tcAboveConfigs = allServices?.activeTcAboveConfigurations;
  const [createWorkOrder] = useLazyApi("/api/v1/order", { method: "POST" });
  const { field, selectedSeason, updateSeasons } = useField();
  const [tcAboveConfig, setTcAboveConfig] = React.useState();
  const [tcPoints, setTcPoints] = React.useState();
  const [tcHeightM, setTcHeightM] = React.useState();
  const [tcBufferM, setTcBufferM] = React.useState();
  const customerAccount = useCustomerAccounts();
  const [soilMap] = useState(customerAccount[0].defaults.soilMap)


  const currentSeasonExists = field[0].seasons.find((s) => s.current);
  const needNewSeason = !Boolean(currentSeasonExists);

  // remove services that require satellite if we are not enrolled in them
  // let services
  // if (servicesList != null)
  const services = selectedSeason?.customerAccount.enrolledSatelliteServices ? servicesList : servicesList?.filter(service => !['asm'].some(s => service.shortName === s))

  const tcAboveServiceId = services?.find(s => s.shortName === 'tcabove')?.id
  const autoSoilMapServiceId = services?.find(s => s.shortName === 'asm')?.id

  const initialSeasonData = {
    fieldId: field[0].id,
    cropId: undefined,
    plantDate: undefined,
  };

  const [seasonData, setSeasonData] = React.useState(initialSeasonData);
  const [seasonErrors, setSeasonErrors] = React.useState({});

  const initialWorkOrderData = {
    fieldId: field[0].id,
    serviceId: undefined,
    specialInstructions: "",
    timing: 3,
    requestedStartDate: new Date(),
    requestedEndDate: undefined,
    isNewSeason: needNewSeason,
    parameters: soilMap
  };

  var newWorkOrderData = {};

  const [workOrderData, setWorkOrderData] = React.useState(
    initialWorkOrderData
  );
  const [workOrderErrors, setWorkOrderErrors] = React.useState({});

  const classes = useStyles();
  const buttonClasses = useButtonStyles();

  const validateSeason = () => {
    if (!needNewSeason) return true;
    const newSeasonErrors = {};
    if (!seasonData.cropId) {
      newSeasonErrors.cropId = "Crop is required.";
    }
    if (!seasonData.plantDate) {
      newSeasonErrors.plantDate = "Plant date is required.";
    }
    setSeasonErrors(newSeasonErrors);
    return Object.keys(newSeasonErrors).length === 0;
  };

  const validateWorkOrder = () => {
    const newWorkOrderErrors = {};
    if (!workOrderData.serviceId) {
      newWorkOrderErrors.serviceId = "You must choose a service.";
    }
    if (workOrderData.serviceId === autoSoilMapServiceId) {
      const today = new Date()
      newWorkOrderData = {
        ...workOrderData,
        requestedStartDate: today,
      }
    } else {
      if (!workOrderData.requestedStartDate) {
        newWorkOrderErrors.dateRange =
          "You must specify a start date (or a date range).";
      }
    }
    setWorkOrderErrors(newWorkOrderErrors);
    return Object.keys(newWorkOrderErrors).length === 0;
  };

  // const updateParams = () => {
  //   if (tcAboveConfig) {
  //     let params = { height: tcHeight, points: tcPoints, buffer: tcBuffer };
  //     params = { ...params, n90: tcAboveConfig.n90, n45: tcAboveConfig.n45, s45: tcAboveConfig.s45, w45: tcAboveConfig.w45, e45: tcAboveConfig.e45 };
  //     setWorkOrderData({ ...workOrderData, parameters: params });
  //   }
  // }

  const submitForm = () => {
    //updateParams();
    const workOrderIsValid = validateWorkOrder();
    const seasonIsValid = validateSeason();
    if (workOrderIsValid && seasonIsValid) {
      const theWorkOrderData = Object.keys(newWorkOrderData).length > 0 ? newWorkOrderData : workOrderData;
      createWorkOrder({
        data: {
          ...theWorkOrderData,
          ...(needNewSeason ? seasonData : {}),
        },
      })
        .then(async (_submitWorkOrderResponse) => {
          await Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Task created",
          });
          await updateSeasons();
          close();
        })
        .catch((error) => {
          console.error(error);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "An error occurred while trying to create your task",
          });
        });
    }
  };

  const clearForm = () => {
    setSeasonData(initialSeasonData);
    setWorkOrderData(initialWorkOrderData);
    setSeasonErrors({});
    setWorkOrderErrors({});
    // setSoilMap(customerAccount[0].defaults.soilMap)
  };

  const updateWorkOrderData = (choice) => {
    let parameters;
    // Initialize parameters if needed.
    // TCAbove is done when we select the service type so we don't need to do it here.
    switch (choice.id) {
      case autoSoilMapServiceId:
        parameters = soilMap;
        break;
      default:
    }

    setWorkOrderData({
      ...workOrderData,
      serviceId: choice ? choice.id : undefined,
      parameters: parameters,
    });
    if (choice.id !== tcAboveServiceId) {
      setTcAboveConfig(null)
    }
  }

  React.useEffect(() => {
    if (tcAboveConfig) {
      let params = { height: tcHeightM, points: tcPoints, buffer: tcBufferM };
      params = { ...params, n90: tcAboveConfig.n90, n45: tcAboveConfig.n45, s45: tcAboveConfig.s45, w45: tcAboveConfig.w45, e45: tcAboveConfig.e45 };
      params = { ...params, name: tcAboveConfig.name, process_standcount: tcAboveConfig.processStandcount }
      setWorkOrderData({ ...workOrderData, parameters: params });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tcHeightM, tcBufferM, tcPoints])

  return (
    <Flex
      flexDirection={"column"}
      alignItems={"stretch"}
      className={classes.addWorkOrderRoot}
    >
      <Flex justifyContent={"space-between"} className={classes.item}>
        <Typography variant={"h3"}>Create a new Task</Typography>
        <div onClick={() => close()} style={{ cursor: "pointer" }}>
          <UndoIcon />
        </div>
      </Flex>
      <Typography className={classes.item} color={"textSecondary"}>
        Fill out the form below to create a new task.
      </Typography>
      <Typography className={classes.item} color={"textSecondary"}>
        All fields marked with a "*" are required.
      </Typography>

      {needNewSeason && (
        <>
          <Typography className={classes.header} variant={"h4"}>
            Field Information
          </Typography>

          <Autocomplete2
            options={
              crops?.sort((a1, a2) => (a1.name < a2.name ? -1 : 1)) ?? []
            }
            getOptionLabel={(o) => o.name}
            className={classes.item}
            labelText={"Crop Type *"}
            error={seasonErrors.cropId}
            value={crops?.find((c) => seasonData.cropId === c.id) || null}
            onChange={(_reactEvent, choice) => {
              if (choice && seasonErrors.cropId) {
                setSeasonErrors(omit(seasonErrors, "cropId"));
              }
              setSeasonData({
                ...seasonData,
                cropId: choice ? choice.id : undefined,
              });
            }}
          />

          <div className={classes.item}>
            <NewDatePicker
              value={seasonData.plantDate}
              disablePast={false}
              onChange={(day) => setSeasonData({ ...seasonData, plantDate: day, })}
            />
          </div>
        </>
      )}

      <Typography className={classes.header} variant={"h4"}>
        Select Service Type
      </Typography>

      <Autocomplete2
        options={services ? services : []}
        getOptionLabel={(o) => o.name}
        labelText={"Select Service *"}
        error={workOrderErrors.serviceId}
        onChange={(_reactEvent, choice) => {
          if (choice && workOrderErrors.serviceId) {
            setWorkOrderErrors(omit(workOrderErrors, "serviceId"));
          }
          updateWorkOrderData(choice);
        }}
        value={
          services
            ? services.find((s) => s.id === workOrderData.serviceId) || null
            : null
        }
      />

      {(workOrderData?.serviceId === tcAboveServiceId) && (
        <>
          <Typography className={classes.header} variant={"h5"}>
            Select TrueCause Above Type
          </Typography>

          <Autocomplete2
            options={tcAboveConfigs ? tcAboveConfigs : []}
            getOptionLabel={(o) => o.name}
            labelText={"Select Type *"}
            error={workOrderErrors.serviceId}
            onChange={(_reactEvent, choice) => {
              if (choice) {
                setTcAboveConfig(choice)
                setTcPoints(choice.max_points)
                setTcHeightM(choice.default_height)
                setTcBufferM(choice.default_buffer)
              }
            }}
            value={
              (tcAboveConfigs && tcAboveConfig)
                ? tcAboveConfigs.find((s) => s.id === tcAboveConfig.id) || null
                : null
            }
          />
        </>
      )}
      {tcAboveConfig && (
        <Flex
          flexDirection="column"
          alignItems="stretch"
        >
          <Typography variant="body2" className={classes.label}>
            Description:<p>
              {tcAboveConfig.description}
            </p><br></br>
          </Typography>
          <Typography variant="body2" className={classes.label}>
            Number of Waypoints (max: {tcAboveConfig.max_points})
          </Typography>
          <NewInput
            placeholder="Waypoints"
            type="number"
            value={tcPoints}
            onChange={(e) => {
              if (e.currentTarget.value < 1) {
                setTcPoints(1)
              }
              else if (e.currentTarget.value <= tcAboveConfig.max_points) {
                setTcPoints(e.currentTarget.value)
              }
              else {
                setTcPoints(tcAboveConfig.max_points)
              }
            }}
          />

          <Typography variant="body2" className={classes.label}>
            Photo Height (default: {tcAboveConfig.default_height}m)
          </Typography>
          <DistanceInput
            value={tcHeightM}
            onChange={(value) => setTcHeightM(value)}
          />
          <Typography variant="body2" className={classes.label}>
            Field Edge Buffer (default: {tcAboveConfig.default_buffer}m)
          </Typography>
          <DistanceInput
            value={tcBufferM}
            onChange={(value) => setTcBufferM(value)}
          />
        </Flex>
      )}

      {(workOrderData?.serviceId === autoSoilMapServiceId) && (
        <>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            style={{ alignSelf: "flex-start" }}
          >
            <Typography className={classes.header} variant={"h4"}>
              Field Buffer Distance
            </Typography>
            <DistanceInput
              value={workOrderData.parameters.soil_field_buffer}
              onChange={(value) => setWorkOrderData({ ...workOrderData, parameters: { ...workOrderData.parameters, soil_field_buffer: value } })}
            />

            <Typography className={classes.header} variant={"h4"}>
              Sample-to-Sample Minimum Distance
            </Typography>
            <DistanceInput
              value={workOrderData.parameters.soil_sample_spacing}
              onChange={(value) => setWorkOrderData({ ...workOrderData, parameters: { ...workOrderData.parameters, soil_sample_spacing: value } })}
            />

          </Grid>
        </>
      )}

      <TextareaWithLabel
        label={"Special Instructions"}
        value={workOrderData.specialInstructions}
        onChange={(event) => {
          setWorkOrderData({
            ...workOrderData,
            specialInstructions: event.target.value,
          });
        }}
        className={classes.itemWithExtraSpace}
      />
      {workOrderData?.serviceId !== autoSoilMapServiceId &&
        <div className={classes.item}>
          <Typography
            className={clsx(
              classes.item,
              workOrderErrors.dateRange && classes.error
            )}
            color={"textSecondary"}
          >
            Select a requested date. *
          </Typography>
          <NewDatePicker
            value={workOrderData.requestedStartDate}
            onChange={(newValue) => {
              setWorkOrderData({ ...workOrderData, requestedStartDate: newValue });
            }}
          />
        </div>
      }
      <div className={classes.item}>
        {(Object.keys(seasonErrors).length > 0 ||
          Object.keys(workOrderErrors).length > 0) && (
            <Typography className={clsx(classes.error)}>
              Could not submit. Check your data for errors, above
            </Typography>
          )}
        <Button
          className={clsx(buttonClasses.primaryAction, classes.item)}
          fullWidth
          disableRipple
          onClick={submitForm}
        >
          <CalendarTodayIcon style={{ marginRight: "4px" }} />
          Schedule Task
        </Button>
      </div>

      <Flex justifyContent={"space-between"} className={classes.item}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "11px",
            cursor: "pointer",
          }}
          onClick={close}
        >
          <CloseIcon />
          <div style={{ marginTop: "4px" }}>Cancel</div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "11px",
            cursor: "pointer",
          }}
          onClick={clearForm}
        >
          <DeleteOutlineIcon fontSize={"small"} />
          <div style={{ marginTop: "4px" }}>Clear form</div>
        </div>
      </Flex>
    </Flex>
  );
};

export default AddWorkOrder;
