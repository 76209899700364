import * as React from "react";
import cn from "clsx";
import { format } from "date-fns";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Container } from "../../../../Layouts/Container";
import { ImageGrid } from "../../components/ImageGrid/ImageGrid";
import { Button } from "../../../../Helpers/Button/Button";
import { Tabs } from "../../../../Helpers/Tabs/Tabs";
import {
  ImageReviewerContentContext,
  ImageReviewerDispatchContext,
  ImageReviewerResponseContext,
  ImageReviewerStateContext,
  initialState,
  useImageReviewerState,
  useImageReviewerDispatch,
  useInitSelectedImage,
  setThumbnailContent,
} from "../../contexts/ImageReviewerContext/ImageReviewerContext";
import styles from "./ShowImageReviewer.module.scss";
import "./ShowImageReviewer.module.scss";
import { ProbeImageThumb } from "../../components/ProbeImage/ProbeImage";
import { ShowImageReviewerActions } from "../../components/ShowImageReviewerActions/ShowImageReviewerActions";
import {
  multipleImageSelect,
  selectUnselectAll,
  singleImageSelect,
} from "../../utils/imageGridState";
import { ProbeImagePreview } from "../../components/ProbeImagePreview/ProbeImagePreview";
import { ProbeImageSkeleton } from "../../components/ProbeImage/ProbeImageSkeleton";
import { FilterMenu } from "../../components/FilterMenu/FilterMenu";
import { useFetchImageReview } from "../../hooks/useFetchImageReview";
import { useImageReviewerFiltersState } from "../../components/ImageReviewerFilters/ImageReviewerFilters";
import { ImageReviewerFiltersController } from "../../components/ImageReviewerFilters/ImageReviewerFilters";
import { ShowImageReviewerShelf } from "../../components/ShowImageReviewerShelf/ShowImageReviewerShelf";
import { ImageReviewerCropsProvider } from "../../contexts/ImageReviewerCropsContext/ImageReviewerCropsContext";
import {
  ImageStressItemFilters,
  useStressItemFiltersState,
} from "../../components/FilterMenu/ImageStressItemFilters";
import { Map } from "../../../../Map/Map";
import {
  getWorkOrder,
  getWorkOrderTasks,
} from "../../../../../controllers/workOrder";
import { useAuth } from "../../../../../hooks/useAuth";
import { TrueCauseItemFilters } from "../../../Field/FieldShelf/TrueCause/FilterTrueCauseStressItem";
import { finishWorkOrderReview } from "../../../../../hooks/useApi";
import { ImageReviewerCropsStressItemsSelectionProvider } from "../../contexts/ImageReviewerCropsContext/ImageReviewerCropsContext";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { dateToString } from "../../../../Helpers";

const useStyles = makeStyles((theme) => ({
  gridLabelTitle: {
    gridColumn: "1/4",
    fontWeight: "bold",
  },
  gridLabelTitleSpaced: {
    marginTop: theme.spacing(1),
  },
  gridLabelSubTitle: {
    gridColumn: "1/4",
  },
}));

// Used for defining the properties we're interested in saving about an
// image
const getSelectedImageProps = (image) => {
  if (!image) {
    return {};
  }
  const initialCropID =
    image.observedCrop.id === 75 || typeof image.observedCrop.id !== "number"
      ? image.collectedCrop.id
      : image.observedCrop.id;
  image.observedCrop.id = initialCropID;
  return {
    ...image,
    date: image.acquisitionDate
      ? format(new Date(image.acquisitionDate), "dd MMMM yyyy")
      : "",
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set-notes": {
      return {
        ...state,
        selectedImagesById: {
          ...state.selectedImagesById,
          [action.payload.id]: {
            ...state.selectedImagesById[action.payload.id],
            notes: action.payload.notes,
          },
        },
      };
    }
    case "image-press": {
      // We won't allow the user to deselect the currently selected
      // image if there's only 1 selected.
      if (
        state.selectedImagesById[action.payload.id] &&
        state.selectedImages.length === 1
      ) {
        return state;

        // If the user hold shift + click we'll let them select
        // multiple
      } else if (action.payload.modifiers?.shiftKey) {
        return multipleImageSelect(state, action, getSelectedImageProps);
      }

      return singleImageSelect(state, action, getSelectedImageProps);
    }

    case "unselect-all": {
      return {
        ...state,
        selectedImagesById: {
          [state.selectedImages[0]?.id]: state.seletedImages[0],
        },
        selectedImages: [state.selectedImages[0]],
      };
    }

    case "select-unselect-all": {
      return selectUnselectAll(state, action, getSelectedImageProps);
    }

    case "image-delete-success": {
      if (
        !action.payload ||
        state.selectedImages.length === action.payload.length
      ) {
        return {
          ...state,
          selectedImagesById: {},
          selectedImages: [],
        };
      }

      return state;
    }

    default:
      return state;
  }
};

const UNLABELED = "unlabeled";

const ShowImageReviewerContent = () => {
  const history = useHistory();
  const { token } = useAuth();
  const classes = useStyles();
  const { selectedImagesById } = useImageReviewerState();
  const { response, onLoad, paginationStatus } = useFetchImageReview();
  const { data, isValidating } = response;
  const prevContentRef = React.useRef();
  const [fieldData, setFieldData] = React.useState(null);

  const [isReviewingCompleted, setIsReviewingCompleted] = React.useState(false);
  const [showFinishReview, setShowFinishReview] = React.useState(false);

  const filters = useImageReviewerFiltersState();
  const stressItemFilterState = useStressItemFiltersState();
  const imageReviewDispatch = useImageReviewerDispatch();
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const imageFilter = React.useMemo(() => {
    if (!stressItemFilterState.client) return [];
    return Object.keys(stressItemFilterState.client).filter(
      (key) => !!stressItemFilterState.client[key]
    );
  }, [stressItemFilterState]);

  const flagHoverCallback = (flag) => {
    if (flag && Array.isArray(flag.types[0]?.images)) {
      const elementId = `img-${flag.types[0]?.images[0].id}`;
      const element = document.getElementById(elementId);
      if (element) element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const flagClickCallback = (flag) => {
    setSelectedTabIndex(0);
    imageReviewDispatch({
      type: "image-press",
      payload: flag.types[0]?.images[0],
    });
  };

  const content = React.useMemo(() => {
    if (!data) {
      // This will let us show something while the new data is on its
      // way
      return prevContentRef.current || {};
    }
    const { probeImages, ...restData } = data[0];
    const newContent = {
      meta: {
        id: restData.id,
        workOrderId: restData.workOrderId,
        fieldName: restData.fieldName,
      },
      pagination: {
        totalImageCount: restData.totalImageCount,
        totalImagePageCount: restData.totalImagePageCount,
      },
      images: data.reduce((reducer, d) => {
        const { probeImages } = d;
        return [...reducer, ...probeImages];
      }, []),
    };

    newContent.groupedImages = newContent.images.reduce(
      (reducer, item, index) => {
        const label = item.label || UNLABELED;
        const baseContent = reducer[label] || [];

        reducer[label] = [...baseContent, item];
        reducer[label].sort((a, b) =>
          a.acquisitionDate > b.acquisitionDate ? 1 : -1
        );
        return reducer;
      },
      {}
    );

    for (let flagGroup in newContent.groupedImages) {
      newContent.groupedImages[flagGroup] = newContent.groupedImages[
        flagGroup
      ].reduce((reducer, item, index) => {
        const label = dateToString(item.acquisitionDate);
        const baseContent = reducer[label] || [];
        reducer[label] = [...baseContent, item];
        return reducer;
      }, {});
    }

    prevContentRef.current = newContent;
    setThumbnailContent(newContent);

    return newContent;
  }, [data]);

  const [workOrderTasks, setWorkOrderTasks] = React.useState([]);

  // fetch work order related tasks.
  React.useEffect(() => {
    if (content && content.meta && !workOrderTasks.length)
      getWorkOrderTasks(token, content.meta.workOrderId).then(
        setWorkOrderTasks
      );
    // eslint-disable-next-line
  }, [content]);

  React.useEffect(() => {
    // determine if the current review has a review task,
    // which will hold the state for showing the `Finish Review` button
    if (content && content.meta && workOrderTasks.length && !showFinishReview) {
      const showFinishReview = workOrderTasks.some(
        (task) => task.type === "review"
      );
      setShowFinishReview(showFinishReview);
    }
    // eslint-disable-next-line
  }, [workOrderTasks, content]);

  React.useEffect(() => {
    if (content && content.meta && !fieldData) {
      getWorkOrder(token, content.meta.workOrderId).then((res) => {
        const { tasks, field } = res;
        /**
         * For now, to determine if a review has been completed,
         * we will check the tasks array for a taskId of the current task Id incremented by 1,
         * this may not be the best way, but for now is the route we will take until we find a better method
         * per dave this should be correct 99.9% percent of the time
         */
        const nextTaskId = content.meta.id + 1;
        const hasFinishedReviewTask = tasks.some((a) => a.id === nextTaskId);
        setIsReviewingCompleted(hasFinishedReviewTask);

        setFieldData(field);
      });
    }
    // eslint-disable-next-line
  }, [content, token]);

  const onFinishReviewClick = () => {
    const { id: taskId } = content.meta;
    finishWorkOrderReview(taskId, token).then(({ data, success }) => {
      setIsReviewingCompleted(success);
      if (success) {
        new Swal({
          title: "Review completed successfully",
          html: "Your review was sucessfully completed.",
          icon: "success",
        }).then(() => {
          history.push(`/image-reviewer`);
        });
      } else {
        new Swal({
          title: "An error occurred finishing review",
          html:
            "We're sorry but we couldn't complete your request. Please try again and if the problem persists let us know.",
          icon: "error",
        });
      }
    });
  };

  const trueCauseFlags = React.useMemo(() => {
    let plotNum = 1;
    if (content && content.groupedImages) {
      let trueCauseGrouping = [];
      for (const [flagNum, imagesByDate] of Object.entries(
        content.groupedImages
      )) {
        // the plot_id/flagNum could be alpha numeric so we'll just assign pin number to them
        let flag = isNaN(+flagNum) ? plotNum++ : +flagNum
        let imagesByFlag = {
          pinNumber: flag,
          types: [{ images: [] }],
        };
        for (let date in imagesByDate) {
          const images = imagesByDate[date];
          imagesByFlag.location = [
            images[0].location.lng,
            images[0].location.lat,
          ];
          imagesByFlag.types = [
            { images: [...imagesByFlag.types[0].images, ...images] },
          ];
        }
        trueCauseGrouping.push(imagesByFlag);
      }
      return trueCauseGrouping;
    }
  }, [content]);

  const filter = React.useMemo(() => {
    return (image) => {
      if (!imageFilter || !imageFilter.length) return true;
      return [
        ...image.customerReviewStressItems,
        ...image.agronomyReviewStressItems,
      ].some((stressItem) => {
        return imageFilter.some((a) => a === stressItem.name);
      });
    };
  }, [imageFilter]);

  // This will take care of selecting the first image returned by the
  // API during page load
  //if (state.selectedImages.length === 0) {
  const firstGroup = content?.groupedImages ? Object.keys(content?.groupedImages)[0] : null;
  const firstGroupImages = firstGroup ? content.groupedImages[firstGroup][Object.keys(content.groupedImages[firstGroup])[0]] : null;
  useInitSelectedImage(firstGroupImages);
  //}

  if (!data && !prevContentRef.current) {
    return <Container>Loading</Container>;
  }

  return (
    <ImageReviewerContentContext.Provider value={content}>
      <ImageReviewerResponseContext.Provider value={response}>
        <ImageReviewerCropsProvider>
          <ImageReviewerCropsStressItemsSelectionProvider>
            <Tabs
              onSelect={(tabIndex) => setSelectedTabIndex(tabIndex)}
              selectedIndex={selectedTabIndex}
              variant="full"
            >
              <Container className={styles.ShowImageReviewer}>
                <div className={styles.ShowImageReviewer__Content}>
                  <div className={styles.ShowImageReviewer__Tabs}>
                    <Tabs.List>
                      <Tabs.Tab>Images</Tabs.Tab>
                      <Tabs.Tab>Map</Tabs.Tab>
                    </Tabs.List>
                  </div>

                  <div className={styles.ShowImageReviewer__Sidebar}>
                    <FilterMenu isValidating={response?.isValidating} />

                    <div className={styles.ShowImageReviewer__SidebarContent}>
                      <ImageGrid>
                        {content.groupedImages &&
                          Object.entries(content.groupedImages).map(
                            ([label, dates], i) => {
                              return (
                                <React.Fragment key={`${label}-${i}`}>
                                  {isNaN(parseInt(label)) ? (
                                    <Typography
                                      variant="body1"
                                      className={cn(
                                        classes.gridLabelTitle,
                                        i > 0 && classes.gridLabelTitleSpaced
                                      )}
                                    >
                                      {label} (Flag {i + 1})
                                    </Typography>
                                  ) : (
                                    <Typography
                                      variant="body1"
                                      className={cn(
                                        classes.gridLabelTitle,
                                        i > 0 && classes.gridLabelTitleSpaced
                                      )}
                                    >
                                      Flag {label}
                                    </Typography>
                                  )
                                  }

                                  {Object.entries(dates).map(
                                    ([date, images], i) => {
                                      return (
                                        <React.Fragment key={i}>
                                          <Typography
                                            className={
                                              classes.gridLabelSubTitle
                                            }
                                            color="textSecondary"
                                          >
                                            {date}
                                          </Typography>

                                          {images
                                            .filter(filter)
                                            .map((image) => {
                                              const selected =
                                                selectedImagesById[image.id];

                                              return (
                                                <React.Fragment key={image.id}>
                                                  <ProbeImageThumb
                                                    selected={selected}
                                                    {...image}
                                                  />
                                                </React.Fragment>
                                              );
                                            })}
                                        </React.Fragment>
                                      );
                                    }
                                  )}
                                </React.Fragment>
                              );
                            }
                          )}

                        {content.images.length === 0 && (
                          <Typography
                            variant="body2"
                            className={cn(classes.gridLabelTitle)}
                          >
                            No images match your criteria
                          </Typography>
                        )}

                        {isValidating && <ProbeImageSkeleton />}

                        {paginationStatus === "enabled" && (
                          <ImageGrid.Sentinel onVisible={onLoad} />
                        )}
                      </ImageGrid>
                    </div>

                    <div className={styles.ShowImageReviewer__SidebarCta}>
                      {showFinishReview && (
                        <Button
                          onClick={onFinishReviewClick}
                          type="button"
                          text="Finish Review"
                          disabled={isReviewingCompleted}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <Grid container className={styles.ShowImageReviewer__Column}>
                  <ShowImageReviewerActions
                    showDeleted={filters?.remote?.status.includes("deleted")}
                  />

                  <Grid
                    container
                    item
                    className={styles.ShowImageReviewer__ImageContainer}
                  >
                    <Tabs.Panel
                      className={cn(
                        "react-tabs__tab-panel",
                        styles.ShowImageReviewer__Tab
                      )}
                    >
                      <ShowImageReviewerShelf />

                      <ProbeImagePreview />
                    </Tabs.Panel>

                    <Tabs.Panel
                      className={cn(
                        "react-tabs__tab-panel",
                        styles.ShowImageReviewer__Tab
                      )}
                    >
                      <Map
                        containerId="image-review-map"
                        fields={[{ id: 0, ...fieldData, show: true }]}
                        trueCauseFlags={trueCauseFlags}
                        onTrueCauseFlagHover={flagHoverCallback}
                        onTrueCauseFlagClick={flagClickCallback}
                        offset={[0, 0]}
                      />
                    </Tabs.Panel>
                  </Grid>
                </Grid>


              </Container>
            </Tabs>
          </ImageReviewerCropsStressItemsSelectionProvider>
        </ImageReviewerCropsProvider>
      </ImageReviewerResponseContext.Provider>
    </ImageReviewerContentContext.Provider>
  );
};

const ShowImageReviewerState = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <ImageReviewerDispatchContext.Provider value={dispatch}>
      <ImageReviewerStateContext.Provider value={state}>
        <ShowImageReviewerContent />
      </ImageReviewerStateContext.Provider>
    </ImageReviewerDispatchContext.Provider>
  );
};

export const ShowImageReviewer = () => {
  return (
    <TrueCauseItemFilters>
      <ImageStressItemFilters>
        <ImageReviewerFiltersController>
          <ShowImageReviewerState />
        </ImageReviewerFiltersController>
      </ImageStressItemFilters>
    </TrueCauseItemFilters>
  );
};
