import React, { useState, useContext } from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import styles from "./Shelf.module.scss";
import { Flex } from "../../Layouts/Flex";
import { MapContext } from "../../../globalState";

export const Shelf = ({ children, className = "", open = true }) => {
  const [hideShelf, setHideShelf] = useState(!open);
  const { setFullScreen } = useContext(MapContext);

  const collapseShelf = () => {
    const newHideShelf = !hideShelf;
    setFullScreen(newHideShelf);
    setHideShelf(newHideShelf);
  };

  return (
    <Flex
      className={`${styles.Shelf} ${
        hideShelf && styles.HideShelf
      } ${className}`}
    >
      {children}
      <Flex
        justifyContent="center"
        alignItems="center"
        className={`${styles.Collapse} ${hideShelf && styles.CollapseRotate}`}
        onClick={collapseShelf}
      >
        <ArrowForwardIosIcon />
      </Flex>
    </Flex>
  );
};
