import React from "react";
import { Switch, Route } from "react-router-dom";

import { Button } from "../../../Helpers/Button/Button";
import { Chip } from "../../../Helpers/Chip/Chip";

import Collapse from "@material-ui/core/Collapse";

import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import cn from "clsx";
import { useApi, useLazyApi } from "../../../../hooks/useApi";

import Swal from "sweetalert2";
import { AddUserPermissionsPanel } from "./AddUserPermissionsPanel";
import { ImageReviewAccounts } from "./ImageReviewAccounts";
import {
  Checkbox,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import { SettingsHeading } from "../Account";

const ImageReviewerRow = ({ name, email, accessLevel, selected, onSelect }) => {
  return (
    <TableRow>
      <TableCell>
        <Checkbox
          color="primary"
          checked={selected}
          onClick={onSelect}
        ></Checkbox>
      </TableCell>
      <TableCell>
        <Typography variant="h6">{name}</Typography>
        <Typography variant="body1">{email}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="h6">{accessLevel}</Typography>
      </TableCell>
    </TableRow>
  );
};

const useStyles = makeStyles((theme) => ({
  toolBarButton: {
    height: "2rem",
    backgroundColor: "inherit",

    "& i": {
      margin: "auto",
    },
  },
  buttonAdd: {
    color: theme.palette.text.primary,
    backgroundColor: "inherit",
    padding: 0,
  },
  buttonAddSelected: {
    color: theme.palette.primary.main,
  },
  buttonSelectAll: {
    backgroundColor: theme.palette.background.quinary,
  },
  buttonDelete: {
    color: theme.palette.primary.main,
    padding: 0,
  },
  buttonSelectAllSelected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.primary,
  },
  muiIcon: {
    stroke: "initial",
    strokeWidth: "1px",
  },
}));

export const ImageReviewUsers = (props) => {
  const [showAddUserPanel, setShowAddUserPanel] = React.useState(false);
  const [selectedUsers, setSelectedUsers] = React.useState({});
  const [isSelectAllMode, setIsSelectAllMode] = React.useState(false);

  const customerAccountId = props.match.params.customerAccountId;

  const { data: customerAccount } = useApi(
    `/api/v1/customer_accounts/${customerAccountId}`
  );

  const { data: userAccessList, revalidate: revalidateUsers } = useApi(
    `/api/v1/image_review/${customerAccountId}/user_access_list/`,
    {
      method: "GET",
    }
  );

  const [addSubscription] = useLazyApi(`/api/v1/subscriptions/`, {
    method: "POST",
  });

  const [removeSubscription] = useLazyApi(`/api/v1/subscriptions/remove`, {
    method: "DELETE",
  });

  const classes = useStyles();

  if (!customerAccount || !userAccessList) {
    return (
      <Grid item container>
        <Grid item xs={12}>
          <SettingsHeading text="Image Reviewer Access" />
        </Grid>
        <Grid item xs={12} justifyContent="center">
          <Typography>Loading...</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container item direction="column">
      <Grid item>
        <SettingsHeading text="Image Reviewer Access" />
      </Grid>

      <Grid container item style={{ padding: "2rem 0" }}>
        <Grid container item xs={10} justifyContent="center" direction="column">
          {customerAccount.name} - Users with Customer Account Access
        </Grid>

        <Grid item container xs={2} justifyContent="center" direction="column">
          <Grid item container wrap="nowrap" spacing={4}>
            <Grid item>
              <Chip
                className={cn(
                  classes.toolBarButton,
                  classes.buttonSelectAll,
                  isSelectAllMode && classes.buttonSelectAllSelected
                )}
                onClick={() => {
                  if (isSelectAllMode) {
                    setSelectedUsers(
                      Object.fromEntries(
                        userAccessList.subscribers.map((s) => [
                          s.subscriptionId,
                          false,
                        ])
                      )
                    );
                    setIsSelectAllMode(false);
                  } else {
                    setSelectedUsers(
                      Object.fromEntries(
                        userAccessList.subscribers.map((s) => [
                          s.subscriptionId,
                          true,
                        ])
                      )
                    );
                    setIsSelectAllMode(true);
                  }
                }}
              >
                Select all
              </Chip>
            </Grid>

            {Object.values(selectedUsers).find((v) => v) && (
              <Grid item justifyContent="center">
                <Button
                  click={async () => {
                    const subscriptionIds = Object.keys(selectedUsers).filter(
                      (key) => selectedUsers[key] === true
                    );
                    if (subscriptionIds.length > 0) {
                      await removeSubscription({
                        data: {
                          subscriptionIds: subscriptionIds,
                        },
                      })
                        .then(async () => {
                          await Swal.fire({
                            icon: "success",
                            title: "Success!",
                            text: "Image reviewers removed",
                          });
                          setSelectedUsers({});
                          revalidateUsers();
                        })
                        .catch(async (err) => {
                          await Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text:
                              "An error occurred while removing image reviewers",
                          });
                          console.error(err);
                          setSelectedUsers({});
                          revalidateUsers();
                        });
                    }
                  }}
                  icon={
                    <i className={cn("far", "fa-trash-alt", classes.muiIcon)} />
                  }
                  className={cn(classes.toolBarButton, classes.buttonDelete)}
                />
              </Grid>
            )}

            <Grid item>
              <Button
                click={() => setShowAddUserPanel(!showAddUserPanel)}
                icon={
                  showAddUserPanel ? (
                    <ClearIcon className={classes.muiIcon} />
                  ) : (
                    <AddIcon className={classes.muiIcon} />
                  )
                }
                className={cn(
                  classes.toolBarButton,
                  classes.buttonAdd,
                  showAddUserPanel && classes.buttonAddSelected
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item>
        <Collapse in={showAddUserPanel}>
          <AddUserPermissionsPanel
            onAddUser={async (userEmail) => {
              await addSubscription({
                data: {
                  resourceId: customerAccountId,
                  resourceType: "CustomerAccount",
                  subscribers: [
                    {
                      email: userEmail,
                      role: null,
                    },
                  ],
                },
              })
                .then(async (res) => {
                  await Swal.fire({
                    icon: "success",
                    title: "Success!",
                    text: "Image reviewer added",
                  });
                  revalidateUsers();
                  return res;
                })
                .catch(async (err) => {
                  await Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "An error occurred while adding image reviewer",
                  });
                  console.error(err);
                  setSelectedUsers({});
                  revalidateUsers();
                });
            }}
            onCancel={() => setShowAddUserPanel(false)}
          />
        </Collapse>
      </Grid>

      <Grid item style={{ padding: "2rem 0" }}>
        <TableContainer>
          <Table>
            <TableBody>
              {userAccessList.subscribers.map((s) => (
                <ImageReviewerRow
                  key={s.subscriptionId}
                  name={s.name || "Name Not Set"}
                  email={s.email}
                  accessLevel={s.role}
                  selected={selectedUsers[s.subscriptionId] === true}
                  onSelect={() => {
                    if (selectedUsers[s.subscriptionId]) {
                      setSelectedUsers({
                        ...selectedUsers,
                        [s.subscriptionId]: false,
                      });
                    } else {
                      setSelectedUsers({
                        ...selectedUsers,
                        [s.subscriptionId]: true,
                      });
                    }
                  }}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export const ImageReviewAccess = (props) => {
  return (
    <Switch>
      <Route
        path={`${props.match.path}/customer-account/:customerAccountId`}
        component={ImageReviewUsers}
      />
      <Route
        exact
        path={`${props.match.path}/`}
        component={ImageReviewAccounts}
      />
    </Switch>
  );
};
