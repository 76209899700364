import React from "react";
import { ShelfLabel } from "../../../../Helpers/ShelfLabel/ShelfLabel";
import { useDrawerStyles } from "../FieldShelf";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Table } from "../../../../Helpers/Table/Table.jsx";
import {
  makeStyles,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Typography,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import DateRangeIcon from "@material-ui/icons/DateRange";
import AddLocationIcon from "@material-ui/icons/AddLocation";
import { useField } from "../../../../../globalState/fieldContext";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.tertiary,
  },
}));

const useMiniStepperConnectorStyles = makeStyles((theme) => ({
  line: {
    borderColor: theme.palette.primary.main,
    borderLeftWidth: 2,
    maxHeight: 10,
  },
  lineVertical: {
    minHeight: 10,
  },
  vertical: {
    paddingTop: "4px",
    paddingBottom: "4px",
    marginLeft: "9px",
  },
}));

const useWorkOrderHistoryDetailStyles = makeStyles((theme) => {
  return {
    labelText: {
      fontSize: 12,
      color: theme.palette.text.primary,
    },
    stepperPadding: {
      padding: 8,
    },
    highlightActiveRow: {
      backgroundColor: theme.palette.background.tertiary,
    },
  };
});

const WorkOrderDetail = ({ row, visibleColumns, seasonData }) => {
  const workOrderData =
    seasonData &&
    seasonData.workOrders &&
    seasonData.workOrders.find((wo) => wo.id === row.values.id);

  const classes = useStyles();
  const connectorClasses = useMiniStepperConnectorStyles();
  const stepperClasses = useWorkOrderHistoryDetailStyles();

  const stepTypes = {
    orderRejected: (t) => (
      <Step key={t.id}>
        <StepLabel
          StepIconComponent={() => <AddToPhotosIcon fontSize={"small"} />}
        >
          <Typography className={stepperClasses.labelText} variant="body1">
            Order rejected ({new Date(t.date).toLocaleDateString()})
          </Typography>
        </StepLabel>
      </Step>
    ),
    orderComplete: (t) => (
      <Step key={t.id}>
        <StepLabel
          StepIconComponent={() => <AddToPhotosIcon fontSize={"small"} />}
        >
          <Typography className={stepperClasses.labelText} variant="body1">
            Order complete ({new Date(t.date).toLocaleDateString()})
          </Typography>
        </StepLabel>
      </Step>
    ),
    orderDataCollected: (t) => (
      <Step key={t.id}>
        <StepLabel
          StepIconComponent={() => <AddToPhotosIcon fontSize={"small"} />}
        >
          <Typography className={stepperClasses.labelText} variant="body1">
            Data collected ({new Date(t.date).toLocaleDateString()})
          </Typography>
        </StepLabel>
      </Step>
    ),
    orderDataProcessing: (t) => (
      <Step key={t.id}>
        <StepLabel
          StepIconComponent={() => <CheckBoxIcon fontSize={"small"} />}
        >
          <Typography className={stepperClasses.labelText} variant="body1">
            Data processing ({new Date(t.date).toLocaleDateString()})
          </Typography>
        </StepLabel>
      </Step>
    ),
    orderRequested: (t) => (
      <Step key={t.id}>
        <StepLabel
          StepIconComponent={() => <AddLocationIcon fontSize={"small"} />}
        >
          <Typography className={stepperClasses.labelText} variant="body1">
            Order requested ({new Date(t.date).toLocaleDateString()})
          </Typography>
        </StepLabel>
      </Step>
    ),
    orderScheduled: (t) => (
      <Step key={t.id}>
        <StepLabel
          StepIconComponent={() => <DateRangeIcon fontSize={"small"} />}
        >
          <Typography className={stepperClasses.labelText} variant="body1">
            Order scheduled ({new Date(t.date).toLocaleDateString()})
          </Typography>
        </StepLabel>
      </Step>
    ),
    orderAccepted: (t) => (
      <Step key={t.id}>
        <StepLabel
          StepIconComponent={() => <CheckBoxIcon fontSize={"small"} />}
        >
          <Typography className={stepperClasses.labelText} variant="body1">
            Order accepted ({new Date(t.date).toLocaleDateString()})
          </Typography>
        </StepLabel>
      </Step>
    ),
  };

  return (
    <tr className={classes.container}>
      <td colSpan={visibleColumns.length}>
        {workOrderData && (
          <Grid container>
            <Stepper
              orientation="vertical"
              connector={<StepConnector classes={connectorClasses} />}
              style={{ backgroundColor: "inherit" }}
              className={stepperClasses.stepperPadding}
            >
              <Step>
                <StepLabel StepIconComponent={() => <Grid />}></StepLabel>
              </Step>
              {workOrderData.timeline.map((t, timelineIndex) => {
                return (
                  (stepTypes[t.type] && stepTypes[t.type](t)) || (
                    <Step key={timelineIndex}>
                      <StepLabel StepIconComponent={() => <Grid />}>
                        <Typography variant="body1">
                          Unknown? {t.type}
                        </Typography>
                      </StepLabel>
                    </Step>
                  )
                );
              })}
            </Stepper>
          </Grid>
        )}
      </td>
    </tr>
  );
};

const WorkOrderDrawer = ({ workOrderToEdit }) => {
  const { selectedSeason } = useField();
  const [open, setOpen] = React.useState(false);
  const drawerClasses = useDrawerStyles();
  const stepperClasses = useWorkOrderHistoryDetailStyles();

  const renderRowSubComponent = React.useCallback(
    ({ row, visibleColumns }) => (
      <WorkOrderDetail
        row={row}
        visibleColumns={visibleColumns}
        seasonData={selectedSeason}
      />
    ),
    [selectedSeason]
  );

  const handleClick = () => {
    setOpen(!open)
  }

  React.useEffect(() => {
    if (workOrderToEdit) {
      setOpen(true)
    }
  }, [workOrderToEdit]);

  return (
    <Grid container className={drawerClasses.root}>
      <ShelfLabel
        label={`Field History (${(selectedSeason.workOrders ?? []).length
          })`}
        showOptions={open}
        select={() => handleClick()}
        classes={clsx(open && drawerClasses.showOptions)}
      />

      {open && (
        <Grid className={drawerClasses.options}>

          {selectedSeason === null ? (
            <>
              <Typography>Loading season data...</Typography>
              <CircularProgress color={"primary"} />
            </>
          ) : (
            <Table
              noResultsLabel={"No work orders found."}
              renderRowSubComponent={renderRowSubComponent}
              clickRowToExpand
              highlightClassName={stepperClasses.highlightActiveRow}
              columns={[
                {
                  Header: "Order ID",
                  sortable: false,
                  accessor: "id",
                },
                // {
                //   Header: "Service Date",
                //   sortable: false,
                //   accessor: (row, idx) => {
                //     const filteredTimeline = row.timeline.find(
                //       (time) => time.type === "orderDataCollected"
                //     );
                //     const date = filteredTimeline?.date;
                //     return !date ? "N/A" : new Date(date).toLocaleDateString();
                //   },
                // },
                {
                  Header: "Service",
                  sortable: false,
                  accessor: "service",
                },
                {
                  Header: " ",
                  sortable: false,
                  accessor: "action"
                },
                {
                  sortable: false,
                  id: "icons",
                  Cell: ({ _value, _row }) => (
                    <div style={{ width: "1rem" }}></div>
                  )
                },
              ]}
              data={selectedSeason.workOrders ?? []}
              dataLoaded={!!selectedSeason}
              autoEditWorkOrderId={workOrderToEdit}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default WorkOrderDrawer;
