import React, { useMemo, useState, useContext } from "react";
import Swal from "sweetalert2";
import EditIcon from "@material-ui/icons/Edit";
import * as Feather from "react-feather";
import {
  deleteRegion,
  maxRegionNameLengthForDisplay
} from "../../../../../controllers/regions";
import { useAuth } from "../../../../../hooks/useAuth";
import {
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Checkbox, ShelfLabel } from "../../../../Helpers";
import { useField } from "../../../../../globalState/fieldContext";
import { generateBorder } from "../../../../../styles/generateStyles";
import { MapContext } from "../../../../../globalState";

const useStyles = makeStyles((theme) => ({
  regionOption: {
    paddingRight: "1rem",
  },
  subOption: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    width: "100%",
    paddingLeft: "1rem",
    boxSizing: "border-box",
    borderTop: generateBorder(1, theme.palette.borderColor.primary),
  },
  label: {
    "& > span:last-of-type": {
      overflowWrap: "anywhere",
    },
  },
  buttons: {
    justifySelf: "flex-end",
    alignSelf: "center",
    "& .MuiIconButton-root": {
      padding: "0.5rem",
    },
    "& svg": {
      fontSize: "18px",
    },
  },
}));

export const RegionOption = ({
  i,
  label,
  typeRegions,
  editRegion,
  setEditRegion,
  regionsByType,
  setRegionsByType,
}) => {
  const classes = useStyles();
  const { fieldMap } = useContext(MapContext);
  const { geometryToEdit, setGeometryToEdit, setDrawingEnabled, refreshRegions, refreshMapDrawing } = useField();
  const [showOptions, setShowOptions] = useState(false);
  const { token } = useAuth();
  const regionIdsDisplayed = useMemo(() => {
    return typeRegions
      .map((region) => (region.show ? region.id : null))
      .filter((region) => region);
    // eslint-disable-next-line
  }, [regionsByType]);

  const selectAll = (e, label) => {
    e.stopPropagation();
    if (typeRegions.length > 0) {
      const newRegionsByType = regionsByType.map((type) => {
        if (type.label === label) {
          for (let region of type.regions) {
            region.show = e.target.checked;
          }
        }
        return type;
      });
      setRegionsByType(newRegionsByType);
    }
  };

  const selectOption = (e, regionSelected) => {
    e.preventDefault();
    setRegionsByType(toggleShowRegion(regionSelected));
  };

  const toggleShowRegion = (regionToUpdate) => {
    return regionsByType.map((type) => {
      for (let region of type.regions) {
        if (region.id === regionToUpdate.id) {
          region.show = !region.show;
        }
      }
      return type;
    });
  };

  const handleEditRegion = (regionToEdit) => {
    if (!regionToEdit.show) {
      setRegionsByType(toggleShowRegion(regionToEdit));
    }
    setEditRegion(true);
    setDrawingEnabled(true);
    setGeometryToEdit(regionToEdit);
  };

  const removeLayer = async (layerId) => {
    fieldMap.style.getLayer(layerId) && (await fieldMap.removeLayer(layerId));
  };

  const removeRegionFromMap = (regionToRemove) => {
    const polygonId = `field-map-polygon-${regionToRemove.id}`;
    const fillId = polygonId + "-fill";
    const lineId = polygonId + "-line";
    const symbolId = polygonId + "-symbol";
    const polygonPointId = polygonId + "-label-point";

    removeLayer(fillId);
    removeLayer(lineId);
    removeLayer(symbolId);
    removeLayer(polygonPointId);
    fieldMap.removeSource(polygonId);
  }

  const handleDeleteRegion = async (regionToDelete) => {
    const result = await Swal.fire({
      title: "Are you sure you want to delete this region?",
      icon: "question",
      dangerMode: true,
      showDenyButton: true,
      denyButtonText: "Cancel",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      const res = await deleteRegion(token, regionToDelete.id);
      if (res.status === 200) {
        removeRegionFromMap(regionToDelete);
        const updatedRegions = regionsByType.map((type) => {
          return {
            ...type,
            regions: type.regions.filter((a) => a.id !== regionToDelete.id),
          };
        });
        setRegionsByType(updatedRegions);
        setGeometryToEdit(null);
        refreshRegions();
        refreshMapDrawing();
      }
    }
  };

  const toggleDropDown = (e) => {
    e.preventDefault();
    setShowOptions(!showOptions);
  };

  return (
    <Grid key={i} container item className={classes.regionOption}>
      <ShelfLabel
        label={
          <FormControlLabel
            control={
              <Checkbox
                checked={regionIdsDisplayed.length === typeRegions.length}
                onClick={(e) => selectAll(e, label)}
              />
            }
            label={label}
          />
        }
        showOptions={showOptions}
        select={(e) => toggleDropDown(e)}
      />
      {showOptions &&
        typeRegions.map((region, i) => {
          return (
            <div key={[region, i].join('')} className={classes.subOption}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: region.color }}
                    checked={region.show}
                  />
                }
                label={
                  (region.type !== 'soil_sample') ? (
                    (region.name.length > maxRegionNameLengthForDisplay) && (region.type !== 'soil_sample')
                      ? `${region.marker}) ${region.name} (${region.size.toFixed(1)} ac)` : `${region.name}  (${region.size.toFixed(1)} ac)`)
                    : ((region.name.length > maxRegionNameLengthForDisplay) && (region.type !== 'soil_sample')
                      ? `${region.marker}) ${region.name}` : `${region.name}`)
                }
                onClick={(e) => selectOption(e, region)}
                className={classes.label}
              />
              <Grid className={classes.buttons}>
                {!geometryToEdit && (
                  <>
                    <IconButton onClick={() => handleEditRegion(region)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteRegion(region)}>
                      <Feather.Trash2 className="feather-icon" size={18} />
                    </IconButton>
                  </>
                )}
                {geometryToEdit &&
                  editRegion &&
                  geometryToEdit.id === region.id && (
                    <Typography variant="subtitle2">Editing...</Typography>
                  )}
              </Grid>
            </div>
          );
        })}
    </Grid>
  );
};
