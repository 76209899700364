import axios from "axios";
const isStagingSite = process.env.REACT_APP_ENV.includes("staging");
const isDevSite = process.env.REACT_APP_ENV.includes("development");
const baseUrl = isDevSite
    ? process.env.REACT_APP_BACK_OFFICE_URL
    : isStagingSite
        ? process.env.REACT_APP_BACK_OFFICE_URL_STAGING
        : process.env.REACT_APP_BACK_OFFICE_URL_PRODUCTION;

//const { baseUrl } = process.env

export const getFarms = async (authToken) => {
    const result = await axios({
        method: 'GET',
        url: `${baseUrl}/api/v1/farms`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
        },
    })
    return result.data.data
}

export const getFarmsFields = async (authToken, farmId) => {
    const result = await axios({
        method: 'GET',
        url: `${baseUrl}/api/v1/farms/${farmId}/fields`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
        },
    })
    return result?.data?.data
}

export const createFarm = async (authToken, farm) => {
    return await axios({
        method: "POST",
        url: `${baseUrl}/api/v1/farms`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
        },
        data: { data: farm }
    }).then(res => {
        return res.data.data
    })
}

export const deleteFarm = async (authToken, farmId) => {
    return await axios({
        method: "DELETE",
        url: `${baseUrl}/api/v1/farms/${farmId}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
        },
    }).then(res => {
        return res.data
    }).catch(err => {
        console.log('err', err);
        return false;
    })
}

export const updateFarm = async (authToken, farmData) => {
    return await axios({
        method: "PATCH",
        url: `${baseUrl}/api/v1/farms/${farmData.farmId}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
        },
        data: { data: { farmTitle: farmData.farmName, defaultFarmHandId: farmData.defaultFarmHandId } }
    }).then(res => {
        return res.data
    }).catch(err => {
        console.log('err', err);
        return false;
    })
}

export const getFarmsPagination = async (authToken, account, per_page, page) => {
    const result = await axios({
        method: "GET",
        url: `${baseUrl}/api/v1/farms_details?per_page=${per_page}&page=${page} ${account ? "&account=" + account : ""}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
        },
    });
    return result.data;
};