import React, { useState } from "react";
import {
  IconButton,
  Grid,
  makeStyles,
  Button,
  Typography,
} from "@material-ui/core";
import NavigateNextRoundedIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeRoundedIcon from "@material-ui/icons/NavigateBefore";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import cn from "clsx";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import InnerImageZoom from 'react-inner-image-zoom';

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
    width: `calc(100% - ${theme.palette.spacing.shelfWidth})`,
    backgroundColor: theme.palette.colors.gray48,
    boxSizing: "border-box",
  },
  imagesWrapper: {
    position: "relative",
    display: "grid",
    gridTemplateColumns: "repeat(3, auto)",
    alignItems: "center",
    height: "100%",
    width: "100%",
    marginBottom: "1rem",
  },
  closeBtn: {
    position: "absolute",
    top: "-44px",
    right: "55px",
    fontSize: theme.typography.body1.fontSize,
    "& .MuiSvgIcon-root": {
      fontSize: "1.1rem",
    },
  },
  navBtn: {
    height: "3rem",
    width: "3rem",
    backgroundColor: theme.palette.background.primary,
    border: `1px solid ${theme.palette.colors.gray70}`,
  },
  btnPrev: {
    marginRight: "1rem",
  },
  btnNext: {
    marginLeft: "1rem",
  },
  imageContainer: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
}));

export const TrueCauseImageViewer = ({ images, setImagesToView }) => {
  const classes = useStyles();
  const [currentImage, setCurrentImage] = useState(0);
  const isOneImage = images.length === 1
  return (
    <Grid container justifyContent="center" className={classes.root}>
      <div className={classes.imagesWrapper}>
        {!isOneImage && <IconButton
          className={cn(classes.navBtn, classes.btnPrev)}
          onClick={
            currentImage !== 0
              ? () => setCurrentImage(currentImage - 1)
              : () => { }
          }
        >
          <NavigateBeforeRoundedIcon />

        </IconButton>}
        {/* Chetu Inner image code */}
        <Grid container alignItems="center" className={classes.imageContainer}>
          <InnerImageZoom
            src={images[currentImage].imagePath}
            alt="observation"
            moveType={"drag"}
          />
        </Grid>
        {!isOneImage && <IconButton
          className={cn(classes.navBtn, classes.btnNext)}
          onClick={
            currentImage < images.length - 1
              ? () => setCurrentImage(currentImage + 1)
              : () => { }
          }
        >
          <NavigateNextRoundedIcon />
        </IconButton>}
        <div className="btn-right">
          <Button
            startIcon={<CloseRoundedIcon />}
            className={classes.closeBtn}
            onClick={() => setImagesToView(null)}
          >
            Close
          </Button>
        </div>
      </div>
      <Typography className="typo-cust">
        Image {currentImage + 1}/{images.length}
      </Typography>
    </Grid>
  );
};
