import axios from "axios";
const isStagingSite = process.env.REACT_APP_ENV.includes("staging");
const isDevSite = process.env.REACT_APP_ENV.includes("development");
const baseUrl = isDevSite
  ? process.env.REACT_APP_BACK_OFFICE_URL
  : isStagingSite
    ? process.env.REACT_APP_BACK_OFFICE_URL_STAGING
    : process.env.REACT_APP_BACK_OFFICE_URL_PRODUCTION;
// const baseUtilUrl = process.env.REACT_APP_UTIL_SERVER;

export const getSeason = async (authToken, seasonId) => {
  const result = await axios({
    method: "GET",
    url: `${baseUrl}/api/v1/seasons/${seasonId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${authToken}`,
    },
  });
  return result.data.data;
};

export const getSeasonConfigs = async () => {
  const result = await axios({
    method: "GET",
    url: `${baseUrl}/api/v1/seasons/configuration`,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return result.data.data;
};

export const getSeasonRegions = async (authToken, seasonId) => {
  const result = await axios({
    method: "GET",
    url: `${baseUrl}/api/v1/seasons/${seasonId}/regions`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${authToken}`,
    },
  });
  return result.data.data;
};

export const addSeason = async (token, season) => {
  const result = await axios({
    method: "POST",
    url: `${baseUrl}/api/v1/seasons`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    data: { data: season },
  });
  return result.data.data;
};

export const editSeason = async (token, seasonId, data) => {
  const result = await axios({
    method: "PATCH",
    url: `${baseUrl}/api/v1/seasons/${seasonId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    data: { data },
  });
  return result.data.data;
};

// export const editFlightPath = async (token, work_order_id, flags) => {
//   return await axios({
//     method: "PATCH",
//     url: `${baseUtilUrl}/scouting_route`,
//     headers: {
//       "Content-Type": "application/json",
//       Accept: "*/*",
//       Authorization: `Token ${token}`,
//     },
//     data: {
//       work_order_id: work_order_id,
//       environment: process.env.REACT_APP_ENV.includes("production")
//         ? "production"
//         : "staging",
//       flags,
//     },
//   })
//     .then(res => res.data)
//     .catch((err) => {
//       console.error(err);
//       return false;
//     });
// };
