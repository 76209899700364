import * as React from "react";

// Holds the filters to be applied when performing API requests to our
// backend (favorited, healthy, etc)
const ImageReviewerStressItemFiltersStateContext = React.createContext();
const ImageReviewerStressItemFiltersDispatchContext = React.createContext(() => { });

const reducer = (state, action) => {
    switch (action.type) {
        case "filter-clear": {
            return { }
        }
        case "filter-apply": {
            const {
                type: filterType,
                name: filterName,
            } = action.payload;

            if (action.payload?.action === "replace") {
                return {
                    ...state,
                    [filterType]: {
                        ...(state[filterType] || {}),
                        [filterName]: filterName,
                    },
                };
            } else if (action.payload?.action === "remove") {
                return {
                    ...state,
                    [filterType]: {
                        ...(state[filterType] || {}),
                        [filterName]: undefined,
                    },
                };
            } else if (action.payload?.action === 'add') {

                return {
                    ...state,
                    [filterType]: {
                        ...(state[filterType] || {}),
                        [filterName]: filterName,
                    },
                };
            }
            break;
        }

        default:
            return state;
    }
};

export const ImageStressItemFilters = ({ children }) => {
    const [state, dispatch] = React.useReducer(reducer, {});

    return (
        <ImageReviewerStressItemFiltersDispatchContext.Provider value={dispatch}>
            <ImageReviewerStressItemFiltersStateContext.Provider value={state}>
                {children}
            </ImageReviewerStressItemFiltersStateContext.Provider>
        </ImageReviewerStressItemFiltersDispatchContext.Provider>
    );
};

export const useStressItemFiltersState = () => {
    const state = React.useContext(ImageReviewerStressItemFiltersStateContext);

    return state || {};
};

export const useStressItemFiltersDispatch = () => {
    const dispatch = React.useContext(ImageReviewerStressItemFiltersDispatchContext);

    return dispatch;
};
