import React from "react";
import { SettingsHeading } from "../Account";
import { Table } from "../../../Helpers/Table/Table";
import { SearchWithIcon } from "../../../Helpers/Search/SearchWithIcon";
import FarmFieldsUsersAccess from "./FarmFieldsUsersAccess";
import { makeStyles, Grid, Typography, Breadcrumbs } from "@material-ui/core";
import { Route, Switch, Link } from "react-router-dom";
import { useFarms } from "../../../../globalState/farmContext";
import { CustomerAccountsSelector } from "../../../Helpers/CustomerAccountsSelector/CustomerAccountsSelector";
import {
  useCustomerAccounts,
  useSelectedCustomerAccount,
} from "../../../../globalState";

const useStyles = makeStyles(() => ({
  sectionContainer: {
    marginBottom: "2rem",
  },
}));

const ListItem = ({ itemType, id, displayValue }) => {
  return (
    <Link
      to={`farm-fields-access/${itemType}/${id}`}
      style={{ textDecoration: "none" }}
    >
      <Typography variant="body1">{displayValue}</Typography>
    </Link>
  );
};

const FarmList = ({ farms, isLoaded }) => {
  return (
    <Table
      columns={[
        {
          Header: "Farm",
          accessor: (farm) => {
            return (
              <ListItem
                key={farm.id}
                id={farm.id}
                displayValue={farm.title}
                itemType="farm"
              />
            );
          },
        },
        {
          Header: "Fields",
          accessor: ({ fields }) => {
            return fields.map((field) => (
              <ListItem
                key={field.id}
                id={field.id}
                displayValue={field.title}
                itemType="field"
              />
            ));
          },
        },
        {
          Header: "# of Users with Access",
          accessor: ({ fields }) => {
            return fields.map((field) => (
              <ListItem
                key={field.id}
                id={field.id}
                displayValue={field.usersWithAccessCount}
                itemType={"field"}
              />
            ));
          },
        },
      ]}
      data={farms || []}
      dataLoaded={isLoaded}
    />
  );
};

export const FarmFieldsList = () => {
  const classes = useStyles();
  const selectedCustomerAccount = useSelectedCustomerAccount();
  const customerAccounts = useCustomerAccounts();
  const customerAccountName = customerAccounts?.find(
    (act) => act?.id === selectedCustomerAccount?.id
  )?.name;
  // this might be in farm context (useFarms) now -- could be worth checking
  // first to save an extra API call
  const { allFarms, farmsAreLoaded } = useFarms();
  const [farms, setFarms] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");

  React.useEffect(() => {
    if (allFarms) {
      let farms = allFarms;
      if (searchValue) {
        farms = farms
          .filter((farm) => {
            let val = farm.fields.find((field) =>
              field.title.toLowerCase().includes(searchValue.toLowerCase())
            );

            return (
              !!val ||
              farm.title.toLowerCase().includes(searchValue.toLowerCase())
            );
          })
          .map((farm) => ({
            ...farm,
            fields: farm.fields.filter((field) =>
              field.title.toLowerCase().includes(searchValue.toLowerCase())
            ),
          }));
      }
      if (customerAccountName)
        farms = farms.filter(
          (farm) => farm.customerAccountName === customerAccountName
        );
      setFarms(farms);
    }
    // eslint-disable-next-line
  }, [allFarms, searchValue, selectedCustomerAccount]);

  return (
    <>
      <Grid container direction="column" justifyContent="flex-start" spacing={3}>
        <Grid item className={classes.sectionContainer}>
          <Breadcrumbs>
            <Typography variant="body1">Farm/Field Access</Typography>
            <Typography variant="body1">Farms</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item>
          <SettingsHeading text="Farms" />
        </Grid>

        <Grid item container direction="column" spacing={2}>
          {
            <>
              <Grid item>
                <Typography>Search for a farm or field:</Typography>
              </Grid>

              <Grid item lg={8}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <SearchWithIcon
                    placeholder="Search all farms"
                    searchValue={searchValue}
                    updateSearchValue={(e) => setSearchValue(e.target.value)}
                  />
                  <div style={{ marginLeft: 15, marginTop: 5, height: 55 }}>
                    <CustomerAccountsSelector />
                  </div>
                </div>
              </Grid>
            </>
          }
          {!farms.length && (
            <Grid item>
              <Typography>No farms found to display.</Typography>
            </Grid>
          )}
        </Grid>

        <Grid item container direction="column">
          <FarmList farms={farms} isLoaded={farmsAreLoaded} />
        </Grid>
      </Grid>
    </>
  );
};

export const FarmFieldsAccess = (props) => {
  return (
    <Switch>
      <Route
        path={`${props.match.path}/:itemType/:id`}
        component={FarmFieldsUsersAccess}
      />
      <Route exact path={`${props.match.path}/`} component={FarmFieldsList} />
    </Switch>
  );
};
