import React, { ReactNode } from "react";
import { lettersArray } from "../Components/Helpers";
import { getSeasonRegions } from "../controllers/seasons";
import { maxRegionNameLengthForDisplay } from "../controllers/regions";
import { useAuth } from "../hooks/useAuth";
import { MapContext } from "./mapContext";
import { getFieldSeasons, getProbeData } from "../controllers/fields";
import {
  IFieldContext,
  IField,
  ISeason,
  IRegion,
  IGeometryToEdit,
} from "../Components/Views/Field/FieldInterfaces";

export const FieldContext = React.createContext({} as IFieldContext);

export const useField = () => React.useContext(FieldContext);

export const FieldProvider = (props: {
  field: IField[];
  setField: (field: IField[] | []) => void;
  children: ReactNode;
}) => {
  const { token } = useAuth();
  const [selectedSeason, setSelectedSeason] = React.useState<ISeason | null>(
    null
  );
  const [selectedWorkOrderId, setSelectedWorkOrderId] = React.useState<number | null>(null);
  const [seasons, setSeasons] = React.useState<ISeason[] | null>(null);
  const [regions, setRegions] = React.useState<IRegion[] | null>(null);
  const [selectedRegions, setSelectedRegions] = React.useState<
    IRegion[] | null
  >(null);
  const [
    geometryToEdit,
    setGeometryToEdit,
  ] = React.useState<IGeometryToEdit | null>(null);
  const [drawingEnabled, setDrawingEnabled] = React.useState(false);

  const [
    trueCauseMarkersEditMode,
    setTrueCauseMarkersEditMode,
  ] = React.useState(false);

  const [addedLngLats, setAddedLngLats] = React.useState<
    { lat: number; lng: number }[] | null
  >(null);
  const [editedLngLats, setEditedLngLats] = React.useState<{
    id: { lat: number; lng: number };
  } | null>(null);
  const [deletedPinNumbers, setDeletedPinNumbers] = React.useState<
    number[] | null
  >(null);

  const [editedFlags, setEditedFlags] = React.useState<
    { lat: number; lng: number }[] | null
  >(null);

  // -------------------------------------------
  const [pointEditMode, setPointEditMode] = React.useState(false)
  const [soilEditMode, setSoilEditMode] = React.useState(false)
  // const [editedSoilLngLats, setEditedSoilLngLats] = React.useState<{
  //   order: { lat: number; lng: number };
  // } | null>(null);
  // const [deletedSoilLocation, setDeletedSoilLocation] = React.useState<
  //   number[] | null
  // >(null);
  // const [addedSoilLngLats,setAddedSoilLngLats] = React.useState<{ lat: number; lng: number} | null>(null);
  const [activityEditData, setActivityEditData] = React.useState<ISeason[] | null>(null);
  // const [activityUpdatedData,setActivityUpdatedData] = React.useState<ISeason[] | null>(null);
  // -------------------------------------------------
  const [newPointPrefix, setNewPointPrefix] = React.useState<string | null>(null);
  const { mapColors, soilMapColors, fieldMap, mapboxLogo } = React.useContext(MapContext);

  const getRegionsByType = (regionsToShow: number[]) => {
    const isStagingSite = process.env.REACT_APP_ENV?.includes("staging");
    const isDevSite = process.env.REACT_APP_ENV?.includes("development");

    if (regions?.length) {
      const showSsurgo = (isDevSite || isStagingSite)
        ? process.env.REACT_APP_SHOW_SSURGO_STAGING
        : process.env.REACT_APP_SHOW_SSURGO_PRODUCTION
      let treatments = [];
      let hybrids = [];
      let soilZones = [];
      //let soilSamples = [];
      let ssurgo = []
      for (let region of regions) {
        const showRegion = regionsToShow?.includes(region.id) ?? false;
        if (region.type === "application" || region.type === "user") {
          treatments.push({ ...region, show: showRegion });
        } else if (region.type === "hybrid") {
          hybrids.push({ ...region, show: showRegion });
        } else if (region.type === "soil") {
          soilZones.push({ ...region, show: showRegion });
        } else if (showSsurgo && (region.type === "ssurgo")) {
          ssurgo.push({ ...region, show: showRegion });
        }
        // else if (region.type === "soil_sample") {
        //   soilSamples.push({ ...region, show: showRegion });
        // }
      }
      return [
        { label: "Treatments", regions: treatments },
        { label: "Hybrids", regions: hybrids },
        { label: "Soil Zones", regions: soilZones },
        //{ label: "Soil Samples", regions: soilSamples },
        { label: "SSURGO", regions: ssurgo },
      ];
    }
    return [];
  };

  const refreshRegions = () => {
    updateRegionsForSelectedSeason();
  };

  const refreshMapDrawing = () => {
    setGeometryToEdit(null);
    setDrawingEnabled(false);
  };

  const fieldSorter = (fields: any) => (a: any, b: any) => fields.map((o: any) => {
    let dir = 1;
    if (o[0] === '-') { dir = -1; o = o.substring(1); }
    return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
  }).reduce((p: any, n: any) => p ? p : n, 0);

  const updateRegionsForSelectedSeason = () => {
    getSeasonRegions(token, selectedSeason?.id).then((res) => {
      let firstSoilIndex = -1;
      res.sort(fieldSorter(['type', 'name']));
      const numSoilRegions = res.filter((r: IRegion) => r.type === "soil").length;
      const soilMapStep = (soilMapColors.length - 1) / (numSoilRegions - 1);
      setRegions(
        res.map((region: IRegion, index: number) => {
          let regionColor = "";
          let marker
          let opacity = 0.1;
          let selectedOpacity = 0.7;
          if (region.type === 'soil') {
            if (firstSoilIndex < 0) {
              firstSoilIndex = index;
            }
            let i = index - firstSoilIndex;
            while (i >= soilMapColors.length) {
              i = i - soilMapColors.length;
            }
            regionColor = soilMapColors[Math.round(i * soilMapStep)];
            marker = region.name.length > maxRegionNameLengthForDisplay
              ? lettersArray[index]
              : region.name;
            opacity = 0.8;
            selectedOpacity = 0.9;
          } else {
            let i = index;
            while (i >= mapColors.length) {
              i = i - mapColors.length;
            }
            regionColor = mapColors[i];
            marker = region.name.length > maxRegionNameLengthForDisplay
              ? lettersArray[index]
              : region.name;
          }
          return {
            ...region,
            show: false,
            color: regionColor,
            marker: marker, //lettersArray[index],
            opacity: opacity,
            selectedOpacity: selectedOpacity,
          };
        })
      );
    });
  };

  const updateSeasons = async () => {
    const { field } = props;
    getFieldSeasons(token, field[0].id).then(async (res) => {
      if (res.length > 0) {
        for await (let image of res) {
          const probeData = await getProbeData(token, image.id);
          image.probeObservations = probeData;
        }
        setSeasons(res);
        setSelectedSeason(res[0]);
      } else {
        setSeasons(null);
        setSelectedSeason(null);
      }
    });
  };

  React.useEffect(() => {
    if (!!props.field.length && !seasons) {
      updateSeasons();
    }
    // eslint-disable-next-line
  }, [props.field, token]);

  React.useEffect(() => {
    if (!!selectedSeason && selectedSeason.id) {
      updateRegionsForSelectedSeason();
    } else {
      setRegions([]);
    }
    // eslint-disable-next-line
  }, [selectedSeason]);

  return (
    <FieldContext.Provider
      value={{
        field: props.field,
        setField: props.setField,
        seasons,
        setSeasons,
        selectedSeason,
        setSelectedSeason,
        regions,
        setRegions,
        getRegionsByType,
        fieldMap,
        mapboxLogo,
        selectedRegions,
        setSelectedRegions,
        geometryToEdit,
        setGeometryToEdit,
        drawingEnabled,
        setDrawingEnabled,
        refreshRegions,
        refreshMapDrawing,
        updateSeasons,
        trueCauseMarkersEditMode,
        setTrueCauseMarkersEditMode,
        addedLngLats,
        setAddedLngLats,
        editedLngLats,
        setEditedLngLats,
        deletedPinNumbers,
        setDeletedPinNumbers,
        editedFlags,
        setEditedFlags,
        selectedWorkOrderId,
        setSelectedWorkOrderId,
        // ---------------------------------
        pointEditMode,
        setPointEditMode,
        soilEditMode,
        setSoilEditMode,
        //  editedSoilLngLats, 
        //  setEditedSoilLngLats,
        //  deletedSoilLocation, 
        //  setDeletedSoilLocation,
        activityEditData,
        setActivityEditData,
        //  activityUpdatedData,
        //  setActivityUpdatedData
        //  addedSoilLngLats,
        //   setAddedSoilLngLats,
        // ---------------------------------
        newPointPrefix,
        setNewPointPrefix,
      }}
    >
      {props.children}
    </FieldContext.Provider>
  );
};
