import React from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import styles from "./ShelfLabel.module.scss";
import { Flex } from "../../Layouts/Flex";
import cn from "clsx";

export const ShelfLabel = ({ label, showOptions, select, classes }) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      className={cn(styles.ShelfLabel, classes)}
      onClick={select}
    >
      <h5>{label}</h5>
      <Flex
        justifyContent="center"
        alignItems="center"
        className={`${styles.ArrowIcon} ${showOptions && styles.Rotate}`}
      >
        <ArrowForwardIosIcon />
      </Flex>
    </Flex>
  );
};
