import React from "react";
import styles from "./ListSkeleton.module.scss";
import cn from "clsx";

export const ListSkeleton = ({
  options = { border: true },
  listCount = 0,
  styleH1 = { width: "80%" },
  styleH5 = { width: "60%" },
  styleP = { width: "70%" },
  animate = true,
}) => {
  let countArray = [];
  for (let i = 0; i < listCount; i++) {
    countArray.push(i);
  }
  return countArray.map((row, i) => (
    <li
      key={i}
      className={`${styles.ListSkeleton} ${
        options.border && styles.SkeletonBorder
      }`}
    >
      <div
        className={cn(styles.h1, animate && styles.animation)}
        style={styleH1}
      />
      <div
        className={cn(styles.h5, animate && styles.animation)}
        style={styleH5}
      />
      <div
        className={cn(styles.p, animate && styles.animation)}
        style={styleP}
      />
    </li>
  ));
};
