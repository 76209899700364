import React from "react";
import { useApi, useLazyApi } from "../../../hooks/useApi";
import { makeStyles, Grid, Typography, Box, useTheme } from "@material-ui/core";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { SettingsHeading } from "./Account";
import { Input } from "../../Helpers/Input/Input";
import { Button } from "../../Helpers/Button/Button";
import { Textarea } from "../../Helpers/Input/Textarea";
import { ColorPicker } from "../../Helpers/ColorPicker/ColorPicker";
import { Autocomplete2 } from "../../Helpers/Autocomplete/Autocomplete";
import Swal from "sweetalert2";
import {
  useCustomerAccounts,
  useSelectedCustomerAccount,
  useSelectedCustomerAccountDispatch,
} from "../../../globalState";
import formatAccountForMenu from "./util/formatAccount";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: "2rem 0 0 2rem",
  },
  sectionContainer: {
    marginBottom: "2rem",
  },
  saveButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.primary,
    stroke: "none",
  },
  clearButton: {
    backgroundColor: "inherit",
    color: theme.palette.text.secondary,
    stroke: "none",
  },
  removeHighlightButton: {
    color: "red",
    cursor: "pointer",
  },
  addNewHighlight: {
    padding: theme.spacing(1),
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
      color: theme.palette.colors.blue,
    },
  },
}));

export const CustomerSettings = () => {
  const classes = useStyles();

  const globallySelectedCustomerAccount = useSelectedCustomerAccount();
  const customerAccounts = useCustomerAccounts();
  const dispatch = useSelectedCustomerAccountDispatch();

  const [
    selectedCustomerSettings,
    setSelectedCustomerSettings,
  ] = React.useState();

  const [imageUploaded, setImageUploaded] = React.useState(false);

  const { data: customerSettingsData } = useApi("/api/v1/customer_accounts");
  const [updateCustomerSettingsData] = useLazyApi(
    selectedCustomerSettings &&
    `/api/v1/customer_accounts/${selectedCustomerSettings.id}`,
    {
      method: "PUT",
      ...(imageUploaded
        ? {
          headers: { "Content-Type": "multipart/form-data" },
        }
        : {}),
    }
  );

  React.useEffect(() => {
    if (customerSettingsData && globallySelectedCustomerAccount) {
      const settingsToLoad = customerSettingsData.find(
        (cs) => cs.id === globallySelectedCustomerAccount.id
      );

      // keep MUI happy about controlled/uncontrolled fields and null starting values
      const textFields = [
        "brandColor",
        "contactEmail",
        "contactName",
        "contactPhone",
        "copyright",
        "highlightsHeader",
        "logoUrl",
        "name",
      ];
      const settingsWithNullsChangedToEmptyStrings = Object.assign(
        {},
        Object.fromEntries(
          Object.entries(settingsToLoad).map(([key, value]) => {
            if (
              textFields.includes(key) &&
              (value === null || value === "null")
            ) {
              return [key, ""];
            } else return [key, value];
          })
        )
      );

      setSelectedCustomerSettings(settingsWithNullsChangedToEmptyStrings);
      return;
    }
    setSelectedCustomerSettings(undefined);
  }, [customerSettingsData, globallySelectedCustomerAccount]);

  const theme = useTheme();

  const logoInputRef = React.useRef();

  const changeHighlight = (highlightIndex, newText) => {
    setSelectedCustomerSettings({
      ...selectedCustomerSettings,
      highlights: selectedCustomerSettings.highlights.map((h, i) =>
        i !== highlightIndex ? h : newText
      ),
    });
  };
  const removeHighlight = (highlightIndex) => {
    const highlights = Array.from(selectedCustomerSettings.highlights);
    highlights.splice(highlightIndex, 1);
    setSelectedCustomerSettings({
      ...selectedCustomerSettings,
      highlights,
    });
  };
  const addNewHighlightField = () => {
    const { highlights } = selectedCustomerSettings;
    setSelectedCustomerSettings({
      ...selectedCustomerSettings,
      highlights: highlights ? [...highlights, ""] : [""],
    });
  };

  return (
    <>
      <Grid container direction="column" justifyContent="flex-start">
        <Grid item className={classes.sectionContainer}>
          <SettingsHeading text="Customer Settings" />
        </Grid>

        <form>
          <Grid
            item
            container
            direction="column"
            lg={4}
            spacing={2}
            className={classes.sectionContainer}
          >
            <Grid item>
              <Typography variant="body1">
                Please select a customer account:
              </Typography>
            </Grid>
            <Grid item>
              <Autocomplete2
                labelText={"Customer Account"}
                options={(
                  (customerSettingsData &&
                    customerSettingsData
                      .filter(
                        (item) => item.permissions && item.permissions.canUpdate // only include items marked can update
                      )
                      .map(formatAccountForMenu)) ||
                  []
                ).sort((c1, c2) => (c1.label < c2.label ? -1 : 1))}
                onChange={(_el, selected) => {
                  dispatch({
                    type: "custom-account-select",
                    payload: {
                      id: selected.value,
                    },
                  });
                }}
                value={
                  customerAccounts && globallySelectedCustomerAccount
                    ? formatAccountForMenu(
                      customerAccounts.find(
                        (a) => a.id === globallySelectedCustomerAccount.id
                      )
                    )
                    : null
                }
                disableClearable
                fullWidth={false}
              />
            </Grid>
          </Grid>

          {selectedCustomerSettings && (
            <>
              <Grid
                item
                container
                direction="column"
                className={classes.sectionContainer}
                spacing={2}
              >
                <Grid item>
                  <Typography variant="body2">
                    Customize your company name and logo for use in reports and
                    communications with your growers.
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="body2">
                    All fields marked with * are required.
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                container
                lg={4}
                direction="column"
                spacing={2}
                className={classes.sectionContainer}
              >
                <Grid item>
                  <Typography variant="h6" className={classes.copyContainer}>
                    Customization Options
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                container
                direction="column"
                className={classes.sectionContainer}
                spacing={1}
              >
                <Grid item>
                  <Typography variant="body1">Logo Upload</Typography>
                </Grid>

                <Grid item>
                  <input
                    type="file"
                    hidden
                    ref={logoInputRef}
                    onChange={(e) => {
                      const logoImg = logoInputRef.current.files[0];
                      const reader = new FileReader();
                      reader.readAsDataURL(logoImg);
                      reader.onloadend = () => {
                        setImageUploaded(true);
                        setSelectedCustomerSettings((prevState) => {
                          return {
                            ...prevState,
                            companyLogo: logoImg,
                            logoUrl: reader.result,
                          };
                        });
                      };
                    }}
                  />
                  <Button
                    text="Choose File"
                    click={(e) => {
                      e.preventDefault();
                      logoInputRef.current && logoInputRef.current.click();
                    }}
                  />
                </Grid>

                {selectedCustomerSettings.logoUrl && (
                  <Grid item lg={4}>
                    <img
                      src={selectedCustomerSettings.logoUrl}
                      alt="Company Logo"
                      style={{ maxWidth: "100%" }}
                    />
                  </Grid>
                )}
              </Grid>

              <Grid
                item
                container
                lg={6}
                direction="column"
                spacing={1}
                className={classes.sectionContainer}
              >
                <Grid item>
                  <Typography variant="body1">Brand Color</Typography>
                </Grid>

                <Grid item>
                  <ColorPicker
                    color={selectedCustomerSettings.brandColor || "#ffffff"}
                    setColor={(newColor) =>
                      setSelectedCustomerSettings((prevState) => {
                        return {
                          ...prevState,
                          brandColor: newColor,
                        };
                      })
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                lg={10}
                direction="column"
                spacing={2}
                className={classes.sectionContainer}
              >
                <Grid item>
                  <Typography variant="h6">Reporting Highlights</Typography>
                </Grid>

                <Grid item>
                  <Input
                    placeholder="Report Headline"
                    value={selectedCustomerSettings.highlightsHeader}
                    onChange={(e) =>
                      setSelectedCustomerSettings((prevState) => {
                        return {
                          ...prevState,
                          highlightsHeader: e.target.value,
                        };
                      })
                    }
                  />
                </Grid>

                {selectedCustomerSettings.highlights &&
                  selectedCustomerSettings.highlights.map((h, i) => (
                    <Grid item key={i} container spacing={1}>
                      <Grid item xs={11}>
                        <Input
                          placeholder="Report Highlight"
                          value={h}
                          onChange={(e) => {
                            changeHighlight(i, e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Box
                          display={"flex"}
                          style={{ width: "100%", height: "100%" }}
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <HighlightOffIcon
                            className={classes.removeHighlightButton}
                            onClick={() => removeHighlight(i)}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ))}

                <Grid item container spacing={1}>
                  <Box
                    display={"flex"}
                    className={classes.addNewHighlight}
                    onClick={addNewHighlightField}
                  >
                    <div>
                      <AddCircleOutlineIcon />
                    </div>{" "}
                    <Typography style={{ marginLeft: theme.spacing(1) }}>
                      Add Report Highlight
                    </Typography>
                  </Box>
                </Grid>

                <Grid item>
                  <Textarea
                    rows={4}
                    style={{ height: "200px", padding: "1rem" }}
                    placeholder="Terms and Conditions Statement"
                    value={selectedCustomerSettings.copyright}
                    onChange={(e) =>
                      setSelectedCustomerSettings((prevState) => {
                        return {
                          ...prevState,
                          copyright: e.target.value,
                        };
                      })
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                lg={10}
                direction="column"
                spacing={2}
                className={classes.sectionContainer}
              >
                <Grid item>
                  <Typography variant="h6">
                    Default Reporting Contact Information
                  </Typography>
                </Grid>

                <Grid item>
                  <Input
                    placeholder="Default Contact Name *"
                    value={selectedCustomerSettings.contactName}
                    onChange={(e) =>
                      setSelectedCustomerSettings((prevState) => {
                        return {
                          ...prevState,
                          contactName: e.target.value,
                        };
                      })
                    }
                  />
                </Grid>

                <Grid item container direction="row" spacing={4}>
                  <Grid item lg={6}>
                    <Input
                      placeholder="Default Contact Email Address *"
                      value={selectedCustomerSettings.contactEmail}
                      onChange={(e) =>
                        setSelectedCustomerSettings((prevState) => {
                          return {
                            ...prevState,
                            contactEmail: e.target.value,
                          };
                        })
                      }
                    />
                  </Grid>

                  <Grid item lg={6}>
                    <Input
                      placeholder="Default Contact Phone Number *"
                      value={selectedCustomerSettings.contactPhone}
                      onChange={(e) =>
                        setSelectedCustomerSettings((prevState) => {
                          return {
                            ...prevState,
                            contactPhone: e.target.value,
                          };
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                container
                lg={8}
                spacing={3}
                className={classes.sectionContainer}
              >
                <Grid item lg={3}>
                  <Button
                    text="Save Changes"
                    icon={<SaveOutlinedIcon />}
                    className={classes.saveButton}
                    type="button"
                    click={() => {
                      updateCustomerSettingsData(
                        imageUploaded
                          ? selectedCustomerSettings
                          : {
                            data: selectedCustomerSettings,
                          }
                      )
                        .then((_res) => {
                          setImageUploaded(false);
                          Swal.fire({
                            icon: "success",
                            title: "Success!",
                            text: "Your profile was updated.",
                          });
                        })
                        .catch((err) => {
                          Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text:
                              "An error occurred while updating your customer settings.",
                          });
                          console.error("Error updating settings", err);
                        });
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </form>
      </Grid>
    </>
  );
};
