import React, { useState } from "react";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { ShelfLabel } from "../../../../Helpers/ShelfLabel/ShelfLabel";
import { Switch } from "../../../../Helpers/Switch/Switch";
import { useField } from "../../../../../globalState/fieldContext";
import { fixTiledriverURL } from "../../../../Helpers/fixTiledriverURL";
import { FlightDateSlider } from "../../FlightDateSlider/FlightDateSlider";
import moment from "moment";

import {
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import * as axios from "axios";

const useClasses = makeStyles((theme) => ({
  images: {
    marginTop: "2rem",
  },
  loadingContainer: {
    position: "relative",
    bottom: theme.spacing(0.5),
    left: 0,
    width: "100%",
  },
}));

export const AerialImageryDrawer = ({
  imageryByDate,
  setImageToDisplay,
  imageToDisplay,
  useDrawerStyles,
  drawerState,
  setDrawerState,
  stressMapToDisplay,
  imageryDates,
  sliderDate,
  setSliderDate,
  imagery
}) => {
  const drawerClasses = useDrawerStyles();
  const classes = useClasses();
  const [showOptions, setShowOptions] = useState(false);

  const handleToggle = async (toggledImage) => {
    let copyDrawerState = { ...drawerState };
    if (imageToDisplay?.id === toggledImage.id) {
      setImageToDisplay(null);
      copyDrawerState.aerialImagery.selectedImageType = "";
      copyDrawerState.aerialImagery.selectedImageId = "";
    } else {
      copyDrawerState.aerialImagery.selectedImageType = toggledImage.name.toUpperCase();
      copyDrawerState.aerialImagery.selectedImageId = toggledImage.id;
      if (toggledImage.type.includes('rgb')) {
        setImageToDisplay(toggledImage);
      } else {
        setImageToDisplay(fixTiledriverURL(toggledImage));
      };
      setDrawerState(copyDrawerState);
    };
  }

  const ImageControl = ({
    image,
    checkOnLoadFlag,
    disabled
  }) => {
    if (image.viewable) {
      return (
        <div>
          <FormControlLabel
            control={<Switch checked={checkOnLoadFlag} />}
            label={image.name.toUpperCase()}
            disabled={disabled}
            onChange={() => handleToggle(image)}
          />
        </div>
      );
    } else {
      return (
        <h5>
          {image.name.toUpperCase()}
        </h5>
      );
    }
  }

  const [downloading, setDownloading] = useState([]);
  const fieldContext = useField();
  const downloads3ToClient = (toggledImage) => {
    const { name } = toggledImage;
    // const fileName = toggledImage.mapDownloadUrl.split("/")[
    //   toggledImage.mapDownloadUrl.split("/").length - 1
    // ];
    return axios.default
      .get(`${toggledImage.mapDownloadUrl}`, {
        headers: {
          Accept: "*/*",
        },
        responseEncoding: "binary",
        responseType: "arraybuffer",
        onDownloadProgress: function (progressEvent) {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          setDownloading((downloading) => [
            ...downloading.map((a) =>
              a.name === name ? { name, progress: percent } : a
            ),
          ]);
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        // We will build a filename to store imagery that "makes sense"
        // Currently: fieldID_flight-date_wo_type.tif
        const fieldId = fieldContext.field[0].id;
        const dateFlown = new Date(toggledImage.dateFlown)
        const flightDayOfMonth = dateFlown.getDate();
        const flightMonth = dateFlown.getMonth(); // Be careful! January is 0, not 1
        const flightYear = dateFlown.getFullYear();
        const flightDate = flightYear + '-' + (flightMonth + 1) + "-" + flightDayOfMonth;
        const localFile = `${fieldId}_${flightDate}_${toggledImage.workOrderId}_${toggledImage.type}.tif`;
        link.href = url;
        link.setAttribute("download", localFile); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  };

  const downloadCallback = async (toggledImage) => {
    // dont allow for redownloading files while download in progress
    if (downloading.some((a) => a.name === toggledImage.name)) return;
    setDownloading([
      ...downloading.filter((a) => a.name !== toggledImage.name),
      { name: toggledImage.name, progress: 0 },
    ]);
    await downloads3ToClient(toggledImage).finally(() => {
      setDownloading(
        downloading.filter((file) => file.name !== toggledImage.name)
      );
    });
  };

  const findProgressValue = (image) => {
    const value = downloading.find((a) => a.name === image.name);
    return value?.progress;
  };


  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      className={drawerClasses.root}
    >
      <ShelfLabel
        label={`Aerial Imagery`}
        showOptions={showOptions}
        select={() => setShowOptions(!showOptions)}
      />
      {showOptions && (
        <Grid container item className={drawerClasses.options}>
          {imageryByDate.length > 0 ? (
            <>
              <Typography
                variant="body1"
                color="textSecondary"
                component="span"
              >
                Use the toggles below to apply select aerial imagery layers to
                the map:
              </Typography>
              <div className="flightDateSliderWrapper">
                {!!imageryDates?.length && (<FlightDateSlider
                  imageryDates={imageryDates}
                  sliderDate={sliderDate}
                  setSliderDate={setSliderDate}
                  drawerState={drawerState}
                  imagery={imagery}
                  setImageToDisplay={setImageToDisplay}
                  stressMapToDisplay={stressMapToDisplay}
                />
                )}
              </div>
              {imageryByDate.map((imagesByDate, i) => {
                return (
                  <Grid
                    key={i}
                    container
                    item
                    direction="column"
                    alignItems="flex-start"
                    className={classes.images}
                    xs={12}
                  >
                    {<span>{imageryDates?.length === 1 && `${moment(imagesByDate.imagery[0].dateFlown).format("YYYY-MM-DD")}`}</span>}
                    {imagesByDate.imagery.map((image, i) => {
                      const shouldCheck = image?.id === imageToDisplay?.id;
                      return (
                        <Grid
                          key={i}
                          container
                          item
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <div>
                            <ImageControl
                              image={image}
                              disabled={stressMapToDisplay}
                              checkOnLoadFlag={shouldCheck}
                              onChangeFn={() => handleToggle(image)}
                            />
                          </div>
                          <IconButton
                            onClick={() => downloadCallback(image)}
                            aria-label="download imagery"
                            visible={image.downloadable}
                            disabled={stressMapToDisplay}
                          >
                            <GetAppOutlinedIcon color="primary" />
                          </IconButton>
                          {downloading.some(
                            (file) => file.name === image.name
                          ) && (
                              <div className={classes.loadingContainer}>
                                <LinearProgress
                                  variant="determinate"
                                  value={findProgressValue(image)}
                                />
                              </div>
                            )}
                        </Grid>
                      );
                    })}
                  </Grid>
                );
              })}
            </>
          ) : (
            <Typography color="textSecondary">No imagery available</Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};
