export const multipleImageSelect = (state, action, getter = (o) => o) => {
  // if there's a single image selected and they are trying to
  // unselect it won't be allowed
  if (
    state.selectedImages.length === 1 &&
    state.selectedImages.includes(action.payload.id)
  ) {
    return state;
  }

  return {
    ...state,
    selectedImagesById: {
      ...state.selectedImagesById,
      [action.payload.id]:
        action.payload.action === "select" ? getter(action.payload) : undefined,
    },
    selectedImages:
      action.payload.action === "select"
        ? [...state.selectedImages, action.payload.id]
        : state.selectedImages.filter((id) => id !== action.payload.id),
  };
};

export const singleImageSelect = (state, action, getter = (o) => o) => {
  const prevSelected =
    state.selectedImages.length > 0
      ? state.selectedImages.reduce((reducer, id) => {
          reducer[id] = false;

          return reducer;
        }, {})
      : {};

  return {
    ...state,
    selectedImagesById: {
      ...state.selectedImagesById,
      ...prevSelected,
      [action.payload.id]: getter(action.payload),
    },
    selectedImages: [action.payload.id],
  };
};

export const selectUnselectAll = (state, action, getter = (o) => o) => {
  if (
    !action.payload ||
    state.selectedImages.length === action.payload.length
  ) {
    return {
      ...state,
      selectedImagesById: {},
      selectedImages: [],
    };
  }

  const selectedImagesById = {};
  const selectedImages = [];
  action.payload.images.forEach((image) => {
    selectedImagesById[image.id] = getter(image);

    if (!state.selectedImages.includes(image.id)) {
      selectedImages.push(image.id);
    }
  });

  return {
    ...state,
    selectedImagesById: {
      ...state.selectedImagesById,
      ...selectedImagesById,
    },
    selectedImages: [...state.selectedImages, ...selectedImages],
  };
};
