import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { NewInput } from "../Input/Input";
import { round, unit } from 'mathjs';

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    input: {
        width: "50%",
        margin: 0,
        marginTop: "0.25rem",
        marginBottom: "1rem",
        fontFamily: theme.typography.fontFamily,
        backgroundColor: theme.palette.background.tertiary,
        color: theme.palette.text.primary,
        borderColor: theme.palette.borderColor.primary,
        borderRadius: 4,
        "& .MuiInputBase-root": {
            backgroundColor: theme.palette.background.tertiary,
        },
        "& .MuiInputBase-input": {
            background: "transparent",
        },
    },
    select: {
        width: "40%",
        margin: 0,
        marginTop: "0.25rem",
        marginBottom: "1rem",
        fontFamily: theme.typography.fontFamily,
        backgroundColor: theme.palette.background.tertiary,
        color: theme.palette.text.primary,
        borderColor: theme.palette.borderColor.primary,
        borderRadius: 4,
    },
}));

export function DistanceInput({ value, onChange, disabled = false }) {
    const classes = useStyles();
    const [distanceUnits, setDistanceUnits] = useState(unit(value || 0, 'm'));
    const [units, setUnits] = useState("meters");

    const handleDistanceChange = (event) => {
        if (distanceUnits.units[0].unit.name === "ft") {
            const valueInMeters = round(unit(event.target.value, 'ft').toNumber('m'), 2)
            setDistanceUnits(unit(event.target.value, 'ft'));
            onChange(valueInMeters)
        } else {
            setDistanceUnits(unit(event.target.value, 'm'));
            onChange(event.target.value);
        }

    };

    const handleUnitChange = (event) => {
        setUnits(event.target.value);
        if (event.target.value === "meters") {
            const valueInMeters = round(distanceUnits.toNumber('m'), 2)
            setDistanceUnits(unit(valueInMeters, 'm'))
        } else {
            const valueInFeet = round(distanceUnits.toNumber('ft'), 2)
            setDistanceUnits(unit(valueInFeet, 'ft'))
        }
    };

    return (
        <div className={classes.container}>
            <NewInput
                className={classes.input}
                value={round(distanceUnits.toNumber(), 2)}
                type="number"
                onChange={handleDistanceChange}
                disabled={disabled}
            />
            <Select
                className={classes.select}
                variant="filled"
                value={units}
                onChange={handleUnitChange}
            >
                <MenuItem value="meters">Meters</MenuItem>
                <MenuItem value="feet">Feet</MenuItem>
            </Select>
        </div>
    );
}
