import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useApi } from "../../../../../hooks/useApi";
import { CustomerAccountsSelector } from "../../../../Helpers/CustomerAccountsSelector/CustomerAccountsSelector";
import { Table } from "../../../../Helpers/Table/Table";
import { Container } from "../../../../Layouts/Container";
import { IMAGE_REVIEWER_BASE_COLUMNS } from "../../config/ImageReviewer.shared";
import { useSelectedCustomerAccount } from "../../../../../globalState/customerAccountContext";
import { FormControl, Typography } from "@material-ui/core";
import { Select } from "../../../../../Components/Helpers/Select/Select";

import { MuiButton, Input } from "../../../../Helpers";
import SearchIcon from "@material-ui/icons/Search";

const columns = [
  ...IMAGE_REVIEWER_BASE_COLUMNS,
  {
    Header: () => null,
    id: "bulk-edit-review",
    Cell: ({ row }) => {
      if (!row.original.id) {
        return "";
      }
      return row.original.id && (
        <Link to={`/image-reviewer/${row.original.id}/bulk-review`}>
          Bulk Image Review
        </Link>
      );
    },
    sortable: false,
  },
];

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gap: theme.spacing(2),
    gridTemplateRows: "90px 1fr",
  },
  accountSelector: {
    display: "flex",
    alignItems: "center",
    justifyContent: "stretch",
    height: "3rem",
    marginTop: theme.spacing(2),
  },
  formControl: {
    minWidth: 120,
    marginLeft: "1rem",
    height: `3rem !important`,
  },
  select: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    "& .MuiSelect-root, .MuiMenu-list": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
    "&.MuiList-root": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
    "&:hover": {
      backgroundColor: theme.palette.background.tertiary,
    },
  },
  selected: {
    backgroundColor: `${theme.palette.background.default} !important`,
    textDecoration: "underline",
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: theme.palette.background.tertiary,
    },
  },
  searchInput: {
    display: "grid",
    gridTemplateColumns: "1.5rem auto",
    alignItems: "center",
    paddingLeft: "0.4rem",
    borderRadius: "3px",
    backgroundColor: theme.palette.background.tertiary,
    boxSizing: "border-box",
    marginLeft: "15rem",
    width: "265px"
  },
}));

const initialState = {
  sortBy: [
    {
      id: "workOrderId",
      desc: false,
    },
  ],
};

const uniqByPredicate = (arr, predicate) => {
  const cb = typeof predicate === "function" ? predicate : (o) => o[predicate];

  return [
    ...arr
      .reduce((map, item) => {
        const key = item === null || item === undefined ? item : cb(item);

        map.has(key) || map.set(key, item);

        return map;
      }, new Map())
      .values(),
  ];
};

const SearchBox = (props) => {
  const { onEnter, onClear } = props;
  const [search, setSearch] = React.useState("");
  const classes = useStyles();
  const keyPressHandler = (e) => {
    if (e.key === 'Enter') {
      onEnter(search);
    }
    setSearch(e.target.value)
  }
  const resetHandler = (e) => {
    setSearch("")
    onClear("")
  }
  return (
    <>
      <FormControl className={classes.formControl}>
        <div className={classes.searchInput}>
          <SearchIcon className={classes.searchIcon} />
          <Input
            className={classes.backgroundColor}
            type="text"
            onKeyPress={(e) => keyPressHandler(e)}
            placeholder={"Filter WO or Field ID"}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            autoFocus
          />
        </div>
      </FormControl>
      <FormControl className={classes.formControl}>
        <MuiButton
          variant="contained"
          color="primary"
          text="Clear"
          onClick={(e) => resetHandler(e)}
        />
      </FormControl>
    </>
  )
}
export const HomeImageReviewer = () => {
  const selectedCustomerAccount = useSelectedCustomerAccount();
  const customerAccountId = React.useMemo(() => selectedCustomerAccount?.id, [
    selectedCustomerAccount,
  ]);
  const classes = useStyles();
  const { data } = useApi(
    customerAccountId
      ? `/api/v1/image_review/by_customer_account/${customerAccountId}`
      : "/api/v1/image_review"
  );
  const { data: authenticationData } = useApi("/api/v1/authentication");
  const [reviewStatus, setReviewStatus] = React.useState("All");
  const [tableData, setTableData] = useState("")
  const [selectedYearFilter, setSelectedYearFilter] = React.useState(
    new Date().getFullYear().toString()
  );
  const filteredData = React.useMemo(() => {
    if (data) {
      const reviewStatusFilter = (item) =>
        reviewStatus === "All"
          ? true
          : reviewStatus === "Reviewed"
            ? item.unreviewedImageCount === 0
            : item.unreviewedImageCount === item.totalImageCount;
      const dateFilter = (item) =>
        selectedYearFilter === "All"
          ? true
          : new Date(item.recentProbeBatchDate).getFullYear().toString() ===
          selectedYearFilter;
      return data?.filter(dateFilter)?.filter(reviewStatusFilter);
    }
    return [];
  }, [selectedYearFilter, reviewStatus, data]);
  const selectOptions = React.useMemo(() => {
    if (data) {
      return uniqByPredicate(data, (item) =>
        new Date(item.recentProbeBatchDate).getFullYear()
      ).map((item) =>
        new Date(item.recentProbeBatchDate).getFullYear().toString()
      );
    }
    return [];
  }, [data]);

  const handleFilterClear = (e) => {
    //setShowGoToData("");
    setTableData(filteredData);
  };

  const handleKeyPress = (text) => {
    let rowData
    if (text === '') {
      rowData = data;
    } else {
      rowData = [...filteredData.filter((d) => (d.workOrderId === Number(text))
        || (d.fieldID === Number(text)))];
    }
    if (rowData.length === 0) {
      rowData[0] = { fieldName: "No Data Found" }
    }
    setTableData(rowData)
  };

  useEffect(() => {
    setTableData(filteredData || []);
  }, [data, filteredData])
  return (
    <Container className={classes.container}>
      {authenticationData?.isImageReviewer ? (
        <>
          <div>
            <Typography variant="h2">Image Reviewer</Typography>
            <div className={classes.accountSelector}>
              <CustomerAccountsSelector handleSelectedAccount={() => { }} />

              {!!data && (
                <FormControl className={classes.formControl}>
                  <Select
                    options={["All", ...selectOptions].map((year, index) => ({
                      id: index,
                      title: year.toString(),
                      value: year,
                    }))}
                    className={classes.select}
                    defaultLabel="Filter By Year"
                    labelId="year-filter-label"
                    id="year-filter-select"
                    selectedOption={{ title: selectedYearFilter }}
                    selectOption={(e) => setSelectedYearFilter(e.title)}
                  />

                </FormControl>
              )}
              {!!data && (
                <FormControl className={classes.formControl}>
                  <Select
                    options={["All", "Reviewed", "Unreviewed"].map(
                      (item, index) => ({
                        id: index,
                        title: item,
                        value: item,
                      })
                    )}
                    className={classes.select}
                    defaultLabel="Revieaugw Status"
                    id="review-filter-status"
                    selectedOption={{ title: reviewStatus }}
                    selectOption={(e) => setReviewStatus(e.value)}
                  />
                </FormControl>
              )}
              <SearchBox onEnter={e => {
                handleKeyPress(e);
              }}
                onClear={e => {
                  handleFilterClear();
                }}
              />
            </div>
          </div>
          <Table
            noResultsLabel="No image reviewer rows found."
            columns={columns}
            data={tableData || []}
            dataLoaded={!!data}
            initialState={initialState}
          />
        </>
      ) : (
        <Typography variant="h2">
          You do not have access to the image reviewer page.
        </Typography>
      )}
    </Container>
  );
};


