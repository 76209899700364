import React from "react";
import { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import { LinearProgress } from '@mui/material';
import { Grid, Typography } from "@material-ui/core";
import { MuiButton } from '../../../../Helpers';
import { TextareaWithLabel } from '../../../../Helpers/Input/Textarea'
import { useField } from '../../../../../globalState/fieldContext';
import { taskActivities } from '../../../../../hooks/useApi'
import { useAuth } from '../../../../../hooks/useAuth';
import { NewDatePicker } from '../../../../Helpers/DatePicker/DatePicker'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import * as turf from "@turf/turf";
import { NumericRangeInput } from '../../../../Helpers/NumericRangeInput/NumericRangeInput';
import { useApi, useLazyApi } from "../../../../../hooks/useApi";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
    text: {
        fontFamily: "var(--fontFamily) !important",
        color: "var(--fontColorMain) !important",
    }
}));

const sampleTypeStyles = {
    color: "var(--fontColorMain)",
    '&.Mui-checked': { color: "var(--logoColor)" },
}

const sampleTypeLabelStyles = {
    color: "var(--fontColorMain)",
    fontFamily: "var(--fontFamily)",
    '&.Mui-focused': { color: "var(--fontColorMain)" },
}

const createGridActivities = (fieldBoundary, gridSpacing) => {
    const metersPerAcre = 63.615;
    const field_buffer = -33; // 33 meters
    const gridSpacingM = Math.sqrt(gridSpacing) * metersPerAcre;
    const bufferedBoundary = turf.buffer(fieldBoundary, field_buffer, { units: 'meters' });

    const bbox = turf.bbox(bufferedBoundary)
    const grid = turf.pointGrid(bbox, gridSpacingM, {
        units: 'meters',
    });

    const clippedGrid = turf.pointsWithinPolygon(grid, bufferedBoundary);

    const points = clippedGrid.features.map((feature) => {
        return feature.geometry.coordinates;
    });

    const activities = points.map((point, index) => {
        return {
            order: index + 1,
            label: `S${index + 1}`,
            lng: point[0],
            lat: point[1],
            activities: [
                {
                    "id": 1,
                    "type": "soil_sample",
                    "notes": ""
                }
            ]
        }
    });

    return activities;
};

export const CreateSoilSampleTaskPopup = (props) => {
    const [numberOfSamplesPoints, setNumberOfSamplesPoints] = useState(3)
    const [gridSpacing, setGridSpacing] = useState(2.5)
    const [specialInstructions, setSpecialInstructions] = useState('')
    const [suggestedPlans, setSuggestedPlans] = useState()
    const [selectedDate, setSelectedDate] = useState(new Date())
    const { field, selectedSeason, setSoilEditMode, updateSeasons } = useField();
    const { token } = useAuth();
    const [sampleType, setsampleType] = useState('Zones');
    const [createWorkOrder] = useLazyApi("/api/v1/order", { method: "POST" });
    const { data: allServices } = useApi("/api/v1/services");
    const [loading, setLoading] = useState(false);
    const servicesList = allServices?.activeServices;
    const soilSampleServiceId = servicesList?.find(s => s.shortName === 'ssamp')?.id

    useEffect(() => {
        if (selectedSeason?.suggestedPlans?.length === 0) {
            setsampleType('Grid')
        } else {
            setSuggestedPlans(selectedSeason?.suggestedPlans)
        }
    }, [selectedSeason])

    const classes = useStyles();

    const createActivities = async (taskId) => {
        var activities;
        if (sampleType === "Zones") {
            const suggestedPoints = suggestedPlans?.filter(item => {
                if (item?.activityType?.name === "soil_sample") {
                    return item;
                }
                return false;
            })[0]?.suggestedPoints
            const selectedPoints = suggestedPoints.map(arr => arr.slice(0, numberOfSamplesPoints));
            activities = selectedPoints.flat();
        } else {
            const fieldBoundary = field[0].geometry
            activities = createGridActivities(fieldBoundary, gridSpacing);
        }
        const taskActivitiesResponse = await taskActivities(token, taskId, { data: { activities } })
        props.setActivityData(taskActivitiesResponse?.data?.activities)
    }

    const createTheWorkOrder = async () => {
        var taskId;
        var workOrderId;

        const workOrderData = {
            fieldId: field[0].id,
            serviceId: soilSampleServiceId,
            specialInstructions: specialInstructions,
            requestedStartDate: selectedDate,
            requestedEndDate: selectedDate,
            isNewSeason: false,
            parameters: {}
        }
        await createWorkOrder({ data: workOrderData })
            .then(async (response) => {
                taskId = response.tasks[0].id;
                workOrderId = response.id;
                createActivities(taskId);
                await updateSeasons();
            })
            .catch((error) => {
                console.error(error);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "An error occurred while trying to create your task",
                });
            });;

        return workOrderId;
    }

    const handleSave = async () => {

        setSoilEditMode(false);
        setLoading(true);
        const workOrderId = await createTheWorkOrder();
        setLoading(false);
        setNumberOfSamplesPoints(3);
        setSpecialInstructions('');
        //const workOrderId = 31763; // for testing
        props.handleClose();
        props.handleOpenConfirmSoilPopup(workOrderId)
    }

    const handleClose = () => {
        props.handleClose(null);
    }

    const handleTypeChange = (event) => {
        setsampleType(event.target.value);
    };

    return (
        <Dialog open={props.open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    backgroundColor: "var(--bgColorMain)",
                    color: "var(--fontColorMain)",
                    minWidth: "30rem",
                }
            }}
        >
            <DialogTitle >Create Soil Sample Task</DialogTitle>
            <DialogContent >
                <DialogContentText className={classes.text}>
                    Create a task to collect soil samples.
                </DialogContentText>
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    style={{ alignSelf: "flex-start", padding: "2rem 1.5rem" }}
                >
                    {selectedSeason?.suggestedPlans?.length > 0 && (
                        <FormControl>
                            <FormLabel id="type-group-label" sx={sampleTypeLabelStyles}>Sample Generation:</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="type-group-label"
                                name="type-radio-buttons-group"
                                value={sampleType}
                                onChange={handleTypeChange}
                            >
                                <FormControlLabel value="Zones" control={<Radio sx={sampleTypeStyles} />} label="Soil Zones" />
                                <FormControlLabel value="Grid" control={<Radio sx={sampleTypeStyles} />} label="Grid" />

                            </RadioGroup>
                        </FormControl>
                    )}
                    {sampleType === "Zones" && (
                        <>
                            <Typography color="textSecondary">Number of sample points per zone</Typography>
                            <NumericRangeInput
                                min={1}
                                max={12}
                                defaultValue={3}
                                label="enter 1 to 12"
                                setValueFn={setNumberOfSamplesPoints}
                            />
                        </>
                    )}
                    {sampleType === "Grid" && (
                        <>
                            <Typography color="textSecondary">Grid spacing (ac)</Typography>
                            <NumericRangeInput
                                min={0.5}
                                max={25.0}
                                defaultValue={2.5}
                                label="enter 0.5 to 25"
                                step={0.5}
                                setValueFn={setGridSpacing}
                            />
                        </>
                    )}
                    <TextareaWithLabel
                        label={"Special Instructions"}
                        value={specialInstructions}
                        onChange={(event) => { setSpecialInstructions(event.target.value) }}
                    />
                    <Typography color="textSecondary">Scheduled Date:</Typography>
                    <NewDatePicker
                        value={selectedDate}
                        onChange={(newValue) => {
                            setSelectedDate(newValue);
                        }}
                    />
                    {loading && (
                        <Dialog open={props.open}
                            PaperProps={{
                                style: {
                                    backgroundColor: "var(--bgColorMain)",
                                    color: "var(--fontColorMain)",
                                    minWidth: "30rem",
                                }
                            }}
                        >
                            <DialogTitle >Creating Task...</DialogTitle>
                            <DialogContent >
                                <LinearProgress />
                            </DialogContent>
                        </Dialog>
                    )}
                    <Grid container justifyContent="space-between" style={{ width: "100%", marginLeft: "-0.5rem", marginTop: "20px" }}>
                        <MuiButton
                            text="Cancel"
                            style={{ width: "45%" }}
                            onClick={handleClose}
                        />
                        <MuiButton
                            text="Save"
                            style={{ width: "45%" }}
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}



