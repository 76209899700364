import React from "react";
import { Grid, Typography, FormControlLabel } from "@material-ui/core";
import { Textarea } from "../../../../Helpers/Input/Textarea";
import { Input } from "../../../../Helpers/Input/Input";
import { Button } from "../../../../Helpers/Button/Button";
import { Checkbox } from "../../../../Helpers/Checkbox/Checkbox";
import { useField } from "../../../../../globalState/fieldContext";
import Swal from "sweetalert2";
import { createRegion } from "../../../../../controllers/regions";
import { useAuth } from "../../../../../hooks/useAuth";
import { Autocomplete2 } from "../../../../Helpers/Autocomplete/Autocomplete";

export const AddRegion = ({ classes, setAddRegion }) => {
  const { token } = useAuth();
  const [error, setError] = React.useState(false);
  const [name, setName] = React.useState("");
  const [cropYield, setCropYield] = React.useState(0);
  const [notes, setNotes] = React.useState("");
  const [exclusion, setExclusion] = React.useState(false);
  const [untreated, setUntreated] = React.useState(false);
  const regionTypes = ['application', 'soil_sample', 'soil', 'user']
  const [regionType, setRegionType] = React.useState('application');
  const {
    selectedSeason,
    setGeometryToEdit,
    geometryToEdit,
    setDrawingEnabled,
    refreshRegions,
    refreshMapDrawing,
  } = useField();

  const handleCancelAddRegion = () => {
    setDrawingEnabled(false);
    setAddRegion(false);
    setGeometryToEdit(null);
  };

  const handleSubmit = async () => {
    const regionData = {
      seasonId: selectedSeason.id,
      name,
      exclusion,
      untreated,
      cropYield,
      notes,
      polygon: JSON.stringify({
        type: "Feature",
        geometry: geometryToEdit.polygon.geometry,
      }),
      bounds: geometryToEdit.bounds,
      size: geometryToEdit.acres,
      type: regionType,
    };
    const newRegionAdded = await createRegion(token, regionData).catch(() => {
      setError(true);
    });
    if (newRegionAdded) {
      setError(false);
      refreshRegions();
      refreshMapDrawing();
      setAddRegion(false);
      await Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Your region was added.",
      });
    }
  };

  return (
    <Grid container>
      <Typography className={classes.newRegionPrompt}>
        Fill out the fields below to create a new region:{" "}
      </Typography>
      <Input
        required
        onChange={(e) => {
          setName(e.currentTarget.value);
        }}
        placeholder="Region Name"
        className={classes.newRegionControl}
      />
      <Typography variant="body2" className={classes.newRegionTypeLabel}>
        Region type*
      </Typography>
      <Autocomplete2
        options={regionTypes}
        value={regionType}
        getOptionLabel={(o) => o}
        onChange={(_, v) => setRegionType(v)}
        labelText=""
        inputClassName={classes.newRegionType}
      />

      <Grid className={classes.newRegionControl}>
        <FormControlLabel
          onClick={() => {
            setExclusion(!exclusion);
          }}
          control={<Checkbox checked={exclusion} />}
          label={"Exclude region from reporting?"}
        />
        <FormControlLabel
          onClick={() => {
            setUntreated(!untreated);
          }}
          control={<Checkbox checked={untreated} />}
          label={"Untreated?"}
        />
      </Grid>
      <Input
        placeholder="Yield"
        type="number"
        step="any"
        className={classes.newRegionControl}
        onChange={(e) => {
          setCropYield(+e.currentTarget.value);
        }}
      />
      <Textarea
        placeholder="Notes"
        rows={6}
        variant="filled"
        onChange={(e) => {
          setNotes(e.currentTarget.value);
        }}
        className={classes.newRegionNotes}
      />
      {error && (
        <Typography color="error" variant="subtitle1">
          The region you have drawn is either outside of the bounds of the field
          or intersects itself.
        </Typography>
      )}
      <Grid container item justifyContent="space-around">
        <Button
          disabled={!geometryToEdit?.polygon || !name}
          text="Submit"
          type="submit"
          className={classes.newRegionActionBtn}
          onClick={handleSubmit}
        />
        <Button
          text="Cancel"
          className={classes.newRegionActionBtn}
          variant="outlined"
          onClick={handleCancelAddRegion}
        />
      </Grid>
    </Grid>
  );
};
