import * as React from "react";
import cn from "clsx";

import { ReactComponent as FilterIcon } from "../../../../../assets/icons/filter.svg";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  useDisclosureState,
} from "../../../../Helpers/Disclosure/Disclosure";
import { Checkbox } from "../../../../Helpers/Checkbox/Checkbox";
import styles from "../../../../../Components/Views/ImageReviewer/components/FilterMenu/FilterStressItemMenu.module.scss";
import { useTrueCauseStressItemFilterState } from "./FilterTrueCauseStressItem";

const FilterIconStatus = ({ status }) => {
  const state = useDisclosureState();

  return (
    <FilterIcon
      className={cn(
        styles.FilterStressItemMenu__Icon,
        (state?.status === "opened" || status === "active") &&
          styles["FilterStressItemMenu__Icon--active"]
      )}
    />
  );
};

const FilterItem = React.memo(
  ({ checked = false, group, name, type, label, onChange }) => {
    const onChangeCallback = React.useCallback(
      (event) => {
        const { value, checked } = event.target;

        if (onChange) {
          onChange({
            checked,
            value,
          });
        }
      },
      [onChange]
    );
    return (
      <label>
        <Checkbox
          style={{ marginBottom: 5 }}
          onChange={onChangeCallback}
          value={name}
          checked={checked}
        ></Checkbox>
        {label}
      </label>
    );
  }
);
FilterItem.displayName = "FilterItem";

export const TrueCauseFilterStressItemMenu = ({ imageData, onChange }) => {
  const state = useTrueCauseStressItemFilterState();
  const images = React.useMemo(() => {
    if (imageData && imageData.length) {
      const probeImages = imageData.flatMap((reducer, d) => {
        const { probeImages } = reducer;
        return [...(probeImages || [])];
      });
      return { probeImages };
    }
    return [];
  }, [imageData]);

  const uniqueStressItems = React.useMemo(() => {
    const uniqueStressItems = [];
    if (images && images.probeImages && images.probeImages.length) {
      images.probeImages.forEach((image) => {
        [
          ...image.customerReviewStressItems,
          ...image.agronomyReviewStressItems,
        ].forEach((stressItem) => {
          if (!uniqueStressItems.some((a) => a.name === stressItem.name))
            uniqueStressItems.push(stressItem);
        });
      });
      return uniqueStressItems;
    } else return [];
  }, [images]);

  const onFilterChange = React.useCallback(
    ({ checked, value }) => {
      // The moment a filter has been changed we'll automatically
      // deselect any image that was previously selected.
      onChange({
        type: "filter-apply",
        payload: {
          type: "client",
          name: value,
          action: checked ? "add" : "remove",
        },
      });
    },
    [onChange]
  );

  const onFilterClear = React.useCallback(() => {
    onChange({
      type: "filter-clear",
    });
    // eslint-disable-next-line
  }, []);

  const [showFilters, setShowFilters] = React.useState(false);

  return (
    <Disclosure>
      <div className={styles.FilterStressItemMenu}>
        <DisclosureButton onClick={() => setShowFilters(true)}>
          <FilterIconStatus status={showFilters ? "active" : undefined} />
        </DisclosureButton>
        <DisclosurePanel
          className={`${styles.FilterStressItemMenu__PanelContent__FullWidth} full-width`}
        >
          {uniqueStressItems.length > 0 && (
            <div
              onClick={onFilterClear}
              style={{ width: "100%", cursor: "pointer" }}
            >
              {" "}
              Clear Filters <i className="fa fa-times"></i> <hr />
            </div>
          )}
          <div style={{ display: "grid" }}>
            {uniqueStressItems.length === 0 && (
              <p style={{ color: "#eee" }}>
                There are no stress items to filter by.
              </p>
            )}
            {uniqueStressItems.map((a) => (
              <FilterItem
                onChange={onFilterChange}
                name={a.name}
                label={a.name}
                key={a.id}
                group="status"
                // using type client instead of remote because remote filters apparently refetch data.
                type="client"
                checked={!!state["client"] && !!state["client"][a.name]}
              />
            ))}
          </div>
        </DisclosurePanel>
      </div>
    </Disclosure>
  );
};
