import { useEffect } from "react";
import PropTypes from "prop-types";
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
import { soilSampleFillColors, standCountColors, standCountFillColors } from "../../styles/colors";

function addAhiLayers(map, imageSourceId, firstOverlayLayerID, valuesToDisplay = [1, 2, 5, 6]) {
  function filter(a) {
    return [
      "any",
      ["==", ["get", "AHI_REGION"], `${a}`],
      ["==", ["get", "AHI_REGION"], a],
      ["==", ["get", "AHI_GRID"], `${a}`],
      ["==", ["get", "AHI_GRID"], a],
    ]
  }

  const ahiFillColors = [
    // AHI 1-6: 1 is best, 6 is worst (green to red, typically)
    "#02E611", "#8BE611", "#FFFF27", "#FFDA07", "#F60804", "#D7191C"
  ]

  function addLayer(a) {
    if (valuesToDisplay.includes(a)) {
      map.addLayer({
        id: `ahi${a}`,
        type: "fill",
        source: imageSourceId,
        paint: {
          "fill-color": ahiFillColors[a - 1],
          "fill-opacity": 1,
          "fill-outline-color": 'rgba(0, 0, 0, 1)',
        },
        filter: filter(a)
      }, firstOverlayLayerID);
    }
    // else {
    //   map.addLayer({
    //     id: `ahi${a}`,
    //     type: "line",
    //     source: imageSourceId,
    //     paint: {
    //       "line-color": 'rgba(0, 0, 0, 1)',
    //       "line-opacity": 1,
    //     },
    //     filter: filter(a)
    //   }, firstOverlayLayerID);
    // }
  }

  function addLabels(n) {
    map.once('idle', function () {
      let labels = []

      for (let a = 1; a <= n; a++) {
        let features = map.queryRenderedFeatures(undefined, { layers: [`ahi${a}`] })
        for (let feature of features) {
          let labelPoint = turf.center(feature)
          let label = {
            'type': 'Feature',
            'geometry': {
              'type': 'Point',
              'coordinates': [labelPoint.geometry.coordinates[0], labelPoint.geometry.coordinates[1]]
            },
            'properties': {
              'title': `${a}`,
            },
          }
          labels.push(label)
        }
      }

      // if we already have this defined, remove it before replacing it
      if (map.getLayer('ahi-label-layer')) {
        map.removeLayer('ahi-label-layer')
        map.removeSource('ahi-labels')
      }

      map.addSource('ahi-labels',
        {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': labels
          }
        }
      );

      map.addLayer({
        'id': 'ahi-label-layer',
        'type': 'symbol',
        'source': 'ahi-labels',
        'layout': {
          // get the title name from the source's "title" property
          'text-field': ['get', 'title'],
          'text-font': [
            'Open Sans Bold',
            'Arial Unicode MS Bold'
          ],
          'text-size': 10,
          'text-offset': [0, 0],
          'text-anchor': 'center',
          // 'text-color': '#000000',
          //'text-opacity': 1,
        }
      });
    });
  }

  for (let i = 1; i < 7; i++) {
    addLayer(i)
  }
  const source = map.getSource(imageSourceId)
  if (source._data.toLowerCase().includes('grid'))
    addLabels(6)
}

function addSoilSampleLayers(map, imageSourceId, firstOverlayLayerID, valuesToDisplay = [0, 1, 2, 3, 4]) {
  function filter(a) {
    return [
      "any",
      ["==", ["get", "ID"], `${a}`],
      ["==", ["get", "ID"], a],
    ]
  }



  function addLayer(a) {
    if (valuesToDisplay.includes(a)) {
      map.addLayer({
        id: `soilSample${a}`,
        type: "fill",
        source: imageSourceId,
        sourceLayer: `soilSample${a}`,
        paint: {
          "fill-color": soilSampleFillColors[a],
          "fill-opacity": 0.3,
          "fill-outline-color": 'rgba(0, 0, 0, 1)',
        },
        filter: filter(a)
      }, firstOverlayLayerID);
    }
  }

  for (let i = 0; i < 5; i++) {
    addLayer(i)
  }
}

function addStandCountLayers(map, imageSourceId, firstOverlayLayerID, valuesToDisplay = [0, 1, 2, 3]) {
  function filter(a) {
    return [
      "any",
      ["==", ["get", "ELEV_MAX"], `${a}`],
      ["==", ["get", "ELEV_MAX"], a],
    ]
  }

  function addLayer(a) {
    if (valuesToDisplay.includes(a)) {
      map.addLayer({
        id: `standCount${a}`,
        type: "fill",
        source: imageSourceId,
        paint: {
          "fill-color": standCountFillColors[a],
          "fill-opacity": 0.5,
          "fill-outline-color": 'rgba(0, 0, 0, 1)',
        },
        filter: filter(a)
      }, firstOverlayLayerID);
    }
  }

  for (let i = 0; i < 5; i++) {
    addLayer(i)
  }
}

const popup = new mapboxgl.Popup({
  closeButton: false, closeOnClick: false, anchor: 'center',
  offset: [-20, -25]
})

export const ImageryLayer = ({
  map,
  imageToDisplay,
  refreshMap,
  containerId,
  fieldLayerId,
  valuesToDisplay,
}) => {
  useEffect(() => {
    if (imageToDisplay?.length === 0) return;
    if (!map.style.stylesheet) return;

    const imageSourceId = imageToDisplay
      ? `${containerId}-imagery-${imageToDisplay.id}`
      : "";
    const layers = map.getStyle().layers;

    // Something changed in the mapstyle updates where we just get the last item, now, and it shows.
    // Otherwise, our layer does not show as it's under the satellite raster, it seems.
    var firstOverlayLayerID = layers[layers.length - 1].id;

    function onEnter(e) {
      let layers = map.queryRenderedFeatures(e.point, {
        layers: ['ahi1', 'ahi2', 'ahi3', 'ahi4', 'ahi5', 'ahi6']
      });
      if (layers.length) {
        map.getCanvas().style.cursor = 'pointer';
        //const coordinates = [layers[0].properties.left, layers[0].properties.top];
        const description = layers[0].properties.AHI_GRID ? `AHI: ${layers[0].properties.AHI_GRID}` : `AHI: ${layers[0].properties.AHI_REGION}`
        popup.setLngLat(e.lngLat).setHTML(description).addTo(map);
      } else {
        map.getCanvas().style.cursor = '';
        if (popup.isOpen()) popup.remove();
      }
    }

    if (map && imageToDisplay) {
      if (map.getSource(imageSourceId)) {
        map.removeSource(imageSourceId);
      } else {
        if (imageToDisplay.geotiffUrl) {
          if (imageToDisplay.geotiffUrl.toLowerCase().includes('ahi')) {
            map.addSource(imageSourceId, {
              type: "geojson",
              data: imageToDisplay.geotiffUrl,
            });

            addAhiLayers(map, imageSourceId, firstOverlayLayerID, valuesToDisplay)

            // function onClick (e) {
            //   let layers = map.queryRenderedFeatures(e.point, { layers: ['Bad','Average','Good'] });
            //   if (layers.length) {
            //     map.getCanvas().style.cursor = 'pointer';
            //     // const diff = [layers[0].properties.right - layers[0].properties.right, 
            //     // layers[0].properties.bottom - layers[0].properties.top];
            //     //const coordinates = [layers[0].properties.left + diff[0], layers[0].properties.top + diff[1]];
            //     const description = `AHI: ${layers[0].properties.ahi}`
            //     popup.setLngLat(e.lngLat).setHTML(description).addTo(map);
            //   }
            // }

            // map.on('click', onClick )
            //map.on('mousemove', onEnter);
          }
          else if (imageToDisplay.geotiffUrl.toLowerCase().includes('soil_sample')) {
            map.addSource(imageSourceId, {
              type: "geojson",
              data: imageToDisplay.geotiffUrl,
            });
            addSoilSampleLayers(map, imageSourceId, firstOverlayLayerID, valuesToDisplay)
          }
          else if (imageToDisplay.geotiffUrl.toLowerCase().includes('stand_count')) {
            map.addSource(imageSourceId, {
              type: "geojson",
              data: imageToDisplay.geotiffUrl,
            });
            addStandCountLayers(map, imageSourceId, firstOverlayLayerID, valuesToDisplay)
          }
          else if (imageToDisplay.geotiffUrl) // TrueCause stress map
          {
            //map.off('mousemove', onEnter);
            map.addSource(imageSourceId, {
              type: "geojson",
              data: imageToDisplay.geotiffUrl,
            });
            map.addLayer({
              id: "Healthy",
              type: "fill",
              source: imageSourceId,
              paint: {
                "fill-color": "#008000",
                "fill-opacity": 1
              },
              filter: ["==", ["get", "Rank"], 1]
            }, firstOverlayLayerID);
            map.addLayer({
              id: "Stressed",
              type: "fill",
              source: imageSourceId,
              paint: {
                "fill-color": "#FFFF00",
                "fill-opacity": 1
              },
              filter: ["==", ["get", "Rank"], 2]
            }, firstOverlayLayerID);
            map.addLayer({
              id: "Unhealthy",
              type: "fill",
              source: imageSourceId,
              paint: {
                "fill-color": "#CD0000",
                "fill-opacity": 1
              },
              filter: ["==", ["get", "Rank"], 3]
            }, firstOverlayLayerID);
          }
        }
        else {
          map
            .addSource(imageSourceId, {
              type: "raster",
              tiles: [imageToDisplay.tileUrl],
            })
            .addLayer({
              id: imageSourceId,
              type: "raster",
              source: imageSourceId,
            }, firstOverlayLayerID);
        }
      }
    }

    return () => {
      if (map && map.getSource(imageSourceId)) {
        if (imageToDisplay.geotiffUrl) // stress map
        {
          if (imageToDisplay.geotiffUrl.toLowerCase().includes('ahi')) {
            for (let i = 1; i < 7; i++) {
              map.off('mousemove', onEnter);
              map.removeLayer(`ahi${i}`)
            }
            if (map.getLayer('ahi-label-layer')) {
              map.removeLayer('ahi-label-layer')
              map.removeSource('ahi-labels')
            }
          } else if (imageToDisplay.geotiffUrl.toLowerCase().includes('soil_sample')) {
            for (let i = 0; i < 5; i++) {
              map.removeLayer(`soilSample${i}`)
            }
          } else if (imageToDisplay.geotiffUrl.toLowerCase().includes('stand_count')) {
            for (let i = 0; i < 3; i++) {
              map.removeLayer(`standCount${i}`)
            }
          }
          else {
            map.removeLayer("Healthy");
            map.removeLayer("Stressed");
            map.removeLayer("Unhealthy");
          }
        }
        else {
          map.removeLayer(imageSourceId);
        }
        map.removeSource(imageSourceId);
      }
    };
  }, [map, imageToDisplay, refreshMap, containerId, fieldLayerId, valuesToDisplay]);

  return null;
};

ImageryLayer.propTypes = {
  map: PropTypes.object,
};
