import * as React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";

import {
  useImageReviewerCropSelectionDispatch,
  useImageReviewerCropSelectionState,
  useImageReviewerSelectedCrop,
} from "../../contexts/ImageReviewerCropsContext/ImageReviewerCropsContext";
import { Checkbox } from "../../../../Helpers/Checkbox/Checkbox";
import { useImageReviewerSelectedImage } from "../../contexts/ImageReviewerContext/ImageReviewerContext";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "grid",
    gridAutoFlow: "row",
    marginTop: `-${theme.spacing(1.5)}`,
  },
  shelfStressItemsContainer: {
    overflowY: "auto",
  },
  itemsContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
  stressItemContainer: {
    marginBottom: `${theme.spacing(6)}px`,
  },
  stressItemTitleContainer: {
    marginTop: `${theme.spacing(2)}`,
    marginBottom: `${theme.spacing(0.5)}`,
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.colors.gray32,
    zIndex: 1,
  },
  label: {
    ...theme.typography.body2,
  },
}));

const StressItemToggle = React.memo(({ checked, cropTypeId, mode, ...item }) => {
  const dispatch = useImageReviewerCropSelectionDispatch();
  const classes = useStyles();

  return (
    <FormControlLabel
      classes={{
        label: classes.label,
      }}
      control={
        <Checkbox
          checked={checked}
          onChange={() => {
            dispatch({
              type: "toggle-stress-item",
              payload: {
                ...item,
                cropTypeId,
              },
            });
          }}
          key={item.id}
          value={item.id}
          name="cropStressItem"
          color="primary"
          // TODO: checkbox is still not recognizing what this prop
          // means.
          mode={mode}
        />
      }
      label={item.name}
    />
  );
});

const StressItem = React.memo(
  ({ stressItems, cropTypeId, cropName, ...itemType }) => {
    const classes = useStyles();
    const selectedImage = useImageReviewerSelectedImage();

    return (
      <div className={classes.stressItemContainer}>
        <Typography
          variant="body1"
          className={classes.stressItemTitleContainer}
        >
          {itemType.name}
        </Typography>

        <div className={classes.itemsContainer}>
          {itemType.cropStressItems.map((item, i) => {
            const hasUserToggledIt = !!stressItems?.[item.id];

            return (
              <StressItemToggle
                key={item.id}
                cropTypeId={cropTypeId}
                checked={hasUserToggledIt ? true : false}
                mode={
                  isStressItemLabeledByMl({
                    crop: cropName,
                    // TODO: `name` doesn't work in this case because
                    // the BE is defining this prop to be user
                    // friendly. It's rendering something like "Common
                    // Lambsquarter" and we would need something like
                    // "common_lambsquarter". We need to validate with
                    // the BE what naming convention they are using
                    // for this.
                    stressItem: item.name,
                    mlData: selectedImage?.mlMetaData
                  })
                    ? "labeled"
                    : undefined
                }
                {...item}
              />
            );
          })}
        </div>
      </div>
    );
  }
);

const ML_THRESHOLD = 25;

const isStressItemLabeledByMl = ({ crop, stressItem, mlData }) => {
  var dataCropName = crop.toLowerCase();
  var dataStressItem = stressItem.toLowerCase();
  dataStressItem = dataStressItem.replace("insect damage", "pest");
  dataStressItem = dataStressItem.replace("weeds (broadleaf)", "weeds");
  dataStressItem = dataStressItem.replace("weeds (grass)", "weeds");
  dataStressItem = dataStressItem.replace("nutrient deficiency", "nutrient");
  if (mlData) {
    if (dataStressItem === "above canopy" && mlData['sky'] > 75) {
      return true;
    }
    return mlData[`${dataCropName}_${dataStressItem}`] >= ML_THRESHOLD;
  } else {
    return false;
  }
};

export const ShowImageReviewerShelfStressItems = ({ mode }) => {
  const selectedCrop = useImageReviewerSelectedCrop();
  const state = useImageReviewerCropSelectionState();
  const dispatch = useImageReviewerCropSelectionDispatch();
  const classes = useStyles();

  if (!selectedCrop?.cropStressTypes) {
    return null;
  }

  return (
    <div className={classes.mainContainer}>
      {mode === "rereview" && (
        <FormControlLabel
          control={
            <Switch
              checked={state.rereview}
              onChange={() => {
                dispatch({ type: "toggle-rereview-mode" });
              }}
              name="checkedB"
              color="primary"
            />
          }
          label="Rereview Mode"
        />
      )}

      <div className={classes.shelfStressItemsContainer}>
        {selectedCrop.cropStressTypes.map((itemType) => {
          return (
            <StressItem
              key={itemType.id}
              stressItems={state.stressItems[selectedCrop.id]}
              cropTypeId={selectedCrop.id}
              cropName={selectedCrop.name}
              {...itemType}
            />
          );
        })}
      </div>
    </div>
  );
};
