import React, { useState } from "react";
import { useTable, useExpanded, useSortBy } from "react-table";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./Table.module.scss";
import { ReactComponent as SortIcon } from "../../../assets/icons/sort.svg";
import clsx from "clsx";
import { Skeleton } from "../Skeletons/Skeleton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import UploadIcon from '@mui/icons-material/Upload';
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import EditWorkOrderShelf from "../../Views/Field/FieldShelf/EditWorkOrderShelf/EditWorkOrderShelf";
//import EditTaskModal from "../../Modals/EditTaskModal";
import { deleteWorkOrder } from "../../../controllers/workOrder";
import Swal from "sweetalert2";
import { useAuth } from "../../../hooks/useAuth";
import { useField } from "../../../globalState/fieldContext";
import { useHistory } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import { useUser } from "../../../hooks/useUser";
import { probeTaskIdFromWorkOrderId } from "../../../hooks/useApi";
import { UploadFilesPopup } from "../../Views/WorkOrders/UploadFilesPopup";

const sortedDesc = {
  "--sort-icon-bottom": "var(--blue)",
};

const sortedAsc = {
  "--sort-icon-top": "var(--blue)",
};

const useExpandableRowStyles = makeStyles({
  hoverRow: {
    "&:hover": {
      backgroundColor: (props) =>
        props.clickRowToExpand ? "rgba(127, 127, 127, 0.2)" : undefined,
    },
  },
});
/**
 * Table Component
 * @param style - Custom style for the table's wrapper
 * @param noResultsLabel - Custom String for when table has no results
 * @param onRowHoverEnter - callback function passing in the row on-mouse hover
 * @param onRowHoverExit - callback function passing in the row on-mouse leave
 * @param stickyHeaders - boolean for if the table should use sticky headers..
 * @param data - Data for the table
 * @param dataLoaded - boolean for if the data has loaded
 * @param autoEditWorkOrderId - work order id for directly entering edit mode
 * @param updateData - callback function to update the data table
 * @returns {Table} JSX Element
 */
export const Table = ({ renderRowSubComponent,
  // setShowEditTaskModal,
  // setTaskModalData,
  ...props }) => {
  const noop = (row) => { };
  const {
    style = {},
    noResultsLabel,
    id,
    data,
    onRowHoverEnter = noop,
    onRowHoverExit = noop,
    stickyHeaders = false,
    dataLoaded, // required
    autoEditWorkOrderId,
    updateData,
  } = props;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable(props, useSortBy, useExpanded);

  const [dropWork, setDropWork] = useState(false);
  // const [edit, setEdit] = useState();
  const { field, updateSeasons } = useField();
  const [workOrderId, setWorkOrderId] = useState(null);
  const expandableRowClasses = useExpandableRowStyles(props);
  const tableClassName = stickyHeaders ? styles.Table__Sticky : styles.Table;
  const { token } = useAuth();
  const history = useHistory();
  const { currentUser } = useUser();
  const [taskId, setTaskId] = useState(null);
  const [fileTypes, setFileTypes] = useState();
  const [uploadType, setUploadType] = useState();
  const [fieldName, setFieldName] = useState(null);
  const [fieldId, setFieldId] = useState(null);
  const [openUploadPopup, setOpenUploadPopup] = useState(false);
  const [editSoilPoints, setEditSoilPoints] = useState(false);
  const [taskCompleted, setTaskCompleted] = useState(false);

  const handleEditOpen = () => {
    setOpenUploadPopup(true);
  };
  const handleEditClose = () => {
    setOpenUploadPopup(false);
  };

  let headerCountArray = headerGroups[0].headers.filter(
    (header) => typeof header.Header === "string"
  );

  // const closeEditTaskModal = () => {
  //   setShowEditTaskModal(false);
  // }

  // const { data: workOrderData } = useApi(`/api/v1/work_orders/${workOrderId}`);

  const handleEditIcon = (editableId) => {
    const allData = data.find((f) => f.id === parseInt(editableId))
    if (!field) {
      if (allData) {
        history.push(`/farm/${allData.farm.id}/field/${allData.field.id}/work-order/${editableId}`)
      }
    } else {
      setDropWork(!dropWork);
      setWorkOrderId(parseInt(editableId));
      if (autoEditWorkOrderId) {
        if (allData) {
          const soil_sampling = allData.serviceId === 51;
          setEditSoilPoints(soil_sampling);
        }
      }
    }
  }

  const handleReviewIcon = async (reviewId) => {
    // look up 
    const taskId = await probeTaskIdFromWorkOrderId(reviewId, token)
    history.push(`/image-reviewer/${taskId}`);
  };

  const handleUploadIcon = async (workOrderId, serviceType, fieldName, completed) => {
    // look up 
    //const taskId = await scoutTaskIdFromWorkOrderId(workOrderId, token)
    const allData = data.find((f) => f.id === workOrderId);
    const task = allData.tasks.find((task) => task.type === "scout" && task.status !== "declined");
    completed = (completed === null) ? task.status.toLowerCase() === "completed" : completed;
    fieldName = (fieldName === null) ? field[0].title : fieldName;
    serviceType = (serviceType === null) ? allData.service : serviceType;
    setTaskId(task.id);
    setWorkOrderId(workOrderId);
    setFieldName(fieldName);
    // if we have the field ID in the table data, use it, otherwise we're in the field
    const fieldId = allData?.field ? allData?.field?.id : field[0].id;
    setFieldId(fieldId);
    setUploadType(serviceType);
    switch (serviceType) {
      case "Field Collection":
        setFileTypes(['zip']);
        break;
      case "Soil Sampling":
        setFileTypes(['csv']);
        break;
      default:
        setFileTypes(null);
    }
    setTaskCompleted(completed);
    setOpenUploadPopup(true);

  };

  const handleData = () => {
    setDropWork(false);
  };

  const handleDeleteIcon = async (row) => {
    //setDropWork(dropWork);

    const workOrderId = row.values.id;
    const alertRes = await Swal.fire({
      icon: "question",
      title: "Delete Work order",
      html: `Are you sure you want to delete  <small>${`work order  ${workOrderId}`}</small>?`,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#ff4444",
    });
    //setWorkOrderData((data) =>data && data.filter((item) => item.id !== workOrderId));
    if (alertRes.isConfirmed) {
      const {
        data: { success },
      } = await deleteWorkOrder(token, workOrderId);
      if (success) {
        //history.push(`/farm/${workOrderId}`);
        if (field) {
          Swal.fire({
            toast: true,
            timer: 3000,
            timerProgressBar: true,
            position: "bottom-right",
            title: `Successfully deleted ${`work order ${workOrderId}`}`,
            icon: "success",
          });
          await updateSeasons();
        } else {
          const newData = data.map((row) => {
            if (row.id === workOrderId) {
              return { ...row, status: 'rejected' };
            } else {
              return row;
            }
          });
          updateData(newData);
        }
        // window.location.reload(false); // for refresh
      } else {
        Swal.fire({
          toast: true,
          timer: 5000,
          timerProgressBar: true,
          title: "An error occurred",
          text:
            "An error occurred while trying to delete the field. Please try again.",
          position: "bottom-right",
          icon: "error",
        });
      }
    }
  };

  const isEditable = (row, i) => {
    let canEdit = data[i].timeline?.length > 0 && !['orderRejected', 'orderComplete'].includes(data[i].timeline[0].type)
    canEdit = canEdit && !['rejected', 'completed'].includes(row.values.Status?.toLowerCase())
    return canEdit
  }

  const isDeleted = (row, i) => {
    let deleted = data[i].timeline?.length > 0 && ['orderRejected'].includes(data[i].timeline[0].type)
    deleted = deleted || ['rejected'].includes(row.values.Status?.toLowerCase())
    return deleted
  }

  const isReviewable = (row, i) => {
    let canReview = currentUser?.isImageReviewer && row.values.service?.includes("TrueCause") && !['rejected'].includes(row.values.Status?.toLowerCase())
    canReview = canReview && data && data[i].timeline && ['orderDataProcessing', 'orderComplete'].includes(data[i].timeline[0].type)
    return canReview
  }

  const isUploadable = (row, i) => {
    const status = row.values.Status?.toLowerCase()
    const service = row.values.service?.toLowerCase()

    const soilSamplingReady = service === 'soil sampling' && !isDeleted(row, i);//&& ['processing'].includes(status)
    const fieldCollectionReady = service === 'field collection' && ['assigned', 'new', 'scheduled'].includes(status)

    return soilSamplingReady || fieldCollectionReady
  }

  React.useEffect(() => {
    if (autoEditWorkOrderId) {

      handleEditIcon(autoEditWorkOrderId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoEditWorkOrderId]);

  return (
    <div
      id={id}
      style={style}
      className={styles.TableWrap}
      {...(props.onScroll ? { onScroll: props.onScroll } : {})}
    >
      <UploadFilesPopup
        open={openUploadPopup}
        handleClose={handleEditClose}
        handleOpen={handleEditOpen}
        taskId={taskId}
        workOrderId={workOrderId}
        fieldName={fieldName}
        fieldId={fieldId}
        fileTypes={fileTypes}
        uploadType={uploadType}
        completed={taskCompleted}
      />

      <table className={tableClassName} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, idx) => (
            <tr key={idx} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, headerIndex) => {
                const headerProps = column.getHeaderProps(
                  column.getSortByToggleProps({ title: undefined })
                );

                const isSortable =
                  column.sortable === undefined || column.sortable;

                return (
                  <Tooltip title="Click to sort">
                    <th {...headerProps} key={headerIndex}>
                      <div className={styles.table__header}>
                        {column.render("Header")}

                        {isSortable && (
                          <span className={styles["table__header-icon"]}>
                            <SortIcon
                              width={14}
                              height={14}
                              style={
                                column.isSorted
                                  ? column.isSortedDesc
                                    ? sortedDesc
                                    : sortedAsc
                                  : {}
                              }
                            />
                          </span>
                        )}
                      </div>
                    </th>
                  </Tooltip>
                );
              })}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {dataLoaded
            ? rows.map((row, i, arr) => {
              prepareRow(row);
              return (
                <React.Fragment key={i}>
                  <tr
                    id={row.values.id}
                    onMouseEnter={() => onRowHoverEnter(row)}
                    onMouseLeave={() => onRowHoverExit(row)}
                    className={clsx(
                      expandableRowClasses.hoverRow,
                      row.isExpanded &&
                      props.highlightClassName &&
                      props.highlightClassName
                    )}
                    {...row.getRowProps()}
                    {...(props.clickRowToExpand
                      ? row.getToggleRowExpandedProps({ title: undefined })
                      : {})}
                  >
                    {row.cells.map((cell, j) => {
                      return (
                        <Tooltip title={cell.row.original?.completedDate ? "Click to expand histoy" : ""}>
                          <td
                            key={`${i}-${j}`}
                            {...cell.getCellProps()}
                            onClick={() => handleData()}
                          >
                            {cell.render("Cell")}
                          </td>
                        </Tooltip>
                      );
                    })}
                    <div style={{ display: "flex{", marginLeft: "-75px", horizontalAlign: "left", verticalAlign: "middle", height: "100%" }}>
                      {row.values.service && !row.values.service.includes("Satellite") && data && isEditable(row, i) ?
                        <>
                          <Tooltip title="Show/Edit">
                            <EditIcon
                              onClick={(e) => { e.stopPropagation(); handleEditIcon(row.values.id) }}
                            />
                          </Tooltip>
                          <Tooltip title="Cancel">
                            <DeleteIcon
                              onClick={(e) => { e.stopPropagation(); handleDeleteIcon(row) }}
                            />
                          </Tooltip>
                        </> : null}
                      {isUploadable(row, i) ?
                        <>
                          <Tooltip title="Upload Data">
                            <UploadIcon
                              onClick={(e) => { e.stopPropagation(); handleUploadIcon(row.values.id, row.values.service, row.values.farm, row.values.Status?.toLowerCase() === 'completed') }}
                            />
                          </Tooltip>
                        </> : null
                      }
                      {isReviewable(row, i) ?
                        <>
                          <Tooltip title="Review Imaginary">
                            <CropOriginalIcon
                              onClick={() => handleReviewIcon(row.values.id)}
                            />
                          </Tooltip>
                        </> : null}
                      {isDeleted(row, i) ?
                        <>
                          <Tooltip title="Deleted">
                            <DeleteIcon
                              style={{ fill: '#ff0000' }}
                              onClick={() => null}
                            />
                          </Tooltip>
                        </> : null}
                    </div>
                  </tr>
                  {dropWork && workOrderId === row.values.id ? (
                    field ? (
                      <EditWorkOrderShelf
                        data={handleData}
                        editableId={workOrderId}
                        editSoilPoints={editSoilPoints}
                      />
                    ) : (
                      <></>
                    )) : (
                    row.isExpanded &&
                    renderRowSubComponent &&
                    renderRowSubComponent({ row, visibleColumns })
                  )}
                </React.Fragment>
              );
            })
            : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((_, i) => (
              <tr key={i}>
                {headerCountArray.map((_, i) => (
                  <td key={i}>
                    <Skeleton key={i} height="1.5rem" />
                  </td>
                ))}
              </tr>
            ))}
        </tbody>

        {data.length === 0 && noResultsLabel && dataLoaded && (
          <tbody>
            <tr>
              <td colSpan={5}>{noResultsLabel}</td>
            </tr>
          </tbody>
        )}
      </table>
    </div>

  );
};
