import React, { useState, useEffect, useContext } from "react";
import mapboxgl from "mapbox-gl";
import { DrawControls } from "./DrawControls";
import MapLayerToggle from "./MapLayerToggle";
import { GeometriesLayer } from "./GeometriesLayer";
import { ImageryLayer } from "./ImageryLayer";
import { MapContext } from "../../globalState";
import { Markers } from "./Markers";
import styles from "./Map.module.scss";
import { TrueCauseFlags } from "./TrueCauseFlags";
import mapboxLogoTransparent from "../../assets/mapbox-logo-white-04.svg";
import { PitchToggle } from "./PitchToggle";
import SoilSampleLayer from "./SoilSampleLayer";
import StandCountLayer from "./StandCountLayer";
import EditPointLocationLayer from "./EditPointLocationLayer";

const defaultOptions = {
  center: [-104, 40],
  zoom: 3.5,
  preserveDrawingBuffer: true, // required for the map's canvas to be exported to a PNG
};
/**
 *
 * @param  props.defaultStyle -- will override whatever value the user had in localStorage
 * @returns
 */

function set_up_3D(map) {
  if (!map.getSource('mapbox-dem')) {
    map.addSource('mapbox-dem', {
      'type': 'raster-dem',
      'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
      'tileSize': 512,
      'maxzoom': 18
    });
    //add the DEM source as a terrain layer with exaggerated height
    //map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 5 });

    // add a sky layer that will show when the map is highly pitched
    if (map.getLayer('sky')) {
      map.removeLayer('sky')
    }
    map.addLayer({
      'id': 'sky',
      'type': 'sky',
      'paint': {
        'sky-type': 'atmosphere',
        'sky-atmosphere-sun': [0.0, 0.0],
        'sky-atmosphere-sun-intensity': 15
      }
    });
  }
}

export const Map = ({
  defaultStyle = "",
  mapOptions = {},
  goToZoomLevel = 18,
  drawingEnabled = false,
  geometryToEdit,
  setGeometryToEdit,
  fields,
  regions,
  imageToDisplay,
  ahiToDisplay,
  ahiValuesToDisplay,
  soilSamplesToDisplay,
  soilSampleValuesToDisplay,
  mapBounds,
  goToPoint = null,
  trueCauseFlags,
  hideMapLayerToggle = false,
  containerId,
  hideControls = false,
  disableZoom = false,
  disableDrag = false,
  onMarkerClick = () => { },
  onTrueCauseFlagClick = () => { },
  onTrueCauseFlagHover = (flag) => { },
  offset = null,
  markerTooltipKey,
  markerScrollIntoView = false,
  markerTooltipLabel = "",
  fitBounds = true,
  stressMapToDisplay,
  soilSampleData,
  showSoilSample,
  activityData,
  selectedNutrient,

  standCountToDisplay,
  standCountData,
  showStandCountSample,
}) => {
  const { mapStyles, preferredMapStyle, setMapboxLogo } = useContext(MapContext);
  const { fieldMap, setFieldMap } = useContext(MapContext); //useState(null);
  const [map, setMap] = useState(null);
  const [refreshMap, setRefreshMap] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(null);
  const [selectedMapStyle, setSelectedMapStyle] = useState(preferredMapStyle);

  useEffect(() => {
    if (!map && mapOptions) {
      const newMap = new mapboxgl.Map({
        ...defaultOptions,
        ...mapOptions,
        container: containerId,
        style: preferredMapStyle
          ? mapStyles[preferredMapStyle].url
          : mapStyles[selectedMapStyle].url,
        accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
        attributionControl: false,
        dragPan: !disableDrag,
      });
      if (!hideControls) {
        newMap.addControl(
          new mapboxgl.AttributionControl({
            compact: true,
          }),
          "top-left"
        );
        const scale = new mapboxgl.ScaleControl({
          maxWidth: 100,
          unit: 'imperial'
        });
        newMap.addControl(scale, "top-right");
        newMap.addControl(new mapboxgl.NavigationControl({ visualizePitch: true }), "bottom-right");
        newMap.addControl(new PitchToggle({}), "bottom-right");
        newMap.addControl(new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
            timeout: 7500
          },
          showUserHeading: false,
          // Creates a toggle button so you can follow if the user moves. This is typically not needed
          // as we use on a web browser on a laptop, but it may be useful if users become more 
          // mobile with this front end
          //trackUserLocation: true,
        }), 'bottom-right')
      }

      if (disableZoom) {
        newMap.scrollZoom.disable();
        newMap.doubleClickZoom.disable();
        newMap.keyboard.disable();
      }
      newMap.on("load", async () => {
        setMapLoaded(true);
        newMap.addLayer({
          id: "base",
          type: "line",
          source: {
            type: "vector",
            url: mapStyles[selectedMapStyle].url,
          },
          "source-layer": "contour",
        });
        set_up_3D(newMap);
      });
      newMap.on("style.load", function (e) {
        let loaded = e.style._loaded;
        if (loaded) {
          setRefreshMap(true);
        }
      });

      newMap.on("error", (e) => {
        // Hide those annoying non-error errors
        if (e && e.error?.status && e.error.status !== 404) console.error(e);
      });

      const loadImage = src =>
        new Promise((resolve, reject) => {
          const img = document.createElement('img');
          img.onload = () => resolve(img);
          img.onerror = reject;
          img.src = src;
        });

      loadImage(mapboxLogoTransparent).then(logo => setMapboxLogo(logo))

      if (defaultStyle) {
        setSelectedMapStyle(defaultStyle);
      } else if (preferredMapStyle) {
        setSelectedMapStyle(preferredMapStyle);
      }

      setMap(newMap);
      setFieldMap(newMap);
    }
  }, [mapOptions, map, fieldMap, refreshMap,
    containerId, defaultStyle, disableDrag, disableZoom, hideControls,
    mapStyles, preferredMapStyle, selectedMapStyle, setFieldMap, setMapboxLogo
  ]);

  useEffect(() => {
    if (fieldMap) {
      fieldMap.setStyle(mapStyles[selectedMapStyle].url);
    }
    if (map) {
      map.setStyle(mapStyles[selectedMapStyle].url);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedMapStyle]);

  useEffect(() => {
    if (fieldMap && mapBounds) {
      fieldMap.fitBounds(mapBounds, {
        animate: false,
        padding: 50,
        offset: [90, 0],
      });
    }
    if (map && mapBounds) {
      map.fitBounds(mapBounds, {
        animate: false,
        padding: 50,
        offset: [90, 0],
      });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [mapBounds]);

  useEffect(() => {
    if (goToPoint && fieldMap) {
      fieldMap.jumpTo({
        center: goToPoint,
        zoom: goToZoomLevel,
        padding: { left: 350 },
      });
    }
    if (goToPoint && map) {
      map.jumpTo({
        center: goToPoint,
        zoom: goToZoomLevel,
        padding: { left: 350 },
      });
    }
  }, [goToPoint, map, goToZoomLevel, fieldMap]);

  const removeLayer = async (layerId) => {
    fieldMap.style.getLayer(layerId) && (await fieldMap.removeLayer(layerId));
  };

  return (
    <div className={styles.Map}>
      <div id={containerId} className={styles.MapContainer}>
        {mapLoaded && (
          <>
            <DrawControls
              map={fieldMap}
              drawingEnabled={drawingEnabled}
              setGeometryToEdit={setGeometryToEdit}
              geometryToEdit={geometryToEdit}
              removeLayer={removeLayer}
            />
            <SoilSampleLayer
              map={fieldMap}
              soilSampleData={soilSampleData}
              showSoilSample={showSoilSample}
              activityData={activityData}
              selectedNutrient={selectedNutrient}
            />
            <StandCountLayer
              map={fieldMap}
              standCounts={standCountData}
              showStandCount={showStandCountSample}
              activityData={activityData}
            />
            <EditPointLocationLayer
              map={fieldMap}
            />
            {imageToDisplay && (fields.length > 0) && (
              <ImageryLayer
                map={fieldMap}
                imageToDisplay={imageToDisplay}
                refreshMap={refreshMap}
                containerId={containerId}
                fieldLayerId={`${containerId}-polygon-${fields[0].id}-line`}
              />
            )}
            {ahiToDisplay && (fields.length > 0) && (
              <ImageryLayer
                map={fieldMap}
                imageToDisplay={ahiToDisplay}
                refreshMap={refreshMap}
                containerId={containerId}
                fieldLayerId={`${containerId}-polygon-${fields[0].id}-line`}
                valuesToDisplay={ahiValuesToDisplay}
              />
            )}
            {soilSamplesToDisplay && (fields.length > 0) && (
              <ImageryLayer
                map={fieldMap}
                imageToDisplay={soilSamplesToDisplay}
                refreshMap={refreshMap}
                containerId={containerId}
                fieldLayerId={`${containerId}-polygon-${fields[0].id}-line`}
                valuesToDisplay={soilSampleValuesToDisplay}
              />
            )}
            {stressMapToDisplay && (
              <ImageryLayer
                map={fieldMap}
                imageToDisplay={stressMapToDisplay}
                refreshMap={refreshMap}
                containerId={containerId}
                fieldLayerId={`${containerId}-polygon-${fields[0].id}-line`}
              />
            )}
            {standCountToDisplay && (
              <ImageryLayer
                map={fieldMap}
                imageToDisplay={standCountToDisplay}
                refreshMap={refreshMap}
                containerId={containerId}
                fieldLayerId={`${containerId}-polygon-${fields[0].id}-line`}
              />
            )}
            <GeometriesLayer
              map={fieldMap}
              containerId={containerId}
              selectedMapStyle={selectedMapStyle}
              bounds={fields && fields.length === 1 ? fields[0].bounds : null}
              geometries={fields}
              removeLayer={removeLayer}
              polygonBorderSize={2}
              refreshMap={refreshMap}
              setRefreshMap={setRefreshMap}
              mapLoaded={mapLoaded}
              offset={offset}
              fitBounds={fitBounds}
            />
            <GeometriesLayer
              map={fieldMap}
              containerId={containerId}
              selectedMapStyle={selectedMapStyle}
              geometries={regions}
              removeLayer={removeLayer}
              layerLabel="regions"
              drawingEnabled={drawingEnabled}
              refreshMap={refreshMap}
              setRefreshMap={setRefreshMap}
              mapLoaded={mapLoaded}
              showMarkers={true}
            />
            <Markers
              scrollIntoView={markerScrollIntoView}
              tooltipLabel={markerTooltipLabel}
              tooltipKey={markerTooltipKey}
              onMarkerClick={onMarkerClick}
              map={fieldMap}
              locations={fields}
              containerId={containerId}
              mapStyle={selectedMapStyle}
            />
            <TrueCauseFlags
              map={fieldMap}
              onFlagClick={onTrueCauseFlagClick}
              flags={trueCauseFlags}
              containerId={containerId}
            />
          </>
        )}
      </div>
      {mapLoaded && !hideMapLayerToggle && (
        <MapLayerToggle
          selectedMapStyle={selectedMapStyle}
          setSelectedMapStyle={setSelectedMapStyle}
        />
      )}
    </div>
  );
};
