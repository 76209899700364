import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { Input, Textarea } from "../../../../../Helpers";

const useStyles = makeStyles((theme) => ({
  body: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "1.5rem",
    width: "46.5rem",
    "& input": {
      marginBottom: "0",
    },
    "& textarea": {
      gridColumn: "span 2",
      height: "15rem",
    },
  },
  subtitle: { marginTop: "0.75rem", marginLeft: "1rem" },
}));

export const ContactInformation = ({
  parentClasses,
  newReport,
  updateNewReport,
}) => {
  const classes = useStyles();

  const {
    contactName,
    contactEmail,
    contactPhone,
    footnote,
    template,
  } = newReport;
  return (
    <Grid
      container
      item
      direction="column"
      className={parentClasses.section}
      style={{
        borderBottom: "none",
        paddingBottom: 0,
      }}
    >
      <Typography variant="h4">Contact Information</Typography>
      <div className={classes.body}>
        {!template.hideContactData && (
          <>
            <Input
              className={parentClasses.input}
              style={{ gridColumn: "span 2", width: "100%" }}
              placeholder="Contact Name"
              value={contactName ? contactName : ""}
              onChange={(e) => updateNewReport("contactName", e.target.value)}
            />
            {/* <Input
              className={parentClasses.input}
              placeholder="Last Name"
              value={contactName ? contactName : ""}
              onChange={(e) => updateNewReport("contactName", e.target.value)}
              /> */}
            <Input
              className={parentClasses.input}
              placeholder="Email Address"
              value={contactEmail ? contactEmail : ""}
              onChange={(e) => updateNewReport("contactEmail", e.target.value)}
              type="email"
            />
            <Grid>
              <Input
                className={parentClasses.input}
                placeholder="Phone Number"
                value={contactPhone ? contactPhone : ""}
                onChange={(e) =>
                  updateNewReport("contactPhone", e.target.value)
                }
                type="tel"
              />
              <Typography
                variant="subtitle2"
                color="textSecondary"
                className={classes.subtitle}
              >
                (###) ###-####
              </Typography>
            </Grid>
          </>
        )}
        <Textarea
          className={parentClasses.textarea}
          placeholder="Terms and Conditions Statement"
          value={footnote ? footnote : ""}
          onChange={(e) => updateNewReport("footnote", e.target.value)}
        />
      </div>
    </Grid>
  );
};
