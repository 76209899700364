import React, { useEffect } from "react";
import { useApi } from "../hooks/useApi";

/**
 * The FarmContext provides access to the farm data currently selected.
 * This allows multiple unrelated components displayed on the same page to have access
 * to that data, without needing to make additional XHR requests.
 * @type {React.Context<{currentFarm: {}}>}
 */
export const FarmContext = React.createContext({
  allFarms: [],
  allFarmsDetails: [],
  currentFarm: {},
  loadingFarms: false,
  setAllFarms: (farms: any) => { },
  setAllFarmsDetails: (farms: any) => { },
  setCurrentFarm: (farm: any) => { },

  farmsAreLoaded: false,
});

export const useFarms = () => React.useContext(FarmContext);

export const FarmProvider = (props: any) => {
  const [currentFarm, setCurrentFarm] = React.useState({});
  const { data, error } = useApi(`/api/v1/farms`);
  const { data: farmsDetailData, error: farmsDetailError } = useApi(`/api/v1/farms_details`);

  const [allFarms, setAllFarms] = React.useState([]);
  const [allFarmsDetails, setAllFarmsDetails] = React.useState([]);

  useEffect(() => {
    if (data && !allFarms.length) setAllFarms(data);
  }, [data, allFarms.length]);

  useEffect(() => {
    if (farmsDetailData && !allFarmsDetails.length) setAllFarmsDetails(farmsDetailData);
  }, [farmsDetailData, allFarmsDetails.length]);

  const loadingFarms = !data && !error && !farmsDetailData && !farmsDetailError;

  return (
    <FarmContext.Provider
      value={{
        setCurrentFarm,
        currentFarm,
        allFarms,
        setAllFarms,
        allFarmsDetails,
        setAllFarmsDetails,
        loadingFarms,
        farmsAreLoaded: !!data
      }}
    >
      {props.children}
    </FarmContext.Provider>
  );
};
