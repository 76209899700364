import * as React from "react";
import { useApi } from "../hooks/useApi";
import omit from '../lib/omit'


const CustomerAccountsStateContext = React.createContext({
  data: null,
});
const CustomerAccountsActionsContext = React.createContext({});

const SelectedCustomerAccountStateContext = React.createContext(null);
const SelectedCustomerAccountDispatchContext = React.createContext(() => { });

export const CustomerAccountsProvider = ({ children }) => {
  const { data, revalidate } = useApi(`/api/v1/customer_accounts`);
  
  const [state, dispatch] = React.useReducer((state, action) => {
    switch (action.type) {
      case "custom-account-select":
        return {
          ...state,
          id: action.payload.id,
        };

      case "clear-customer-account-selection":
        return omit(state || {}, 'id')

      default:
        return state;
    }
  });

  const actions = React.useMemo(() => {
    return {
      revalidate,
    };
  }, [revalidate]);

  return (

    <CustomerAccountsStateContext.Provider value={data}>
      <CustomerAccountsActionsContext.Provider value={actions}>
        <SelectedCustomerAccountDispatchContext.Provider value={dispatch}>
          <SelectedCustomerAccountStateContext.Provider value={state}>

            {children}
          </SelectedCustomerAccountStateContext.Provider>
        </SelectedCustomerAccountDispatchContext.Provider>
      </CustomerAccountsActionsContext.Provider>
    </CustomerAccountsStateContext.Provider>

  );
};

export const useCustomerAccounts = () => {
  const data = React.useContext(CustomerAccountsStateContext);

  return data;
};

export const useSelectedCustomerAccount = () => {
  const state = React.useContext(SelectedCustomerAccountStateContext);

  return state;
}

export const useSelectedCustomerAccountDispatch = () => {
  const dispatch = React.useContext(SelectedCustomerAccountDispatchContext);

  return dispatch;
};
