import React from "react";
import { useAuth } from "../hooks/useAuth";
import { getCurrentUser } from "../controllers/auth";

export const UserContext = React.createContext({});

export const useUser = () => React.useContext(UserContext);

export const UserProvider = (props) => {
  const { token, userId } = useAuth();
  const [currentUser, setCurrentUser] = React.useState({});

  React.useEffect(() => {
    if (userId && token) {
      const CurrentUser = async () => {
        await getCurrentUser(userId, token)
          .then((userData) => {
            setCurrentUser(userData);
          })
          .catch((err) => {
            console.error(err);
          });
      };

      CurrentUser();
    }
  }, [token, userId]);

  const value = React.useMemo(() => {
    return {
      currentUser,
      setCurrentUser,
    };
  }, [currentUser]);

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
};
