import React, { useContext } from "react";
import { ThemeContext } from "../../globalState";
import styles from "./Navigation.module.scss";
import { Icon } from "@material-ui/core";

export const ThemeToggle = () => {
  const { darkTheme, setDarkTheme } = useContext(ThemeContext);

  return (
    <div
      className={`${styles.ThemeToggle} ${darkTheme && styles.DarkTheme}`}
      onClick={() => setDarkTheme(!darkTheme)}
    >
      <div className={styles.ToggleBall}>
        <Icon
          style={{}}
          className={
            darkTheme
              ? `fas fa-moon ${styles.MoonIcon}`
              : `fas fa-sun ${styles.MoonIcon}`
          }
        />
      </div>
    </div>
  );
};
