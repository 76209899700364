import React from "react";
import * as ReactTabs from "react-tabs";
import cn from "clsx";

import "./TabsBase.scss";

export const Tabs = (props) => {
  return (
    <ReactTabs.Tabs
      {...props}
      className={cn(
        "Tabs",
        props.variant === "full" && "Tabs--full",
        props.className
      )}
    />
  );
};

Tabs.Tab = ReactTabs.Tab;
Tabs.List = ReactTabs.TabList;
Tabs.Panel = ReactTabs.TabPanel;
