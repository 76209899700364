import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import cn from "clsx";

/**
 * Component for creating a numeric input field with min/max values
 */

const useStyles = makeStyles((theme) => ({
  input: {
    width: "40%",
    margin: 0,
    marginTop: "0.5rem",
    marginBottom: "1rem",
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.background.tertiary,
    color: theme.palette.text.primary,
    borderColor: theme.palette.borderColor.primary,
    borderRadius: 4,
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.background.tertiary,
    },
    "& .MuiInputBase-input": {
      background: "transparent",
    },
  },
}));

export const NumericRangeInput = (props) => {
  const classes = useStyles();
  const propAttributes = { ...props };
  const className = props.className ? props.className : "";
  const min = props.min ? props.min : 1;
  const max = props.max ? props.max : 50;
  const step = props.step ? props.step : 1;
  const setValueFn = props.setValueFn ? props.setValueFn : () => { };
  const [value, setValue] = React.useState(props.defaultValue || 1);

  const setValues = (value) => {
    setValue(value);
    setValueFn(value);
  }

  const handleChange = (event) => {
    setValues(event.target.value);
  }

  const handleBlur = (event) => {
    if (value < min) {
      setValues(min);
    }
    if (value > max) {
      setValues(max)
    }
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 38) {
      if (value <= max) {
        setValues(parseFloat(value) + step);
      } else {
        event.preventDefault();
      }
    }
    if (event.keyCode === 40) {
      if (value >= min) {
        setValues(parseFloat(value) - step);
      } else {
        event.preventDefault();
      }
    }
  }

  return (
    <TextField
      type="number"
      value={value}
      variant="outlined"
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      label={props.label}
      inputProps={{ inputProps: { min: min, max: max }, step: step }}
      {...propAttributes}
      className={cn(classes.input, className)}
    />
  );
};
