import React from "react";

import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Switch } from "../../Helpers/Switch/Switch";
//import LanguageIcon from "@material-ui/icons/Language";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import EmailIcon from "@material-ui/icons/Email";
import { SettingsHeading } from "./Account";
import { Autocomplete2 } from "../../Helpers/Autocomplete/Autocomplete";
import { useApi, useLazyApi } from "../../../hooks/useApi";
import Paper from "@material-ui/core/Paper";
import Swal from "sweetalert2";
import {
  useCustomerAccounts,
  useSelectedCustomerAccount,
  useSelectedCustomerAccountDispatch,
} from "../../../globalState";
import formatAccountForMenu from "./util/formatAccount";

const useStyle = makeStyles((theme) => ({
  tableBanner: {
    backgroundColor: theme.palette.background.quinary,
  },
  icons: {
    verticalAlign: "bottom",
    fontSize: "120%",
    margin: "5px",
  },
}));

const NotificationTableRow = ({
  id,
  title,
  isPlatform = false,
  isEmail = false,
  isSms = false,
  onChange,
}) => {
  return (
    <TableRow>
      <TableCell>{title}</TableCell>
      {/* TODO: UI removed until this is in place in the API */}
      {/* <TableCell>
        <Switch
          checked={isPlatform}
          onChange={(el, isChecked) =>
            onChange && onChange(id, "platform", isChecked)
          }
        ></Switch>
      </TableCell> */}
      <TableCell>
        <Switch
          checked={isSms}
          onChange={(el, isChecked) =>
            onChange && onChange(id, "sms", isChecked)
          }
        ></Switch>
      </TableCell>
      <TableCell>
        <Switch
          checked={isEmail}
          onChange={(el, isChecked) =>
            onChange && onChange(id, "email", isChecked)
          }
        ></Switch>
      </TableCell>
    </TableRow>
  );
};

const NotificationTable = ({ title, children }) => {
  const classes = useStyle();

  return (
    <TableContainer>
      <Table component={Paper}>
        <TableHead>
          <TableRow className={classes.tableBanner}>
            <TableCell colSpan={4}>
              <Typography variant="h6">{title}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Activity</TableCell>
            {/* TODO: UI removed until this is in place in the API */}
            {/* <TableCell>
              <LanguageIcon className={classes.icons} />
              Platform
            </TableCell> */}
            <TableCell>
              <PhoneIphoneIcon className={classes.icons} />
              SMS
            </TableCell>
            <TableCell>
              <EmailIcon className={classes.icons} />
              As Email
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

const NotificationTableRowWrapper = ({ setting, onSuccess, onFailure }) => {
  const [updateNotification] = useLazyApi(
    `/api/v1/notification_preferences/${setting.id}`,
    {
      method: "PUT",
    }
  );

  return (
    <NotificationTableRow
      title={setting.name}
      isPlatform={setting.isPlatform || false}
      isSms={setting.isSms || false}
      isEmail={setting.isEmail || false}
      onChange={async (_, type, isChecked) => {
        const updatedSetting = {
          ...setting,
          isPlatform: type === "platform" ? isChecked : setting.isPlatform,
          isSms: type === "sms" ? isChecked : setting.isSms,
          isEmail: type === "email" ? isChecked : setting.isEmail,
        };
        await updateNotification({
          data: {
            platform: type === "platform" ? isChecked : setting.isPlatform,
            sms: type === "sms" ? isChecked : setting.isSms,
            email: type === "email" ? isChecked : setting.isEmail,
          },
        })
          .then(async (response) => {
            onSuccess && onSuccess(type, isChecked, updatedSetting);
            return response.data;
          })
          .catch(async () => {
            onFailure && onFailure(type, isChecked);
          });
      }}
    />
  );
};

export const NotificationsPreferences = () => {
  const {
    data: notificationsData,
    revalidate: revalidateNotifications,
  } = useApi(`/api/v1/notification_preferences`);

  const globallySelectedCustomerAccount = useSelectedCustomerAccount();
  const customerAccounts = useCustomerAccounts();
  const dispatch = useSelectedCustomerAccountDispatch();

  const [notifications, setNotifications] = React.useState({});

  React.useEffect(() => {
    const farmActivityHeadings = [
      "New Aerial Imagery",
      "New TrueCause Imagery",
      "New Regions",
    ];
    const workOrdersHeadings = [
      "Work Order Complete",
      "Flight Scheduled",
      "Pilot Arriving Soon",
    ];

    if (notificationsData) {
      if (globallySelectedCustomerAccount) {
        let mappedNotifications = notificationsData
          .filter(
            (n) =>
              n.customerAccountManagement &&
              n.customerAccountManagement.customerAccountId ===
              globallySelectedCustomerAccount.id
          )
          .map(({ notificationType, email, sms, platform, id }) => {
            return {
              [`${notificationType.name}`]: {
                name: `${notificationType.name}`,
                isEmail: email,
                isSms: sms,
                isPlatform: platform,
                id,
              },
            };
          });

        const farmActivitySettings = mappedNotifications
          .map((setting) =>
            Object.values(setting).filter(({ name }) =>
              farmActivityHeadings.includes(name)
            )
          )
          .flat();

        const workOrderSettings = mappedNotifications
          .map((setting) =>
            Object.values(setting).filter(({ name }) =>
              workOrdersHeadings.includes(name)
            )
          )
          .flat();

        const notifications = {
          "My Farms Activity": {
            name: "My Farms Activity",
            settings: { ...farmActivitySettings },
          },
          "Work Orders": {
            name: "Work Orders",
            settings: { ...workOrderSettings },
          },
        };

        setNotifications(notifications);
      }
    }
  }, [notificationsData, globallySelectedCustomerAccount]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SettingsHeading text="Notifications Preferences" />
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Stay up to date with notifications on activity that involves you
            including field updates, stresssor information, changes to your
            accounts, and important administration updates.
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Autocomplete2
            labelText={"Cutomer Account"}
            options={(
              (customerAccounts &&
                customerAccounts
                  .filter(
                    (item) => item.permissions && item.permissions.canUpdate
                  )
                  .map(formatAccountForMenu)) ||
              []
            ).sort((c1, c2) => (c1.label < c2.label ? -1 : 1))}
            onChange={(_e, selected) => {
              dispatch({
                type: "custom-account-select",
                payload: {
                  id: selected.value,
                },
              });
            }}
            value={
              customerAccounts && globallySelectedCustomerAccount
                ? formatAccountForMenu(
                  customerAccounts.find(
                    (a) => a.id === globallySelectedCustomerAccount.id
                  )
                )
                : null
            }
            getOptionSelected={(option, value) => option.value === value.value}
            disableClearable
            fullWidth
          />
        </Grid>
        <Grid container item xs={12} spacing={4}>
          {Object.entries(notifications).map(([nid, n]) => (
            <Grid key={nid} item xs={12}>
              <NotificationTable key={nid} title={n.name}>
                {Object.entries(n.settings).map(([sid, s]) => (
                  <NotificationTableRowWrapper
                    key={sid}
                    setting={s}
                    onSuccess={async (type, isChecked, updated) => {
                      setNotifications(
                        Object.keys(notifications).map((key) => {
                          return {
                            ...notifications[key],
                            settings: Object.keys(
                              notifications[key].settings
                            ).map((settingsKey) => {
                              return notifications[key].settings[settingsKey]
                                .id === updated.id
                                ? updated
                                : notifications[key].settings[settingsKey];
                            }),
                          };
                        })
                      );
                      await Swal.fire({
                        icon: "success",
                        title: "Success!",
                        text: "Notification preference updated",
                        toast: true,
                        timerProgressBar: true,
                        timer: 5000,
                        position: "bottom-right",
                      });
                    }}
                    onFailure={async (type, isChecked) => {
                      await Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text:
                          "An error occurred while updating the notification preference",
                      });

                      revalidateNotifications();

                      setNotifications((prev) => {
                        return {
                          ...prev,
                          [`${nid}`]: {
                            ...prev[nid],
                            settings: {
                              ...prev[nid]["settings"],
                              [`${sid}`]: {
                                ...prev[nid]["settings"][sid],
                                isPlatform:
                                  type === "platform"
                                    ? isChecked
                                    : prev[nid]["settings"][sid]["isPlatform"],
                                isSms:
                                  type === "sms"
                                    ? isChecked
                                    : prev[nid]["settings"][sid]["isSms"],
                                isEmail:
                                  type === "email"
                                    ? isChecked
                                    : prev[nid]["settings"][sid]["isEmail"],
                              },
                            },
                          },
                        };
                      });
                    }}
                  />
                ))}
              </NotificationTable>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};
