import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  makeStyles,
  Typography,
  MenuItem,
  Menu,
} from "@material-ui/core";
import { ShelfLabel } from "../../../../Helpers/ShelfLabel/ShelfLabel";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import * as Feather from "react-feather";
import cn from "clsx";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import { useAuth } from "../../../../../hooks/useAuth";
import { deleteReport } from "../../../../../controllers/fields";
import Swal from "sweetalert2";
import { useField } from "../../../../../globalState/fieldContext";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { downloads3ToClient } from "../../../../../hooks/useApi";
const useStyles = makeStyles((theme) => ({
  newReportBtn: {
    height: "3rem",
    width: "100%",
    fontSize: theme.typography.body1.fontSize,
    fontFamily: theme.typography.fontFamilyMedium,
    borderColor: theme.palette.text.primary,
  },
  reportsContainer: {
    marginTop: "1.5rem",
  },
  reportRow: {
    padding: "1rem 0",
    borderTop: `1px solid ${theme.palette.colors.gray70}`,
    boxSizing: "border-box",
    "&:last-of-type": {
      borderBottom: `1px solid ${theme.palette.colors.gray70}`,
    },
  },
  reportTitle: {
    maxWidth: "50%",
  },
  actionBtnsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(4, auto)",
    alignItems: "center",
    columnGap: "1rem",
    "& p": {
      borderBottom: `1px solid ${theme.palette.text.primary}`,
      cursor: "pointer",
    },
  },
  actionBtn: {
    width: "1.1rem",
    cursor: "pointer",
  },
  popupDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  menuContainer: {
    "& .MuiPaper-root, .MuiList-root": {
      backgroundColor: theme.palette.background.default,
    },
  },
  menu: {
    backgroundColor: theme.palette.background.default,
    "&:hover": {
      backgroundColor: theme.palette.colors.quaternary,
      color: theme.palette.text.primary,
    },
  },
  typography: {
    color: theme.palette.text.primary,
    display: "inline",
    marginRight: "0.55rem",
  },
}));

export const ReportDrawer = ({
  useDrawerStyles,
  selectedSeasonReports,
  setReportToView,
  setReportToEdit,
  updateSeasonReports,
}) => {
  const { token } = useAuth();
  const [showOptions, setShowOptions] = useState(false);
  const drawerClasses = useDrawerStyles();
  const classes = useStyles();
  const fieldContext = useField();
  const seasons = fieldContext.seasons;
  const [reportMap, setReportMap] = React.useState([]);
  const { trueCauseMarkersEditMode } = useField();

  useEffect(() => {
    if (reportMap.length === 0 && selectedSeasonReports?.length) {
      const initialMap = selectedSeasonReports?.map((report) => ({
        id: report.id,
        isOpen: false,
      }));
      setReportMap(initialMap);
    }
  }, [selectedSeasonReports, reportMap.length]);

  const handleDeleteReport = async (reportId) => {
    const { isConfirmed } = await Swal.fire({
      title: "Are you sure you want to delete this report?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    });
    if (isConfirmed) {
      await deleteReport(token, reportId);
      updateSeasonReports();
      setReportToEdit(null);
    }
  };

  const downloadReport = async (report) => {
    await downloads3ToClient(report.url)
      .then(() =>
        Swal.fire({
          position: "bottom-right",
          toast: true,
          timer: 5000,
          timerProgressBar: true,
          title: "Successfully downloaded report.",
          icon: "success",
          html: "You have successfully downloaded " + report.title + ".",
        })
      )
      .catch(() =>
        Swal.fire({
          position: "bottom-right",
          timer: 5000,
          toast: true,
          timerProgressBar: true,
          title: "An error occurred downloading report",
          icon: "error",
          html:
            "We ran into an issue while downloading the" +
            report.title +
            " report. Please try again in a minute. If this message persists, contact support@aker.ag",
        })
      );
  };

  const copyReportURL = (report) => {
    navigator.clipboard.writeText(report.url);
  };

  const menuOptions = [
    {
      label: "Download",
      icon: "fa fa-download",
      onClick: (report, popupState) => {
        downloadReport(report).then(popupState.close);
      },
    },
    {
      label: "Copy",
      icon: "fa fa-clipboard",
      onClick: (report, popupState) => {
        copyReportURL(report);
        Swal.fire({
          toast: true,
          position: "bottom-right",
          timerProgressBar: true,
          timer: 5000,
          title: "Successfully copied to clipboard.",
          icon: "success",
          html: "Copied link to " + report.title + " to clipboard.",
        });
        popupState.close();
      },
    },
  ];

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      className={drawerClasses.root}
    >
      <ShelfLabel
        label={`Reports`}
        showOptions={showOptions}
        select={() => setShowOptions(!showOptions)}
        classes={showOptions && drawerClasses.showOptions}
      />
      {showOptions && (
        <Grid className={drawerClasses.options}>
          {selectedSeasonReports.length === 0 && (
            <Typography
              color="textSecondary"
              style={{ marginBottom: "1.5rem" }}
            >
              You have no reports
            </Typography>
          )}
          <Button
            variant="outlined"
            startIcon={<AddRoundedIcon />}
            disabled={trueCauseMarkersEditMode}
            className={classes.newReportBtn}
            onClick={() => {
              if (!seasons || seasons.length === 0) {
                Swal.fire({
                  title:
                    "You must have a season before you can create a report.",
                  icon: "warning",
                  showCancelButton: false,
                  dangerMode: true,
                  confirmButtonText: "OK",
                  closeOnConfirm: true,
                });
              } else {
                setReportToEdit({ new: true, flights: [] });
              }
            }}
          >
            New Report
          </Button>
          {selectedSeasonReports.length > 0 && (
            <Grid container item className={classes.reportsContainer}>
              {selectedSeasonReports &&
                selectedSeasonReports.map((report, i) => {
                  return (
                    <Grid
                      key={i}
                      container
                      item
                      justifyContent="space-between"
                      alignItems="center"
                      className={classes.reportRow}
                    >
                      <Typography
                        variant="body2"
                        className={classes.reportTitle}
                      >
                        {report.title}
                      </Typography>
                      <div className={classes.actionBtnsContainer}>
                        <Feather.Edit3
                          className={cn("feather-icon", classes.actionBtn)}
                          onClick={() =>
                            setReportToEdit({ ...report, new: false })
                          }
                        />

                        <PopupState
                          variant="popover"
                          popupId={`popup-${report.id}`}
                        >
                          {(popupState) => (
                            <React.Fragment>
                              <ShareOutlinedIcon
                                id={report.id}
                                className={classes.actionBtn}
                                {...bindTrigger(popupState)}
                              />
                              <Menu
                                className={classes.menuContainer}
                                {...bindMenu(popupState)}
                              >
                                {menuOptions.map((option, idx) => (
                                  <MenuItem
                                    className={classes.menu}
                                    onClick={() => {
                                      option.onClick(report, popupState);
                                    }}
                                    key={idx}
                                  >
                                    <div>
                                      <p className={classes.typography}>
                                        {option.label}
                                      </p>
                                      <i className={option.icon} />
                                    </div>
                                  </MenuItem>
                                ))}
                              </Menu>
                            </React.Fragment>
                          )}
                        </PopupState>
                        <Feather.Trash2
                          className={cn("feather-icon", classes.actionBtn)}
                          onClick={() => handleDeleteReport(report.id)}
                        />
                        <Typography
                          variant="body2"
                          onClick={() => setReportToView(report.url)}
                        >
                          View
                        </Typography>
                      </div>
                    </Grid>
                  );
                })}
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};
