import * as React from "react";
import cn from "clsx";

import { Chip } from "../../../../Helpers/Chip/Chip";
import {
  useImageReviewerDispatch,
  useImageReviewerState,
} from "../../contexts/ImageReviewerContext/ImageReviewerContext";

export const ImageReviewerViewModeAction = React.memo((props) => {
  const { viewMode } = useImageReviewerState();
  const dispatch = useImageReviewerDispatch();

  const { viewMode: propsViewMode } = props;
  const onClick = React.useCallback(() => {
    dispatch({ type: "view-mode", payload: propsViewMode });
  }, [dispatch, propsViewMode]);

  return (
    <Chip
      status={propsViewMode === viewMode ? "selected" : "unselected"}
      onClick={onClick}
      className={cn(props.className)}
    >
      {props.children}
    </Chip>
  );
});
ImageReviewerViewModeAction.displayName = "ImageReviewerViewModeAction";
