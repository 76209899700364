import * as React from "react";

import { ImageGrid } from "../ImageGrid/ImageGrid";
import { useImageReviewerDispatch } from "../../contexts/ImageReviewerContext/ImageReviewerContext";
import { useTrueCauseStressItemFilterDispatch } from "../../../Field/FieldShelf/TrueCause/FilterTrueCauseStressItem";

const getImageVariant = (imgProps) => {
  // if (imgProps.isBlurry) {
  //   return "blurry";
  // }
  if(imgProps.mlMetaData){
    if(imgProps.mlMetaData["prediction"]){
        if (imgProps.mlMetaData["prediction"].includes("_Disease") || imgProps.mlMetaData["prediction"].includes("_Pest")) {
          return "unhealthy";
        }
      }
  }
  
  return undefined;
};

export const ProbeImageThumb = React.memo(({ selected, ...imgProps }) => {
  const dispatch = useImageReviewerDispatch();
  const trueCauseDispatch = useTrueCauseStressItemFilterDispatch();
  
  const onMouseEnter = () => trueCauseDispatch({ type: 'image-hover', payload: { id: imgProps.id } });
  const onMouseLeave = () => trueCauseDispatch({ type: 'image-off-hover' })
  
  const onClick = React.useCallback(
    (event) => {
      dispatch({
        type: "image-press",
        payload: {
          ...imgProps,
          action: !selected ? "select" : "",
          modifiers: {
            shiftKey: event.shiftKey,
          },
        },
      });
    },
    [dispatch, imgProps, selected]
  );

  return (
    <ImageGrid.Item
      id={`img-${imgProps.id}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      src={imgProps.thumbnailPath}
      selected={selected}
      ratio={4 / 3}
      onClick={onClick}
      variant={getImageVariant(imgProps)}
    />
  );
});
ProbeImageThumb.displayName = "BulkImage";
