import React from "react";
import { Input } from "../../Helpers/Input/Input";
import { Button } from "../../Helpers/Button/Button";
import { SettingsHeading } from "./Account";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import { UserContext } from "../../../globalState/userContext";
import { resetPassword } from "../../../controllers/auth";
import { useLazyApi } from "../../../hooks/useApi";
import { makeStyles, Grid, Typography, InputLabel } from "@material-ui/core";
import Swal from "sweetalert2";
import { InternalLink } from "../../Helpers/InternalLink/InternalLink";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: "2rem 0 0 2rem",
  },
  sectionContainer: {
    marginBottom: "2rem",
  },
  saveButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.primary,
    stroke: "none",
  },
  clearButton: {
    backgroundColor: "inherit",
    color: theme.palette.text.secondary,
    stroke: "none",
  },
  labelContainer: {
    margin: "0.5rem 0 0 1rem",
  },
}));

export const AccountSettings = () => {
  const { currentUser, setCurrentUser } = React.useContext(UserContext);

  const [formState, setFormState] = React.useState(currentUser);

  const classes = useStyles();

  React.useEffect(() => {
    if (currentUser) {
      setFormState({ ...currentUser });
    }
  }, [currentUser]);

  const onChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  const {
    firstName,
    lastName,
    email,
    mobile,
    address1,
    address2,
    city,
    state,
    zipCode,
  } = formState;

  const [updateUser] = useLazyApi(`/api/v1/users/${currentUser.id}`, {
    method: "PUT",
  });

  return (
    <>
      <Grid direction="column" justifyContent="flex-start">
        <Grid item className={classes.sectionContainer}>
          <SettingsHeading text="Account Settings" />
        </Grid>

        <Grid
          item
          container
          direction="column"
          lg={10}
          spacing={2}
          className={classes.sectionContainer}
        >
          <Grid item>
            <Typography variant="body1" color="textSecondary">
              The information you provide will be used for used for billing and
              other communications with Aker.
            </Typography>
          </Grid>
          <Grid item variant="body1">
            <Typography variant="body1" color="textSecondary">
              All fields marked with * are required.
            </Typography>
          </Grid>
        </Grid>

        <form>
          <Grid
            item
            container
            lg={10}
            direction="column"
            spacing={2}
            className={classes.sectionContainer}
          >
            <Grid item>
              <Typography variant="h6">Personal Information</Typography>
            </Grid>

            <Grid item container direction="row" spacing={3}>
              <Grid item lg={6}>
                <Input
                  placeholder="First Name *"
                  required={true}
                  name="firstName"
                  onChange={onChange}
                  value={firstName}
                />
              </Grid>
              <Grid item lg={6}>
                <Input
                  placeholder="Last Name *"
                  required={true}
                  name="lastName"
                  onChange={onChange}
                  value={lastName}
                />
              </Grid>
            </Grid>

            <Grid item container direction="row" spacing={3}>
              <Grid item container direction="column" lg={6}>
                <Grid item>
                  <Input
                    placeholder="Email Address *"
                    required={true}
                    name="email"
                    onChange={onChange}
                    value={email}
                  />
                </Grid>
              </Grid>

              <Grid item container direction="column" lg={6}>
                <Grid item>
                  <Input
                    placeholder="Mobile Number *"
                    required={true}
                    name="mobile"
                    onChange={onChange}
                    value={mobile}
                  />
                </Grid>
                <Grid item className={classes.labelContainer}>
                  <InputLabel html-for="mobile">(###) ###-####</InputLabel>
                </Grid>
              </Grid>
            </Grid>

            <Grid item container direction="row-reverse" spacing={7}>
              <Grid item lg={6}>
                <Typography variant="body2">
                  By providing your mobile number, you acknowledge that Aker may
                  send you alerts via SMS if applicable.
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            lg={10}
            direction="column"
            spacing={2}
            className={classes.sectionContainer}
          >
            <Grid item>
              <Typography variant="h6">Billing Information</Typography>
            </Grid>

            <Grid item container direction="row" spacing={3}>
              <Grid item lg={12}>
                <Input
                  placeholder="Address Line 1 *"
                  required={true}
                  name="address1"
                  onChange={onChange}
                  value={address1}
                />
              </Grid>
              <Grid item lg={12}>
                <Input
                  placeholder="Address Line 2"
                  required={false}
                  name="address2"
                  onChange={onChange}
                  value={address2}
                />
              </Grid>
              <Grid item container direction="row" spacing={3}>
                <Grid item lg={6}>
                  <Input
                    placeholder="Town / City *"
                    required={true}
                    name="city"
                    onChange={onChange}
                    value={city}
                  />
                </Grid>
                <Grid item lg={3}>
                  <Input
                    placeholder="State *"
                    required={true}
                    name="state"
                    onChange={onChange}
                    value={state}
                  />
                </Grid>
                <Grid item lg={3}>
                  <Input
                    placeholder="Zip Code *"
                    required={true}
                    name="zipCode"
                    onChange={onChange}
                    value={zipCode}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            container
            lg={8}
            spacing={2}
            className={classes.sectionContainer}
          >
            <Grid item lg={3}>
              <Button
                text="Save Changes"
                icon={<SaveOutlinedIcon />}
                className={classes.saveButton}
                type="button"
                click={async (e) => {
                  await updateUser({
                    data: formState,
                  })
                    .then((res) => {
                      setCurrentUser(res);
                      return res;
                    })
                    .then(async (res) => {
                      await Swal.fire({
                        icon: "success",
                        title: "Success!",
                        text: "Your profile was updated.",
                      });
                      return res;
                    })
                    .catch(async (err) => {
                      await Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "An error occurred while creating your account.",
                      });
                      console.error(err);
                    });
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            className={classes.sectionContainer}
          >
            <Grid item lg={2}>
              <Typography variant="h6">Password</Typography>
            </Grid>
            <Grid item container direction="row" spacing={1} lg={8}>
              <Grid item>
                <Typography variant="body1">
                  Want to change your password?
                </Typography>
              </Grid>
              <Grid item>
                <InternalLink
                  onClick={async () => {
                    await resetPassword(
                      currentUser.email,
                      "settings_account-settings"
                    )
                      .then(async () => {
                        return await Swal.fire({
                          icon: "success",
                          title: "Success!",
                          text:
                            "A password reset email was sent to your email address.",
                        });
                      })
                      .catch(async (err) => {
                        console.error(err);
                        return await Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text:
                            "There was a problem sending the password reset email. Please try again.",
                        });
                      });
                  }}
                >
                  Click here to send reset password email.
                </InternalLink>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
};
