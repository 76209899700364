import React from "react";
import styles from "./Flex.module.scss";

export const Flex = ({
  children,
  flexDirection = "",
  justifyContent = "",
  alignItems = "",
  flexWrap = "",
  className = "",
  onClick = () => {},
  key = null,
}) => {
  let styleString = "";

  flexDirection === "column" &&
    (styleString = styleString + " " + styles.FlexDirCol);
  justifyContent === "space-between" &&
    (styleString = styleString + " " + styles.JustContBetween);
  justifyContent === "center" &&
    (styleString = styleString + " " + styles.JustContCenter);
  justifyContent === "flex-end" &&
    (styleString = styleString + " " + styles.JustContEnd);
  alignItems === "flex-start" &&
    (styleString = styleString + " " + styles.AlignItemsStart);
  alignItems === "flex-end" &&
    (styleString = styleString + " " + styles.AlignItemsEnd);
  alignItems === "stretch" &&
    (styleString = styleString + " " + styles.AlignItemsStretch);
  flexWrap === "wrap" && (styleString = styleString + " " + styles.FlexWrap);

  return (
    <div
      key={key}
      className={`${className} ${styles.Flex} ${styleString}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
