import React from "react";
import styles from "./ColorPicker.module.scss";
import { HexColorPicker, HexColorInput } from "react-colorful";

export const ColorPicker = ({ color, setColor }) => {
  return (
    <div className={styles.ColorPicker}>
      <HexColorPicker color={color} onChange={setColor} />
      <HexColorInput
        color={color}
        onChange={setColor}
        style={{ marginTop: "1rem" }}
      />
    </div>
  );
};
