import React, { useState } from "react";
import { Grid, Button, IconButton } from "@material-ui/core";
import { AddRounded } from "@material-ui/icons";
import RemoveCircleOutlineRoundedIcon from "@material-ui/icons/RemoveCircleOutlineRounded";
import { Map } from "../../../../../../Map/Map";
import { OldSelect } from "../../../../../../Helpers";
import { useField } from "../../../../../../../globalState/fieldContext";

export const MultiFlightSelect = ({
  i,
  classes,
  flight,
  date,
  newReport,
  setNewReport,
  imageryDates,
  selectFlightDate,
}) => {
  const [newFlightCount, setNewFlightCount] = useState(0);
  const { field } = useField();

  const addFlight = () => {
    if (newReport.flights.length < newReport.template.flightLimit) {
      const newFlights = [...newReport.flights, {}];
      setNewReport({ ...newReport, flights: newFlights });
      setNewFlightCount(+newFlightCount);
    }
  };

  const removeFlight = (i) => {
    const newFlights = [...newReport.flights];
    newFlights.splice(i, 1);
    setNewReport({ ...newReport, flights: newFlights });
    setNewFlightCount(-newFlightCount);
  };

  return (
    <>
      <Grid key={i} item className={classes.imageContainer}>
        <Grid className={classes.imagery}>
          <Map
            containerId={`imagery-map-${i}`}
            defaultStyle="blankDark"
            fields={field.map((f) => f)}
            fitBounds={false}
            imageToDisplay={
              newReport.flights[i].id ? newReport.flights[i] : null
            }
            hideMapLayerToggle
            hideControls
            disableZoom
            disableDrag
          />
        </Grid>
        <OldSelect
          defaultLabel="Please select a flight date"
          options={imageryDates}
          selectedOption={date ? date : ""}
          setSelectedOption={(flight) => selectFlightDate(flight, i)}
        />
        {(i > 0 || !flight) && (
          <IconButton
            className={classes.removeFlightBtn}
            onClick={() => removeFlight(i)}
          >
            <RemoveCircleOutlineRoundedIcon />
          </IconButton>
        )}
      </Grid>
      {newReport.flights.length < newReport.template.flightLimit &&
        newReport.flights.length - 1 === i && (
          <Grid key="addFlight" item className={classes.imageContainer}>
            <Button
              startIcon={<AddRounded />}
              className={classes.imagery}
              onClick={addFlight}
            ></Button>
          </Grid>
        )}
    </>
  );
};
