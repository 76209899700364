export class PitchToggle {
  constructor({ bearing = -20, pitch = 70, minpitchzoom = null }) {
    this._bearing = bearing;
    this._pitch = pitch;
    this._minpitchzoom = minpitchzoom;
    this._updating = false;
  }

  onAdd(map) {
    this._map = map;
    let _this = this;

    this._btn = document.createElement("button");
    this._btn.className = "mapboxgl-ctrl-pitchtoggle-3d";

    this._btn.type = "button";
    this._btn.title = "Toggle Display";
    this._btn["aria-label"] = "Toggle Pitch";
    this._btn.onclick = function () {
      if (!_this._updating && map.getPitch() === 0) {
        _this._updating = true;
        let options = { pitch: _this._pitch, bearing: _this._bearing };
        if (_this._minpitchzoom && map.getZoom() > _this._minpitchzoom) {
          options.zoom = _this._minpitchzoom;
        }
        // map.setTerrain();
        map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 5 });
        map.easeTo(options);
        _this._btn.className =
          "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d";
        _this._updating = false;
      } else if (!_this._updating) {
        _this._updating = true;
        map.easeTo({ pitch: 0, bearing: 0 });
        map.setTerrain();
        // map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 20 });
        _this._btn.className =
          "mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d";
        _this._updating = false;
      }
    };

    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
    this._container.appendChild(this._btn);

    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}