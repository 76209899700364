import axios from 'axios'

export const searchForAddress = async (searchText) => {
    if (!searchText) return;
    let formattedSearchText = searchText;
    // check if search text is lattitude and longitude to format based on mapbox's expected api format.
    const split = searchText.split(',');
    if (split.length === 2 && !isNaN(split[0]) && !isNaN(split[1])) {
        const x = split[1], y = split[0];
        formattedSearchText = `${x}, ${y}`;
    }

    return await axios({
        method: 'GET',
        // if no search text, default to united states
        url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${formattedSearchText}.json?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
    }).then(res => {
        return res.data.features
    }).catch(err => {
        console.log('err', err)
    })


}