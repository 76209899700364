import * as React from "react";
import cn from "clsx";

import { Chip } from "../../../../Helpers/Chip/Chip";
import {
  useImageReviewerDispatch,
  useImageReviewerContent,
} from "../../contexts/ImageReviewerContext/ImageReviewerContext";

export const ImageReviewerSelectAllAction = React.memo(({ className }) => {
  const dispatch = useImageReviewerDispatch();
  const data = useImageReviewerContent();
  const [isSelected, setIsSelected] = React.useState(false);

  const onSelectAll = React.useCallback(() => {
    const selected = !isSelected;
    setIsSelected(selected);
    if (selected) {
      dispatch({
        type: "select-unselect-all",
        payload: data,
      });
    } else {
      dispatch({ type: "unselect-all" });
    }
    // eslint-disable-next-line
  }, [data, isSelected]);

  return (
    <Chip variant="contained" onClick={onSelectAll} className={cn(className)}>
      {!isSelected ? `Select All` : `Unselect All`}
    </Chip>
  );
});
ImageReviewerSelectAllAction.displayName = "ImageReviewerSelectAllAction";
