import { format } from 'date-fns'

export const dateToString = (dateToFormat, outputFormat = "MM/dd/yyyy") => {
    if (typeof outputFormat !== 'string' && !(outputFormat instanceof String)){
        outputFormat = "MM/dd/yyyy";   
    }
    if (dateToFormat ) {
        const date = new Date(dateToFormat);
        //date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
        return format(date, outputFormat);
    }
    return ''
}