import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { generateBorder } from "../../../../../../styles/generateStyles";
import { format } from "date-fns/esm";

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: "3rem",
    paddingBottom: "3rem",
    borderBottom: generateBorder(1, theme.palette.borderColor.primary),
    "& h2": {
      fontFamily: theme.typography.fontFamilyBold,
    },
    "& span": {
      fontFamily: theme.typography.fontFamilyBold,
    },
  },
  headerTop: {
    marginBottom: "3rem",
  },
}));

export const EditReportHeader = ({ newReport }) => {
  const classes = useStyles();

  return (
    <Grid container item direction="column" className={classes.header}>
      <Grid
        container
        item
        justifyContent="space-between"
        className={classes.headerTop}
      >
        <Typography variant="h2">Create a New Report</Typography>
        {newReport.updatedAt && (
          <Typography color="textSecondary">
            Last Updated{" "}
            <Typography component="span" color="textPrimary">
              {format(new Date(newReport.updatedAt), "MM/dd/yy")}
            </Typography>
          </Typography>
        )}
      </Grid>
      <Typography color="textSecondary">
        The information you provide will be used to generate a printable report.
      </Typography>
    </Grid>
  );
};
