import React from "react";
import { AspectRatio } from "../../../../Helpers/AspectRatio/AspectRatio";
import { Skeleton } from "../../../../Helpers/Skeletons/Skeleton";

export const ProbeImageSkeleton = ({ ratio = 4 / 3 }) => {
  return (
    <AspectRatio ratio={ratio}>
      <Skeleton variant="rect" width="100%" height="100%" />
    </AspectRatio>
  );
};
