import React, { useState, useRef, useEffect } from "react";
import { makeStyles, Button, Typography } from "@material-ui/core";
import { ChooseTemplate } from "./ChooseTemplate";
import { CustomizeTemplate } from "./CustomizeTemplate";
import { Details } from "./Details";
import { AddFieldRegions } from "./AddFieldRegions";
import { AddAerialImagery } from "./AddAerialImagery";
import { EditReportHeader } from "./EditReportHeader";
import { generateBorder } from "../../../../../../styles/generateStyles";
import { ContactInformation } from "./ContactInformation";
import CreateNewFolderOutlinedIcon from "@material-ui/icons/CreateNewFolderOutlined";
import cn from "clsx";
import axios from "axios";
import { useAuth } from "../../../../../../hooks/useAuth";
import { useCustomerAccounts } from "../../../../../../globalState";
import Swal from "sweetalert2";
import { TrueCauseIssues } from "./TrueCauseIssues/TrueCauseIssues";
import { dateToString } from "../../../../../Helpers";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { useField } from "../../../../../../globalState/fieldContext";
import { saveAerialImageryReport } from "../../../../../../controllers/fields";

const useStyles = makeStyles((theme) => ({
  editReport: {
    position: "absolute",
    top: "0",
    right: "0",
    height: "100%",
    width: `calc(100% - ${theme.palette.spacing.shelfWidth})`,
    padding: "2rem 1.5rem",
    borderLeft: generateBorder(1, theme.palette.borderColor.primary),
    boxSizing: "border-box",
    backgroundColor: theme.palette.background.secondary,
    overflowY: "scroll",
    zIndex: 9,
  },
  section: {
    paddingBottom: "3rem",
    marginBottom: "3rem",
    borderBottom: generateBorder(1, theme.palette.borderColor.primary),
    "& h4, > p": {
      marginBottom: "1.5rem",
    },
  },
  headerWithSwitch: {
    "& h4": { marginBottom: "2px" },
  },
  input: {
    width: "22.5rem",
    marginBottom: "1.5rem",
    "&::placeholder": {
      color: theme.palette.text.secondary,
    },
  },
  textarea: {
    padding: "0.8rem 1rem",
    letterSpacing: "1px",
    "&::placeholder": {
      color: theme.palette.text.secondary,
    },
  },
  formButtons: {
    display: "grid",
    gridTemplateColumns: "12rem 9rem",
    columnGap: "1.5rem",
    "& .feather-icon": {
      width: "1.2rem",
    },
  },
  button: { height: "3rem" },
  cancelBtn: {
    color: theme.palette.text.secondary,
    width: "8rem",
  },
}));

export const EditReport = ({
  reportToEdit,
  setReportToEdit,
  imageryDates,
  imageryByDate,
  updateSeasonReports,
}) => {
  const classes = useStyles();
  const { token } = useAuth();
  const { selectedSeason, getRegionsByType } = useField();
  const customerAccount = useCustomerAccounts();
  const selectedCustomerAccount = customerAccount.find(
    (c) => selectedSeason && c.id === selectedSeason.customerAccount.id
  );
  const [regionsByType, setRegionsByType] = useState(
    getRegionsByType(reportToEdit?.regions?.map((reg) => reg.id))
  );
  const [newReport, setNewReport] = useState({
    season: selectedSeason,
    seasonId: selectedSeason.id,
    crop: selectedSeason.crop.name,
    contactName: selectedCustomerAccount.contactName,
    contactEmail: selectedCustomerAccount.contactEmail,
    contactPhone: selectedCustomerAccount.contactPhone,
    footnote: selectedCustomerAccount.copyright,
    ...reportToEdit,
    cropStressFindings: reportToEdit.observations,
  });
  const [waitingOnPdfGeneration, setWaitingOnPdfGeneration] = useState(false);
  const logoInput = useRef(null);

  useEffect(() => {
    if (selectedCustomerAccount) {
      let logoUrl =
        newReport && newReport.logoUrl
          ? newReport.logoUrl
          : selectedCustomerAccount.logoUrl;
      updateNewReport("companyLogo", logoUrl || "");
    }
    // eslint-disable-next-line
  }, [selectedCustomerAccount]);

  const updateNewReport = (prop, value) => {
    setNewReport({ ...newReport, [prop]: value });
  };

  const handleSaveReport = async () => {
    let logoImg;
    setWaitingOnPdfGeneration(true);

    if (logoInput.current.files) {
      logoImg = logoInput.current.files[0];
      if (logoImg && !logoImg.name.match(/.(jpg|jpeg|png|gif)$/i)) {
        await Swal.fire({
          title: "File must be an image.",
          icon: "warning",
          showCancelButton: false,
          dangerMode: true,
          confirmButtonText: "OK",
          closeOnConfirm: true,
        });
      }
    }

    const params = generateParams(logoImg);
    let report = newReport;
    report = await saveAerialImageryReport(token, params);
    if (report) {
      updateSeasonReports();
      await generateReport(report.season.id, report.id, report.uuid);
      await Swal.fire({
        title: "Great, your report has been saved!",
        icon: "success",
        closeModal: true,
      });
    } else {
      await Swal.fire({
        title: "It's not you, it's us.",
        text: "Please try again later or contact info@aker.ag.",
        icon: "error",
        closeModal: true,
      });
    }
    setWaitingOnPdfGeneration(false);
    setReportToEdit(null);
  };

  const generateReport = async (seasonId, reportId, uuid) => {
    const envUrl =
      process.env.REACT_APP_ENV === "production"
        ? "https://oldiscover.aker.ag"
        : "https://discover.staging.aker.ag";
    const generateReportURL = () => {
      let url = `${envUrl}/farm/1234/field/1234/season/`;
      url += seasonId;
      url += "/aerial-imagery-report/";
      url += reportId;
      return url;
    };

    try {
      const customer = selectedSeason.customerAccount.id;
      const bucket =
        process.env.REACT_APP_ENV === "production"
          ? "aker-reports"
          : "aker-reports-staging";
      await axios({
        method: "POST",
        url: "https://nodeutil.aker.ag:4001/generatePdfFromUrl",
        data: {
          reportName: uuid,
          customer,
          username: process.env.REACT_APP_PUPPETEER_LOGIN,
          password: process.env.REACT_APP_PUPPETEER_PASSWORD,
          url: generateReportURL(),
          bucket,
          envUrl,
        },
      });
    } catch {
      await Swal.fire({
        title: "Error generating report PDF.",
        text: "Please contact info@aker.ag for more information.",
        icon: "error",
        closeModal: true,
      });
    }
  };

  const generateParams = (logoImg) => {
    return {
      id: newReport.id,
      seasonId: newReport.seasonId,
      title: newReport.title,
      brandColor: newReport.brandColor,
      templateId: newReport.template.id,
      productHeader: newReport.productHeader,
      commodityPrice: newReport.commodityPrice,
      location: newReport.location,
      seedType: newReport.seedType,
      applicationDate: newReport.applicationDate
        ? dateToString(newReport.applicationDate)
        : "",
      footnote: newReport.footnote,
      regions:
        regionsByType.reduce((accArray, type) => {
          const typeRegionsShowing = type.regions.filter((reg) => reg.show);
          if (typeRegionsShowing.length > 0) {
            accArray.push(typeRegionsShowing.map((reg) => ({ id: reg.id })));
          }
          return accArray;
        }, [])[0] ?? [],
      contactName: newReport.contactName,
      contactPhone: newReport.contactPhone,
      contactEmail: newReport.contactEmail,
      flights: newReport.flights.filter((f) => !!f.id),
      company_logo: logoImg,
      cropStressFindings: newReport.cropStressFindings,
      observationDate: newReport.observationDate
        ? dateToString(newReport.observationDate)
        : "",
      custId: newReport.uuid,
    };
  };

  return (
    <form
      className={classes.editReport}
      onSubmit={(e) => {
        e.preventDefault();
        handleSaveReport();
      }}
    >
      <EditReportHeader newReport={newReport} />
      <ChooseTemplate
        parentClasses={classes}
        newReport={newReport}
        setNewReport={setNewReport}
      />
      {newReport.template && (
        <>
          <CustomizeTemplate
            generateBorder={generateBorder}
            parentClasses={classes}
            newReport={newReport}
            setNewReport={setNewReport}
            updateNewReport={updateNewReport}
            logoInput={logoInput}
          />
          <Details
            parentClasses={classes}
            newReport={newReport}
            updateNewReport={updateNewReport}
          />
          <AddFieldRegions
            parentClasses={classes}
            newReport={newReport}
            updateNewReport={updateNewReport}
            regionsByType={regionsByType}
            setRegionsByType={setRegionsByType}
          />
          <AddAerialImagery
            parentClasses={classes}
            newReport={newReport}
            setNewReport={setNewReport}
            updateNewReport={updateNewReport}
            imageryDates={imageryDates}
            imageryByDate={imageryByDate}
          />
          <TrueCauseIssues
            parentClasses={classes}
            newReport={newReport}
            setNewReport={setNewReport}
            trueCauseObservations={selectedSeason.probeObservations}
            updateNewReport={updateNewReport}
            selectedSeasonId={selectedSeason.id}
          />
          <ContactInformation
            parentClasses={classes}
            newReport={newReport}
            updateNewReport={updateNewReport}
          />
        </>
      )}
      <div className={classes.formButtons}>
        {!waitingOnPdfGeneration ? (
          <>
            <Button
              startIcon={<CreateNewFolderOutlinedIcon />}
              color="primary"
              variant="contained"
              className={classes.button}
              type="submit"
            >
              {newReport.new ? "Generate Report" : "Save Report"}
            </Button>
            <Button
              startIcon={<CloseRoundedIcon />}
              className={cn(classes.button, classes.cancelBtn)}
              onClick={() => setReportToEdit(null)}
            >
              Cancel
            </Button>
          </>
        ) : (
          <Typography>Saving Report...</Typography>
        )}
      </div>
    </form>
  );
};
