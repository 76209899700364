// This gets the UTC date and returns it without converting for local time zones. 
// We store some database dates as a day (only) and want to return that day as-is 
// (such as flight dates)
export const utcDateToString = (dateToFormat, locale = 'en-US') => {
    if (dateToFormat) {
        const dateOptions = { timeZone: 'UTC', month: 'numeric', day: 'numeric', year: 'numeric' };
        const dateFormatter = new Intl.DateTimeFormat(locale, dateOptions);
        return dateFormatter.format(new Date(dateToFormat));
    }
    return ''
}

export function getDateWithoutTimezone(dateString) {
    // Parse the input date string and create a new Date object
    const date = new Date(dateString);

    // Set the time to midnight in UTC (which will be the same as local time if no timezone is specified)
    date.setUTCHours(0, 0, 0, 0);

    // Return the new Date object
    return date.toISOString();
}

export function getDateFromString(dateString) {
    const date = dateString.split('T')[0];
    return date;
}