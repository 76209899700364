import React from "react";
import { Checkbox as MuiCheckbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import cn from "clsx";

const useCheckboxStyles = makeStyles((theme) => ({
  labeledBy: {
      "&::after": {
        content: 'var(--checkbox-label-text, "AI")',
        position: "absolute",
        bottom: 4,
        right: 4,
        width: 16,
        height: 16,
        borderRadius: "50%",
        background: theme.palette.primary.main,
        fontSize: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.colors.black,
      },
    },
}));

export const Checkbox = ({ color = "primary", mode, ...props }) => {
  const classes = useCheckboxStyles();

  return (
    <MuiCheckbox
      color='primary'
      {...props}
      className={cn(mode === "labeled" && classes.labeledBy, props.className)}
    />
  );
};