import * as React from "react";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { useImageReviewerFiltersDispatch } from "../../components/ImageReviewerFilters/ImageReviewerFilters";


export const ImageReviewerShowDeletedAction = ({showDeleted, setShowDeleted}) => {
  const dispatch = useImageReviewerFiltersDispatch();
  const group = "status";
  const type = "remote";
  const onChange = (_, checked) => {
    const optionValue = checked ? "deleted" : "";
    setShowDeleted(checked);
    dispatch({
      type: "filter-apply",
      payload: {
        name: optionValue,
        group,
        type,
        action: "replace",
        showDeleted: checked,
      },
    });
  };

  return (
    <FormControlLabel
      control={<Switch onChange={onChange} name="checkedC" color="primary" checked={showDeleted} />}
      label="Show Deleted"
    />
  );
};
ImageReviewerShowDeletedAction.displayName = "ImageReviewerShowDeletedAction";
