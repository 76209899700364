import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import React from "react";
import { Radio, RadioGroup } from "../../../../Helpers/Radio/Radio";
import { useImageReviewerSelectedImage, useImageReviewerRefresh } from "../../contexts/ImageReviewerContext/ImageReviewerContext";
import {
  useImageReviewerCropSelectionDispatch,
  useImageReviewerCropSelectionState,
  useImageReviewerCropsStressItems,
} from "../../contexts/ImageReviewerCropsContext/ImageReviewerCropsContext";
import { useApi } from "../../../../../hooks/useApi";
import { useAuth } from "../../../../../hooks/useAuth";

import { Autocomplete2 } from "../../../../Helpers/Autocomplete/Autocomplete";
import { updateImageGrowthStage, updateAllGrowthStagesInWO } from "../../../../../controllers/image";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "grid",
    gridAutoFlow: "row",
    width: "100%",
    gap: `${theme.spacing(4)}px`,
  },
  shelfCropsListContainer: {
    width: "100%",
  },
  shelfCropsList: {
    overflowY: "auto",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
  },
  cropsContainer: {
    marginBottom: `${theme.spacing(6)}px`,
  },
  cropsTitleContainer: {
    marginBottom: `${theme.spacing(2)}px`,
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.colors.gray32,
    zIndex: 1,
  },
  label: {
    ...theme.typography.body2,
  },
  formControlLabel: {
    fontSize: '0.8rem',
    '& label': { fontSize: '0.8rem' }
  },
}));

const isCropLabeledByMl = (crop, mlData) => {
  if (mlData != null) {
    return mlData.crop_type === crop.name;
  } else {
    return false;
  }
};

function parseGrowthStages(crops, cropId) {
  const crop = crops.filter((crop) => crop.id === cropId)[0]
  const growthStages = [];
  for (var i = 0; i < crop.growthStages?.length; i++) {
    growthStages.push({ "name": crop.growthStages[i] })
  }
  return growthStages;
}

export const ShowImageReviewerShelfCrop = () => {
  const classes = useStyles();
  const { token } = useAuth();
  const cropsStressItems = useImageReviewerCropsStressItems();
  const selectedImage = useImageReviewerSelectedImage();
  const { data: crops } = useApi("/api/v1/crops");
  // Keeps track of a potentially unsaved user selection. If it's
  // present/defined, we'll give it priority. Otherwise we'll use the
  // 'server' (db saved) data
  const internalState = useImageReviewerCropSelectionState();
  const internalStateDispatch = useImageReviewerCropSelectionDispatch();
  const initialCropID =
    selectedImage?.observedCrop.id === 75 ||
      typeof selectedImage?.observedCrop.id !== "number"
      ? selectedImage?.collectedCrop.id
      : selectedImage?.observedCrop.id;
  if (selectedImage)
    selectedImage.observedCrop.id = initialCropID;
  const [growthStages, setGrowthStages] = React.useState(null)
  const [growthStage, setGrowthStage] = React.useState({ "name": selectedImage?.growthStage })
  const [selectedCrop, setSelectedCrop] = React.useState(initialCropID)
  const [updateAllImages, setUpdateAllImages] = React.useState(false)
  const [growthStageChanged, setGrowthStageChanged] = React.useState(false)
  const [isSaving, setIsSaving] = React.useState(false)

  const refresh = useImageReviewerRefresh();

  React.useEffect(() => {
    if (selectedImage) {
      setGrowthStage({ "name": selectedImage.growthStage });
    }
  }, [selectedImage]);

  React.useEffect(() => {
    if (selectedCrop && crops) {
      const growthStages = parseGrowthStages(crops, parseInt(selectedCrop));
      setGrowthStages(growthStages);
    }
  }, [selectedCrop, crops]);

  if (!cropsStressItems) {
    return null;
  }

  const updateGrowthStage = async (gs, all = false) => {
    let success = false;
    if (gs) {
      if (updateAllImages || all) {
        setIsSaving(true);
        setGrowthStageChanged(false); // we updated all so we don't need to change it again
        success = await updateAllGrowthStagesInWO(token, selectedImage.taskId, gs.name);
        setIsSaving(false);
      } else {
        setGrowthStageChanged(true); // we changed it on one so remember this
        success = await updateImageGrowthStage(token, selectedImage.id, gs.name);
      }
    }
    if (success) {
      selectedImage.growthStage = gs.name
      if (updateAllImages || all) refresh()
    }
  }

  if (!selectedImage) return null;
  return (
    <div className={classes.mainContainer}>
      <div className={classes.shelfCropsListContainer}>
        <Typography variant="body2" className={classes.label}>
          Verify crop growth stage:
        </Typography>
        <FormControlLabel
          control={
            <Switch
              checked={updateAllImages}
              onChange={() => {
                setUpdateAllImages(!updateAllImages);
                if (growthStageChanged) {
                  setIsSaving(true);
                  updateGrowthStage(growthStage, true);
                }
              }}
              name="updateAllImages"
              color="primary"
            />
          }
          label={<Typography className={classes.formControlLabel}>Apply to all images in work order</Typography>}
        />
        {!!isSaving && (
          <>
            Saving &nbsp; <i className="fas fa-spinner fa-spin"></i>
          </>
        )}
        <Autocomplete2
          options={growthStages ?? []}
          value={growthStage || null}
          getOptionLabel={(o) => o.name}
          onChange={(_, v) => {
            setGrowthStage(v);
            updateGrowthStage(v);
          }
          }
          labelText=""
          inputClassName={classes.cropInput}
        />
        <RadioGroup
          aria-label="Crop Type"
          name="crop-type"
          value={`${internalState.crop || selectedImage.observedCrop.id}`}
          onChange={(event) => {
            internalStateDispatch({
              type: "select-crop",
              payload: event.target.value,
            });
            setSelectedCrop(event.target.value);
          }}
          className={classes.shelfCropsList}
        >
          {cropsStressItems.map((item, i) => {
            return (
              <FormControlLabel
                key={item.id}
                value={`${item.id}`}
                classes={{
                  label: classes.label,
                }}
                control={
                  <Radio
                    mode={
                      isCropLabeledByMl(item, selectedImage.mlMetaData)
                        ? "labeled"
                        : undefined
                    }
                  />
                }
                label={item.name}
              />
            );
          })}
        </RadioGroup>
      </div>
    </div>
  );
};
