import axios from "axios";
const isStagingSite = process.env.REACT_APP_ENV.includes("staging");
const isDevSite = process.env.REACT_APP_ENV.includes("development");
const baseUrl = isDevSite
    ? process.env.REACT_APP_BACK_OFFICE_URL
    : isStagingSite
        ? process.env.REACT_APP_BACK_OFFICE_URL_STAGING
        : process.env.REACT_APP_BACK_OFFICE_URL_PRODUCTION;

export const updateImageGrowthStage = async (authToken, imageId, growthStage) => {
    const data = { "growthStage": growthStage }
    const result = await axios({
        method: "PATCH",
        url: `${baseUrl}/api/v1/probe_images/${imageId}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
        },
        data: { data },
        timeout: 30000,
    });
    return (result) ? result.data.success : null;
};

export const updateAllGrowthStagesInWO = async (authToken, taskId, growthStage) => {
    const data = { "taskId": taskId, "growthStage": growthStage }
    const result = await axios({
        method: "PATCH",
        url: `${baseUrl}/api/v1/probe_images/change_growth_stage_task`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${authToken}`,
        },
        data: { data },
        timeout: 30000,
    });
    return (result) ? result.data.success : null;
};