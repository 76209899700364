// This updates the given image structure to use the non optimized
// image for Tiledriver until we can figure out why the optimized 
// one is so slow to load/process!
export const fixTiledriverURL = (imageToDisplay) => {
    if (imageToDisplay.tileUrl.includes('tiledriver')) {
        let s3Index = imageToDisplay.tileUrl.indexOf('s3://');
        if (s3Index > 0) {
            let url = imageToDisplay.tileUrl.substring(0, s3Index)
            let new_source = imageToDisplay.mapDownloadUrl.replace('https://s3.amazonaws.com/', 's3://')
            url += new_source;
            imageToDisplay.tileUrl = url;
        }
    }
    return imageToDisplay;
}