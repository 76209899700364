import React, { useState } from "react";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { ShelfLabel } from "../../../../Helpers/ShelfLabel/ShelfLabel";
import { Switch } from "../../../../Helpers/Switch/Switch";
import { useField } from "../../../../../globalState/fieldContext";
import {
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import * as axios from "axios";

const useClasses = makeStyles((theme) => ({
  images: {
    marginTop: "2rem",
  },
  loadingContainer: {
    position: "relative",
    bottom: theme.spacing(0.5),
    left: 0,
    width: "100%",
  },
  ahiColors: {
    marginLeft: "2rem",
  }
}));

export const AhiImageryDrawer = ({
  imageryByDate,
  setImageToDisplay,
  imageToDisplay,
  useDrawerStyles,
  drawerState,
  setDrawerState,
  stressMapToDisplay,
  ahiValuesToDisplay,
  setAhiValuesToDisplay,
}) => {
  const drawerClasses = useDrawerStyles();
  const classes = useClasses();
  const [showOptions, setShowOptions] = useState(false);
  const [ahiGreen, setAhiGreen] = useState(true);
  const [ahiYellow, setAhiYellow] = useState(false);
  const [ahiRed, setAhiRed] = useState(true);
  const [valuesToDisplay, setValuesToDisplay] = useState([0, 1, 2, 5, 6]); // need 0 to have a non-empty array even if turning off all the selections

  const handleToggle = async (toggledImage) => {
    let copyDrawerState = { ...drawerState };
    if (imageToDisplay?.id === toggledImage.id) {
      setImageToDisplay(null);
      copyDrawerState.ahiImagery.selectedAhiType = "";
      copyDrawerState.ahiImagery.selectedAhiId = "";
    } else {
      copyDrawerState.ahiImagery.selectedAhiType = toggledImage.name.toUpperCase();
      copyDrawerState.ahiImagery.selectedAhiId = toggledImage.id;
      setImageToDisplay(toggledImage);
    }
    setDrawerState(copyDrawerState);
  };

  const addItemsToValuesArray = (items) => {
    var temp = [...valuesToDisplay];
    items.forEach(item => {
      if (!temp.includes(item)) {
        temp.push(item);
      }
    });
    setValuesToDisplay(temp);
  };

  const removeItemsFromValuesArray = (items) => {
    var temp = [...valuesToDisplay];
    items.forEach(item => {
      if (temp.includes(item)) {
        const index = temp.indexOf(item);
        temp.splice(index, 1);
      }
    });
    setValuesToDisplay(temp);
  };

  const handleGreenAhiToggle = () => {
    if (!ahiGreen) {
      addItemsToValuesArray([1, 2]);
    } else {
      removeItemsFromValuesArray([1, 2]);
    }
    setAhiGreen(!ahiGreen);
  };

  const handleYellowAhiToggle = () => {
    if (!ahiYellow) {
      addItemsToValuesArray([3, 4]);
    } else {
      removeItemsFromValuesArray([3, 4]);
    }
    setAhiYellow(!ahiYellow);
  };

  const handleRedAhiToggle = () => {
    if (!ahiRed) {
      addItemsToValuesArray([5, 6]);
    } else {
      removeItemsFromValuesArray([5, 6]);
    }
    setAhiRed(!ahiRed);
  };

  const ImageControl = ({
    image,
    checkOnLoadFlag,
    disabled
  }) => {

    return (
      <div>
        <FormControlLabel
          control={<Switch checked={checkOnLoadFlag} />}
          label={image.name.toUpperCase()}
          disabled={disabled}
          onChange={() => handleToggle(image)}
        />
      </div>
    );
  }

  const [downloading, setDownloading] = useState([]);
  const fieldContext = useField();
  const downloads3ToClient = (toggledImage) => {
    const { name } = toggledImage;
    return axios.default
      .get(`${toggledImage.shapefileUrl}`, {
        headers: {
          Accept: "*/*",
        },
        responseEncoding: "binary",
        responseType: "arraybuffer",
        onDownloadProgress: function (progressEvent) {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          setDownloading((downloading) => [
            ...downloading.map((a) =>
              a.name === name ? { name, progress: percent } : a
            ),
          ]);
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        // We will build a filename to store imagery that "makes sense"
        // Currently: fieldID_flight-date_wo_type.tif
        const fieldId = fieldContext.field[0].id;
        const dateFlown = new Date(toggledImage.observedAt)
        const flightDayOfMonth = dateFlown.getDate();
        const flightMonth = dateFlown.getMonth(); // Be careful! January is 0, not 1
        const flightYear = dateFlown.getFullYear();
        const flightDate = flightYear + '-' + (flightMonth + 1) + "-" + flightDayOfMonth;
        const localFile = `${fieldId}_${flightDate}_AHI_${toggledImage.name}.zip`;
        link.href = url;
        link.setAttribute("download", localFile); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  };

  const downloadCallback = async (toggledImage) => {
    // dont allow for redownloading files while download in progress
    if (downloading.some((a) => a.name === toggledImage.name)) return;
    setDownloading([
      ...downloading.filter((a) => a.name !== toggledImage.name),
      { name: toggledImage.name, progress: 0 },
    ]);
    await downloads3ToClient(toggledImage).finally(() => {
      setDownloading(
        downloading.filter((file) => file.name !== toggledImage.name)
      );
    });
  };

  const findProgressValue = (image) => {
    const value = downloading.find((a) => a.name === image.name);
    return value?.progress;
  };

  React.useEffect(() => {
    if (valuesToDisplay.length > 0) {
      setAhiValuesToDisplay(valuesToDisplay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesToDisplay]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      className={drawerClasses.root}
    >
      <ShelfLabel
        label={`AHI (Aker Health Index)`}
        showOptions={showOptions}
        select={() => setShowOptions(!showOptions)}
      />
      {showOptions && (
        <Grid container item className={drawerClasses.options}>
          {imageryByDate.length > 0 ? (
            <>
              <Typography
                variant="body1"
                color="textSecondary"
                component="span"
              >
                Use the toggles below to apply selected AHI layers to
                the map:
              </Typography>
              {imageryByDate.map((imagesByDate, i) => {
                return (
                  <Grid
                    key={i}
                    container
                    item
                    direction="column"
                    alignItems="flex-start"
                    className={classes.images}
                    xs={12}
                  >
                    {imagesByDate.imagery.map((image, i) => {
                      const shouldCheck = image?.id === imageToDisplay?.id;
                      image.name = imagesByDate.dateFlown
                      return (
                        <Grid
                          key={i}
                          container
                          item
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <ImageControl
                            image={image}
                            disabled={stressMapToDisplay}
                            checkOnLoadFlag={shouldCheck}
                            onChangeFn={() => handleToggle(image)}
                          />
                          <IconButton
                            onClick={() => downloadCallback(image)}
                            aria-label="download AHI map"
                            visible={image.downloadable}
                            disabled={stressMapToDisplay}
                          >
                            <GetAppOutlinedIcon color="primary" />
                          </IconButton>
                          {downloading.some(
                            (file) => file.name === image.name
                          ) && (
                              <div className={classes.loadingContainer}>
                                <LinearProgress
                                  variant="determinate"
                                  value={findProgressValue(image)}
                                />
                              </div>
                            )}
                        </Grid>
                      );
                    })}
                    <FormGroup className={classes.ahiColors} aria-label="position" column >
                      <FormControlLabel
                        control={<Switch checked={ahiRed} />}
                        label={"Red"}
                        onChange={handleRedAhiToggle}
                      />
                      <FormControlLabel
                        control={<Switch checked={ahiYellow} />}
                        label={"Yellow"}
                        onChange={handleYellowAhiToggle}
                      />
                      <FormControlLabel
                        control={<Switch checked={ahiGreen} />}
                        label={"Green"}
                        onChange={handleGreenAhiToggle}
                      />

                    </FormGroup>
                  </Grid>
                );
              })}
            </>
          ) : (
            <Typography color="textSecondary">No imagery available</Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};
