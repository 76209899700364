import React, { useEffect } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import { formatDate } from "react-day-picker/moment";
import "react-day-picker/lib/style.css";
import { makeStyles } from "@material-ui/core/styles";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { Button } from "../../Helpers";
import {
  Typography,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormControl,
  IconButton,
} from "@material-ui/core";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import clsx from "clsx";
import { Flex } from "../../Layouts/Flex";

export function DatePicker({ onChange, className, ...props }) {
  const DATE_FORMAT = "MM/DD/YYYY";

  const handleChange = (day) => {
    onChange(formatDate(day, DATE_FORMAT));
  };

  return (
    <DayPicker {...props} onDayClick={handleChange} className={className} />
  );
}

const useDatePickerInputStyles = makeStyles((theme) => ({
  singleDayPicker: {
    "& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)": {
      backgroundColor: theme.palette.colors.blue,
      color: "white",
      borderRadius: "50%",
    },
    "& .DayPicker-Day--disabled": {
      opacity: 0.4,
    },
    position: "absolute",
    top: 76,
    left: 0,
    zIndex: 100,
    backgroundColor: theme.palette.background.quaternary,
    border: `1px solid`,
    borderColor: theme.palette.borderColor.primary, //'white',
    borderRadius: 4,
  },
  datePicker: {
    borderRadius: 4,
    borderColor: theme.palette.borderColor.primary,
    color: theme.palette.text.primary,
    width: "100%",
    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.background.tertiary,
      margin: 0,
    },
    "& .MuiInputBase-input": {
      background: "transparent",
    },
  },
  pickerContainer: {
    position: "relative",
  },
  dateRangePicker: {
    "& .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)": {
      backgroundColor: theme.palette.colors.blue,
      color: "white",
    },
    "& .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)": {
      backgroundColor: theme.palette.colors.gray128,
      color: theme.palette.colors.blue,
    },
    "& .DayPicker-Day": {
      borderRadius: 0,
    },
    "& .DayPicker-Day--start": {
      color: "purple",
      borderTopLeftRadius: `50%`,
      borderBottomLeftRadius: `50%`,
    },
    "& .DayPicker-Day--end": {
      borderTopRightRadius: `50%`,
      borderBottomRightRadius: `50%`,
    },
    "& .DayPicker-Day--disabled": {
      opacity: 0.4,
    },
  },
  error: {
    color: theme.palette.error.main,
    fontSize: 10.5,
    marginLeft: "1em",
    marginTop: 4,
  },
}));
const useInputLabelStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
  },
  shrink: {
    transform: `translate(0px, -18px) scale(0.75) !important`,
  },
}));

export function DatePickerInput({
  className = "",
  date,
  setDate,
  error,
  autoclose = true,
  labelText = "Choose a date...",
  onChange = () => { },
  ...props
}) {
  const classes = useDatePickerInputStyles();
  const inputLabelClasses = useInputLabelStyles();

  const [showingPlantDatePicker, setShowingPlantDatePicker] = React.useState(
    false
  );

  useEffect(() => {
    const closeOnOutsideClick = (e) => {
      const thisDiv = document.getElementById("date-picker-with-input-aker");
      if (thisDiv !== e.target && !thisDiv.contains(e.target)) {
        setShowingPlantDatePicker(false);
      }
    };
    window.addEventListener("click", closeOnOutsideClick);
    return () => {
      window.removeEventListener("click", closeOnOutsideClick);
    };
  }, []);

  return (
    <div id="date-picker-with-input-aker" className={classes.pickerContainer}>
      <FormControl
        className={clsx(classes.datePicker, className)}
        variant="outlined"
        error={Boolean(error)}
      >
        <InputLabel
          classes={inputLabelClasses}
          htmlFor="outlined-adornment-password"
        >
          {labelText}
        </InputLabel>
        <OutlinedInput
          onClick={() => setShowingPlantDatePicker(!showingPlantDatePicker)}
          type={"text"}
          fullWidth
          className={className}
          value={date ? formatDate(date, "MM/DD/YYYY") : ""}
          error={Boolean(error)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="toggle date picker visibility" edge="end">
                <CalendarTodayIcon fontSize={"small"} />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {error && (
        <Typography variant={"subtitle2"} className={classes.error}>
          {error}
        </Typography>
      )}
      {showingPlantDatePicker && (
        <DayPicker
          initialMonth={date}
          className={classes.singleDayPicker}
          onDayClick={(day, modifiers = {}) => {
            if (modifiers.disabled) return;
            onChange();
            setDate(day);
            if (autoclose) {
              setShowingPlantDatePicker(false);
            }
          }}
          selectedDays={date}
          inputProps={{ className: classes.datePicker }}
          {...props}
        />
      )}
    </div>
  );
}

/**
 *
 * @param props.indefinite -- changes the help messages to not be worded as though an end date is required
 * @returns
 */

export function DateRangePicker({
  from,
  to,
  setDateRange,
  onChange,
  onUpdate,

  indefinite = false,
  ...props
}) {
  const classes = useDatePickerInputStyles();
  return (
    <>
      <Flex justifyContent={"space-between"}>
        <Typography variant={"subtitle2"} style={{ marginRight: "20px" }}>
          {!from && !to && "Select the first day."}
          {indefinite ? (
            <>
              {from &&
                !to &&
                `You have selected to start on ${new Date(
                  from
                ).toLocaleDateString()}. You may optionally select an end date.`}
            </>
          ) : (
            <>{from && !to && "Select the last day."}</>
          )}
          {from &&
            to &&
            `You selected from ${from.toLocaleDateString()} to
              ${to.toLocaleDateString()}`}{" "}
        </Typography>
        {from && to && (
          <Typography
            variant={"subtitle2"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.data(true)
              setDateRange({ from: undefined, to: undefined })
              props.farmShelf && props.resetAndUpdateData()

            }}
          >
            {!props.formedit && <Button className="pop-btn" type="" text="Reset" />}
          </Typography>
        )}
        {from && to && (
          <Typography
            variant={"subtitle2"}
            style={{ cursor: "pointer" }}
            onClick={onUpdate}
          >
            {!props.formedit && <Button className="pop-btn postr8" type="" text="Update" />}
          </Typography>
        )}

      </Flex>
      <DayPicker
        initialMonth={from}
        className={classes.dateRangePicker}
        numberOfMonths={2}
        selectedDays={[from, { from, to }]}
        modifiers={{ start: from, end: to }}
        onDayClick={(day, modifiers = {}) => {
          if (modifiers.disabled) return;
          onChange(day);
          setDateRange(DateUtils.addDayToRange(day, { from, to }));
        }}
        {...props}
      />
    </>
  );
}

export function NewDatePicker({ onChange, value, disablePast = true, className = 'datepicker_wrapper' }) {

  const newDatePickerStyle = {
    svg: { color: 'var(--fontColorMain)' },
    input: { color: 'var(--fontColorMain)' },
  };

  const popperSx = {
    "& .MuiPaper-root": {
      backgroundColor: 'var(--bgColorTertiary)',
      color: 'var(--fontColorMain)',
    },
    "& .MuiCalendarPicker-root": {
      backgroundColor: 'var(--bgColorTertiary)',
      color: 'var(--fontColorMain)',
    },
    "& .MuiPickersDay-dayWithMargin": {
      backgroundColor: 'var(--bgColorTertiary)',
      color: 'var(--fontColorMain)',
    },
    "& .MuiPickersDay-root.Mui-selected": {
      backgroundColor: 'var(--logoColor)',
      "&:hover": { backgroundColor: 'var(--logoColor)' }
    },
    "& .MuiSvgIcon-root": { color: 'var(--fontColorMain)' },
    "& .MuiTypography-caption": { color: 'var(--fontColorMain)' },
    "& .MuiButtonBase-root.Mui-disabled": { color: 'var(--fontColorSecondary)' },
    "& .PrivatePickersYear-yearButton.Mui-disabled": { color: 'var(--fontColorSecondary)' },
  };

  return (
    <div className={className}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          //open={open}
          // onOpen={() => setOpen(true)}
          // onClose={() => setOpen(false)}
          inputFormat="YYYY-MM-DD"
          value={value}
          disablePast={disablePast}
          onChange={onChange}
          disableHighlightToday={true}
          renderInput={(params) => <TextField {...params}
            //onClick={(e) => setOpen(true)}
            sx={newDatePickerStyle}
          />}
          PopperProps={{
            sx: popperSx
          }}
        />
      </LocalizationProvider>
    </div>
  );
}