import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import cn from "clsx";

const useStyles = makeStyles((theme) => ({
  healthLegend: {
    position: "absolute",
    height: "3rem",
    width: "21rem",
    padding: "0.7rem 1rem 0.5rem 1rem",
    backgroundColor: theme.palette.background.primary75opaque,
    borderRadius: "4px",
    boxSizing: "border-box",
    transition: "all 0.3s",
    bottom: "0px",
    left: "50%",
    transform: `translateX(calc(-50% + 12rem))`,
  },
  healthLegend_fullScreen: {
    left: "1rem",
  },
  text: { opacity: "0.87" },
  legendColors: {
    height: "2px",
    width: "100%",
    background: `linear-gradient(
      90deg,
      red,
      orange,
      yellow,
      green,
      turquoise,
      blue
    )`,
  },
}));

export const HealthLegend = ({ isVisible }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
      className={cn(
        classes.healthLegend,
        `health-legend`
      )}
    >

      <div className={`${classes.legendColors} health`} />
      <Grid
        container
        item
        justifyContent="space-between"
        className={classes.legendLabels}
      >
        <Typography variant="caption">Unhealthy</Typography>
        <Typography variant="body2" className={classes.text}>Crop Health</Typography>
        <Typography variant="caption">Healthy</Typography>
      </Grid>
    </Grid>
  );
};
